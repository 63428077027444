import React from 'react';
import {IAutomationSeasonInfo} from '@/types/event-automations-misc.types';

import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import MatchesFilterCompetitionSelector from './matches-filter-competition-selector.view';
import {MatchScore} from '@/components/common/match-score/MatchScore';
import {formatDate} from '@/helpers/general/general.helper';

interface IMatchesFilterSeasonProps {
  seasonInfo: IAutomationSeasonInfo;
  observingMatchIds: string[];
  onObservingMatchIdChange: (matchIds: string[]) => void;
}

function MatchesFilterSeason(props: IMatchesFilterSeasonProps): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Handlers
   */
  function _onMatchSelect(matchId: string) {
    const _newMatchIds = [...props.observingMatchIds];
    const _indexOfMatch = _newMatchIds.indexOf(matchId);

    if (_indexOfMatch > -1) {
      _newMatchIds.splice(_indexOfMatch, 1);
    } else {
      _newMatchIds.push(matchId);
    }

    props.onObservingMatchIdChange(_newMatchIds);
  }

  /*
   * Return
   */
  return (
    <List dense>
      <MatchesFilterCompetitionSelector
        competitions={props.seasonInfo.competitions}
        matches={props.seasonInfo.matches}
        seasonId={props.seasonInfo.seasonId}
        seasonLabel={props.seasonInfo.seasonLabel}
        observingMatchIds={props.observingMatchIds}
        onObservingMatchIdChange={props.onObservingMatchIdChange}
      />

      {props.seasonInfo.matches.map((match) => {
        const _isMatchChecked = props.observingMatchIds.includes(match._id);
        return (
          <ListItemButton
            key={match._id}
            sx={{pl: 1}}
            onClick={() => _onMatchSelect(match._id)}>
            <ListItemIcon>
              <Checkbox
                color="primary"
                checked={_isMatchChecked}
                onChange={() => _onMatchSelect(match._id)}
              />
            </ListItemIcon>
            <ListItemText
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
              primary={<MatchScore match={match} />}
              secondary={formatDate(match.date, 'DD/MM/YY')}
            />
          </ListItemButton>
        );
      })}
    </List>
  );
}

export default MatchesFilterSeason;
