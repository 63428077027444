import React from 'react';
import {ICommonSentenceProps} from '@/types/sentence-form.types';

import Segment from '../segment';

import {
  ACTION,
  FIConditionalEventFilters,
  FIEventAutomationPostData,
  FIEventAutomationSubject,
} from '@my-game-plan/types';
import Sentence from '../sentence.view';

interface ISentenceDetailsLineProps<DataType = FIEventAutomationPostData>
  extends ICommonSentenceProps<DataType> {
  value?: FIConditionalEventFilters;
  property: 'details' | 'success_details';
  action?: ACTION;
  count?: number;
  required?: boolean;
  observingPlayers?: FIEventAutomationSubject;
}

function SentenceDetailsLine<DataType = FIEventAutomationPostData>(
  props: ISentenceDetailsLineProps<DataType>,
): JSX.Element {
  /* Hooks n State */

  /* Handlers */

  /* Render */
  const _commonProps: ICommonSentenceProps<DataType> = {
    ...props,
  };

  const _isRatio = props.property === 'success_details';
  const _startKey =
    !_isRatio || !props.withTarget
      ? 'sentenceForm.details.thatAre'
      : 'sentenceForm.details.mustBe';

  const _detailsProperty: keyof Partial<FIEventAutomationPostData> = _isRatio
    ? 'success_details'
    : 'details';
  const _detailsPlaceholder = _isRatio
    ? 'sentenceForm.details.placeholder.successDetails'
    : 'sentenceForm.details.placeholder.details';

  let _error = undefined;
  if (props.errors && props.errors[props.property]) {
    _error = props.errors[props.property];
  }

  return (
    <>
      <Sentence.Segment>
        <Segment.Text
          {..._commonProps}
          translationKey={_startKey}
          translationKeyOptions={{count: props.count || 2}}
        />
      </Sentence.Segment>
      <Sentence.Segment>
        <Segment.Details
          {..._commonProps}
          required={props.required}
          property={_detailsProperty as keyof DataType}
          placeholder={_detailsPlaceholder}
          action={props.action}
          value={props.value}
          error={_error}
        />
      </Sentence.Segment>
    </>
  );
}

export default SentenceDetailsLine;
