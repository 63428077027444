import React from 'react';
import styles from './Divider.module.scss';

interface DividerProps {
  height?: number;
}

export const Divider = ({height = 24}: DividerProps) => {
  return (
    <div className={styles.divider} style={{margin: `${height}px 0px`}}></div>
  );
};
