import {
  FIPlayerSearchFilters,
  PlayerPosition,
  TPlayerPreferredFoot,
} from '@my-game-plan/types';

export function playerFiltersToURLParams(
  data: FIPlayerSearchFilters,
): Record<string, string | string[]> {
  const _params: Record<string, string | string[]> = {};

  Object.entries(data).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      const _newVal: string[] = [];

      value.forEach((v) => {
        if (typeof v === 'string') {
          _newVal.push(v);
        } else if (typeof v === 'number') {
          _newVal.push(v.toString());
        }
      });
      _params[key] = _newVal;
    } else if (typeof value === 'number') {
      _params[key] = value.toString();
    } else {
      _params[key] = value;
    }
  });

  return _params;
}

export function urlParamsToPlayerFilters(
  data: URLSearchParams,
): FIPlayerSearchFilters | null {
  const _filters: FIPlayerSearchFilters = {};

  const _name = data.get('name');
  if (_name) {
    _filters.name = _name;
  }
  const _countries = data.getAll('country');
  if (_countries.length) {
    _filters.country = _countries;
  }

  const _positions = data.getAll('position');
  if (_positions.length) {
    _filters.position = _positions as PlayerPosition[];
  }

  const _preferredFoot = data.getAll('preferred_foot');
  if (_preferredFoot.length) {
    _filters.preferred_foot = _preferredFoot as TPlayerPreferredFoot[];
  }

  const _ages = data.getAll('age');
  if (_ages.length) {
    _filters.age = _ages.map((age) => parseInt(age));
  }

  const _heights = data.getAll('height');
  if (_heights.length) {
    _filters.height = _heights.map((height) => parseInt(height));
  }

  const _weights = data.getAll('weight');
  if (_weights.length) {
    _filters.weight = _weights.map((weight) => parseInt(weight));
  }

  const _competitionIds = data.getAll('competition_id');
  if (_competitionIds.length) {
    _filters.competition_id = _competitionIds.map((id) => parseInt(id));
  }

  const _limit = data.get('limit');
  if (_limit) {
    _filters.limit = parseInt(_limit);
  }

  const _page = data.get('page');
  if (_page) {
    _filters.page = parseInt(_page);
  }

  if (!Object.keys(_filters).length) {
    return null;
  }

  return _filters;
}
