import ShotAnalysisBarChart from '@/components/shot-analysis/shot-analysis-bar-chart/shot-analysis-bar-chart.view';
import React, {useEffect, useState} from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import {useConfirm} from 'material-ui-confirm';
import {TShotAnalysisTeam, ShotCategory} from '@my-game-plan/types';
import {useShotAnalysis} from '@/context/shot-analysis.context';

import ShotAnalysisTable from '@/components/shot-analysis/shot-analysis-table/shot-analysis-table.view';
import {translateValue} from '@/helpers/translation.helper';
import {DEFAULT_MODAL_CONFIG} from '@/config/modal.config';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import {CenteredSpinner} from '@/components/common/centered-spinner/CenteredSpinner';
import {Card} from '@/components/common/card/Card';
import PitchVisual from '@/components/dashboard/pitch-visual/PitchVisual';
import CustomEmptyState from '@/components/error-states/CustomEmptyState';
import {useVideo} from '@/context/video/video.context';
import InsightDetailsPopup from '@/components/shot-analysis/insight/details/insight-details-popup.view';
import {useAuth} from '@/context/auth.context';
import InsightCard from '@/components/shot-analysis/insight/insight-card/insight-card.view';
import {useTeams} from '@/context/team.context';
import {REQUEST_ERRORS} from '@/config/errors.config';
import {useAnalytics} from '@/context/analytics.context';

interface ShotAnalysisViewProps {
  type: TShotAnalysisTeam;
}

function ShotAnalysisView(props: ShotAnalysisViewProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _shotAnalysisContext = useShotAnalysis();
  const {t} = useTranslation();
  const _confirm = useConfirm();
  const _videoContext = useVideo();
  const _authContext = useAuth();
  const _teamsContext = useTeams();
  const _analyticsContext = useAnalytics();
  const [_activeInsightIndex, _setActiveInsightindex] = useState<number>(0);

  /* Fetch analysis on load */
  useEffect(() => {
    _shotAnalysisContext.getShotAnalysis(props.type);

    return () => {
      _shotAnalysisContext.clear();
    };
  }, [props.type]);

  /*
   * Handlers
   */
  function _onPlayVideoClick(shotCategory?: ShotCategory) {
    let _events = _shotAnalysisContext.events;
    let _matches = _shotAnalysisContext.matches;
    if (shotCategory) {
      _events = _shotAnalysisContext.events.filter((event) => {
        return event.shot?.shot_category === shotCategory;
      });

      const _matchIdsInEvents = _events.map((event) => event.match._id);
      _matches = _matches.filter((match) =>
        _matchIdsInEvents.includes(match._id),
      );
    }
    _videoContext.openVideoPlayer(
      _events,
      _matches,
      false,
      true,
      {
        action: 'shot',
      },
      _events[0],
    );
  }

  function _onViewDetailsClick(shotCategory?: ShotCategory) {
    if (!_shotAnalysisContext.analysis || !_authContext.user) {
      return;
    }

    const _title = shotCategory
      ? capitalize(translateValue(shotCategory))
      : t('shotAnalysis.total');

    let _categoryData = _shotAnalysisContext.analysis?.total;
    if (shotCategory) {
      const _matchedCategory =
        _shotAnalysisContext.analysis?.shot_categories.find(
          (category) => category.shot_category === shotCategory,
        );
      if (_matchedCategory) {
        _categoryData = _matchedCategory;
      }
    }

    const _insight = _shotAnalysisContext.insights.find(
      (insight) => insight.shot_category === shotCategory,
    );

    _confirm({
      ...DEFAULT_MODAL_CONFIG,
      // title: _title,
      title: (
        <Stack direction="row" gap={1} alignItems="center">
          <Typography variant="h6">{_title}</Typography>
          {shotCategory && (
            <Tooltip title={t(`shotAnalysis.typeExplanation.${shotCategory}`)}>
              <IconButton color="secondary" size="small">
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Stack>
      ),
      content: (
        <InsightDetailsPopup
          team={props.type}
          benchmarkLabel={_shotAnalysisContext.benchmarkLabel}
          currentTeam={_teamsContext.ownTeam || undefined}
          teamId={_authContext.user.team}
          data={_categoryData || null}
          insight={_insight}
          shotCategory={shotCategory}
          onPlayVideo={_onPlayVideoClick}
          trackEvent={_analyticsContext.trackEvent}
        />
      ),
      dialogProps: {
        maxWidth: 'sm',
      },
    });
  }

  function _onInsightSelect(nextInsight: 'next' | 'previous' | number) {
    if (_shotAnalysisContext.insights) {
      let _nextIndex = 0;
      if (nextInsight === 'next') {
        _nextIndex =
          _activeInsightIndex === _shotAnalysisContext.insights.length - 1
            ? 0
            : _activeInsightIndex + 1;
      } else if (nextInsight === 'previous') {
        _nextIndex =
          _activeInsightIndex === 0
            ? _shotAnalysisContext.insights.length - 1
            : _activeInsightIndex - 1;
      } else if (typeof nextInsight === 'number') {
        _nextIndex = nextInsight;
      }

      _setActiveInsightindex(_nextIndex);
    }
  }

  /*
   * Render
   */

  if (_shotAnalysisContext.isLoadingAnalysis) {
    return <CenteredSpinner />;
  } else if (
    !_shotAnalysisContext.isLoadingAnalysis &&
    _shotAnalysisContext.error
  ) {
    const _errorKey =
      _shotAnalysisContext.error === REQUEST_ERRORS.NO_DATA
        ? REQUEST_ERRORS.NO_DATA
        : 'defaultError';
    return (
      <CustomEmptyState
        header={t(`error-states.${_errorKey}.header`)}
        description={t(`error-states.${_errorKey}.description`)}
        imageType={
          _shotAnalysisContext.error === REQUEST_ERRORS.NO_DATA
            ? 'default'
            : 'error'
        }
      />
    );
  }

  const _activeInsight = _shotAnalysisContext.insights[_activeInsightIndex];

  return (
    <Stack gap={6} py={2}>
      {/* Bar charts */}
      <Stack direction="row" gap={2}>
        <Stack direction="row" gap={6} flex={1}>
          <ShotAnalysisBarChart
            data={_shotAnalysisContext.analysis}
            team={props.type}
            result="shots"
            onCategoryClick={_onViewDetailsClick}
            activeInsightCategory={
              _activeInsight?.result === 'shots'
                ? _activeInsight?.shot_category || 'total'
                : undefined
            }
          />
          <ShotAnalysisBarChart
            data={_shotAnalysisContext.analysis}
            team={props.type}
            result="goals"
            onCategoryClick={_onViewDetailsClick}
            activeInsightCategory={
              _activeInsight?.result === 'goals'
                ? _activeInsight?.shot_category || 'total'
                : undefined
            }
          />
        </Stack>
        <InsightCard
          shotCategory={_activeInsight?.shot_category}
          insight={_activeInsight}
          onInsightChange={_onInsightSelect}
          onViewDetails={_onViewDetailsClick}
          onPlayVideo={_onPlayVideoClick}
        />
      </Stack>

      {/* Table + Pitch visual */}
      <Stack direction="row" gap={2}>
        <ShotAnalysisTable
          data={_shotAnalysisContext.analysis}
          onPlayVideo={_onPlayVideoClick}
          onViewDetails={_onViewDetailsClick}
          type={props.type}
        />
        <Card sx={{width: 400}}>
          {_shotAnalysisContext.isLoadingEvents && <CenteredSpinner />}
          {!_shotAnalysisContext.isLoadingEvents &&
            !_shotAnalysisContext.pitchVisualData && (
              <p>{t('eventAutomations.noData')}</p>
            )}
          {!_shotAnalysisContext.isLoadingEvents &&
            _shotAnalysisContext.pitchVisualData && (
              <PitchVisual
                activeTab={0}
                data={_shotAnalysisContext.pitchVisualData}
                action="shot"
                isOwnTeam={true}
                isObservingOpponent={props.type === 'conceded'}
                matches={_shotAnalysisContext.matches}
              />
            )}
        </Card>
      </Stack>
    </Stack>
  );
}

export default ShotAnalysisView;
