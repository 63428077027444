import React from 'react';

import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import {FIMatch} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';

import MatchVideosTableRow from './match-videos-table-row.view';

interface IMatchVideosTableProps {
  matches: FIMatch[];
}

function MatchVideosTable(props: IMatchVideosTableProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Side effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              '& .MuiTableCell-root': {
                py: 0,
                pb: 1,
              },
            }}>
            <TableCell>{t('matches.properties.date')}</TableCell>
            <TableCell>{t('matches.match')}</TableCell>
            {/* <TableCell align="center">
              {t('matches.properties.video.source.broadcast')}
            </TableCell> */}
            <TableCell align="center">
              {t('matches.properties.video.source.tactical')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.matches.map((match) => (
            <MatchVideosTableRow key={match._id} match={match} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default MatchVideosTable;
