import React from 'react';
import {ITypedOption} from '@/types/option.types';

import Pitch from '@/components/dashboard/pitch-visual/Pitch';
import {
  ACTION,
  FIConditionalEventFilter,
  FIFormattedAutomation,
  FIPitchZone,
  PitchVisualFocusType,
  PitchVisualType,
} from '@my-game-plan/types';

export function generateZoneOptions(
  zones: FIPitchZone[],
  action?: ACTION,
  isOwnTeam?: boolean,
  image_url?: string,
  isObservingOpponent?: boolean,
): ITypedOption<string>[] {
  const _filteredZones = zones.filter((zone) => {
    if (!action) {
      return zone.group !== 'actions';
    }

    return zone.group !== 'actions' || zone.actions?.includes(action);
  });
  const _options: ITypedOption<string>[] = _filteredZones.map((zone) => {
    return {
      value: zone._id,
      label: zone.name,
      name: zone.name,
      group: zone.group,
      Tooltip: (
        // <div style={{width: 200, height: 200, backgroundColor: 'red'}}></div>
        <Pitch
          type={PitchVisualType.SCATTER}
          eventGroups={[]}
          linkedTable=""
          action="zone_visit"
          defaultFocusType={PitchVisualFocusType.START}
          showPath={false}
          fullWidth
          zones={[zone]}
          isOwnTeam={isOwnTeam}
          teamLogo={image_url}
          isObservingOpponent={isObservingOpponent}
          displayDirectionIcon
        />
      ),
    };
  });

  return _options;
}

export function extractZonesFromAutomation(
  automation: FIFormattedAutomation | null,
): string[] {
  const _zones: string[] = [];
  if (!automation) return _zones;
  if (automation.pitch_zone) {
    _zones.push(automation.pitch_zone);
  }

  if (automation?.details?.filters) {
    automation.details.filters.forEach((filter: FIConditionalEventFilter) => {
      if (filter.property.includes('coordinates') && filter.values) {
        filter.values.forEach((zone) => {
          if (typeof zone === 'string') {
            _zones.push(zone);
          }
        });
      }
    });
  }

  if (automation?.success_details?.filters) {
    automation.success_details.filters.forEach(
      (filter: FIConditionalEventFilter) => {
        if (filter.property.includes('coordinates') && filter.values) {
          filter.values.forEach((zone) => {
            if (typeof zone === 'string') {
              _zones.push(zone);
            }
          });
        }
      },
    );
  }

  return _zones;
}
