import React, {useEffect, useState} from 'react';
import {
  FIBenchmarkTimeOptions,
  FICompactMatchInfo,
  FIMatch,
  formatSeason,
} from '@my-game-plan/types';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

import Popover from '@mui/material/Popover';
import {lighten, useTheme} from '@mui/material';

import {IAutomationSeasonInfo} from '@/types/event-automations-misc.types';
import {useCompetitions} from '@/context/competitions.context';
import MatchesFilterSeason from './matches-filter-season.view';
import TimeFilterPopup from '../time-filter/time-filter-popup.view';
import {timeRangeFiltersToText} from '@/helpers/event-automations.helper';
import {useAuth} from '@/context/auth.context';
import {capitalize} from 'lodash';

export interface IMatchFilterConfig {
  observingMatchIds: string[];
  onMatchSelect: (matchIds: string[]) => void;
  allMatches: FIMatch[] | FICompactMatchInfo[];
}

interface IMatchesFilterPopupProps {
  value?: FIBenchmarkTimeOptions;
  onDateRangeChange: (options: FIBenchmarkTimeOptions) => void;
  anchorEl?: Element | null;
  onClose: () => void;

  matchFiltersConfig: IMatchFilterConfig;
}

function MatchesFilterPopup(props: IMatchesFilterPopupProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _theme = useTheme();
  const _authContext = useAuth();
  const [_timeFilterButtonText, _setTimeFilterButtonText] =
    useState<string>('');
  const [_timeFilterAnchorEl, _setTimeFilterAnchorEl] =
    useState<null | HTMLElement>(null);
  const _competitionsContext = useCompetitions();
  // const [_visibeSeasons, _setVisibleSeasons] = React.useState<number[]>([]);
  const [_seasonsWithMatches, _setSeasonsWithMatches] = useState<
    IAutomationSeasonInfo[]
  >([]);

  /*
   * Side effects
   */
  // Define list of seasons included in matches
  useEffect(() => {
    if (!props.matchFiltersConfig?.allMatches?.length) {
      _setSeasonsWithMatches([]);
      return;
    }

    const _foundSeasonIds = Array.from(
      new Set(props.matchFiltersConfig?.allMatches.map((m) => m.season_id)),
    ).sort((a, b) => a - b);
    // _setVisibleSeasons(_foundSeasons || []);

    const _seasons: IAutomationSeasonInfo[] = _foundSeasonIds.map(
      (seasonId) => {
        const _matchesOfSeason =
          props.matchFiltersConfig?.allMatches.filter(
            (m) => m.season_id === seasonId,
          ) || [];

        const _uniqueCompetitionIdsInSeason = Array.from(
          new Set(_matchesOfSeason?.map((m) => m.competition_id)),
        );
        const _competitionsOfSeason = _competitionsContext.all.filter((c) =>
          _uniqueCompetitionIdsInSeason.includes(c._id),
        );

        const _season: IAutomationSeasonInfo = {
          seasonId: seasonId,
          seasonLabel: formatSeason(
            seasonId,
            _competitionsContext.displayMultiYear,
          ),
          competitions: _competitionsOfSeason,
          matches: _matchesOfSeason,
        };

        return _season;
      },
    );

    _setSeasonsWithMatches(_seasons);
  }, [
    props.matchFiltersConfig?.allMatches,
    _competitionsContext.all,
    _competitionsContext.displayMultiYear,
  ]);

  // Define time filter button text
  useEffect(() => {
    let _newText = 'period';
    const _seasonValueText = timeRangeFiltersToText(
      props.value,
      _authContext.user?.current_season_id,
      _competitionsContext.displayMultiYear,
    );
    if (props.value?.since_date) {
      _newText = `${_seasonValueText.prefix} ${_seasonValueText.value}`;
    } else if (_seasonValueText) {
      _newText = _seasonValueText.value;
    }

    _setTimeFilterButtonText(capitalize(_newText));
  }, [
    props.value,
    _authContext.user?.current_season_id,
    _competitionsContext.displayMultiYear,
  ]);

  /*
   * Handlers
   */
  function _onTimeFilterOpen(event: React.MouseEvent<HTMLElement>) {
    _setTimeFilterAnchorEl(event.currentTarget);
  }

  function _onTimeFilterClose() {
    _setTimeFilterAnchorEl(null);
  }

  function _onTimeFilterChange(options: FIBenchmarkTimeOptions) {
    props.onDateRangeChange(options);
    _onTimeFilterClose();
  }

  /*
   * Render
   */

  return (
    <Popover
      open={Boolean(props.anchorEl)}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
      slotProps={{
        paper: {
          sx: {
            bgcolor: lighten(_theme.palette.background.paper, 0.03),
            width: 280,
          },
        },
      }}>
      {/* High level time selector */}
      <List>
        <ListItemButton
          onClick={_onTimeFilterOpen}
          selected={Boolean(_timeFilterAnchorEl)}>
          <ListItemText primary={_timeFilterButtonText} />

          <ChevronRightIcon color="secondary" />
        </ListItemButton>
        <TimeFilterPopup
          anchorEl={_timeFilterAnchorEl}
          onClose={_onTimeFilterClose}
          onDateRangeChange={_onTimeFilterChange}
          anchorOrigin="right"
          value={props.value}
        />
      </List>

      <Divider />
      {/* Matches */}
      {_seasonsWithMatches.map((seasonWithMatches) => {
        return (
          <MatchesFilterSeason
            key={seasonWithMatches.seasonId}
            seasonInfo={seasonWithMatches}
            observingMatchIds={props.matchFiltersConfig?.observingMatchIds}
            onObservingMatchIdChange={props.matchFiltersConfig.onMatchSelect}
          />
        );
      })}
    </Popover>
  );
}

export default MatchesFilterPopup;
