import {IDashboardCategory} from '@/types/action.types';
import {ACTION} from '@my-game-plan/types';
import i18n from '@/localization';

export const ACTION_DASHBOARDS: ACTION[] = [
  'goal_kick',
  'start_of_play',
  'final_third_pass',
  'cross',
  'run',
  'shot_assist',
  'shot_pre_assist',
  'shot',
  'corner_kick',
  'wide_free_kick',
  'direct_free_kick',
  'penalty',
  'interception',
  'pressure_action',
  'opponent_final_third_pass',
  'opponent_cross',
  'opponent_shot_assist',
  'opponent_shot',
  'opponent_corner_kick',
  'opponent_wide_free_kick',
  'opponent_direct_free_kick',
  'opponent_penalty',
];

const _defendingPrefix = i18n.t('dashboard.categories.defending.label');
export const ACTION_DASHBOARD_CATEGORIES: IDashboardCategory[] = [
  {
    key: 'build-up',
    label: i18n.t('dashboard.categories.attacking.subcategories.build-up'),
    actions: ['goal_kick', 'start_of_play'],
  },
  {
    key: 'chance-creation',
    label: i18n.t(
      'dashboard.categories.attacking.subcategories.chance-creation',
    ),
    actions: [
      'final_third_pass',
      'cross',
      'run',
      'shot_assist',
      'shot_pre_assist',
      'shot',
    ],
  },
  {
    key: 'set-pieces',
    label: i18n.t('dashboard.categories.attacking.subcategories.set-pieces'),
    actions: ['corner_kick', 'wide_free_kick', 'direct_free_kick', 'penalty'],
  },
  {
    key: 'defending-build-up',
    label:
      i18n.t('dashboard.categories.attacking.subcategories.build-up') +
      ` (${_defendingPrefix})`,
    actions: ['interception', 'pressure_action'],
  },
  {
    key: 'defending-chance-creation',
    label:
      i18n.t('dashboard.categories.attacking.subcategories.chance-creation') +
      ` (${_defendingPrefix})`,
    actions: [
      'opponent_final_third_pass',
      'opponent_cross',
      'opponent_shot_assist',
      'opponent_shot',
    ],
  },
  {
    key: 'defending-set-pieces',
    label:
      i18n.t('dashboard.categories.attacking.subcategories.set-pieces') +
      ` (${_defendingPrefix})`,
    actions: [
      'opponent_corner_kick',
      'opponent_wide_free_kick',
      'opponent_direct_free_kick',
      'opponent_penalty',
    ],
  },
];
