import React from 'react';

import PlayerView from './player.view';

function TeamView(): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  return <PlayerView />;
}

export default TeamView;
