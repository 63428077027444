import React, {useEffect} from 'react';
import {CustomDashboardView} from './custom_dashboard.view';
import {NormalDashboardView} from './normal_dashboard.view';
import {useParams} from 'react-router-dom';
import {useOverviewDashboards} from '@/context/dashboard/overview-dashboards.context';
import {FiltersProvider} from '@/context/filters.context';
import {DashboardProvider} from '@/context/dashboard/dashboard.context';
import {DashboardScoresProvider} from '@/context/dashboard/dashboard-scores.context';
import Screen from '@/components/screen/screen.view';
import {Filter} from '@/components/dashboard/filter/Filter';

import Stack from '@mui/material/Stack';
import {EditOvervieDashboardButton} from '@/components/dashboard/overview-dashboards/edit-overview-dashboard-button/EditOverviewDashboardButton';
import {DashboardIndicator} from '@/components/dashboard/Indicator/dashboard-indicator/DashboardIndicator';
import DashboardTitle from '@/components/dashboard/dashboard-title/dashboard-title.view';

export const DashboardView = () => {
  /*
   * Hooks n State
   */
  const _params = useParams();
  const {isCustomDashboard, setSelectedDashboard, selectedDashboard} =
    useOverviewDashboards();

  /*
   * Side effects
   */
  // TODO should be in a better location. Something like a param Manager
  useEffect(() => {
    if (_params.dashboard) setSelectedDashboard(_params.dashboard);
  }, [_params.dashboard]);

  /*
   * Render
   */
  let _DashboardView = null;
  if (selectedDashboard && isCustomDashboard(selectedDashboard)) {
    _DashboardView = <CustomDashboardView />;
  } else if (selectedDashboard) {
    _DashboardView = <NormalDashboardView />;
  }

  let _HeaderRight = null;
  if (isCustomDashboard(selectedDashboard)) {
    _HeaderRight = (
      <Stack direction="row" alignItems="center" gap={2}>
        <Filter />
        <EditOvervieDashboardButton />
      </Stack>
    );
  } else {
    _HeaderRight = (
      <Stack direction="row" alignItems="center" gap={2}>
        <DashboardIndicator />
        <Filter />
      </Stack>
    );
  }

  return (
    <DashboardProvider>
      <FiltersProvider>
        <DashboardScoresProvider>
          <Screen
            coloredHeader
            HeaderContent={<DashboardTitle />}
            HeaderRightContent={_HeaderRight}>
            {_DashboardView}
          </Screen>
        </DashboardScoresProvider>
      </FiltersProvider>
    </DashboardProvider>
  );
};
