const DASHBOARDS_TRANSLATIONS = {
  deepDive: 'Deep dive',
  trackers: 'Performance trackers',
  opponent: 'Opponent',
  dataExplorer: {
    matches: 'Matches',
    formations: 'Formations',
    'time-blocks': 'Time blocks',
    players: 'Players',
    'game-position': 'Game position',
  },
  'player-statistics': {
    'no-data': 'No data',
    table: {
      'all-teams': 'All teams',
    },
  },
  filters: {
    title: 'Filter dashboard',
    apply: 'Apply filters',
    clear: 'Clear',
    filter: 'Filter',
  },
  pitchVisual: {
    types: {
      scatter: 'Scatter Plot',
      'scatter-goal': 'Scatter Plot',
      'scatter-half-pitch': 'Scatter Plot',
      heatmap: 'Heatmap',
      'heatmap-goal': 'Heatmap',
      'heatmap-half-pitch': 'Heatmap',
      dominance: 'Dominance',
    },
    focusTypes: {
      'start-end': 'Start-End Position',
      'start-focus': 'Start Position Focus',
      start: 'Start Position',
      'end-focus': 'End Position Focus',
      end: 'End Position',
    },
  },
  scores: {
    view: 'View dashboard scores',
    table: {
      label: 'Dashboard scores table',
      empty: 'No dashboard scores available',
      team: 'Team',
      matches: 'matches',
      match: 'match',
      score: 'Score',
      total: '# Of {{action}}',
      goals: '# Of goals',
      success: 'Successful',
      component: 'Component',
      weight: 'Weight',
    },
    buttons: {
      'see-details': 'See details',
    },
    information: {
      moreInfo: 'More info',
      title: 'Score explained',
      body: 'The dashboard score is calculated using the following components and their corresponding weight (combined being 100%).',
    },
    error: {
      noData: 'No scores to display',
    },
  },
  categories: {
    attacking: {
      label: 'Attacking',
      subcategories: {
        'build-up': 'Build up',
        'chance-creation': 'Chance creation',
        'set-pieces': 'Set pieces',
        runs: 'Runs',
      },
    },
    defending: {
      label: 'Defending',
      subcategories: {
        '-': '-',
        'build-up': 'Build up',
        'chance-creation': 'Chance creation',
        'set-pieces': 'Set pieces',
        other: 'Other',
      },
    },
    'offensive-transition': {
      label: 'Offensive transition',
      subcategories: {
        '-': '-',
      },
    },
    'defensive-transition': {
      label: 'Defensive transition',
      subcategories: {
        '-': '-',
      },
    },
  },
  view: 'View dashboard',
};

export default DASHBOARDS_TRANSLATIONS;
