import React from 'react';
import cn from 'classnames';

import {ICommonSentenceProps, TError} from '@/types/sentence-form.types';

import styles from './conditions.module.scss';
import Condition from './condition.view';
import Segment from '../segment';
import {OPERATOR_OPTIONS} from '@/config/event-automation-builder.config';
import AddConditionButton from './add-condition-button.view';

import Tooltip from '../tooltip/tooltip.view';
import {
  FIEventCondition,
  FIEventConditions,
  TEventConditionType,
  TEventObservingTeam,
  TQueryOperatorType,
} from '@my-game-plan/types';

function Conditions(props: ICommonSentenceProps): JSX.Element | null {
  /* Hooks n State */

  /* Handlers */
  function _onOperatorChange(value: TQueryOperatorType) {
    if (props.data.conditions && props.onChange) {
      props.onChange({
        conditions: {
          ...props.data.conditions,
          operator: value,
        },
      });
    }
  }

  function _onNewConditionTypeSelect(type: TEventConditionType) {
    const _defaultTeam: TEventObservingTeam =
      props.data?.observing_players?.team || 'own';
    const _newConditionsList = [...(props.data?.conditions?.conditions || [])];
    _newConditionsList.push({
      type: type,
      ...(type !== 'when' && {
        observing_players: {team: _defaultTeam},
        seconds: 5,
      }),
      details: {
        operator: 'and',
        filters: [],
      },
    });

    const _newConditions: FIEventConditions = {
      operator: props.data?.conditions?.operator || 'and',
      conditions: _newConditionsList,
    };

    if (props.onChange) {
      props.onChange({
        conditions: _newConditions,
      });
    }
  }

  function _onConditionChange(index: number, data: Partial<FIEventCondition>) {
    if (props.data?.conditions && props.onChange) {
      const _conditionsList: FIEventCondition[] = [
        ...(props.data.conditions.conditions || []),
      ];

      const _updatedCondition: FIEventCondition = {
        ..._conditionsList[index],
        ...data,
      };

      _conditionsList[index] = _updatedCondition;

      props.onChange({
        conditions: {
          ...props.data.conditions,
          conditions: _conditionsList,
        },
      });
    }
  }

  function _onConditionDelete(index: number) {
    if (props.onChange && props.data.conditions) {
      const _conditionsList: FIEventCondition[] = [
        ...(props.data.conditions?.conditions || []),
      ];
      _conditionsList.splice(index, 1);

      props.onChange({
        conditions: {
          ...props.data.conditions,
          conditions: _conditionsList,
        },
      });
    }
  }

  /* Render */
  if (!props.isValid && !props.data.conditions?.conditions.length) return null;

  const _containerClasses = cn(
    styles.container,
    styles[`container-${props.size}`],
    {
      [styles.containerReadonly]: props.readonly,
    },
  );

  const _shouldShowTooltip =
    (props.readonly &&
      props.size !== 'medium' &&
      props.data.conditions?.conditions &&
      props.data.conditions?.conditions?.length > 1) ||
    (props.readonly &&
      props.data.conditions?.conditions &&
      props.data.conditions?.conditions.length > 2);

  let _ConditionsList = null;
  if (props.data.conditions) {
    const _size = _shouldShowTooltip ? 'small' : props.size;
    const _conditionsClasses = cn(
      styles.conditions,
      styles[`conditions-${_size}`],
    );
    _ConditionsList = (
      <div className={_conditionsClasses}>
        {props.data.conditions.conditions.map((condition, index) => {
          let _errors = undefined;
          if (props.errors?.conditions && props.errors.conditions[index]) {
            _errors = props.errors.conditions[
              index
            ] as TError<FIEventCondition>;
          }
          let _AndOrLine = null;
          if (!props.readonly && index === 1) {
            _AndOrLine = (
              <div
                className={cn(styles.andOr, styles.andOrInput)}
                key={`operator-${index}`}>
                <Segment.Dropdown
                  options={OPERATOR_OPTIONS}
                  onChange={_onOperatorChange}
                  data={props.data}
                  size={_size}
                  value={props.data.conditions?.operator}
                />
              </div>
            );
          } else if (index > 0) {
            _AndOrLine = (
              <div
                className={cn(styles.andOr, styles.andOrText)}
                key={`operator-${index}`}>
                <Segment.Text
                  {...props}
                  size={_size}
                  type="operator"
                  translationKey={`sentenceForm.${props.data.conditions?.operator}`}></Segment.Text>
              </div>
            );
          }
          return (
            <div key={index}>
              {_AndOrLine}
              <Condition
                {...props}
                data={condition}
                action={props.data.action}
                size={_size}
                condition={condition}
                conditionIndex={index}
                key={`condition-${index}`}
                onChange={_onConditionChange}
                onDelete={_onConditionDelete}
                errors={_errors}
                observingTeam={props.data.observing_players?.team}
              />
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div className={_containerClasses}>
      {_shouldShowTooltip ? (
        <div>
          <Tooltip
            content={_ConditionsList}
            count={props.data.conditions?.conditions.length || 0}
            countSuffixKey={'sentenceForm.conditions.conditions'}
            size={props.size === 'xsmall' ? 'xsmall' : 'small'}
          />
        </div>
      ) : (
        _ConditionsList
      )}
      {props.isValid && (
        <AddConditionButton
          onChange={_onNewConditionTypeSelect}
          hasConditions={Boolean(props.data.conditions?.conditions.length)}
        />
      )}
    </div>
  );
}

export default Conditions;
