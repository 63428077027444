import {FIBenchmarkTimeOptions} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Button from '@mui/material/Button';
import Popover, {PopoverOrigin} from '@mui/material/Popover';
import Stack from '@mui/material/Stack';

import {lighten, useTheme} from '@mui/material';
import TimeFilterContent from '@/components/filter/time-filter-content.view';
import {useTranslation} from 'react-i18next';

interface ITimeFilterPopupProps {
  value?: FIBenchmarkTimeOptions;
  onDateRangeChange: (options: FIBenchmarkTimeOptions) => void;
  anchorEl?: Element | null;
  onClose: () => void;
  anchorOrigin?: 'bottom' | 'right';
}

function TimeFilterPopup(props: ITimeFilterPopupProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _theme = useTheme();
  const {t} = useTranslation();

  const [_data, _setData] = useState<FIBenchmarkTimeOptions | null>(null);

  /*
   * Side effects
   */
  useEffect(() => {
    _setData(props.value || null);
  }, [props.value]);

  /*
   * Handlers
   */
  function _onSubmit() {
    if (!_data) return;

    props.onDateRangeChange(_data);
    props.onClose();
  }

  function _onChange(data: FIBenchmarkTimeOptions) {
    _setData(data);
  }

  /*
   * Render
   */
  const _isOpen = Boolean(props.anchorEl);

  let _anchorOrigin: PopoverOrigin = {vertical: 'bottom', horizontal: 'left'};
  if (props.anchorOrigin === 'right') {
    _anchorOrigin = {
      vertical: 'top',
      horizontal: 'right',
    };
  }
  return (
    <Popover
      open={_isOpen}
      anchorEl={props.anchorEl}
      onClose={props.onClose}
      slotProps={{
        paper: {
          sx: {
            bgcolor: lighten(_theme.palette.background.paper, 0.03),
          },
        },
      }}
      elevation={4}
      anchorOrigin={_anchorOrigin}>
      <Stack p={3} gap={3} alignItems="flex-start">
        <TimeFilterContent onChange={_onChange} value={_data || undefined} />
        <Button onClick={_onSubmit} variant="contained" color="primary">
          {t('eventAutomations.filter.filterData')}
        </Button>
      </Stack>
    </Popover>
  );
}

export default TimeFilterPopup;
