import React, {useEffect, useState} from 'react';

import {ButtonProps, ButtonTypeMap} from '@mui/material/Button';

import AutomationExampleModal from '../examples/automation-examples-modal.view';
import {
  FIEventAutomationPostData,
  FIFormattedAutomation,
  TEventAutomationType,
} from '@my-game-plan/types';
import AutomationCreatorModal from '../automation-builder/automation-builder-modal/automation-builder-modal.view';
import {formattedAutomationToPostData} from '@/helpers/event-automations.helper';
import {
  AutomationFormProvider,
  useAutomationForm,
} from '@/context/event-automations/automations-form.context';

import {ExtendButtonBase} from '@mui/material';

interface IAutomationCreateButtonProps {
  data?: FIFormattedAutomation;
  type: TEventAutomationType;
  onClose?: () => void;
  onSave?: (savedAutomation: FIFormattedAutomation) => void;
  optionalActionInfo?: Partial<FIEventAutomationPostData>;
  Button?: React.ReactElement<ButtonProps, ExtendButtonBase<ButtonTypeMap>>;
}

function AutomationCreateButtonContent(props: IAutomationCreateButtonProps) {
  /*
   * Hooks n State
   */

  const [_isExamplesModalOpen, _setIsExamplesModalOpen] = useState(false);
  // const [_isBuilderModalOpen, _setIsBuilderModalOpen] = useState(false);
  const _automationFormContext = useAutomationForm();
  const [_data, _setData] = useState<
    Partial<FIEventAutomationPostData> | undefined
  >(undefined);

  /*
   * Format data
   */

  useEffect(() => {
    if (props.data) {
      let _newData: Partial<FIEventAutomationPostData> | undefined = undefined;
      if (props.data) {
        _newData = formattedAutomationToPostData(props.data);
      }

      _setData(_newData);
    }
  }, [props.data]);

  useEffect(() => {
    if (_automationFormContext.isOpened) {
      _automationFormContext.initForm(
        props.type,
        _data,
        props.optionalActionInfo,
      );
    }
  }, [
    _data,
    props.type,
    _automationFormContext.isOpened,
    props.optionalActionInfo,
  ]);

  /*
   * Handlers: Examples Modal
   */
  function _handleOpenExamplesModal() {
    _setIsExamplesModalOpen(true);
  }
  function _handleCloseExamplesModal() {
    _setIsExamplesModalOpen(false);
    _automationFormContext.clearForm(true);
  }

  /*
   * Handlers: Builder Modal
   */
  function _handleOpenBuilderModal() {
    if (props.onClose) {
      props.onClose();
    }
    _automationFormContext.setIsOpened(true);
  }
  function _handleCloseBuilderModal() {
    _automationFormContext.setIsOpened(false);
  }

  function _onExampleSubmit(data?: Partial<FIEventAutomationPostData>) {
    _setIsExamplesModalOpen(false);

    _setData(data);

    _handleOpenBuilderModal();
  }

  function _onButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
    if (props.optionalActionInfo || _data) {
      _handleOpenBuilderModal();
    } else {
      _handleOpenExamplesModal();
    }

    if (props.Button?.props.onClick) {
      props.Button.props.onClick(event);
    }
  }

  /*
   * Render
   */

  // const _onButtonClick =
  //   _data || props.optionalActionInfo
  //     ? _handleOpenBuilderModal
  //     : _handleOpenExamplesModal;
  return (
    <>
      {/* {_Button} */}
      {props.Button &&
        React.cloneElement(props.Button, {
          onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
            _onButtonClick(e),
        })}
      <AutomationExampleModal
        onClose={_handleCloseExamplesModal}
        open={_isExamplesModalOpen}
        onSubmit={_onExampleSubmit}
        type={props.type}
      />
      <AutomationCreatorModal
        onSave={props.onSave}
        type={props.type}
        isOpened={_automationFormContext.isOpened}
        isNewAutomation={!props.data}
        onClose={_handleCloseBuilderModal}
      />
    </>
  );
}
function AutomationCreateButton(props: IAutomationCreateButtonProps) {
  return (
    <AutomationFormProvider>
      <AutomationCreateButtonContent {...props} />
    </AutomationFormProvider>
  );
}
export default AutomationCreateButton;
