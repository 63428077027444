import React from 'react';
import {useTranslation} from 'react-i18next';
import {IFormationsWidgetData} from '@/types/dashboard/overview-dashboards.types';
import {CustomBarChart} from '../../../../common/custom-bar-chart/CustomBarChart';
import {Card} from '../../../../common/card/Card';
import {Spacer} from '../../../../common/spacer/Spacer';
import Indicator from '../../../Indicator/Indicator';
import {WidgetHeader} from '../widget-header/WidgetHeader';
import {WidgetSubtitle} from '../widget-subtitle/WidgetSubtitle';
import {
  getCurrentTeamScore,
  getMinMaxScores,
} from '@/helpers/dashboard/dashboard.helper';
import {useTeams} from '@/context/team.context';

interface IFormationsWidgetProps {
  data: IFormationsWidgetData;
  label: string;
}

export const FormationsWidget = ({data, label}: IFormationsWidgetProps) => {
  const {t} = useTranslation();
  const _teamsContext = useTeams();

  // Check
  if (!_teamsContext.current || !data) return null;

  // Data
  const {consistencyScores, formations} = data;
  const {min, max} = getMinMaxScores(consistencyScores);
  const value = getCurrentTeamScore(
    consistencyScores,
    _teamsContext.current._id,
  );

  return (
    <Card>
      <WidgetHeader label={label} />

      {Boolean(consistencyScores.length) && (
        <>
          <WidgetSubtitle
            text={t('overview-dashboards.widgets.formations.consistency')}
          />
          <Indicator
            value={value}
            min={min}
            max={max}
            teamId={_teamsContext.current._id}
            scores={consistencyScores}
            showInfo={true}
          />
        </>
      )}

      <Spacer />

      <WidgetSubtitle
        text={t('overview-dashboards.widgets.formations.time-in-formation')}
      />
      <div style={{marginTop: 16, marginBottom: -32}}>
        <CustomBarChart group={formations} />
      </div>
    </Card>
  );
};
