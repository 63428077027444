import Screen from '@/components/screen/screen.view';

import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import SearchDialog from '@/components/search/search-dialog.view';
import Stack from '@mui/material/Stack';
import {useScouting} from '@/context/scouting.context';

import SearchResultsGrid from '@/components/search/search-results-grid.view';
import {useLocation} from 'react-router-dom';
import {LOADING_STATE} from '@/types/screen.types';
import {urlParamsToPlayerFilters} from '@/helpers/scouting.helper';
import {
  DEFAULT_PLAYER_SEARCH_LIMIT,
  FIPlayer,
  FIPlayerWithPerformance,
} from '@my-game-plan/types';
import {useAuth} from '@/context/auth.context';

import {IPagination} from '@/types/api.types';
import {TScoutingViewType} from '@/types/scouting.types';
import ScoutingHeaderActions from '@/components/search/scouting-header-actions.view';
import PlayerBioCard from '@/components/player-detail/card/player-bio-card.view';
import PlayerPerformanceCard from '@/components/player-detail/card/player-performance-card.view';
import {ILinkState} from '@/types/navigation.types';
import {NAV_LEVEL_1_ROUTE} from '@/config/navigation.config';

interface IScoutingViewProps {
  viewType: TScoutingViewType;
}
function ScoutingView(props: IScoutingViewProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );

  const _authContext = useAuth();
  const _scoutingContext = useScouting();
  const [_players, _setPlayers] = useState<
    (FIPlayer | FIPlayerWithPerformance)[]
  >([]);
  const [_pagination, _setPagination] = useState<IPagination | null>(null);
  const _location = useLocation();
  const [_showingAmountOfResultsText, _setShowingAmountOfResultsText] =
    useState<string>('');
  const [_cardsBackLink, _setCardsBackLink] = useState<ILinkState | undefined>(
    undefined,
  );
  const [_overviewBackLink, _setOverviewBackLink] = useState<
    ILinkState | undefined
  >(undefined);
  const [_isSearchDialogOpen, _setIsSearchDialogOpen] =
    useState<boolean>(false);

  useEffect(() => {
    // Set back link

    async function _search() {
      let _newCardBacklink: ILinkState = {
        route: `${NAV_LEVEL_1_ROUTE.SCOUTING}${_location.search}`,
        label: t('scouting.title'),
      };

      let _newOverviewBacklink: ILinkState | undefined = undefined;

      if (!_authContext.user) {
        return;
      }

      const _searchFilters = urlParamsToPlayerFilters(
        new URLSearchParams(_location.search),
      );

      // Search players
      if (props.viewType === 'search') {
        _newOverviewBacklink = {
          label: t('scouting.title'),
          route: NAV_LEVEL_1_ROUTE.SCOUTING,
        };

        _newCardBacklink = {
          label: t('scouting.search'),
          route: `${NAV_LEVEL_1_ROUTE.SCOUTING}/search${_location.search}`,
        };

        if (_searchFilters) {
          try {
            if (_loadingState !== LOADING_STATE.INITING) {
              _setLoadingState(LOADING_STATE.LOADING);
            }

            const _response = await _scoutingContext.search(
              _searchFilters,
              'properties',
            );

            if (_response.pagination) {
              _setPagination(_response.pagination);
            }

            _setPlayers(_response.data || []);
            _setLoadingState(LOADING_STATE.SUCCESS);
          } catch (error) {
            _setLoadingState(LOADING_STATE.ERROR);
          }
        } else {
          _setLoadingState(LOADING_STATE.SUCCESS);
        }
      } else {
        try {
          // Set loaded state
          if (_loadingState !== LOADING_STATE.INITING) {
            _setLoadingState(LOADING_STATE.LOADING);
          }

          const _searchFilters = urlParamsToPlayerFilters(
            new URLSearchParams(_location.search),
          );
          const _scoutedPlayersResponse =
            await _scoutingContext.getScoutedPlayers(
              _searchFilters || undefined,
            );
          if (_scoutedPlayersResponse.pagination) {
            _setPagination(_scoutedPlayersResponse.pagination);
          }
          _setPlayers(
            _scoutedPlayersResponse.data?.players_with_performance || [],
          );
          _setLoadingState(LOADING_STATE.SUCCESS);
          // _setPlayers([]);
          // _setPagination(null);
        } catch (error) {
          _setLoadingState(LOADING_STATE.ERROR);
        }
      }

      _setOverviewBackLink(_newOverviewBacklink);
      _setCardsBackLink(_newCardBacklink);
    }

    _search();

    return () => {
      _setPlayers([]);
      _setPagination(null);
      _setLoadingState(LOADING_STATE.INITING);
    };
  }, [_location.search, _authContext.user, props.viewType, _location.hash]);

  // Set "showing x of y" text
  useEffect(() => {
    let _newText = '';
    if (_pagination?.total_results) {
      let _val = `${_pagination.total_results}`;
      if (_pagination.total_pages > 1) {
        // Get amount of visible elements based on current_page, total_results and total_pages
        // Get start and end index based on current_page and DEFAULT_PLAYER_SEARCH_LIMIT
        const _start =
          DEFAULT_PLAYER_SEARCH_LIMIT * (_pagination.current_page - 1);
        const _end = Math.min(
          _start + DEFAULT_PLAYER_SEARCH_LIMIT,
          _pagination.total_results,
        );

        _val = `${_start + 1}-${_end}`;
      }

      _newText = t('scouting.showingResults', {
        value: _val,
        count: _pagination.total_results,
      });
    }

    _setShowingAmountOfResultsText(_newText);
  }, [_pagination]);

  /*
   * Handlers
   */
  function _onSearchClick() {
    _setIsSearchDialogOpen(true);
  }

  function _onSearchDialogClose() {
    _setIsSearchDialogOpen(false);
  }

  /* Render */
  function _renderCard(player: FIPlayer | FIPlayerWithPerformance) {
    if (props.viewType === 'scouted-players') {
      return (
        <PlayerPerformanceCard player={player as FIPlayerWithPerformance} />
      );
    }

    return <PlayerBioCard player={player as FIPlayer} />;
  }

  const _Content = (
    <SearchResultsGrid
      viewType={props.viewType}
      onSearchModalOpen={_onSearchClick}
      backLink={_cardsBackLink}
      data={_players}
      pagination={_pagination}
      renderCard={_renderCard}
    />
  );

  const _isOnSearchPage = props.viewType === 'search';

  let _HeaderBottomContent = null;
  if (_showingAmountOfResultsText) {
    _HeaderBottomContent = (
      <Typography fontSize={12} color="text.secondary">
        {_showingAmountOfResultsText}
      </Typography>
    );
  }
  return (
    <Screen
      coloredHeader
      loadingState={_loadingState}
      title={_isOnSearchPage ? t('scouting.search') : t('scouting.title')}
      backLink={_overviewBackLink}
      HeaderBottomContent={_HeaderBottomContent}
      HeaderRightContent={
        <Stack direction="row" spacing={1}>
          <Button
            color="primary"
            variant="outlined"
            onClick={_onSearchClick}
            startIcon={<SearchIcon />}>
            {t('scouting.search')}
          </Button>
          <ScoutingHeaderActions />
        </Stack>
      }>
      {_Content}
      <SearchDialog open={_isSearchDialogOpen} onClose={_onSearchDialogClose} />
    </Screen>
  );
}

export default ScoutingView;
