import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ExamplesOverview from '@/components/automations/examples/automation-examples-content.view';
import {useTranslation} from 'react-i18next';
import {
  FIEventAutomationPostData,
  TEventAutomationType,
} from '@my-game-plan/types';

interface IAutomationExamplesModalProps {
  onClose: () => void;
  open: boolean;
  onSubmit: (data?: Partial<FIEventAutomationPostData>) => void;
  type: TEventAutomationType;
}

function AutomationExamplesModal(
  props: IAutomationExamplesModalProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Render
   */
  return (
    <Dialog
      open={props.open}
      maxWidth="md"
      fullWidth
      scroll="body"
      onClose={props.onClose}>
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        {t(
          `eventAutomations.${
            props.type === 'opponent-automation'
              ? 'opponentAutomations'
              : 'trackers'
          }New`,
        )}
        <IconButton
          onClick={props.onClose}
          title={t('general.cancel')}
          sx={{alignSelf: 'flex-end'}}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <ExamplesOverview type={props.type} onSubmit={props.onSubmit} />
      </DialogContent>
    </Dialog>
  );
}

export default AutomationExamplesModal;
