import {PropsWithChildren, useEffect} from 'react';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {
  FIPerformanceOverview,
  FIBenchmarkedPhysicalData,
  FITeam,
  FIShadowPlayerPostData,
  FIPlayerObjective,
  FIAutomationDataQueryParams,
  FIBenchmarkTimeOptions,
  FIBenchmarkOptions,
  FIPlayer,
  FIPlayerSummary,
} from '@my-game-plan/types';
import {useState} from 'react';

import {
  getPlayerOverview,
  getScoutedPlayerPerfornance,
  stopScoutingPlayer,
} from '@/controllers/players.controller';

import {LOADING_STATE} from '@/types/screen.types';

import {useCompetitions} from './competitions.context';
import {useTeams} from './team.context';

import {getTeamOverview} from '@/controllers/teams.controller';

import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import {useNavigate, useSearchParams} from 'react-router-dom';

import {getPlayerObjectives} from '@/controllers/player-objectives.controller';
import {useAnalytics} from './analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';
import {formatSearchParamsToQueryParams} from '@/helpers/automation/automation.helper';
import {serializeParams} from '@/helpers/api.helper';
import {NAV_LEVEL_1_ROUTE} from '@/config/navigation.config';

export interface PlayerDetailAPI {
  player: FIPlayer | null;
  playerSummary: FIPlayerSummary | null;
  playerLoadingState: LOADING_STATE;
  performanceStats: FIPerformanceOverview | null;

  isSettingMirrorInfo: boolean;
  mirrorringLoadingState: LOADING_STATE;
  stopScouting: (playerId?: string) => Promise<void>;

  hasPhysicalData: boolean;
  physicalData: FIBenchmarkedPhysicalData | null;

  objectives: FIPlayerObjective[];
  objectivesLoadingState: LOADING_STATE;
  fetchObjectives: () => Promise<void>;

  isTeamPage: boolean;
  team: FITeam | null;

  mirrorPlayer: (mirroringPlayerId?: string) => Promise<void>;

  benchmarkAndDateFilters: FIAutomationDataQueryParams | null;
  onDateFilterChange: (filters: FIBenchmarkTimeOptions) => Promise<void>;
  onBenchmarkFilterChange: (filters: FIBenchmarkOptions) => Promise<void>;
}

interface IProviderProps {
  playerId?: string;
}

const context = createCustomContext<PlayerDetailAPI>();
export const usePlayerDetail = createContextHook(context);

export function PlayerDetailProvider(props: PropsWithChildren<IProviderProps>) {
  /*
   * Init
   */
  const _analyticsContext = useAnalytics();

  const [_player, _setPlayer] = useState<FIPlayer | null>(null);
  const [_playerSummary, _setPlayerSummary] = useState<FIPlayerSummary | null>(
    null,
  );
  const [_playerLoadingState, _setPlayerLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );
  const [_team, _setTeam] = useState<FITeam | null>(null);
  const [_isTeamPage, _setIsTeamPage] = useState<boolean>(false);

  // Performance
  const [_performanceStats, _setPerformanceStats] =
    useState<FIPerformanceOverview | null>(null);

  const [_isSettingMirrorInfo, _setIsSettingMirrorInfo] =
    useState<boolean>(false);
  const [_mirroringLoadingState, _setMirroringLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);

  // Physical data
  const [_hasPhysicalData, _setHasPhysicalData] = useState<boolean>(false);

  const [_physicalData, _setPhysicalData] =
    useState<FIBenchmarkedPhysicalData | null>(null);

  // Objectives
  const [_objectives, _setObjectives] = useState<FIPlayerObjective[]>([]);
  const [_objectivesLoadingState, _setObjectivesLoadingState] =
    useState<LOADING_STATE>(LOADING_STATE.INITING);

  // Benchmark / date filters
  const [_benchmarkAndDateFilters, _setBenchmarkAndDateFilters] =
    useState<FIAutomationDataQueryParams | null>(null);
  const [_searchParams, _setSearchParams] = useSearchParams();

  //   const [_error, _setError] = useState<string>('');
  const {t} = useTranslation();
  const _teamContext = useTeams();
  const _navigate = useNavigate();
  const _competitionsContext = useCompetitions();
  const _snackbar = useSnackbar();

  /* Side effects */
  /* Fetch player info on page load */
  useEffect(() => {
    if (
      !_teamContext.ownTeam ||
      _playerLoadingState !== LOADING_STATE.INITING
    ) {
      return;
    }

    // Handle search params
    let _parsedQuery: FIAutomationDataQueryParams | undefined = undefined;
    if (_searchParams.toString()) {
      _parsedQuery = formatSearchParamsToQueryParams(_searchParams);
    }

    _fetchData(_parsedQuery);
  }, [
    props.playerId,
    _teamContext.ownTeam,
    _searchParams,
    _playerLoadingState,
  ]);

  /* PLAYER - Define whether physical data is available */
  useEffect(() => {
    if (
      !_teamContext.ownTeam ||
      !_competitionsContext.domesticCompetition ||
      !_playerSummary ||
      !_player
    ) {
      _setHasPhysicalData(false);
      return;
    }

    if (!_player.teams?.length) {
      return;
    }
    const _playerCompetition = _competitionsContext.getCompetitionForTeam(
      _player.teams[0]._id,
    );

    const _physicalProvider = _playerSummary.is_own_player
      ? _competitionsContext.domesticCompetition.physical_provider
      : _playerCompetition?.physical_provider;

    const _physicalDataCheck = !!_physicalProvider;
    _setHasPhysicalData(_physicalDataCheck);

    /* Fetch objectives */
    _fetchObjectives();
  }, [
    _player,
    _playerSummary,
    _teamContext.ownTeam,
    _competitionsContext.domesticCompetition,
  ]);

  /* TEAM - Define whether physical data is available */
  useEffect(() => {
    if (
      !_teamContext.ownTeam ||
      !_competitionsContext.domesticCompetition ||
      !_team
    ) {
      _setHasPhysicalData(false);
      return;
    }

    const _physicalDataCheck =
      !!_competitionsContext.domesticCompetition.physical_provider;
    _setHasPhysicalData(_physicalDataCheck);

    if (!_physicalDataCheck) {
      return;
    }
  }, [_team, _teamContext.ownTeam, _competitionsContext.domesticCompetition]);

  /* Set default benchmark filters */
  useEffect(() => {
    //
  }, [_player, _team, _isTeamPage]);

  /*
   * Handlers
   */
  async function _fetchData(
    filters?: FIAutomationDataQueryParams,
    shouldSetURLQuery = false,
  ) {
    if (!_teamContext.ownTeam) {
      return;
    }

    if (_playerLoadingState !== LOADING_STATE.INITING) {
      _setPlayerLoadingState(LOADING_STATE.LOADING);
    }

    _setIsTeamPage(!props.playerId);

    if (props.playerId) {
      try {
        /* Fetch player info */
        const _fetchedPlayerWithInfo = await getPlayerOverview(
          _teamContext.ownTeam._id,
          props.playerId,
          filters,
        );

        if (!_fetchedPlayerWithInfo.player_info) {
          _setPlayerLoadingState(LOADING_STATE.ERROR);
          return;
        }
        if (
          (!_teamContext.ownTeam ||
            !_fetchedPlayerWithInfo.player_summary?.is_own_player) &&
          _fetchedPlayerWithInfo.performance_overview.automations_count === 0
        ) {
          _setIsSettingMirrorInfo(true);
        }

        _setPlayer(_fetchedPlayerWithInfo.player_info);
        _setPlayerSummary(_fetchedPlayerWithInfo.player_summary || null);
        _setPerformanceStats(
          _fetchedPlayerWithInfo.performance_overview || null,
        );
        _setPhysicalData(_fetchedPlayerWithInfo.physical || null);
        _setPlayerLoadingState(LOADING_STATE.SUCCESS);
        _setBenchmarkAndDateFilters(_fetchedPlayerWithInfo.filters || null);

        if (shouldSetURLQuery) {
          _setSearchParams(serializeParams(_fetchedPlayerWithInfo.filters));
        }
      } catch (error) {
        _setPlayerLoadingState(LOADING_STATE.ERROR);
      }
    } else {
      const _teamInfo = await getTeamOverview(
        _teamContext.ownTeam._id,
        filters,
      );

      /* Set own team for now. FUTURE: Use params team id to fetch and set team */
      _setTeam(_teamContext.ownTeam);
      _setPerformanceStats(_teamInfo.performance_overview || null);
      _setPhysicalData(_teamInfo.physical || null);
      _setPlayerLoadingState(LOADING_STATE.SUCCESS);
      _setBenchmarkAndDateFilters(_teamInfo.filters || null);
      if (shouldSetURLQuery) {
        _setSearchParams(serializeParams(_teamInfo.filters));
      }
    }
  }
  async function _mirrorPlayer(mirroringPlayerId?: string) {
    if (!_teamContext.ownTeam || !_player) {
      return;
    }

    if (!mirroringPlayerId) {
      _setMirroringLoadingState(LOADING_STATE.INITING);
      _setIsSettingMirrorInfo(true);
      return;
    }

    try {
      _setMirroringLoadingState(LOADING_STATE.LOADING);
      _setIsSettingMirrorInfo(true);
      const _postData: FIShadowPlayerPostData = {
        team_id: _teamContext.ownTeam._id,
        observing_player_id: _player._id,
        mirroring_player_id: mirroringPlayerId,
        benchmarkOptions: {
          position: _player.position,
        },
      };

      _analyticsContext.trackEvent(
        ANALYTICS_EVENT.PLAYER_PAGE_MIRRORED_PERFORMANCE,
        {
          team_id: _teamContext.ownTeam._id,
          player_id: _player._id,
          mirroring_player_id: mirroringPlayerId,
        },
      );

      const _fetchedPerformanceStats = await getScoutedPlayerPerfornance(
        _postData,
      );

      if (_fetchedPerformanceStats.automations_count === 0) {
        _setMirroringLoadingState(LOADING_STATE.SUCCESS);
        _setIsSettingMirrorInfo(true);
      } else {
        /* Fetch player performance stats */
        _setPerformanceStats(_fetchedPerformanceStats);
        _setIsSettingMirrorInfo(false);

        _setMirroringLoadingState(LOADING_STATE.SUCCESS);
      }
    } catch (error) {
      _setMirroringLoadingState(LOADING_STATE.INITING);
      // _setMirroringLoadingState(LOADING_STATE.ERROR);
      _snackbar.enqueueSnackbar(
        t('players.performance.mirrorring.builder.error.header'),
        {variant: 'error'},
      );
    }
  }

  async function _stopScouting(playerId?: string) {
    if (!_player || !_teamContext.ownTeam) {
      return;
    }

    await stopScoutingPlayer(_teamContext.ownTeam._id, playerId || _player._id);
    _navigate(NAV_LEVEL_1_ROUTE.SCOUTING);
  }

  async function _fetchObjectives() {
    if (!_teamContext.ownTeam || !_player || !_playerSummary) {
      return;
    }

    if (!_playerSummary.is_own_player) {
      _setObjectivesLoadingState(LOADING_STATE.SUCCESS);
      return;
    }

    try {
      _setObjectivesLoadingState(LOADING_STATE.LOADING);
      const _fetchedObjectives = await getPlayerObjectives(
        _teamContext.ownTeam._id,
        _player._id,
      );

      _setObjectives(_fetchedObjectives);
      _setObjectivesLoadingState(LOADING_STATE.SUCCESS);
    } catch (error) {
      _setObjectivesLoadingState(LOADING_STATE.ERROR);
    }
  }

  async function _onDateFilterChange(filters: FIBenchmarkTimeOptions) {
    if (!_benchmarkAndDateFilters) {
      return;
    }
    const _filters: FIAutomationDataQueryParams = {
      ..._benchmarkAndDateFilters,
      own_data: {
        time: filters,
      },
    };

    _fetchData(_filters, true);
  }

  async function _onBenchmarkFilterChange(filters: FIBenchmarkOptions) {
    if (!_benchmarkAndDateFilters) {
      return;
    }
    const _filters: FIAutomationDataQueryParams = {
      ..._benchmarkAndDateFilters,
      benchmark: filters,
    };

    _fetchData(_filters, true);
  }

  /*
   * Return context
   */
  const _apiValue: PlayerDetailAPI = {
    player: _player,
    playerSummary: _playerSummary,
    playerLoadingState: _playerLoadingState,
    performanceStats: _performanceStats,

    isSettingMirrorInfo: _isSettingMirrorInfo,
    mirrorPlayer: _mirrorPlayer,
    mirrorringLoadingState: _mirroringLoadingState,
    stopScouting: _stopScouting,

    hasPhysicalData: _hasPhysicalData,
    physicalData: _physicalData,

    objectives: _objectives,
    objectivesLoadingState: _objectivesLoadingState,
    fetchObjectives: _fetchObjectives,

    isTeamPage: _isTeamPage,
    team: _team,

    benchmarkAndDateFilters: _benchmarkAndDateFilters,
    onDateFilterChange: _onDateFilterChange,
    onBenchmarkFilterChange: _onBenchmarkFilterChange,
  };

  return createProvider<PlayerDetailAPI>(context, props, _apiValue);
}
