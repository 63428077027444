import AutomationBaseline from './base-line/automation-base-line.view';
import ConditionBaseLine from './condition-base-line.view';
import SentenceDetailsLine from './details-line.view';
import SentenceOpponentAutomationMatchLimitLine from './opponent-automation-match-limit-line.view';
import SentenceOpponentAutomationShareLine from './opponent-automation-share-line.view';
import SentenceNotificationLine from './notification-line.view';
import SentenceTrackersBaseLineTrackingData from './base-line/automation-base-line-tracking-data.view';
import RuleConditionLine from './rule-condition-line.view';
import AutomationBaseLineSequence from './base-line/automation-base-line-sequence.view';

const SentenceLine = {
  AutomationBaseline: AutomationBaseline,
  AutomationBaselineTrackingData: SentenceTrackersBaseLineTrackingData,
  AutomationBaseLineSequence: AutomationBaseLineSequence,
  ConditionBase: ConditionBaseLine,
  Details: SentenceDetailsLine,
  OpponentAutomationsMatchLimit: SentenceOpponentAutomationMatchLimitLine,
  OpponentAutomationsShare: SentenceOpponentAutomationShareLine,
  Notify: SentenceNotificationLine,
  RuleCondition: RuleConditionLine,
};

export default SentenceLine;
