import React, {useEffect, useState} from 'react';

import Popover from '@mui/material/Popover';

import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import ObservingDropdownContent, {
  IObservingDropdownProps,
} from './observing-dropdown-content.view';

import Segment from '../..';
import {ITypedOption} from '@/types/option.types';
import {
  generateTransferredPlayerOption,
  getPlayerOptions,
  getPositionOptions,
  getSelectedPositionOptions,
  getSelectedValuesInSentence,
  isShareField,
} from '@/helpers/automation/automation-form.helper';

import dropdownStyles from '../dropdown.module.scss';
import {useTeams} from '@/context/team.context';
import {FIEventAutomationPostData} from '@my-game-plan/types';
import {TRANSFERED_PLAYER} from '@/config/event-automation-builder.config';
import ObservingDropdownOpponentSelector from './observing-dropdown-opponent-selector.view';
import {ISubSegment} from '@/types/sentence-form.types';
import Sentence from '../../../sentence.view';

const MAX_ITEMS_TO_SHOW = 5;

interface IDisplayValue {
  value: string;
  segments: ISubSegment[];
  selectedValues: string[];
  tooltipContent: string | null;
  tooltipCount: number;
}

function ObservingDropdown<DataType = FIEventAutomationPostData>(
  props: IObservingDropdownProps<DataType>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_anchorEl, _setAnchorEl] = useState<HTMLElement | null>(null);
  // const [_popupIsOpened, _setPopupIsOpen] = useState<boolean>(false);
  const [_hasOpenedNestedPopups, _setHasOpenedNestedPopups] =
    useState<boolean>(false);

  const [_playerOptions, _setPlayerOptions] = useState<ITypedOption<string>[]>(
    [],
  );
  const [_hasTransferredPlayer, _setHasTransferredPlayer] =
    useState<boolean>(false);

  const [_selectedPlayerOptions, _setSelectedPlayerOptions] = useState<
    ITypedOption<string>[]
  >([]);
  const [_positionOptions, _setPositionOptions] = useState<
    ITypedOption<string>[]
  >([]);

  const [_selectedPositionOptions, _setSelectedPositionOptions] = useState<
    ITypedOption<string>[]
  >([]);
  const [_valueToDisplay, _setValueToDisplay] = useState<IDisplayValue>({
    value: '',
    segments: [],
    selectedValues: [],
    tooltipContent: '',
    tooltipCount: 0,
  });

  const {t} = useTranslation();
  const _teamsContext = useTeams();

  /* Get Players options */
  useEffect(() => {
    if (props.additionalPlayerInfo?.length) {
      const _playerOptionsFromInfo: ITypedOption<string>[] =
        props.additionalPlayerInfo.map((player) => ({
          value: player._id,
          label: player.name,
          name: player.name,
        }));

      _setPlayerOptions(_playerOptionsFromInfo);
      return;
    }

    const _sortedPlayers = getPlayerOptions(_teamsContext.ownPlayers || []);

    _setPlayerOptions(_sortedPlayers);
  }, [_teamsContext.ownPlayers, props.additionalPlayerInfo]);

  /* Get position options */
  useEffect(() => {
    _setPositionOptions(getPositionOptions());
  }, []);

  /* Prefill player or position options from value property */
  useEffect(() => {
    /* Players */

    let _tempSelectedPlayerOptions: ITypedOption<string>[] = [];
    const _transferredPlayerOptions: ITypedOption<string>[] = [];
    if (_playerOptions.length && props.value?.players) {
      // Get selected options based on value prop
      _tempSelectedPlayerOptions = props.value.players.map((playerID) => {
        const _matchingPlayerFromOptions = _playerOptions.find(
          (player) => player.value === playerID,
        );

        // Player was found, set option
        if (_matchingPlayerFromOptions) {
          return _matchingPlayerFromOptions;
        }

        // Player was not found, add "transferred player" option to value
        const _transferredPlayerOption =
          generateTransferredPlayerOption(playerID);
        _transferredPlayerOptions.push(_transferredPlayerOption);

        return _transferredPlayerOption;
      });

      // Add "transferred player" option if necessary so user can uncheck
      if (_transferredPlayerOptions.length) {
        const _updatedPlayerOptions = [
          ..._playerOptions.filter(
            (option) => option.name !== TRANSFERED_PLAYER,
          ),
        ];
        _updatedPlayerOptions.unshift(..._transferredPlayerOptions);
        _setPlayerOptions(_updatedPlayerOptions);
      }

      const _shouldSetHasTransferredPlayer = Boolean(
        _tempSelectedPlayerOptions.filter(
          (option) => option.name === TRANSFERED_PLAYER,
        ).length,
      );
      _setHasTransferredPlayer(_shouldSetHasTransferredPlayer);
    }

    _setSelectedPlayerOptions(_tempSelectedPlayerOptions);

    /* Positions */
    let _tempSelectedPositionOptions: ITypedOption<string>[] = [];
    if (props.value?.positions) {
      _tempSelectedPositionOptions = getSelectedPositionOptions(
        props.value.positions,
        true,
      ) as ITypedOption<string>[];
    }
    _setSelectedPositionOptions(_tempSelectedPositionOptions);
  }, [props.value, _playerOptions]);

  useEffect(() => {
    const _maxItemsToShow = props.maxItemsToShow || MAX_ITEMS_TO_SHOW;
    const _team = props.value?.team || 'own';
    const _selectedOptionGroup = props.options.find(
      (group) => group.team === _team,
    );
    let _valueText = '';
    let _hiddenValuesCount = 0;
    let _tooltipContent: string | null = null;
    let _plusValuesLabel = '';
    // let _valuesToDisplay: string[] = ['none'];
    // let _valuesForTooltip: string[] = [];

    const _segments: ISubSegment[] = [];

    let _selectedOptions: ITypedOption<string>[] = [];
    if (_selectedPlayerOptions?.length) {
      _selectedOptions = _selectedPlayerOptions;
    } else if (_selectedPositionOptions?.length) {
      _selectedOptions = getSelectedPositionOptions(
        _selectedPositionOptions.map((option) => option.value),
        true,
        true,
      ) as ITypedOption<string>[];

      // Add prefix if necessary
      if (_selectedOptionGroup?.positionsPrefix) {
        _valueText += `${t(_selectedOptionGroup.positionsPrefix)} `;
      }
    }

    if (_selectedOptions?.length) {
      const _valuesToDisplay = _selectedOptions.slice(0, _maxItemsToShow);
      _valueText += _valuesToDisplay.map((option) => option.label).join(', ');

      if (_selectedOptions?.length > _maxItemsToShow) {
        _hiddenValuesCount = _selectedOptions.length - _maxItemsToShow;
        _plusValuesLabel = ` +${_hiddenValuesCount}`;
        _tooltipContent = getSelectedValuesInSentence(_selectedOptions);
      }
    } else {
      /* No selected players or positions */
      const _placeholder =
        _selectedOptionGroup?.placeholder || 'sentenceForm.observing.anyPlayer';

      if (!props.canSelectOpponent && !_selectedOptionGroup?.withSuffix) {
        _valueText = t(_placeholder);
      }

      // Hack - show "all players" for share field
      const _shouldDisplayAllPlayers =
        isShareField(props.property) && props.value?.team === 'own';
      if (_shouldDisplayAllPlayers) {
        _valueText = t('sentenceForm.players.all');
      }
    }

    /* Add "+X" after positions */
    if (!props.readonly) {
      _valueText += _plusValuesLabel;
    }

    /* Add POSITIONS or PLAYERS to sentence segments */
    if (_valueText) {
      _segments.push({
        type: 'value',
        text: _valueText,
        hiddenItemsCount: _hiddenValuesCount,
        tooltipContent: _tooltipContent || undefined,
        color: _hasTransferredPlayer ? 'warning' : undefined,
      });
    }

    if (_selectedOptionGroup?.withSuffix) {
      /* Add "of" word if not readonly and no positions are selected */
      if (_selectedOptions?.length) {
        _segments.push({
          type: 'default',
          text: t('sentenceForm.of'),
        });
      }

      /* Add "team playing" or other describer if necessary */
      if (_selectedOptionGroup?.suffixOpponentDescription) {
        _segments.push({
          type: 'value',
          text: t(_selectedOptionGroup.suffixOpponentDescription),
        });
      }

      /* "next opponent" or "next opponent" if we can't select opponent  */
      if (!props.canSelectOpponent && _selectedOptionGroup?.suffixTeamName) {
        _segments.push({
          type: 'value',
          text: t(_selectedOptionGroup.suffixTeamName),
        });
      } else if (props.canSelectOpponent) {
        // Add oppponent
        // TODO - Might be deleted
      }
    }

    /* When observing opponent team, add "team playing" */
    if (_selectedOptionGroup?.withSuffix && _selectedOptionGroup?.placeholder) {
      // if !props.canSelectOpponent
      // Push suffix ("opponent", "next opponent", "team playing next opponent") to segments
      // const _key =
      //   props.automationType === 'opponent-automation'
      //     ? 'nextOpponent'
      //     : 'entireTeam';
      // _segments.push({
      //   type: 'value',
      //   text: t(`sentenceForm.observing.${_key}`),
      // });
    }

    /* Add asterisk for required fields */
    if (!_selectedOptions?.length && props.required && !props.readonly) {
      _segments.push({
        type: 'default',
        text: '*',
      });
    }

    const _valueToSet: IDisplayValue = {
      value: _valueText,
      segments: _segments,
      tooltipContent: _tooltipContent,
      tooltipCount: _hiddenValuesCount,
      selectedValues: _selectedOptions.map((option) => option.label),
    };

    _setValueToDisplay(_valueToSet);
  }, [
    props.value,
    props.options,
    props.maxItemsToShow,
    props.canSelectOpponent,
    props.automationType,
    props.readonly,
    props.property,
    props.required,
    _hasTransferredPlayer,
    _selectedPlayerOptions,
    _selectedPositionOptions,
  ]);

  /*
   * Handlers
   */
  function _onPopupTrigger(event: React.MouseEvent<HTMLButtonElement>) {
    _setAnchorEl(event.currentTarget);
  }

  function _onPopoverClose() {
    _setAnchorEl(null);
  }

  function _onNestedPopupToggle(hasOpenPopup: boolean) {
    _setHasOpenedNestedPopups(hasOpenPopup);
  }

  /*
   * Render
   */

  const _isOpen = Boolean(_anchorEl);
  let _hasValue = false;

  if (
    props.requiredField &&
    (props.value?.players?.length || props.value?.positions?.length)
  ) {
    _hasValue = true;
  } else if (!props.requiredField && props.value) {
    _hasValue = true;
  }
  const _triggerItemClasses = cn(dropdownStyles.triggerItem, {
    [dropdownStyles.triggerItemWithValues]: _hasValue,
    [dropdownStyles.triggerItemOpened]: _isOpen,
    [dropdownStyles.triggerItemRequired]: props.required && !_hasValue,
    [dropdownStyles.triggerItemReadOnly]: props.readonly,
    [dropdownStyles.triggerItemError]: props.error,
  });

  let _translatedPlaceholder = t(
    props.placeholder || 'sentenceForm.observing.anyPlayer',
  );
  if (props.required) _translatedPlaceholder += '*';

  if (props.readonly) {
    return (
      <>
        {_valueToDisplay.segments.map((segment, index) => {
          return (
            <Sentence.Segment key={index}>
              <Segment.Text
                data={props.data}
                size={props.size}
                text={segment.text}
                type={segment.type}
                hiddenItemsCount={segment.hiddenItemsCount}
                tooltipContent={segment.tooltipContent}
              />
            </Sentence.Segment>
          );
        })}
        {props.canSelectOpponent && (
          <Sentence.Segment>
            <ObservingDropdownOpponentSelector {...props} />
          </Sentence.Segment>
        )}
      </>
    );
  }

  const _TriggerValue = (
    <span
      className={cn(
        _triggerItemClasses,
        dropdownStyles[`triggerItem-${props.size}`],
      )}>
      {_valueToDisplay.segments.length
        ? _valueToDisplay.segments.map((segment) => segment.text).join(' ')
        : _translatedPlaceholder}
    </span>
  );

  return (
    <Sentence.Segment>
      <button className={dropdownStyles.trigger} onClick={_onPopupTrigger}>
        {_TriggerValue}
      </button>
      <Popover
        open={_isOpen}
        // open
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        anchorEl={_anchorEl}
        PaperProps={{
          sx: {bgcolor: 'background.default', boxShadow: 4},
        }}
        onClose={_hasOpenedNestedPopups ? undefined : _onPopoverClose}>
        <ObservingDropdownContent
          hasOpenedPopup={_hasOpenedNestedPopups}
          onNestedPopupToggle={_onNestedPopupToggle}
          playerOptions={_playerOptions}
          selectedPlayerOptions={_selectedPlayerOptions}
          positionOptions={_positionOptions}
          selectedPositionOptions={_selectedPositionOptions}
          {...props}
        />
      </Popover>
    </Sentence.Segment>
  );
}

export default ObservingDropdown;
