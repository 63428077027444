import React, {MouseEvent, useEffect, useState} from 'react';
import {ICommonSentenceInputProps} from '@/types/sentence-form.types';
import {
  FIBenchmarkObservingOptions,
  FIBenchmarkOptions,
} from '@my-game-plan/types';

import dropdownStyles from '../dropdown/dropdown.module.scss';
import Popover from '@mui/material/Popover';

import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import BenchmarkSubjectPopupContent from './benchmark-subject-popup-content.view';
import {useCompetitions} from '@/context/competitions.context';

function hasObservingValue(value?: FIBenchmarkObservingOptions): boolean {
  return Boolean(
    value && (value.competition_id?.length || value.team_id?.length),
  );
}

function BenchmarkSubjectSegment(
  props: ICommonSentenceInputProps<
    FIBenchmarkObservingOptions,
    FIBenchmarkOptions
  >,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _competitionsContext = useCompetitions();
  const [_anchorEl, _setAnchorEl] = useState<HTMLElement | null>(null);
  const [_valueText, _setValueText] = useState<string>('league/team');

  /*
   * Side Effects
   */
  // Define value text to display in preview
  useEffect(() => {
    let _newValueText = t('eventAutomations.filter.observing.leagueOrTeams');
    if (props.placeholder) {
      _newValueText = props.placeholder;
    }

    if (props.required) {
      _newValueText += '*';
    }

    if (
      props.value?.competition_id?.length &&
      props.value?.competition_id.length === 1
    ) {
      // Single competition

      const _matchingCompetition = _competitionsContext.all.find(
        (competition) => props.value?.competition_id?.includes(competition._id),
      );
      if (_matchingCompetition) {
        _newValueText = _matchingCompetition.name;
      }

      // _newValueText = _matchingCompetitions
      //   .map((competition) => competition.name)
      //   .join(', ');
    } else if (props.value?.competition_id?.length) {
      // Multiple competitions
      _newValueText = t('eventAutomations.filter.observing.leagues', {
        count: props.value.competition_id.length,
      });
    } else if (props.value?.team_id?.length) {
      _newValueText = t('eventAutomations.filter.observing.teams', {
        count: props.value.team_id.length,
      });
    }

    _setValueText(_newValueText);
  }, [
    props.value,
    _competitionsContext.all,
    props.placeholder,
    props.required,
  ]);

  /*
   * Handlers
   */
  function _onPopupOpen(event: MouseEvent<HTMLElement>) {
    _setAnchorEl(event.currentTarget);
  }

  function _onChange(value: FIBenchmarkObservingOptions) {
    if (!props.onChange) {
      return;
    }

    props.onChange({
      [props.property]: value,
    });
  }
  function _onPopupClose() {
    _setAnchorEl(null);
  }

  /*
   * Render
   */
  const _isOpen = Boolean(_anchorEl);
  const _hasValue = hasObservingValue(props.value);
  const _triggerItemClasses = classNames(dropdownStyles.triggerItem, {
    [dropdownStyles.triggerItemOpened]: _isOpen,
    [dropdownStyles.triggerItemRequired]: !_hasValue && props.required,
    [dropdownStyles.triggerItemWithValues]: _hasValue,
    [dropdownStyles.triggerItemReadOnly]: props.readonly,
    [dropdownStyles.triggerItemError]: props.error,
  });
  return (
    <>
      <button className={dropdownStyles.trigger} onClick={_onPopupOpen}>
        <span
          className={classNames(
            _triggerItemClasses,
            dropdownStyles[`triggerItem-${props.size}`],
          )}>
          {_valueText}
        </span>
      </button>
      <Popover
        open={_isOpen}
        anchorEl={_anchorEl}
        onClose={_onPopupClose}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        slotProps={{
          paper: {sx: {bgcolor: 'background.default', boxShadow: 4}},
        }}>
        <BenchmarkSubjectPopupContent
          value={props.value}
          onChange={_onChange}
        />
      </Popover>
    </>
  );
}

export default BenchmarkSubjectSegment;
