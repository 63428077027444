export enum AUTH_STATE {
  NOT_LOGGED_IN = 'not-logged-in',
  LOADING = 'loading',
  LOGGED_IN = 'logged-in',
  RELOADING = 'reloading',
}

export interface ISwitchAdminUserTeamPostData {
  team_id: string;
  user_id: string;
}
