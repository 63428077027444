import {IExplorerGroup} from '@/types/dashboard/dashboard-explorer.types';
import {IDashboardScoreData} from '@/types/dashboard/dashboard-score.types';
import {IDashboard} from '@/types/dashboard/dashboard.types';

import {Resource} from '@/types/api.types';
import {DASHBOARD_API_CONFIG, SCORES_API_CONFIG} from '@/config/api.config';
import {useAPI} from '@/hooks/api.hooks';
import {toggleActionSnakeCase} from '@/helpers/translation.helper';
import {FIEventFilters} from '@my-game-plan/types';
import {REQUEST_ERRORS} from '@/config/errors.config';

const api = useAPI();

export const getDashboard = async (
  team: string,
  action: string,
  filters?: FIEventFilters,
): Promise<IDashboard | null> => {
  try {
    const _action = toggleActionSnakeCase(action);
    const {data} = await api.get<IDashboard>({
      resource: Resource.dashboards,
      url: DASHBOARD_API_CONFIG.getDashboard(team, _action),
      params: filters,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);

    return null;
  }
};

export const getDashboardDataExplorer = async (
  team: string,
  action: string,
  filters?: FIEventFilters,
): Promise<IExplorerGroup[] | null> => {
  try {
    const _action = toggleActionSnakeCase(action);
    const {data} = await api.get<IExplorerGroup[]>({
      resource: Resource.dashboards,
      url: DASHBOARD_API_CONFIG.getDashboardDataExplorer(team, _action),
      params: filters,
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    console.log(
      '[dashboard.controller] something went wrong trying to fetch the current dashboard data-explorer',
    );

    return null;
  }
};

export const getDashboardScores = async (
  team: string,
  action: string,
  matches_limit: number,
  ownTeam: string,
): Promise<IDashboardScoreData | null> => {
  try {
    const _action = toggleActionSnakeCase(action);
    const {data} = await api.get<IDashboardScoreData>({
      resource: Resource.scores,
      url: SCORES_API_CONFIG.getDashboardScores(team, _action),
      params: {
        match_limit: matches_limit,
        'own_team._id': ownTeam,
      },
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    return null;
  }
};
