import React from 'react';

import {useApp} from '@/context/app.context';

import HomeButton from './buttons/home-button.view';
import {SIDEBAR_NAVIGATION_ROUTES} from '@/config/navigation.config';

import List from '@mui/material/List';
import Stack from '@mui/material/Stack';
import SidebarButton from './buttons/sidebar-button.view';
import Divider from '@mui/material/Divider';
import UserButton from './buttons/user-button.view';

import {Drawer} from './sidebar.mixins';

function Sidebar(): JSX.Element {
  /*
   * Hooks n State
   */
  const _appContext = useApp();

  /*
   * Side Effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */

  return (
    <Drawer
      variant="permanent"
      // anchor="left"
      open={_appContext.navigation.isSidebarExpanded}>
      <Stack gap={3} flexGrow={1}>
        {/* MGP Logo - Home */}
        <HomeButton />

        <Stack flexGrow={1}>
          {/* Menus */}
          {SIDEBAR_NAVIGATION_ROUTES.map((menu, menuIndex) => {
            const _isMainMenu = menuIndex === 0;
            const _isLastMenu =
              menuIndex === SIDEBAR_NAVIGATION_ROUTES.length - 1;
            return (
              <List
                key={menu[0].path}
                sx={{flexGrow: _isMainMenu ? 1 : undefined}}>
                {menu.map((route) => {
                  return <SidebarButton route={route} key={route.path} />;
                })}
                {!_isMainMenu && !_isLastMenu && <Divider flexItem />}
              </List>
            );
          })}

          {/* User avatar */}
          <UserButton />
        </Stack>
      </Stack>
    </Drawer>
  );
}

export default Sidebar;
