import Button from '@mui/material/Button';

import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from './ErrorState.module.scss';

import CustomEmptyState from './CustomEmptyState';
import {LOCAL_STORAGE_USER_ID} from '@/config/api.config';

export const ForbiddenState = () => {
  const {t} = useTranslation();

  function _onLogoutPress() {
    const user = localStorage.getItem(LOCAL_STORAGE_USER_ID);
    if (user) {
      localStorage.removeItem(LOCAL_STORAGE_USER_ID);
    }
    window.location.reload();
  }
  return (
    <div className={styles.container}>
      <CustomEmptyState
        header={t('error-states.forbidden.header')}
        description={t('error-states.forbidden.description')}
        primaryAction={
          <Button variant="contained" onClick={_onLogoutPress}>
            {t('login.otherAccount')}
          </Button>
        }
        secondaryAction={
          <Button variant="text" href="/">
            {t('general.back')}
          </Button>
        }
      />
    </div>
  );
};
