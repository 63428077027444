import React from 'react';

import Dialog from '@mui/material/Dialog';
import ReactPlayer from 'react-player';

interface IBareVideoPlayerProps {
  isOpened: boolean;
  onClose: () => void;
  videoUrl: string;
  forceHLS?: boolean;
}

const VIDEO_WIDTH = '100%';
const VIDEO_HEIGHT = '100%';
const VIDEO_STRETCH = {width: '100%', height: '100%', objectFit: 'fit'};

function BareVideoPlayerView(props: IBareVideoPlayerProps): JSX.Element {
  /*
   * Hooks n State
   */
  const [_isPlaying, _setIsPlaying] = React.useState<boolean>(false);
  const VIDEO_CONFIG = {
    file: {
      attributes: {
        style: VIDEO_STRETCH,
      },
      forceHLS: props.forceHLS,
      hlsVersion: '1.4.12',
    },
  };

  /*
   * Handlers
   */
  function _onVideoReady() {
    _setIsPlaying(true);
  }

  /*
   * Render
   */

  return (
    <Dialog
      open={props.isOpened}
      onClose={props.onClose}
      maxWidth={false}
      fullWidth>
      <ReactPlayer
        url={props.videoUrl}
        width={VIDEO_WIDTH}
        height={VIDEO_HEIGHT}
        config={VIDEO_CONFIG}
        controls
        muted
        onReady={() => _onVideoReady()}
        playing={_isPlaying}
      />
    </Dialog>
  );
}

export default BareVideoPlayerView;
