import {AUTH_STATE} from '@/types/user/user.types';
import {Navigate, Route, Routes} from 'react-router-dom';
import {CreateOverviewDashboardModal} from '@/components/dashboard/overview-dashboards/overview-dashboard-modal/OverviewDashboardModal';

import LoginRoute from './login.routes';
import React from 'react';
import {DashboardRoutes} from './dashboard/dashboard.routes';

import {useAuth} from '@/context/auth.context';

import VideoPlayerModal from '@/components/video/events-video-player/video-player-modal.view';

import {PreferencesView} from '@/views/preferences.view';
import ShotAnalysisRoutes from './shot-analysis.routes';
import PlayersRoutes from './players.routes';

import ScoutingRoutes from './scouting.routes';

import Box from '@mui/material/Box';
import Sidebar from '@/components/navigation/sidebar/sidebar.view';
import {AUTH_ROUTES, NAV_LEVEL_1_ROUTE} from '@/config/navigation.config';
import {OpponentAutomationRoutes} from './opponent-automations.routes';
import TrackersView from '@/views/automations/trackers.view';
import LibraryView from '@/views/library.view';
import TeamRoutes from './team.routes';

export const Navigation = () => {
  /*
   * Hooks n State
   */
  const _auth = useAuth();

  /*
   * NOT LOGGED IN
   */
  if (_auth.authState !== AUTH_STATE.LOGGED_IN)
    return (
      <Routes>
        <Route path={AUTH_ROUTES.LOGIN} element={<LoginRoute />} />
        <Route
          path="*"
          element={<Navigate to={AUTH_ROUTES.LOGIN}></Navigate>}
        />
      </Routes>
    );

  /*
   * LOGGED IN
   */

  return (
    <>
      <CreateOverviewDashboardModal />
      <VideoPlayerModal />

      <Box position="relative" display="flex">
        <Sidebar />
        <Box flexGrow={1}>
          <Routes>
            <Route path={NAV_LEVEL_1_ROUTE.HOME} element={<TrackersView />} />
            <Route
              path={`${NAV_LEVEL_1_ROUTE.DASHBOARDS}/*`}
              element={<DashboardRoutes />}
            />

            <Route
              path={`${NAV_LEVEL_1_ROUTE.OPPONENT_AUTOMATIONS}/*`}
              element={<OpponentAutomationRoutes />}
            />
            <Route
              path={`${NAV_LEVEL_1_ROUTE.SHOTS}/*`}
              element={<ShotAnalysisRoutes />}
            />

            {/* TODO - Remove trackers routes */}

            <Route
              path={`${NAV_LEVEL_1_ROUTE.PREFERENCES}/*`}
              element={<PreferencesView />}
            />

            <Route
              path={`${NAV_LEVEL_1_ROUTE.PLAYERS}/*`}
              element={<PlayersRoutes />}
            />
            <Route
              path={`${NAV_LEVEL_1_ROUTE.TEAM}/*`}
              element={<TeamRoutes />}
            />
            <Route
              path={`${NAV_LEVEL_1_ROUTE.SCOUTING}/*`}
              element={<ScoutingRoutes />}
            />

            <Route
              path={`${NAV_LEVEL_1_ROUTE.LIBRARY}/*`}
              element={<LibraryView />}
            />
            {/* Default - Redirect to home */}
            <Route
              path="*"
              element={<Navigate to={NAV_LEVEL_1_ROUTE.HOME} replace />}
            />
          </Routes>
        </Box>
      </Box>
    </>
  );
};
