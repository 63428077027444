import {noop} from 'lodash';
import React, {PropsWithChildren, useEffect} from 'react';
import {useParams, useSearchParams} from 'react-router-dom';

import {IFilter, IFilters, TFilterField} from '@/types/filter.types';

import {formatDate} from '@/helpers/general/general.helper';
import {useTeams} from '@/context/team.context';
import {useOverviewDashboards} from '@/context/dashboard/overview-dashboards.context';
import {useMatches} from '@/context/matches.context';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {useDashboard} from '@/context/dashboard/dashboard.context';
import {FIMatch, FIPlayer} from '@my-game-plan/types';

export const possibleFilters: IFilter = {
  'match._id': {
    label: 'Matches',
    options: [],
  },
  time_block: {
    label: "Match 15'",
    options: [
      {label: 'Select all', value: '*'},
      {label: "0'-15'", value: '0'},
      {label: "15'-30'", value: '1'},
      {label: "30'-45'", value: '2'},
      {label: "45'-60'", value: '3'},
      {label: "60'-75'", value: '4'},
      {label: "75'-90'", value: '5'},
    ],
  },
  position_in_match: {
    label: 'Position in Match',
    options: [
      {label: 'Select all', value: '*'},
      {label: 'Winning', value: 'winning'},
      {label: 'Drawing', value: 'drawing'},
      {label: 'Losing', value: 'losing'},
    ],
  },
  ['player._id']: {
    label: 'Club Players',
    options: [],
  },
  ['team.formation']: {
    label: 'Formation',
    options: [],
  },
  ['opponent_team.defence']: {
    label: 'Against Defence',
    options: [],
  },
};

interface IFilterContext {
  isLoading: boolean;
  filters: IFilters;
  possibleFilters: IFilter;
  updateFilters: (
    category: string,
    value: string,
    resetCategory?: boolean,
  ) => void;
  clearFiltersByKey: (category: string) => void;
  clearFilters: (reload?: boolean) => void;
  submitFilters: (event: React.MouseEvent<Element, MouseEvent>) => void;
  isFiltersSidebarOpened: boolean;
  toggleFiltersSidebar: () => void;
  filteredMatches: FIMatch[];
}

// TODO should be remove
const FilterContextDefaultState: IFilterContext = {
  isLoading: true,
  filters: {
    'match._id': [],
    time_block: [],
    position_in_match: [],
    'player._id': [],
    'team.formation': [],
    'opponent_team.defence': [],
  },
  possibleFilters,
  updateFilters: noop,
  clearFiltersByKey: noop,
  clearFilters: noop,
  submitFilters: noop,
  isFiltersSidebarOpened: false,
  toggleFiltersSidebar: noop,
  filteredMatches: [],
};

const context = createCustomContext<IFilterContext>();
export const useFilters = createContextHook(context);

export const FiltersProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  const [_isLoading, _setIsLoading] = React.useState(true);
  const [_filters, _setFilters] = React.useState(
    FilterContextDefaultState.filters,
  );
  const [_possibleFilters, _setPossibleFilters] =
    React.useState<IFilter>(possibleFilters);
  const [_isOpen, _setIsOpen] = React.useState<boolean>(false);
  const _matches = useMatches();
  const _teamsContext = useTeams();
  const _dashboard = useDashboard();
  const _overviewDashboard = useOverviewDashboards();
  const _params = useParams();
  const [_searchParams, _setSearchParams] = useSearchParams();
  const [_initialised, _setInitialised] = React.useState<boolean>(false);
  const [_filteredMatches, _setFilteredMatches] = React.useState<FIMatch[]>([]);

  const {isCustomDashboard} = useOverviewDashboards();

  /* Side effects */
  useEffect(() => {
    if (
      _teamsContext.players.length &&
      _matches.all.length &&
      _teamsContext.formations &&
      !_initialised
    ) {
      // Players
      const _newPossibleFilters: IFilter = {...possibleFilters};
      const playersOptions = _teamsContext.players.map((player: FIPlayer) => ({
        label: player.display_name,
        value: player._id.toString(),
      }));
      playersOptions.unshift({label: 'All players', value: '*'});
      _newPossibleFilters['player._id'].options = playersOptions;

      // Matches
      const matchesOptions = _matches.all
        .filter((match) => match.event_data_available)
        .map((match: FIMatch) => ({
          label: formatDate(match.date, 'DD/MM/YYYY'),
          value: match._id.toString(),
        }));
      matchesOptions.unshift({label: 'All matches', value: '*'});
      _newPossibleFilters['match._id'].options = matchesOptions;

      // Select 10 first matches
      const matchIds = matchesOptions
        .slice(1, 11)
        .map((option) => option.value);

      // Own formations
      const ownFormationsOptions = _teamsContext.formations.own.map(
        (formation) => ({
          label: formation.label,
          value: formation.label,
          sublabel: formation.minutes_played,
        }),
      );
      ownFormationsOptions.unshift({
        label: 'All formations',
        value: '*',
        sublabel: _teamsContext.formations.own.reduce(
          (acc, b) => acc + b.minutes_played,
          0,
        ),
      });
      _newPossibleFilters['team.formation'].options = ownFormationsOptions;

      // Against defences
      const againstDefenceOptions =
        _teamsContext.formations.against_defence.map((defence) => ({
          label: defence.label,
          value: defence.label,
          sublabel: defence.minutes_played,
        }));
      againstDefenceOptions.unshift({
        label: 'All defenses',
        value: '*',
        sublabel: _teamsContext.formations.against_defence.reduce(
          (acc, b) => acc + b.minutes_played,
          0,
        ),
      });
      _newPossibleFilters['opponent_team.defence'].options =
        againstDefenceOptions;

      _setPossibleFilters(_newPossibleFilters);

      // _setIsLoading(false);

      // Check if filters are present in query params
      const _tempFilters = {...FilterContextDefaultState.filters};
      Object.keys(_filters).forEach((filterKey) => {
        const _key = filterKey as TFilterField;
        const _filterFromQuery = _searchParams.getAll(filterKey);
        _tempFilters[_key] = _filterFromQuery;
      });

      // If no match id filter is set, set default matches filter
      if (!_tempFilters['match._id'].length) {
        _tempFilters['match._id'] = matchIds;
      }
      _setFilters(_tempFilters);
      _setInitialised(true);
    }
  }, [
    _teamsContext.players,
    _matches.all,
    _teamsContext.formations,
    _initialised,
    _searchParams,
  ]);

  /* Team changed, set inititialised: false */
  useEffect(() => {
    if (_initialised) {
      _setFilters(FilterContextDefaultState.filters);
      _setInitialised(false);
    }
    _setIsLoading(true);
  }, [_teamsContext.current, _matches.all]);

  /* Initialized */
  useEffect(() => {
    if (_initialised) {
      getDashboardHandler(undefined, true);
    }
  }, [_initialised, _params.dashboard]);

  useEffect(() => {
    if (!_filters['match._id'].length) {
      _setFilteredMatches(_matches.all);
    } else {
      const _matchesFromFilters = _matches.all.filter((match) =>
        new Set(_filters['match._id']).has(match._id),
      );
      _setFilteredMatches(_matchesFromFilters);
    }
  }, [_matches.all, _filters]);

  /* Handlers */
  const getDashboardHandler = React.useCallback(
    (filters?: IFilters, initialLoad = false) => {
      const _filtersToUse = filters || _filters;

      _hideSidebar();

      if (_initialised) {
        _setIsLoading(true);
        _setSearchParams(_filtersToUse);

        if (_params.dashboard && !isCustomDashboard(_params.dashboard))
          _dashboard.getDashboard(_filtersToUse, initialLoad); // Dashboard
        else {
          // Overview dashboard
          const overviewDashboardId = _params.dashboard;
          if (overviewDashboardId)
            _overviewDashboard.getCurrent(
              overviewDashboardId,
              _filtersToUse,
              initialLoad,
            );
        }

        _setIsLoading(false);
      }
    },
    [_filters, _params.dashboard, _initialised],
  );
  const updateFilters = (
    category: string,
    value: string,
    resetCategory = false,
  ) => {
    const _category = category as TFilterField;
    const _filtersTemp = {..._filters};

    if (resetCategory) _filtersTemp[_category] = [];

    // Check if value is a "Select all" option
    if (value === '*') {
      _filtersTemp[_category] = [];

      _setFilters(_filtersTemp);
      return;
    }

    // Add filter
    if (!_filtersTemp[_category].includes(value)) {
      _filtersTemp[_category].push(value);
      _setFilters(_filtersTemp);
      return;
    }

    // Remove filter
    else {
      // Remove value from array
      _filtersTemp[_category] = _filtersTemp[_category].filter(
        (filter: string) => filter !== value && filter !== '*',
      );
      _setFilters(_filtersTemp);
    }
  };

  const clearFiltersByKey = (category: string) => {
    const _filtersTemp = {..._filters};

    _filtersTemp[category as TFilterField] = [];

    // _setInitialised(true);
    _setFilters(_filtersTemp);
    getDashboardHandler(_filtersTemp);
  };

  const clearFilters = () => {
    const _filtersTemp = {..._filters};

    Object.keys(_filtersTemp).forEach((category: string) => {
      _filtersTemp[category as TFilterField] = [];
    });

    // _setInitialised(true);
    _setFilters(_filtersTemp);
    getDashboardHandler(_filtersTemp);
  };

  const submitFilters = (event?: React.MouseEvent<Element, MouseEvent>) => {
    event?.preventDefault();
    getDashboardHandler(_filters);
  };

  const _hideSidebar = () => {
    _setIsOpen(false);
  };

  const _toggleSidebar = () => {
    _setIsOpen(!_isOpen);
  };

  return createProvider(context, props, {
    isLoading: _isLoading,
    filters: _filters,
    possibleFilters: _possibleFilters,
    updateFilters: updateFilters,
    clearFiltersByKey: clearFiltersByKey,
    clearFilters: clearFilters,
    submitFilters: submitFilters,
    isFiltersSidebarOpened: _isOpen,
    toggleFiltersSidebar: _toggleSidebar,
    filteredMatches: _filteredMatches,
  });
};
