import React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';

import {useTranslation} from 'react-i18next';
import AutomationBuilderForm from '../automation-builder-form/automation-builder-form.view';

import {DialogActions} from '@mui/material';
import {useConfirm} from 'material-ui-confirm';
import {ModalDeleteButton} from '@/components/common/modal/modal-delete-button/modal-delete-button.view';
import {FIFormattedAutomation, TEventAutomationType} from '@my-game-plan/types';
import {useAutomationForm} from '@/context/event-automations/automations-form.context';
import {translateAutomationType} from '@/helpers/automation/automation-form.helper';
import AutomationShareCheckbox from '../automation-builder-form/automation-share-checkbox/automation-share-checkbox.view';

interface IAutomationCreatorModalProps {
  isOpened: boolean;
  onClose: () => void;
  isNewAutomation: boolean;
  type: TEventAutomationType;
  onSave?: (automation: FIFormattedAutomation) => void;
}

function AutomationCreatorModal(
  props: IAutomationCreatorModalProps,
): JSX.Element {
  /* Hooks n State */

  const {t} = useTranslation();
  const _automationForm = useAutomationForm();
  const _confirm = useConfirm();

  const _typeTranslation = translateAutomationType(props.type, 1, true, true);

  /* Handlers */
  async function _onClose() {
    if (_automationForm.changeCount > 0) {
      // Show modal
      try {
        await _confirm({
          confirmationText: t('general.discard'),
          title: t('eventAutomations.create.cancelWarning.title'),
          description: t('eventAutomations.create.cancelWarning.text'),
        });
        _onCloseConfirm();
      } catch (error) {
        // User closed modal
      }
    } else {
      _onCloseConfirm();
    }
  }

  function _onCloseConfirm() {
    _automationForm.resetChangeCount();
    props.onClose();
  }

  async function _onSave() {
    const _savedAutomation = await _automationForm.saveAutomation();
    if (_savedAutomation && props.onSave) {
      props.onSave(_savedAutomation);
    }
  }

  async function _onDeleteClick() {
    if (_automationForm.data._id) {
      await _automationForm.deleteAutomation(_automationForm.data._id);
    }
  }

  function _onModalExit() {
    _automationForm.clearForm(true);
  }

  /* Render */

  const _saveKey = props.isNewAutomation ? 'create' : 'edit';
  const _btnSaveLabel = t(`eventAutomations.${_saveKey}.save`, {
    type: _typeTranslation,
  });

  return (
    <Dialog
      open={props.isOpened}
      maxWidth="lg"
      fullWidth
      scroll="body"
      onClose={_onClose}
      TransitionProps={{
        onExited: _onModalExit,
      }}>
      <DialogTitle
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'space-between',
        }}>
        <IconButton
          onClick={_onClose}
          title={t('general.discard')}
          sx={{alignSelf: 'flex-end'}}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <AutomationBuilderForm />
      </DialogContent>
      <DialogActions>
        {!props.isNewAutomation && (
          <ModalDeleteButton
            onConfirm={_onDeleteClick}
            title={t('eventAutomations.delete.title', {
              type: _typeTranslation,
            })}
            description={t('eventAutomations.delete.message', {
              type: _typeTranslation,
            })}
          />
        )}
        {props.type === 'tracker' && <AutomationShareCheckbox />}
        <LoadingButton
          loading={_automationForm.isLoading}
          variant="contained"
          title={_btnSaveLabel}
          onClick={_onSave}>
          {_btnSaveLabel}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AutomationCreatorModal;
