import {useTheme} from '@mui/material';
import {
  FIShotAnalysisRankingTeam,
  TShotAnalysisTeam,
} from '@my-game-plan/types';

import React from 'react';
import {
  CartesianGrid,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import {useTranslation} from 'react-i18next';
import {roundAndFormatNumber} from '@/helpers/general/general.helper';

interface IInsightDetailsScatterProps {
  scores: FIShotAnalysisRankingTeam[];
  team: TShotAnalysisTeam;
}

function InsightDetailsScatter(
  props: IInsightDetailsScatterProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {palette} = useTheme();
  const {t} = useTranslation();

  /*
   * Handlers
   */
  function _handleTooltip({payload}: TooltipProps<ValueType, NameType>) {
    if (!payload || payload?.length < 2) return null;

    const _shotsData = payload[0];
    const _goalsData = payload[1];

    return (
      <Card sx={{bgcolor: 'background.default'}}>
        <Stack p={2} spacing={1}>
          <Typography fontSize={14} fontWeight="600" color="text.secondary">
            {_shotsData.payload.name}
          </Typography>
          <Box gap={0.5}>
            <Typography fontSize={14}>
              {t('shotAnalysis.result.shotsCount', {
                count: _shotsData.value as number,
                countFormatted: roundAndFormatNumber(
                  _shotsData.value as number,
                ),
              })}
            </Typography>
            <Typography fontSize={14}>
              {t('shotAnalysis.result.goalsCount', {
                count: _goalsData.value as number,
                countFormatted: roundAndFormatNumber(
                  _goalsData.value as number,
                ),
              })}
            </Typography>
          </Box>
        </Stack>
      </Card>
    );
  }

  /*
   * Render
   */
  return (
    <ResponsiveContainer width="99%" height={400}>
      <ScatterChart
        data={props.scores}
        margin={{top: 20, left: 0, right: 0, bottom: 20}}>
        <CartesianGrid strokeDasharray={4} stroke={palette.divider} />
        <XAxis
          domain={['dataMin', 'auto']}
          dataKey="shots"
          type="number"
          stroke={palette.secondary.main}
          axisLine={false}
          tickLine={false}
          tick={{fontSize: 12}}
          reversed={props.team === 'conceded'}
        />
        <YAxis
          dataKey="goals"
          type="number"
          stroke={palette.secondary.main}
          axisLine={false}
          tickLine={false}
          tick={{fontSize: 12}}
          allowDecimals={false}
          width={32}
          reversed={props.team === 'conceded'}
        />
        <Scatter
          shape={(shapeData: any) => {
            const {cx, cy, payload} = shapeData;
            const THUMB_SIZE = 24;

            return (
              <g transform={`translate(${cx},${cy})`}>
                <defs>
                  {/* <clipPath id="circleView">
                    <circle
                      cx="0"
                      cy={THUMB_SIZE * -0.5}
                      r={THUMB_SIZE}
                      fill="#fff"
                    />
                  </clipPath> */}
                  <filter id="shadow">
                    <feDropShadow dx="0.2" dy="0.4" stdDeviation="0.8" />
                  </filter>
                </defs>
                <image
                  width={THUMB_SIZE}
                  height={THUMB_SIZE}
                  x={THUMB_SIZE * -0.5}
                  y={THUMB_SIZE * -0.5}
                  xlinkHref={payload.image_url}
                  // clipPath="url(#circleView)"
                  filter="url(#shadow)"
                />
              </g>
            );
          }}
        />
        <Tooltip
          cursor={{strokeDasharray: '3 3', stroke: palette.secondary.main}}
          content={_handleTooltip}
        />
      </ScatterChart>
    </ResponsiveContainer>
  );
}

export default InsightDetailsScatter;
