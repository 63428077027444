import React, {useEffect, useState} from 'react';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import {ICommonSentenceInputProps} from '@/types/sentence-form.types';
import SegmentedControl from '../../../../../common/segmented-control/segmented-control.view';

import styles from './observing-dropdown.module.scss';
import {useTranslation} from 'react-i18next';
import {capitalize} from 'lodash';

import {ITypedOption} from '@/types/option.types';

import {
  handlePositionOptionChange,
  isShareField,
} from '@/helpers/automation/automation-form.helper';
import {
  PlayerPosition,
  FIEventAutomationPostData,
  FIEventAutomationSubject,
  TEventObservingTeam,
} from '@my-game-plan/types';
import CustomAutocomplete from '@/components/material-customised/autocomplete/autocomplete.view';

export interface IObservingDropdownOption {
  label: string;
  value?: FIEventAutomationSubject;
  subject?: TObservingSubject;
  singleSelect?: boolean;
}

export interface IObservingDropdownOptionGroup {
  label: string;
  placeholder?: string;
  positionsPrefix?: string;
  withSuffix?: boolean;
  suffixTeamName?: string;
  suffixOpponentDescription?: string;
  teamLabel?: string;

  team?: TEventObservingTeam;
  options: IObservingDropdownOption[];
}

export interface IObservingDropdownProps<DataType>
  extends ICommonSentenceInputProps<
    Partial<FIEventAutomationSubject>,
    DataType
  > {
  options: IObservingDropdownOptionGroup[];
  maxItemsToShow?: number;
  defaultSubject?: TObservingSubject;
  requiredField?: TObservingSubject;
}

interface IObservingDropdownContentProps<DataType>
  extends IObservingDropdownProps<DataType> {
  hasOpenedPopup: boolean;
  onNestedPopupToggle: (hasOpenPopup: boolean) => void;
  playerOptions: ITypedOption<string>[];
  selectedPlayerOptions: ITypedOption<string>[];
  positionOptions: ITypedOption<string>[];
  selectedPositionOptions: ITypedOption<string>[];
}

type TObservingSubject = 'all' | 'players' | 'positions' | '';

function ObservingDropdownContent<DataType = FIEventAutomationPostData>(
  props: IObservingDropdownContentProps<DataType>,
) {
  /*
   * Hooks n State
   */
  const [_teamOptions, _setTeamOptions] = useState<
    ITypedOption<TEventObservingTeam>[]
  >([]);
  const [_radioOptions, _setRadioOptions] = useState<
    IObservingDropdownOption[]
  >([]);
  const [_selectedSubject, _setSelectedSubject] =
    useState<TObservingSubject>('');

  const {t} = useTranslation();

  /* Set default active tab and checked subject type */
  useEffect(() => {
    if (props.value?.positions) {
      _setSelectedSubject('positions');
    } else if (props.value?.players) {
      _setSelectedSubject('players');
    } else if (props.defaultSubject && !_selectedSubject) {
      _setSelectedSubject(props.defaultSubject);
    } else if (props.value?.team && !_selectedSubject) {
      _setSelectedSubject('all');
    }
  }, [props.value, _selectedSubject, props.defaultSubject]);

  useEffect(() => {
    let _matchingGroupIndex = props.options.findIndex(
      (group) => group.team === props.value?.team,
    );

    if (_matchingGroupIndex === -1) {
      _matchingGroupIndex = 0;
    }

    const _matchingOptions = props.options[_matchingGroupIndex];
    if (_matchingOptions) {
      _setRadioOptions(_matchingOptions.options);
      if (_matchingOptions.options.length === 1) {
        _setSelectedSubject(_matchingOptions.options[0].subject || 'all');
      }
    }
  }, [props.value?.team, props.options]);

  useEffect(() => {
    const _formattedOptions: ITypedOption<TEventObservingTeam>[] =
      props.options.map((group) => {
        return {
          label: capitalize(t(group.label)),
          value: group.team || 'own',
        };
      });
    _setTeamOptions(_formattedOptions);
  }, [props.options]);

  /*
   * Handlers
   */
  function _onTeamChange(team: TEventObservingTeam) {
    if (!props.hasOpenedPopup) {
      _onPropertyChange({
        team: team,
      });
    }
  }

  function _onSelectFocus() {
    props.onNestedPopupToggle(true);
  }

  function _onSelectBlur() {
    setTimeout(() => {
      props.onNestedPopupToggle(false);
    }, 200);
  }

  /* General onChange handler */
  function _onPropertyChange(data: FIEventAutomationSubject | undefined) {
    const _property = props.property as keyof DataType;
    if (props.onChange) {
      props.onChange({
        [_property]: data,
      } as Partial<DataType>);
    }
  }

  function _onRadioChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (!props.hasOpenedPopup) {
      const _value = event.currentTarget.value as TObservingSubject;
      _setSelectedSubject(_value);

      if (!_value) {
        _onPropertyChange(undefined);
        return;
      }

      let _team = props.value?.team;

      if (isShareField(props.property) && _value === 'all') {
        _team = 'own';
      }
      _onPropertyChange({
        team: _team,
      });
    }
  }

  function _onPlayersSelectChange(
    data: ITypedOption<string> | ITypedOption<string>[] | null,
  ) {
    let _playersToSet: string[] | undefined = undefined;
    if (Array.isArray(data)) {
      _playersToSet = data?.map((selectedOption) => selectedOption.value);
    } else if (data) {
      _playersToSet = [data.value];
    }
    _onPropertyChange({
      team: props.value?.team,
      players: _playersToSet,
    });
  }

  function _onPositionsSelectChange(
    data: ITypedOption<string> | ITypedOption<string>[] | null,
  ) {
    let _selectedPositions: PlayerPosition[] | undefined = undefined;
    if (data && Array.isArray(data)) {
      _selectedPositions = handlePositionOptionChange(data);
    }
    _onPropertyChange({
      team: props.value?.team,
      positions: _selectedPositions,
    });
  }

  /*
   * Render
   */

  function _positionsGroupHeaderKey(text: string): string {
    return capitalize(t(`playerPosition.${text}s`));
  }

  const _hasTabs = _teamOptions.length > 1;

  return (
    <div className={styles.dropdownContent}>
      {/* {props.canSelectOpponent && (
        <SegmentedControl

          onChange={_onTeamChange}
          value={props.value?.team}
        />
      )} */}
      {_hasTabs && (
        <SegmentedControl
          options={_teamOptions}
          onChange={_onTeamChange}
          value={props.value?.team || 'own'}
        />
      )}
      <FormControl
        className={styles.subjects}
        sx={{mt: _hasTabs ? 2 : 0}}
        fullWidth>
        <RadioGroup value={_selectedSubject} onChange={_onRadioChange}>
          {_radioOptions.map((option) => {
            let _PositionsDropdown = null;
            let _PlayersDropdown = null;
            const _displayPositionsDropdown =
              option.subject === 'positions' &&
              _selectedSubject === 'positions';

            if (_displayPositionsDropdown) {
              _PositionsDropdown = (
                <div className={styles.subjectGroupContent}>
                  <CustomAutocomplete<string, true>
                    disableCloseOnSelect
                    onOpen={_onSelectFocus}
                    onClose={_onSelectBlur}
                    placeholder={t(
                      'sentenceForm.observing.positionsPlaceholder',
                    )}
                    property={props.property as unknown as string}
                    options={props.positionOptions}
                    onChange={_onPositionsSelectChange}
                    groupHeader={_positionsGroupHeaderKey}
                    multiple={true}
                    value={props.selectedPositionOptions}
                  />
                </div>
              );
            }

            const _displayPlayersDropdown =
              option.subject === 'players' && _selectedSubject === 'players';
            if (_displayPlayersDropdown && !option.singleSelect) {
              // Show players multiselect
              _PlayersDropdown = (
                <div className={styles.subjectGroupContent}>
                  <CustomAutocomplete<string, true>
                    disableCloseOnSelect
                    onOpen={_onSelectFocus}
                    onClose={_onSelectBlur}
                    placeholder={t('sentenceForm.observing.playersPlaceholder')}
                    property={props.property as unknown as string}
                    options={props.playerOptions}
                    onChange={_onPlayersSelectChange}
                    groupHeader={_positionsGroupHeaderKey}
                    multiple={true}
                    value={props.selectedPlayerOptions}
                  />
                </div>
              );
            } else if (_displayPlayersDropdown) {
              // Show players single select
              _PlayersDropdown = (
                <div className={styles.subjectGroupContent}>
                  <CustomAutocomplete<string, false>
                    onOpen={_onSelectFocus}
                    onClose={_onSelectBlur}
                    placeholder={t('sentenceForm.observing.playersPlaceholder')}
                    property={props.property as unknown as string}
                    options={props.playerOptions}
                    onChange={_onPlayersSelectChange}
                    groupHeader={_positionsGroupHeaderKey}
                    multiple={false}
                    value={props.selectedPlayerOptions[0] || null}
                  />
                </div>
              );
            }
            return (
              <div className={styles.subjectGroup} key={option.label}>
                <FormControlLabel
                  value={option.subject}
                  label={t(option.label)}
                  control={<Radio size="small" />}
                />
                {/* Positions */}
                {_PositionsDropdown}

                {/* Players */}
                {_PlayersDropdown}
              </div>
            );
          })}
          {/* No one */}
          {/* {!_isObservingField && (
            <div className={styles.subjectGroup}>
              <FormControlLabel
                // value="none"
                value=""
                label={_staffOnlyLabel}
                control={<Radio size="small" />}
              />
            </div>
          )} */}

          {/* All players - teammates - opponents */}
          {/* <div className={styles.subjectGroup}>
            <FormControlLabel
              value="all"
              label={_allRadioLabel}
              control={<Radio size="small" />}
            />
          </div> */}

          {/* Players - teammates - opponents by POSITION */}
          {/* <div className={styles.subjectGroup}>
            <FormControlLabel
              value="positions"
              label={_positionsRadioLabel}
              control={<Radio size="small" />}
            />
            {_selectedSubject === 'positions' && (
              <div className={styles.subjectGroupContent}>
                <CustomAutocomplete<string, true>
                  disableCloseOnSelect
                  onOpen={_onSelectFocus}
                  onClose={_onSelectBlur}
                  placeholder={t('sentenceForm.observing.positionsPlaceholder')}
                  property={props.property as unknown as string}
                  options={props.positionOptions}
                  onChange={_onPositionsSelectChange}
                  groupHeader={_positionsGroupHeaderKey}
                  multiple={true}
                  value={props.selectedPositionOptions}
                />
              </div>
            )}
          </div> */}

          {/* Players - teammates - opponents by NAME */}
          {/* {_isOwnTeam && (
            <div className={styles.subjectGroup}>
              <FormControlLabel
                value="players"
                label={_playersRadioLabel}
                control={<Radio size="small" />}
              />
              {_selectedSubject === 'players' && (
                <div className={styles.subjectGroupContent}>
                  <CustomAutocomplete<string, true>
                    disableCloseOnSelect
                    onOpen={_onSelectFocus}
                    onClose={_onSelectBlur}
                    placeholder={t('sentenceForm.observing.playersPlaceholder')}
                    property={props.property as unknown as string}
                    options={props.playerOptions}
                    onChange={_onPlayersSelectChange}
                    groupHeader={_positionsGroupHeaderKey}
                    multiple={true}
                    value={props.selectedPlayerOptions}
                  />
                </div>
              )}
            </div> 
          )}
              */}
        </RadioGroup>
      </FormControl>
    </div>
  );
}

export default ObservingDropdownContent;
