import React from 'react';
import {ICommonSentenceProps} from '@/types/sentence-form.types';
import Segment from '../segment';

import AutomationTypeDropdown from '../type-dropdown/type-dropdown.view';
import Sentence from '../sentence.view';
import {
  RULE_OBSERVING_OPTIONS_EVENT,
  TRACKING_DATA_ACTION_OPTIONS_THIRD,
} from '@/config/event-automation-builder.config';
import {ACTION, FIEventCondition, isTrackingAction} from '@my-game-plan/types';

import {getVerbKey, startsWithVowel} from '@/helpers/translation.helper';

function RuleConditionLine(props: ICommonSentenceProps): JSX.Element | null {
  if (!props.data.rule_condition) {
    return null;
  }
  /* Hooks n State */

  /* Handlers */
  function _onChange(data: Partial<FIEventCondition>) {
    if (props.onChange && props.data.rule_condition) {
      props.onChange({
        rule_condition: {
          ...props.data.rule_condition,
          ...data,
        },
      });
    }
  }

  function _onTrackingActionChange(value: ACTION) {
    _onChange({
      action: value,
    });
  }

  /* Render */

  // if (!_isRatio)
  const _commonProps: ICommonSentenceProps = {
    ...props,
  };

  if (_commonProps.onChange) {
    _commonProps.onChange = _onChange;
  }

  {
    /* Sentence Start */
  }
  const _WhenSegment = (
    <Sentence.Segment>
      <Segment.Text
        {..._commonProps}
        translationKey={'sentenceForm.rule.when'}
        capitalize
      />
    </Sentence.Segment>
  );

  const _verbKey = getVerbKey(props.data.rule_condition.action);
  const _VerbSegment = (
    <Sentence.Segment>
      <Segment.Text {..._commonProps} translationKey={_verbKey} />
    </Sentence.Segment>
  );

  let _articleKey = 'sentenceForm.an';
  if (
    props.data.rule_condition.action &&
    !startsWithVowel(props.data.rule_condition.action)
  ) {
    _articleKey = 'sentenceForm.a';
  }
  const _ActionArticleSegment = (
    <Sentence.Segment>
      <Segment.Text translationKey={_articleKey} {..._commonProps} />
    </Sentence.Segment>
  );

  {
    /* OBSERVING TEAM / PLAYERS */
  }

  const _ObservingDropdownSegment = (
    <Segment.Observing
      {..._commonProps}
      property="observing_players"
      value={props.data.rule_condition.observing_players}
      options={RULE_OBSERVING_OPTIONS_EVENT}
    />
  );

  {
    /* Action */
  }
  const _ActionInputSegment = (
    <Sentence.Segment>
      <Segment.Action
        {..._commonProps}
        property="action"
        required
        count={1}
        value={props.data.rule_condition.action}
        error={props.errors?.action}
      />
    </Sentence.Segment>
  );

  {
    /* Automation Type Dropdown */
  }
  const _AutomationTypeDropdown = (
    <AutomationTypeDropdown
      {..._commonProps}
      property="rule_condition_action"
      action={props.data.rule_condition.action}
    />
  );

  if (
    props.data.rule_condition.action &&
    isTrackingAction(props.data.rule_condition.action)
  ) {
    {
      /* Is in" Segment */
    }

    const _IsInSegment = (
      <Sentence.Segment>
        {/* <Segment.Text
          {..._commonProps}
          translationKey={'sentenceForm.tracking.isIn'}
        /> */}
        <Segment.Dropdown
          {..._commonProps}
          value={props.data.rule_condition.action}
          options={TRACKING_DATA_ACTION_OPTIONS_THIRD}
          onChange={_onTrackingActionChange}
        />
      </Sentence.Segment>
    );

    {
      /* Zone */
    }

    const _ZoneInputSegment = (
      <Sentence.Segment>
        <Segment.Zones
          {..._commonProps}
          property="pitch_zone"
          value={props.data.rule_condition.pitch_zone}
          error={props.errors?.pitch_zone}
        />
      </Sentence.Segment>
    );
    return (
      <Sentence.Line>
        {/* When */}
        {_WhenSegment}
        {/* By + target */}
        {_ObservingDropdownSegment}
        {_IsInSegment}
        {_ZoneInputSegment}

        {/* Tracking data / actions switch */}
        {_AutomationTypeDropdown}
      </Sentence.Line>
    );
  }
  return (
    <Sentence.Line>
      {/* When */}
      {_WhenSegment}
      {/* By + target */}
      {_ObservingDropdownSegment}
      {_VerbSegment}
      {_ActionArticleSegment}

      {/* Action */}
      {_ActionInputSegment}

      {/* Tracking data / actions switch */}
      {_AutomationTypeDropdown}
    </Sentence.Line>
  );
}

export default RuleConditionLine;
