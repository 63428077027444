import React, {useEffect} from 'react';
import {
  FIBenchmarkOptions,
  FICompetition,
  PLAYER_POSITIONS,
  TBenchmarkDisabledReason,
} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';

import TuneIcon from '@mui/icons-material/Tune';
import HeaderFilterButton, {
  IFilterButtonValueSubSegment,
} from './header-filter-button.view';
import BenchmarkFilterPopup from '@/components/automations/automation-detail/benchmark/benchmark-filter/benchmark-filter-popup.view';
import {useCompetitions} from '@/context/competitions.context';
import {getSelectedPositionOptions} from '@/helpers/automation/automation-form.helper';
import {timeRangeFiltersToText} from '@/helpers/event-automations.helper';
import {useAuth} from '@/context/auth.context';

export interface IBenchmarkButtonTrackerInfo {
  isRatioOrRule?: boolean;
  isObservingPlayer?: boolean;
}
interface IBenchmarkButtonProps {
  benchmarkOptions?: FIBenchmarkOptions;
  onBenchmark: (options: FIBenchmarkOptions) => Promise<void>;
  benchmarkDisabledReason?: TBenchmarkDisabledReason;
  trackerInfo?: IBenchmarkButtonTrackerInfo;
}

function BenchmarkButton(props: IBenchmarkButtonProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _competitionsContext = useCompetitions();
  const _authContext = useAuth();

  const [_anchorEl, _setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [_labelValues, _setLabelValues] = React.useState<
    IFilterButtonValueSubSegment[][]
  >([]);
  const [_disabledText, _setDisabledText] = React.useState<string>('');

  /*
   * Side effects
   */
  // Define sentence preview structure
  useEffect(() => {
    if (!props.benchmarkOptions) {
      _setLabelValues([]);
      return;
    }

    const _generatedValues: IFilterButtonValueSubSegment[][] = [];
    // League or teams
    let _leagueValue = t('eventAutomations.benchmark.league');

    const _leagues: FICompetition[] = _competitionsContext.all.filter(
      (competition) =>
        props.benchmarkOptions?.observing?.competition_id?.includes(
          competition._id,
        ),
    );

    if (
      _leagues.length &&
      _leagues.length === 1 &&
      _leagues[0]._id !== _competitionsContext.domesticCompetition?._id
    ) {
      _leagueValue = _leagues[0].name;
    } else if (_leagues.length > 1) {
      _leagueValue = t('eventAutomations.filter.observing.leagues', {
        count: _leagues.length,
      });
    } else if (props.benchmarkOptions?.observing?.team_id?.length) {
      _leagueValue = t('eventAutomations.filter.observing.teams', {
        count: props.benchmarkOptions.observing.team_id.length,
      });
    }

    // Positions
    const _positions = props.benchmarkOptions?.position;

    if (_positions?.length) {
      let _positionsValue = getSelectedPositionOptions(_positions, false).join(
        ', ',
      );
      if (
        PLAYER_POSITIONS.every((position) => _positions?.includes(position))
      ) {
        _positionsValue = t('eventAutomations.benchmark.players.placeholder');
      }
      _generatedValues.push([
        {
          type: 'value',
          value: _positionsValue,
        },
        {
          type: 'default',
          value: t('eventAutomations.benchmark.of'),
        },
      ]);
    }

    // League
    _generatedValues.push([
      {
        type: 'value',
        value: _leagueValue,
      },
    ]);

    // Season

    const _seasonValueText = timeRangeFiltersToText(
      props.benchmarkOptions?.time,
      _authContext.user?.current_season_id,
      _competitionsContext.displayMultiYear,
    );
    _generatedValues.push([
      {
        type: 'default',
        value: _seasonValueText.prefix,
      },
      {
        type: 'value',
        value: _seasonValueText.value,
      },
    ]);

    _setLabelValues(_generatedValues);
  }, [
    props.benchmarkOptions,
    _competitionsContext.all,
    _authContext.user?.current_season_id,
    _competitionsContext.displayMultiYear,
  ]);

  // Define disabled text
  useEffect(() => {
    if (!props.benchmarkDisabledReason) {
      _setDisabledText('');
      return;
    }

    _setDisabledText(
      t(`eventAutomations.benchmark.disabled.${props.benchmarkDisabledReason}`),
    );
  }, [props.benchmarkDisabledReason]);

  /*
   * Handlers
   */
  function _onPopupClose() {
    _setAnchorEl(null);
  }
  function _onPopupOpen(event: React.MouseEvent<HTMLElement>) {
    _setAnchorEl(event.currentTarget);
  }

  /*
   * Render
   */
  return (
    <HeaderFilterButton
      isOpen={Boolean(_anchorEl)}
      buttonPrefix={t('eventAutomations.benchmark.benchmarkAgainst')}
      ButtonIcon={TuneIcon}
      buttonValues={_labelValues}
      onPopupOpen={_onPopupOpen}
      disabledText={_disabledText}
      PopupContent={
        <BenchmarkFilterPopup
          anchorEl={_anchorEl}
          onClose={_onPopupClose}
          isObservingPlayer={props.trackerInfo?.isObservingPlayer}
          isRatioOrRule={props.trackerInfo?.isRatioOrRule}
          benchmarkOptions={props.benchmarkOptions}
          onBenchmarkSubmit={props.onBenchmark}
        />
      }
    />
  );
}

export default BenchmarkButton;
