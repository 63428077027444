import React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import Logo from '../../common/Logo/Logo';
import {usePlayerDetail} from '@/context/player-detail.context';
import {isNullOrUndefined} from '@/helpers/general/general.helper';
import Indicator from '@/components/dashboard/Indicator/Indicator';
import {LOADING_STATE} from '@/types/screen.types';

function TeamDetailHeader(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const _playerDetailContext = usePlayerDetail();

  /*
   * Render
   */

  let _OverallScoreContent = null;
  if (
    !isNullOrUndefined(_playerDetailContext.performanceStats?.benchmarked_score)
  ) {
    _OverallScoreContent = (
      <Stack gap={0.5}>
        {/* <Typography variant="caption" color="secondary">
            {t('players.benchmark.overallPosBenchmark', {
              pos: _playerDetailContext.player.position[0],
            })}
          </Typography> */}
        <Box width={'12vw'} pt={1}>
          <Indicator
            value={_playerDetailContext.performanceStats?.benchmarked_score}
            bigValue
            coloredValue
          />
        </Box>
      </Stack>
    );
  }

  let _LeftContent = null;
  if (_playerDetailContext.playerLoadingState === LOADING_STATE.INITING) {
    _LeftContent = (
      <>
        <Stack direction="row" gap={2} flex={1}>
          <Skeleton
            variant="circular"
            sx={{
              width: 24,
              height: 24,
            }}
          />
          <Skeleton variant="text" sx={{width: 120}} />
        </Stack>
      </>
    );
  } else if (_playerDetailContext.team) {
    _LeftContent = (
      <Stack direction="row" alignItems="center" flex={1} gap={2}>
        <Logo src={_playerDetailContext.team.image_url} />
        <Typography fontWeight={600} fontSize={24} lineHeight={1}>
          {_playerDetailContext.team.name}
        </Typography>
      </Stack>
    );
  }
  return (
    <Stack direction="row" alignItems="center" width="100%" gap={2}>
      {_LeftContent}
      {_OverallScoreContent}
    </Stack>
  );
}

export default TeamDetailHeader;
