import {
  FIBenchmarkTimeOptions,
  FIEventAutomationPostData,
  FIFormattedAutomation,
  formatSeason,
} from '@my-game-plan/types';
import i18n from '@/localization';
import moment from 'moment';

export function formattedAutomationToPostData(
  eventAutomation: FIFormattedAutomation,
): Partial<FIEventAutomationPostData> {
  const {players, ..._otherFields} = eventAutomation;

  const _postData: Partial<FIEventAutomationPostData> = {..._otherFields};

  Object.keys(_postData).forEach((key) => {
    const _typedKey = key as keyof FIEventAutomationPostData;
    if (typeof _postData[_typedKey] === 'undefined') {
      delete _postData[_typedKey];
    }
  });

  return _postData;
}

interface ITimeRangeFilterValue {
  value: string;
  prefix: string;
}
export function timeRangeFiltersToText(
  timeOptions?: FIBenchmarkTimeOptions,
  currentSeasonId?: number,
  displayAsMultiYear?: boolean,
): ITimeRangeFilterValue {
  if (!timeOptions) {
    return {value: '', prefix: ''};
  }

  let _prefix = i18n.t('eventAutomations.benchmark.in');

  if (timeOptions.since_date) {
    const _formattedDate = moment(timeOptions.since_date as Date).format('ll');

    _prefix = i18n.t('eventAutomations.filter.time.since');

    return {
      value: _formattedDate,
      prefix: _prefix,
    };
  }

  if (timeOptions.match_limit) {
    const _matchLimitText = i18n.t(
      'eventAutomations.filter.time.lastCountGames',
      {
        count: timeOptions.match_limit,
      },
    );

    return {
      value: _matchLimitText,
      prefix: _prefix,
    };
  }

  if (timeOptions.season_id?.length) {
    let _seasonValue = i18n.t('eventAutomations.filter.time.seasonsCount', {
      count: timeOptions.season_id.length,
    });
    if (
      timeOptions.season_id.length === 1 &&
      timeOptions.season_id[0] === currentSeasonId
    ) {
      _seasonValue = i18n.t('eventAutomations.filter.time.currentSeason');
    } else if (timeOptions.season_id.length === 1) {
      _seasonValue = formatSeason(
        timeOptions.season_id[0],
        displayAsMultiYear || false,
      );
    }

    return {
      value: _seasonValue,
      prefix: _prefix,
    };
  }

  return {value: '', prefix: ''};
}
