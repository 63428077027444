import React from 'react';
import {ICommonSentenceProps} from '@/types/sentence-form.types';
import Segment from '../../segment';

import AutomationTypeDropdown from '../../type-dropdown/type-dropdown.view';
import Sentence from '../../sentence.view';
import {
  OPPONENT_AUTOMATION_TEAM_SELECTOR_OPTIONS,
  TRACKER_TEAM_SELECTOR_OPTIONS,
} from '@/config/event-automation-builder.config';
import {TEventObservingTeam, isTrackingAction} from '@my-game-plan/types';
import ObservingDropdownOpponentSelector from '../../segment/dropdown/observing-dropdown/observing-dropdown-opponent-selector.view';
import {useTranslation} from 'react-i18next';

function AutomationBaseLineSequence(props: ICommonSentenceProps): JSX.Element {
  /* Hooks n State */
  const {t} = useTranslation();

  /* Render */
  const _isRatio = props.data.calculation === 'ratio';
  const _isRule = props.data.calculation === 'rule';
  const _countToDisplay = _isRule ? 1 : 0;
  // if (!_isRatio)
  const _commonProps: ICommonSentenceProps = {
    ...props,
  };

  {
    /* Sentence Start */
  }
  const _CountSegment = (
    <Sentence.Segment>
      <Segment.Text
        {..._commonProps}
        translationKey={'sentenceForm.count'}
        capitalize
      />
    </Sentence.Segment>
  );

  /* Amount/Percent Of segmemt */
  const _shouldDisplayAmountOfText =
    props.data.metric || !props.readonly || _isRatio;
  let _AmountOfSegment = null;
  if (_isRatio) {
    _AmountOfSegment = (
      <Sentence.Segment>
        <Segment.Text
          {..._commonProps}
          capitalize={props.readonly}
          translationKey={'sentenceForm.percentOf'}
        />
      </Sentence.Segment>
    );
  } else {
    _AmountOfSegment = (
      <>
        <Sentence.Segment>
          <Segment.Metrics
            {..._commonProps}
            property="metric"
            onChange={props.onChange}
          />
        </Sentence.Segment>
        <Sentence.Segment>
          <Segment.Text {..._commonProps} translationKey={'sentenceForm.of'} />
        </Sentence.Segment>
      </>
    );
  }

  const _SequenceSegment = (
    <Sentence.Segment>
      <Segment.Text
        translationKey={'sentenceForm.sequence.sequences'}
        type="value"
        {..._commonProps}
      />
    </Sentence.Segment>
  );

  const _ContainingSegment = (
    <Sentence.Segment>
      <Segment.Text
        translationKey={'sentenceForm.sequence.containing'}
        {..._commonProps}
      />
    </Sentence.Segment>
  );

  function _onObservingTeamChange(value: TEventObservingTeam) {
    if (props.onChange) {
      props.onChange({
        observing_players: {
          team: value,
        },
      });
    }
  }
  const _byDropdownOptions =
    props.automationType === 'opponent-automation'
      ? OPPONENT_AUTOMATION_TEAM_SELECTOR_OPTIONS
      : TRACKER_TEAM_SELECTOR_OPTIONS;
  const _ByAgainstDropdownSegment = (
    <Sentence.Segment>
      <Segment.Dropdown
        {...props}
        onChange={_onObservingTeamChange}
        // property="observing_players"
        options={_byDropdownOptions}
        displayReadonlyValueAsRegularText
        value={props.data.observing_players?.team || 'own'}
      />
    </Sentence.Segment>
  );

  let _ObservingDropdownSegment = null;

  if (props.automationType === 'opponent-automation' && !props.hideSubject) {
    // Opponent automations
    // "EVT by POS of next opponent" - "EVT by pos against next opponent"

    let _OpponentSegment = (
      <Segment.Text
        {..._commonProps}
        translationKey={'sentenceForm.observing.nextOpponent'}
      />
    );
    if (props.canSelectOpponent) {
      _OpponentSegment = (
        <ObservingDropdownOpponentSelector {..._commonProps} />
      );
    }

    _ObservingDropdownSegment = (
      <>
        <Sentence.Segment>{_OpponentSegment}</Sentence.Segment>
      </>
    );
  } else if (props.data.observing_players?.team === 'opponent') {
    // Trackers - Opponent
    // "EVT by POS against team"
    _ObservingDropdownSegment = (
      <>
        <Sentence.Segment>
          <Segment.Text
            {..._commonProps}
            translationKey={'sentenceForm.observing.entireTeam'}
          />
        </Sentence.Segment>
      </>
    );
  } else if (props.data.calculation === 'rule') {
    _ObservingDropdownSegment = (
      <Segment.Players
        {..._commonProps}
        property="observing_players"
        value={props.data.observing_players}
        placeholder={t('sentenceForm.observing.anyPlayer')}
      />
    );
  } else if (!props.hideSubject) {
    // Trackers - Own team
    // "EVT by team" - "EVT by player"
    _ObservingDropdownSegment = (
      <>
        <Sentence.Segment>
          <Sentence.Segment>
            <Segment.Players
              {..._commonProps}
              property="observing_players"
              value={props.data.observing_players}
            />
          </Sentence.Segment>
        </Sentence.Segment>
      </>
    );
  }

  {
    /* Automation Type Dropdown */
  }
  let _AutomationTypeDropdown = null;
  if (
    !_isRule ||
    (_isRule &&
      props.data.rule_condition?.action &&
      !isTrackingAction(props.data.rule_condition.action))
  ) {
    _AutomationTypeDropdown = (
      <AutomationTypeDropdown {..._commonProps} action={props.data.action} />
    );
  }

  return (
    <>
      {/* "Count amount of" / "Count % of"  */}
      {_CountSegment}
      {_shouldDisplayAmountOfText && _AmountOfSegment}

      {_SequenceSegment}
      {props.readonly && _ObservingDropdownSegment && _ByAgainstDropdownSegment}
      {props.readonly && _ObservingDropdownSegment}
      {_ContainingSegment}

      {/* Tracking data / actions / sequence switch */}
      {_AutomationTypeDropdown}
    </>
  );
}

export default AutomationBaseLineSequence;
