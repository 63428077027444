import React from 'react';
import {FIPlayerSearchFilters} from '@my-game-plan/types';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import {useTranslation} from 'react-i18next';

interface ISearchRangeInputProps {
  label: string;
  property: keyof FIPlayerSearchFilters;
  value?: number[];
  onChange: (data: Partial<FIPlayerSearchFilters>) => void;
  min: number;
  max: number;
  unit?: string;
}

function SearchRangeInput(props: ISearchRangeInputProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  /*
   * Handlers
   */
  function _onRangeChange(event: Event, newValue: number | number[]) {
    if (Array.isArray(newValue)) {
      props.onChange({[props.property]: newValue});
    }
  }

  /*
   * Render
   */

  let _valueText = t('scouting.searchForm.anyX', {
    field: props.label.toLocaleLowerCase(),
  });
  if (
    props.value?.length &&
    (props.value[0] !== props.min || props.value[1] !== props.max)
  ) {
    _valueText = `${props.value[0]}${props.unit || ''} - ${props.value[1]}${
      props.unit || ''
    }`;
  }
  return (
    <Stack spacing={1}>
      <Stack
        direction={'row'}
        alignItems={'center'}
        gap={1}
        justifyContent="space-between">
        <Typography color="text.secondary" fontSize={12}>
          {props.label}
        </Typography>
        <Typography color="text.disabled" fontSize={12} fontWeight={500}>
          {_valueText}
        </Typography>
      </Stack>
      <Box px={1}>
        <Slider
          color="secondary"
          min={props.min}
          max={props.max}
          onChange={_onRangeChange}
          value={props.value || []}
          // valueLabelDisplay="on"
          valueLabelFormat={(value) => `${value}${props.unit || ''}`}
          valueLabelDisplay="auto"
          marks={[
            {
              value: props.min,
              // label: `${props.min}${props.unit || ''}`,
              label: props.min,
            },
            {
              value: props.max,
              // label: `${props.max}${props.unit || ''}`,
              label: props.max,
            },
          ]}
          sx={{
            '& .MuiSlider-mark': {
              bgcolor: 'transparent',
            },
            '& .MuiSlider-markLabel': {
              fontSize: 12,
              color: 'secondary.main',
            },
          }}
        />
      </Box>
    </Stack>
  );
}

export default SearchRangeInput;
