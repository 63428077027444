import React, {PropsWithChildren, useEffect, useState} from 'react';

import {
  removeEmptyFilters,
  translateAutomationType,
} from '@/helpers/automation/automation-form.helper';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {TAutomationErrors, TError} from '@/types/sentence-form.types';

import {useTrackers} from '@/context/event-automations/tracker.context';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {useAuth} from '@/context/auth.context';

import {useSnackbar} from 'notistack';

import {
  ACTIONS_CONFIG,
  FIEventAutomationPostData,
  FIEventCondition,
  FIFormattedAutomation,
  FISequenceAction,
  isTrackingAction,
  TEventAutomationType,
} from '@my-game-plan/types';
import {
  addAutomation,
  deleteAutomation,
  editAutomation,
} from '@/controllers/event-automations.controller';
import {
  EVENT_AUTOMATION_SKELETONS,
  NOTIFICATION_OFFSET,
  OPPONENT_MATCH_LIMIT,
} from '@/config/event-automation-builder.config';
import {useOpponentAutomations} from './opponent-automations.context';
import {cloneDeep} from 'lodash';
import {useTeams} from '../team.context';
import {useAnalytics} from '../analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';
import {generateBaseURLForAutomation} from '@/helpers/automation/automation.helper';
import {NAV_LEVEL_1_ROUTE} from '@/config/navigation.config';

export interface AutomationFormAPI {
  initForm: (
    type?: TEventAutomationType,
    data?: Partial<FIEventAutomationPostData>,
    optionalInfo?: Partial<FIEventAutomationPostData>,
  ) => void;
  clearForm: (clearEverything?: boolean) => void;
  data: Partial<FIEventAutomationPostData>;
  setData: (
    data: Partial<FIEventAutomationPostData>,
    overrideAllFields?: boolean,
  ) => void;
  isLoading: boolean;
  isOpened: boolean;
  isValid: boolean;
  setIsOpened: (open: boolean) => void;
  saveAutomation: () => Promise<FIFormattedAutomation | null>;
  deleteAutomation: (id?: string) => Promise<void>;
  errors: TAutomationErrors;
  changeCount: number;
  resetChangeCount: () => void;
  currentAutomationType: TEventAutomationType | undefined;
  setCurrentAutomationType: (type: TEventAutomationType) => void;
}

const DEFAULT_ERROR_STATE: TAutomationErrors = {
  conditions: [] as TError<FIEventCondition>[],
  sequence_actions: [] as TError<FISequenceAction>[],
};
const context = createCustomContext<AutomationFormAPI>();
export const useAutomationForm = createContextHook(context);

export const AutomationFormProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  const [_currentAutomationType, _setCurrentAutomationType] = useState<
    TEventAutomationType | undefined
  >(undefined);

  const [_isOpened, _setIsOpened] = useState<boolean>(false);
  const [_isLoading, _setIsLoading] = useState<boolean>(false);
  const [_data, _setData] = useState<Partial<FIEventAutomationPostData>>({});
  const [_errors, _setErrors] =
    useState<TAutomationErrors>(DEFAULT_ERROR_STATE);
  const [_isValid, _setIsValid] = useState<boolean>(false);
  const [_changeCount, _setChangeCount] = useState(0);

  const _auth = useAuth();
  const _teamsContext = useTeams();
  const _trackers = useTrackers();
  const _analyticsContext = useAnalytics();

  const _opponentAutomations = useOpponentAutomations();
  const _navigate = useNavigate();

  const {enqueueSnackbar} = useSnackbar();
  const {t} = useTranslation();

  useEffect(() => {
    let _newValidState = true;

    const _isSuccessDetailsInvalid =
      _data.calculation === 'ratio' && !_data.success_details?.filters?.length;

    if (!_data.action || _isSuccessDetailsInvalid) {
      _newValidState = false;
    }
    _setIsValid(_newValidState);
  }, [_data]);

  /*
   * Handlers
   */
  async function _onChange(
    data: Partial<FIEventAutomationPostData>,
    overrideAllFields = false,
  ) {
    if (overrideAllFields) {
      _setData(data);
      return;
    }
    _setChangeCount(_changeCount + 1);

    const _newData: Partial<FIEventAutomationPostData> = {
      ..._data,
      ...data,
    };
    _setData(_newData);

    const _newErrors = {..._errors};
    if (data.action && _newErrors.action) {
      delete _newErrors.action;
    }

    if (data.success_details?.filters?.length && _newErrors.success_details) {
      delete _newErrors.success_details;
    }

    if (data.conditions) {
      data.conditions.conditions?.forEach((condition, conditionIndex) => {
        let _conditionErrors: TError<FIEventCondition> = {};
        if (_newErrors?.conditions) {
          if (_newErrors.conditions[conditionIndex]) {
            _conditionErrors = _newErrors.conditions[conditionIndex];
          }

          if (condition.type === 'when') {
            if (
              condition.details?.filters?.length &&
              condition.details.filters[0].values?.length &&
              _conditionErrors.details
            ) {
              delete _conditionErrors.details;
            }
          } else {
            if (condition.seconds && _conditionErrors.seconds) {
              delete _conditionErrors.seconds;
            }

            if (condition.action && _conditionErrors.action) {
              delete _conditionErrors.action;
            }
          }

          _newErrors.conditions[conditionIndex] = _conditionErrors;
        }
      });
    }

    if (data.sequence_actions && data.action === 'sequence') {
      data.sequence_actions.forEach((action, actionIndex) => {
        let _actionErrors: TError<FISequenceAction> = {};
        if (_newErrors?.sequence_actions) {
          if (_newErrors.sequence_actions[actionIndex]) {
            _actionErrors = _newErrors.sequence_actions[actionIndex];
          }

          if (action.action) {
            delete _actionErrors.action;
          }

          _newErrors.sequence_actions[actionIndex] = _actionErrors;
        }
      });
    }

    _setErrors(_newErrors);
  }

  function _validate(data: Partial<FIEventAutomationPostData>): boolean {
    const _newErrors: TAutomationErrors = {...DEFAULT_ERROR_STATE};

    /* Action */
    if (!data.action) {
      _newErrors.action = 'sentenceForm.action.error';
    }

    /* Ratio: Success details */
    if (
      data.calculation === 'ratio' &&
      !data.success_details?.filters?.length
    ) {
      _newErrors.success_details = 'sentenceForm.details.error.successDetails';
    }

    /* Conditions */
    if (data.conditions) {
      _newErrors.conditions = [];
      data.conditions.conditions?.forEach((condition) => {
        const _conditionErrors: TError<FIEventCondition> = {};

        if (condition.type === 'when') {
          if (
            !condition.details?.filters?.length ||
            !condition.details.filters[0].values?.length
          ) {
            _conditionErrors.details = 'sentenceForm.conditions.error.when';
          }
        } else {
          // if (!condition.seconds) {
          //   _conditionErrors.seconds = 'sentenceForm.conditions.error.seconds';
          // }

          if (!condition.action) {
            _conditionErrors.action = 'sentenceForm.action.error';
          }
        }
        if (_newErrors.conditions) {
          _newErrors.conditions.push(_conditionErrors);
        }
      });
    }

    /* Sequence actions */
    if (data.sequence_actions && data.action === 'sequence') {
      _newErrors.sequence_actions = [];
      data.sequence_actions.forEach((action) => {
        const _actionErrors: TError<FISequenceAction> = {};

        if (!action.action) {
          _actionErrors.action = 'sentenceForm.action.error';
        }

        if (_newErrors.sequence_actions) {
          _newErrors.sequence_actions.push(_actionErrors);
        }
      });
    }

    /* Tracking data - Player should be required */
    if (data.action && isTrackingAction(data.action)) {
      if (
        !data.observing_players?.players?.length &&
        !data.observing_players?.positions?.length
      ) {
        _newErrors.observing_players = 'sentenceForm.players.error';
      }

      const _actionConfig = ACTIONS_CONFIG[data.action];
      if (_actionConfig?.tracking?.withBox && !data.pitch_zone) {
        _newErrors.pitch_zone = 'sentenceForm.tracking.zone_error';
      }
    }

    /* Rule trackers */
    if (data.rule_condition && data.calculation === 'rule') {
      const _ruleConditionErrors: TError<FIEventCondition> = {};

      if (!data.rule_condition.action) {
        _ruleConditionErrors.action = 'sentenceForm.action.error';
      }

      if (
        data.rule_condition.action &&
        isTrackingAction(data.rule_condition.action)
      ) {
        if (!data.rule_condition.observing_players?.positions?.length) {
          _ruleConditionErrors.observing_players = 'sentenceForm.players.error';
        }

        if (!data.rule_condition.pitch_zone) {
          _ruleConditionErrors.pitch_zone = 'sentenceForm.tracking.zone_error';
        }
      }

      if (Object.keys(_ruleConditionErrors).length) {
        _newErrors.rule_condition = _ruleConditionErrors;
      }
    }

    if (_newErrors.conditions) {
      let _hasConditionError = false;
      _newErrors.conditions.forEach((condition) => {
        if (Object.keys(condition).length > 0) {
          _hasConditionError = true;
        }
      });

      if (!_hasConditionError) {
        delete _newErrors.conditions;
      }
    }

    if (_newErrors.sequence_actions) {
      let _hasActionError = false;
      _newErrors.sequence_actions.forEach((action) => {
        if (Object.keys(action).length > 0) {
          _hasActionError = true;
        }
      });

      if (!_hasActionError) {
        delete _newErrors.sequence_actions;
      }
    }

    _setErrors(_newErrors);
    return Object.keys(_newErrors).length === 0;
  }

  function _initForm(
    type?: TEventAutomationType,
    data?: Partial<FIEventAutomationPostData>,
    optionalInfo?: Partial<FIEventAutomationPostData>,
  ) {
    if (type) {
      _setCurrentAutomationType(type);
      if (type === 'opponent-automation') {
        // _setWithTarget(false);
      }
      if (data) {
        _setData(data);
        // _setWithTarget(typeof data.target !== 'undefined');
      } else {
        const _skeleton = {
          ...cloneDeep(EVENT_AUTOMATION_SKELETONS[type]),
          ...optionalInfo,
        };
        // if (optionalInfo?.action) {
        //   _skeleton.action = optionalInfo.action;
        // }
        // if (optionalInfo?.filters) {
        //   _skeleton.details = optionalInfo.filters;
        // }

        _setData(_skeleton);
      }
    }
  }

  function _clearForm(clearAll?: boolean) {
    _setIsValid(false);
    _setErrors(DEFAULT_ERROR_STATE);
    _setData({});
    // _setWithTarget(true);

    if (clearAll) {
      _setCurrentAutomationType(undefined);
    }
  }

  async function _deleteAutomation(id?: string) {
    if (id && _currentAutomationType) {
      await deleteAutomation(_currentAutomationType, id);
      if (_currentAutomationType === 'opponent-automation') {
        _opponentAutomations.getAll();
        _navigate(NAV_LEVEL_1_ROUTE.OPPONENT_AUTOMATIONS);
      } else if (_currentAutomationType === 'tracker') {
        _trackers.getAllPerPlayer();
        _navigate(NAV_LEVEL_1_ROUTE.HOME);
      }

      const _typeTranslation = translateAutomationType(
        _currentAutomationType,
        1,
      );
      enqueueSnackbar(
        t('eventAutomations.delete.success', {type: _typeTranslation}),
        {
          variant: 'success',
        },
      );
    }
  }

  async function _saveAutomation(): Promise<FIFormattedAutomation | null> {
    _setChangeCount(0);
    let _automation: FIFormattedAutomation | null = null;
    const _isCreatingNewAutomation = !_data._id;
    const _isValidLocal = _validate(_data);

    if (!_currentAutomationType) return null;

    try {
      if (_isValidLocal) {
        if (_auth.user) {
          _setIsLoading(true);
          const _postData: Partial<FIEventAutomationPostData> = {..._data};

          const _basePostData: Partial<FIEventAutomationPostData> = {
            team_id: _auth.user.team,
          };

          if (_postData.tags && _postData.tags.length > 0) {
            const teamTags = _teamsContext?.preferences?.tags;

            // TODO this is rather ugly. The CustomChipSelect works with labels but our tracker tags only with ids. so our tags need to be transformed
            //  to labels for the input but in case we edit the tracker without changing the tags the ids are used instead
            _postData.newTags = teamTags
              ? _postData.tags.filter(
                  (tag) =>
                    !teamTags.some((t) => t.label == tag || t._id == tag),
                )
              : _postData.tags;
            _postData.tags = _postData.tags
              .map((tag) =>
                teamTags
                  ? teamTags.find((t) => t.label == tag || t._id == tag)
                  : undefined,
              )
              .filter((tag) => !!tag)
              .map((tag) => tag!._id);
          }

          /* Details */
          if (_postData.details) {
            _postData.details = removeEmptyFilters(_postData.details, true);
          }
          /* Success Details */
          if (_postData.calculation === 'occurrences') {
            delete _postData.success_details;
          } else if (_postData.success_details) {
            _postData.success_details = removeEmptyFilters(
              _postData.success_details,
              true,
            );
          }

          /* Observing Players */
          if (
            !_postData.observing_players?.positions?.length &&
            !_postData.observing_players?.players?.length &&
            !_postData.observing_players?.team
          ) {
            delete _postData.observing_players;
          }

          /* Shared With Players */
          if (!_postData.shared_with_players) {
            delete _postData.shared_with_players;
          }

          /* Conditions */
          if (_postData.conditions) {
            _postData.conditions.conditions.forEach((condition) => {
              if (condition.details) {
                condition.details = removeEmptyFilters(condition.details, true);
              }

              if (
                (condition.type === 'before' || condition.type === 'after') &&
                !condition.seconds
              ) {
                condition.seconds = 0;
              }
            });

            if (!_postData.conditions.conditions.length) {
              _postData.conditions = undefined;
            }
          }

          /* Comment */
          if (_postData.comment === '') {
            delete _postData.comment;
          }

          /* Title */
          if (_postData.title === '') {
            delete _postData.title;
          }

          /* Pitch zone */
          if (
            _postData.action &&
            !isTrackingAction(_postData.action) &&
            _postData.pitch_zone
          ) {
            delete _postData.pitch_zone;
          }

          if (_currentAutomationType === 'opponent-automation') {
            if (typeof _postData.notification_offset === 'undefined') {
              _postData.notification_offset = NOTIFICATION_OFFSET;
            }
            if (!_postData.match_limit) {
              _postData.match_limit = OPPONENT_MATCH_LIMIT;
            }
          }

          // Rule condition
          if (_postData.calculation === 'rule') {
            if (
              _postData.rule_condition?.action &&
              !isTrackingAction(_postData.rule_condition.action) &&
              _postData.rule_condition?.pitch_zone
            ) {
              delete _postData.rule_condition.pitch_zone;
            }
          } else {
            delete _postData.rule_condition;
          }

          // Sequence actions
          if (_postData.sequence_actions) {
            _postData.sequence_actions.forEach((action) => {
              if (action.details) {
                action.details = removeEmptyFilters(action.details, true);
              }
            });

            if (_postData.action !== 'sequence') {
              delete _postData.sequence_actions;
            }
          }

          const _automationToSave: Partial<FIEventAutomationPostData> = {
            ..._basePostData,
            ..._postData,
          };

          const _typeTranslation = translateAutomationType(
            _currentAutomationType,
            1,
          );
          if (_isCreatingNewAutomation) {
            /* Logic to handle new automation */
            const _automation = await addAutomation(
              _currentAutomationType,
              _automationToSave,
            );
            _setIsLoading(false);
            if (_automation) {
              if (_currentAutomationType === 'tracker') {
                /* Refresh trackers and navigate to screen */
                _trackers.getAllPerPlayer();

                _analyticsContext.trackEvent(ANALYTICS_EVENT.CREATED_TRACKER);

                const _automationURL = generateBaseURLForAutomation(
                  'tracker',
                  _automation._id,
                  Boolean(_automation.observing_players?.players?.length),
                );

                _navigate({
                  pathname: _automationURL,
                });
              } else if (_currentAutomationType === 'opponent-automation') {
                /* Refresh opponent automations */
                _opponentAutomations.getAll();

                _analyticsContext.trackEvent(
                  ANALYTICS_EVENT.CREATED_OPPONENT_AUTOMATION,
                );
                enqueueSnackbar(
                  t('eventAutomations.create.success', {
                    type: _typeTranslation,
                  }),
                  {
                    variant: 'success',
                  },
                );

                // _setIsLoading(false);
                // _setIsOpened(false);
                const _automationURL = generateBaseURLForAutomation(
                  'opponent-automation',
                  _automation._id,
                );
                _navigate({
                  pathname: _automationURL,
                });
              }
            }
          } else {
            /* Logic to handle edited automation */
            _automation = await editAutomation(
              _currentAutomationType,
              _postData._id || '',
              _automationToSave,
            );

            if (_currentAutomationType === 'tracker') {
              /* Set current tracker */
              _trackers.getAllPerPlayer();
            } else if (_currentAutomationType === 'opponent-automation') {
              _opponentAutomations.getAll();
            }

            _setIsLoading(false);
            _setIsOpened(false);
            enqueueSnackbar(
              t('eventAutomations.edit.success.title', {
                type: _typeTranslation,
              }),
              {
                variant: 'success',
              },
            );
          }
        }
      }
      _teamsContext.getOwnTeam();
      return _automation;
    } catch (error) {
      _setIsLoading(false);
      enqueueSnackbar(t('error-states.default'), {
        variant: 'error',
      });
    }

    return null;
  }

  return createProvider(context, props, {
    clearForm: _clearForm,
    initForm: _initForm,
    data: _data,
    setData: _onChange,
    isLoading: _isLoading,
    isOpened: _isOpened,
    isValid: _isValid,
    setIsOpened: _setIsOpened,
    saveAutomation: _saveAutomation,
    deleteAutomation: _deleteAutomation,
    errors: _errors,
    changeCount: _changeCount,
    resetChangeCount: () => _setChangeCount(0),
    currentAutomationType: _currentAutomationType,
    setCurrentAutomationType: _setCurrentAutomationType,
  });
};
