import Box from '@mui/material/Box';
import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

import {useTranslation} from 'react-i18next';

import {FIBenchmarkOptions} from '@my-game-plan/types';

import BenchmarkSentence from '@/components/automations/sentence/benchmark-sentence.view';
import {lighten, useTheme} from '@mui/material';

interface IBenchmarkFilterPopupProps {
  onClose: () => void;
  anchorEl?: Element | null;
  isObservingPlayer?: boolean;
  isRatioOrRule?: boolean;

  benchmarkOptions?: FIBenchmarkOptions;
  onBenchmarkSubmit: (options: FIBenchmarkOptions) => Promise<void>;
}

function BenchmarkFilterPopup(props: IBenchmarkFilterPopupProps) {
  /*
   * Hooks n State
   */
  const [_data, _setData] = React.useState<FIBenchmarkOptions | null>(null);
  const {t} = useTranslation();
  const _theme = useTheme();

  /*
   * Side effects
   */
  useEffect(() => {
    _setData(props.benchmarkOptions || null);
  }, [props.benchmarkOptions]);

  /*
   * Handlers
   */
  function _onChange(data: Partial<FIBenchmarkOptions>) {
    const _filters = {
      ...(_data || {}),
      ...data,
    };
    _setData(_filters);

    // props.updateBenchmarkFilters(_filters, false, true);
  }

  function _onSubmit() {
    if (!_data) return;

    props.onBenchmarkSubmit(_data);
    props.onClose();
  }

  // function _onReset() {
  //   _trackerContext.updateBenchmarkFilters();
  //   // console.log('RESTORE DEFAULTS');
  // }
  function _onClose() {
    //
    props.onClose();
  }

  /*
   * Render
   */

  const _isOpen = Boolean(props.anchorEl);
  if (!_data) return null;

  return (
    <Popover
      slotProps={{
        paper: {
          sx: {
            bgcolor: lighten(_theme.palette.background.paper, 0.03),
          },
        },
      }}
      elevation={4}
      anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
      anchorEl={props.anchorEl}
      open={_isOpen}
      onClose={_onClose}>
      <Box p={3} width={440}>
        <Box mb={4}>
          <BenchmarkSentence
            isObservingPlayer={props.isObservingPlayer}
            isRatioOrRule={props.isRatioOrRule}
            onChange={_onChange}
            benchmarkOptions={_data}
          />
        </Box>

        {/* Buttons */}
        <Box display="flex" flexDirection="row" gap={4} alignItems="center">
          <Button variant="contained" color="primary" onClick={_onSubmit}>
            {t('eventAutomations.benchmark.benchmark')}
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}

export default BenchmarkFilterPopup;
