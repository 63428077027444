import {IDataExplorer} from './dashboard-explorer.types';
import {IPitchVisual} from './dashboard-pitch-visual.types';
import {IPlayerStats} from './dashboard-player-stats.types';

import {ACTION} from '@my-game-plan/types';

/* Blocks */
export enum IDashboardBlockType {
  PLAYER_STATISTICS = 'player-statistics',
  DATA_EXPLORER = 'data-explorer',
  PITCH_VISUAL = 'pitch-visual',
}

export interface IDashboardBlock {
  type: IDashboardBlockType;
  data: IPlayerStats | IDataExplorer | IPitchVisual;
}

export enum DASHBOARD_STATUS {
  IN_BUILD = 'in-build',
  LIVE = 'live',
}

export interface IDashboard {
  action: ACTION;
  status: DASHBOARD_STATUS;
  blocks: IDashboardBlock[];
  isOpponentAction?: boolean;
}

export type BlocksRow = IDashboardBlock[];
