import React from 'react';
import {ChartSvgText} from './ChartSvgText';

interface Props {
  src: string;
  value: string;
  isObserving?: boolean;
  size?: number;
}

export const ChartSvgIcon = ({
  src,
  value,
  isObserving = true,
  size = 24,
}: Props) => {
  return (
    <>
      {/* <defs>
        <clipPath id="circleView">
          <circle cx="0" cy="-4" r="12" fill="#fff" />
        </clipPath>
      </defs> */}
      <image
        width={size}
        height={size}
        x={-12}
        y={-16}
        opacity={isObserving ? 1 : 0.2}
        xlinkHref={src}
        // clipPath="url(#circleView)"
      />
      <ChartSvgText value={value} dy={30} />
    </>
  );
};
