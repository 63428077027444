import {usePlayerDetail} from '@/context/player-detail.context';
import {FIEventAutomationPostData} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';

import InsightsIcon from '@mui/icons-material/Insights';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

import {useTranslation} from 'react-i18next';
import AutomationCreateButton from '../automations/automation-create-button/automation-create-button.view';
import PlayerObjectiveCreateButton from '../player-objectives/player-objective-create-button.view';

function PlayerSpeedDial(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _playerDetailContext = usePlayerDetail();
  const [_isOpen, _setIsOpen] = useState(false);
  const [_newTrackerData, _setNewTrackerData] = useState<
    Partial<FIEventAutomationPostData>
  >({});

  /*
   * Side effects
   */
  useEffect(() => {
    if (_playerDetailContext.player) {
      _setNewTrackerData({
        observing_players: {
          players: [_playerDetailContext.player._id],
        },
      });
    }
  }, [
    _playerDetailContext.team,
    _playerDetailContext.player,
    _playerDetailContext.isTeamPage,
  ]);

  /*
   * Handlers
   */
  function _handleClose() {
    _setIsOpen(false);
  }
  function _handleOpen() {
    _setIsOpen(true);
  }

  function _onOptionSelect() {
    _setIsOpen(false);
  }

  /*
   * Render
   */

  if (!_playerDetailContext.player) {
    return null;
  }

  return (
    <>
      {/* <Backdrop open={_isOpen} /> */}
      <SpeedDial
        open={_isOpen}
        onOpen={_handleOpen}
        onClose={_handleClose}
        onBlur={_handleClose}
        icon={<SpeedDialIcon />}
        ariaLabel={t('players.actions.title')}
        sx={{position: 'fixed', bottom: 16, right: 16}}>
        <AutomationCreateButton
          optionalActionInfo={_newTrackerData}
          type={'tracker'}
          Button={
            <SpeedDialAction
              sx={{whiteSpace: 'nowrap'}}
              open={_isOpen}
              key={t('eventAutomations.trackersNew')}
              icon={<InsightsIcon />}
              tooltipTitle={t('eventAutomations.trackersNew')}
              onClick={() => _onOptionSelect()}
              //   tooltipOpen
            />
          }
        />
        <PlayerObjectiveCreateButton
          playerId={_playerDetailContext.player._id}
          Button={
            <SpeedDialAction
              sx={{whiteSpace: 'nowrap'}}
              open={_isOpen}
              key={t('playerObjectives.new')}
              icon={<TrackChangesIcon />}
              //   tooltipOpen
              tooltipTitle={t('playerObjectives.new')}
              onClick={() => _onOptionSelect()}
            />
          }
        />
      </SpeedDial>
    </>
  );
}

export default PlayerSpeedDial;
