import {FIPlayerObjectiveComment} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import EditIcon from '@mui/icons-material/Edit';

import PlayerObjectiveStatusIcon from './player-objective-status-icon.view';
import {lighten, useTheme} from '@mui/material';
import PlayerObjectiveCommentCreateButton from './player-objective-comment-create-button.view';
import moment from 'moment';

interface IPlayerObjectiveCommmetCardProps {
  objectiveId: string;
  comment: FIPlayerObjectiveComment;
}

function PlayerObjectiveCommentCard(
  props: IPlayerObjectiveCommmetCardProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const _theme = useTheme();
  const [_dateText, _setDateText] = useState<string>('');

  useEffect(() => {
    const _timeSince = moment(props.comment.created_at).fromNow();
    _setDateText(_timeSince);
  }, [props.comment]);

  /*
   * Render
   */

  return (
    <Card
      elevation={0}
      sx={{
        bgcolor: lighten(_theme.palette.background.paper, 0.05),
      }}>
      <CardContent>
        <Typography variant="body2">{props.comment.text}</Typography>

        <Stack direction="row" alignItems="center" gap={2}>
          <Stack direction="row" alignItems="center" flex={1} gap={1}>
            <PlayerObjectiveStatusIcon status={props.comment.status} />
            <Typography variant="body2" color="text.disabled">
              {_dateText}
            </Typography>
          </Stack>

          <PlayerObjectiveCommentCreateButton
            objectiveId={props.objectiveId}
            data={props.comment}
            Button={
              <IconButton color="secondary" size="small">
                <EditIcon />
              </IconButton>
            }
          />
        </Stack>
      </CardContent>
    </Card>
  );
}

export default PlayerObjectiveCommentCard;
