import React from 'react';
import {ITypedOption} from '@/types/option.types';
import {AutocompleteRenderOptionState} from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import Tooltip from '@mui/material/Tooltip';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Box from '@mui/material/Box';

interface IAutocompleteOptionProps<T> {
  optionProps: React.HTMLAttributes<HTMLLIElement>;
  option: ITypedOption<T>;
  state: AutocompleteRenderOptionState;
  multiple?: boolean;
}

function AutocompleteOption<T>(props: IAutocompleteOptionProps<T>) {
  const _Icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const _CheckedIcon = <CheckBoxIcon fontSize="small" />;

  let _Content = (
    <Box component="li" {...props.optionProps} key={`${props.option.value}`}>
      {props.option.label}
    </Box>
  );

  if (props.multiple) {
    _Content = (
      <Box
        {...props.optionProps}
        component="li"
        key={`${props.option.value}`}
        sx={{pl: '0 !important'}}>
        <Checkbox
          icon={_Icon}
          checkedIcon={_CheckedIcon}
          sx={{mr: 0.5}}
          size="small"
          checked={props.state.selected}
        />
        {props.option.label}
      </Box>
    );
  }

  if (!props.option.Tooltip) {
    return _Content;
  }
  return (
    <Tooltip
      // open
      key={`tooltip-${props.option.value}`}
      title={props.option.Tooltip}
      placement="right-start"
      sx={{
        padding: 0,
      }}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: 'transparent',
            p: 0,
            // borderRadius: 0,
            borderWidth: 8,
            borderColor: 'background.default',
            borderStyle: 'solid',
          },
        },
      }}
      PopperProps={{
        sx: {
          width: 200,
        },
      }}>
      {_Content}
    </Tooltip>
  );
}

export default AutocompleteOption;
