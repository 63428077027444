import React from 'react';

import Stack from '@mui/material/Stack';

import {IScreenProps} from '@/types/navigation.types';
import ScreenHeader from './screen-header.view';
import ScreenContent from './screen-content.view';

function Screen(props: IScreenProps): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Handlers
   */

  /*
   * Render
   */

  return (
    <Stack>
      {/* Header */}
      <ScreenHeader {...props} />

      {/* Main Content */}
      <Stack
        px={3}
        paddingTop={3}
        paddingBottom={8}
        gap={4}
        position="relative">
        <ScreenContent
          loadingState={props.loadingState}
          errorPrefix={props.errorPrefix}>
          {props.children}
        </ScreenContent>
      </Stack>
    </Stack>
  );
}

export default Screen;
