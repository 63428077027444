import React from 'react';
import {ICommonSentenceProps} from '@/types/sentence-form.types';

import Segment from '../segment';
import {OPPONENT_MATCH_LIMIT} from '@/config/event-automation-builder.config';
import Sentence from '../sentence.view';

function SentenceOpponentAutomationMatchLimitLine(
  props: ICommonSentenceProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const _commonProps: ICommonSentenceProps = {
    ...props,
  };

  /*
   * Handlers
   */

  /*
   * Render
   */
  const _introKey = props.readonly
    ? 'sentenceForm.share.inTheirLast'
    : 'sentenceForm.share.shareVideos';
  return (
    <>
      <Sentence.Segment>
        <Segment.Text translationKey={_introKey} {..._commonProps} />
      </Sentence.Segment>
      <Sentence.Segment>
        <Segment.NumberInput
          property="match_limit"
          placeholder={OPPONENT_MATCH_LIMIT.toString()}
          value={props.data.match_limit}
          {..._commonProps}
        />
      </Sentence.Segment>
      <Sentence.Segment>
        <Segment.Text
          translationKey="sentenceForm.share.matches"
          translationKeyOptions={{count: props.data.match_limit || 0}}
          {..._commonProps}
        />
      </Sentence.Segment>
    </>
  );
}

export default SentenceOpponentAutomationMatchLimitLine;
