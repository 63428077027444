import React, {useEffect, useState} from 'react';
import {TRACKER_CALCULATION_OPTIONS} from '@/config/event-automation-builder.config';

import SegmentedControl from '../../../common/segmented-control/segmented-control.view';
import AutomationTypeInfo from './automation-type-info/autiomation-type-info.view';

import styles from './automation-builder-form.module.scss';

import Sentence from '../../sentence/builder-sentence.view';

import {
  FIEventAutomationPostData,
  TEventCalculationType,
  isTrackingAction,
} from '@my-game-plan/types';
import {useAutomationForm} from '@/context/event-automations/automations-form.context';

import ExtraAutomationActions from '../../sentence/ExtraAutomationActions';
import {ITypedOption} from '@/types/option.types';

function AutomationBuilderForm(): JSX.Element {
  /* Hooks n State */
  const _automationForm = useAutomationForm();
  const [_calculationOptions, _setCalculationOptions] = useState<
    ITypedOption<TEventCalculationType>[]
  >([]);

  useEffect(() => {
    const _filteredCalculationOptions = TRACKER_CALCULATION_OPTIONS.filter(
      (group) => {
        if (
          _automationForm.currentAutomationType === 'opponent-automation' &&
          group.value === 'rule'
        ) {
          return false;
        }
        return true;
      },
    );

    _setCalculationOptions(_filteredCalculationOptions);
  }, [_automationForm.currentAutomationType]);

  /* Listeners */
  function _onCalculationTypeChange(value: TEventCalculationType) {
    const _data = {..._automationForm.data};
    const _postData: Partial<FIEventAutomationPostData> = {
      calculation: value,
    };

    if (value === 'ratio' && _data.action && isTrackingAction(_data.action)) {
      _postData.action = undefined;
    }

    if (value === 'ratio' && _data.details) {
      _postData.details = undefined;
      _postData.success_details = _data.details;
    } else if (value === 'occurrences') {
      _postData.success_details = undefined;
      if (!_data.details) {
        _postData.details = _data.success_details;
      }
    }

    /* Rule logic */
    if (value === 'rule') {
      _postData.success_details = undefined;
      _postData.rule_condition = {
        type: 'rule',
        seconds: 5,
      };
    } else {
      _postData.rule_condition = undefined;
    }

    if (_data.action === 'sequence') {
      // When changing from a sequence action, sequence_actions must be removed
      delete _postData.sequence_actions;
    }

    _automationForm.setData({
      ..._postData,
    });
  }

  function _onChange(data: Partial<FIEventAutomationPostData>) {
    _automationForm.setData({
      ..._automationForm.data,
      ...data,
    });
  }

  /* Render */
  return (
    <div className={styles.container}>
      <div className={styles.topControls}>
        <div className={styles.typeSelector}>
          <SegmentedControl
            options={_calculationOptions}
            onChange={_onCalculationTypeChange}
            value={_automationForm.data.calculation}
            info={
              <AutomationTypeInfo
                automationType={_automationForm.currentAutomationType}
              />
            }
            infoTitle="eventAutomations.examples.title"
          />
        </div>
        {/* <div className={styles.targetSwitch}>
            <AutomationTragetSwitch selected={_automationForm.withTarget} />
          </div> */}
      </div>
      <div className={styles.sentence}>
        <Sentence
          automationType={_automationForm.currentAutomationType}
          size="medium"
          data={_automationForm.data}
          onChange={_onChange}
          errors={_automationForm.errors}
          isValid={_automationForm.isValid}
        />
      </div>
      <ExtraAutomationActions
        data={_automationForm.data}
        onChange={_onChange}
        automationType={_automationForm.currentAutomationType}
      />
    </div>
  );
}

export default AutomationBuilderForm;
