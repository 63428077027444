import React from 'react';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

interface IPlayerDetailBlockProps {
  title: string;
  HeaderRight?: React.ReactNode;
  children: React.ReactNode;
}

function PlayerDetailBlock(props: IPlayerDetailBlockProps) {
  /*
   * Hooks n State
   */

  /*
   * Render
   */
  return (
    <Stack gap={4}>
      {/* Header */}
      <Stack gap={2}>
        <Stack
          direction={'row'}
          justifyContent={'space-between'}
          alignItems="center">
          <Typography color="text.secondary" variant="h6" lineHeight={1}>
            {props.title}
          </Typography>
          {props.HeaderRight}
        </Stack>
        <Divider />
      </Stack>

      {/* Content */}
      <Box width="100%">{props.children}</Box>
    </Stack>
  );
}

export default PlayerDetailBlock;
