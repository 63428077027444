import React from 'react';

interface Props {
  x: number;
  y: number;
  children: React.ReactNode;
}

export const ChartSvgGroup = ({x, y, children}: Props) => {
  return <g transform={`translate(${x},${y + 16})`}>{children}</g>;
};
