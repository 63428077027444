import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

interface CenteredSpinnerProps {
  style?: React.CSSProperties;
  size?: number;
}

export const CenteredSpinner = ({style, size}: CenteredSpinnerProps) => {
  const _size = size || 24;
  return (
    <div className="center" style={style}>
      <CircularProgress size={_size} />
    </div>
  );
};
