import React from 'react';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Indicator from '../dashboard/Indicator/Indicator';

export interface IScoredTab {
  value: string;
  benchmarked_score?: number;
  label: string;
  isOtherCat?: boolean;
}

interface IScoredTabsProps {
  tabs: IScoredTab[];
  activeTabId: string;
  onTabChange: (value: string) => void;
}

function ScoredTabs(props: IScoredTabsProps): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Hooks n State
   */

  /*
   * Render
   */
  return (
    <Stack direction="row" spacing={2}>
      {props.tabs.map((tab) => {
        const _isActive = tab.value === props.activeTabId;
        return (
          <ButtonBase
            key={tab.value}
            onClick={() => props.onTabChange(tab.value)}
            sx={{
              alignItems: 'flex-start',
              flex: 1,
              p: 2,
              gap: 1,
              flexDirection: 'column',
              borderRadius: 2,
              borderWidth: 1,
              borderStyle: 'solid',
              borderColor: _isActive ? 'text.primary' : 'secondary.dark',
              textAlign: 'left',
              maxWidth: 320,
              '&:hover': {
                borderColor: _isActive ? undefined : 'text.secondary',
              },
            }}>
            <Typography
              color={_isActive ? 'text.primary' : 'text.secondary'}
              fontSize={12}
              lineHeight={1}
              fontWeight={600}>
              {tab.label}
            </Typography>
            {typeof tab.benchmarked_score !== 'undefined' && (
              <Box style={{width: '100%'}}>
                <Indicator value={tab.benchmarked_score} hideValue />
              </Box>
            )}
          </ButtonBase>
        );
      })}
    </Stack>
  );
}

export default ScoredTabs;
