import React, {useEffect} from 'react';
import {ICommonSentenceInputProps} from '@/types/sentence-form.types';
import {IBenchmarkTimeOptions} from '@my-game-plan/types';

import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';

function DateInputSegment(
  props: ICommonSentenceInputProps<Date, IBenchmarkTimeOptions>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_value, _setValue] = React.useState<moment.Moment>(moment());

  /*
   * Side effects
   */
  useEffect(() => {
    _setValue(moment(props.value));
  }, [props.value]);

  /*
   * Handlers
   */
  function _onChange(date: moment.Moment | null) {
    if (!props.onChange) {
      return;
    }

    if (date) {
      props.onChange({
        [props.property]: date.toDate(),
      });
    }
  }

  /*
   * Render
   */

  return (
    <DatePicker
      disabled={props.disabled}
      onChange={_onChange}
      value={_value}
      slotProps={{
        textField: {
          required: props.required,
          variant: 'standard',
          inputProps: {
            size: 12,
            width: 40,
          },
        },
      }}
    />
  );
}

export default DateInputSegment;
