import {getZonesByTeam} from '@/controllers/zones.controllers';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {FIPitchZone} from '@my-game-plan/types';
import {PropsWithChildren, ReactNode, useState} from 'react';
import {useTranslation} from 'react-i18next';

export interface ZonesAPI {
  all: FIPitchZone[];
  getZones: (teamID: string) => void;
}

const context = createCustomContext<ZonesAPI>();
export const useZones = createContextHook(context);

export function ZonesProvider(
  props: PropsWithChildren<ReactNode>,
): JSX.Element {
  const [_zones, _setZones] = useState<FIPitchZone[]>([]);
  const {t} = useTranslation();

  async function _getZonesByTeam(teamID: string) {
    _setZones([]);

    const _fetchedZones = await getZonesByTeam(teamID);

    _fetchedZones.forEach((zone) => {
      if (!zone.team_id) {
        zone.name = t(`zones.defaultZones.${zone.name}`);
      }
    });

    _setZones(_fetchedZones);
  }

  return createProvider(context, props, {
    all: _zones,
    getZones: _getZonesByTeam,
  });
}
