import React, {useEffect, useState} from 'react';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';

import {FISingleSend, FISingleSendPostData} from '@my-game-plan/types';
import {ModalDeleteButton} from '@/components/common/modal/modal-delete-button/modal-delete-button.view';
import {useTranslation} from 'react-i18next';
import {Box, FormControl, TextField} from '@mui/material';
import {ITypedOption} from '@/types/option.types';

import {useTeams} from '@/context/team.context';
import {getPlayerOptions} from '@/helpers/automation/automation-form.helper';

import {capitalize} from 'lodash';
import {useSingleSends} from '@/context/single-sends.context';
import CustomAutocomplete from '@/components/material-customised/autocomplete/autocomplete.view';
import i18n from '@/localization';
import {useVideo} from '@/context/video/video.context';

interface ISingleSendCreatorModalProps {
  data?: FISingleSend;
  isOpened: boolean;
  onClose: () => void;
  events: string[];
}

function SingleSendCreatorModal(
  props: ISingleSendCreatorModalProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const context = useSingleSends();
  const _videoContext = useVideo();
  const {t} = useTranslation();
  const [_playerOptions, _setPlayerOptions] = useState<ITypedOption<string>[]>(
    [],
  );
  const [_selectedPlayerOptions, _setSelectedPlayerOptions] = useState<
    ITypedOption<string>[]
  >([]);
  const [_data, _setData] = useState<Partial<FISingleSendPostData>>({});

  const [loading, setLoading] = React.useState(false);
  const _teamsContext = useTeams();
  const [_error, _setError] = React.useState<string | null>(null);

  useEffect(() => {
    const _sortedPlayers = getPlayerOptions(_teamsContext.ownPlayers || []);
    _setPlayerOptions(_sortedPlayers);
  }, [_teamsContext.ownPlayers]);

  useEffect(() => {
    if (props.data && props.isOpened) {
      const _newData: Partial<FISingleSendPostData> = {
        ...(props.data && {
          title: props.data.title,
          comment: props.data.comment,
          // shared_with_players: props.data.shared_with_players,
        }),
        events: props.events,
        action: props.data.action,
      };

      _setData(_newData);
    } else if (!props.isOpened) {
      _setData({});
    }
  }, [props.data, props.events, props.isOpened]);

  useEffect(() => {
    /* Players */
    let _tempSelectedPlayerOptions: ITypedOption<string>[] = [];
    if (_playerOptions && props.data?.shared_with_players) {
      _tempSelectedPlayerOptions = _playerOptions.filter((player) =>
        props.data?.shared_with_players.players?.includes(player.value),
      );
    }
    _setSelectedPlayerOptions(_tempSelectedPlayerOptions);
  }, [props.data?.shared_with_players, _playerOptions, props.isOpened]);

  /*
   * Handlers
   */
  async function _onDeleteClick() {
    if (props.data?._id) {
      await context.deleteSingleSend(props.data._id);
      props.onClose();
    }
  }

  async function _saveSingleSend() {
    setLoading(true);
    try {
      let _action = _data.action;
      if (!_action && _videoContext.actionInfo?.action) {
        _action = _videoContext.actionInfo.action;
      }
      const _singleSendData = {
        ..._data,
        events: props.events,
        action: _action,
      } as FISingleSendPostData;

      if (_selectedPlayerOptions.length) {
        _singleSendData.shared_with_players = {
          players: _selectedPlayerOptions.map((player) => player.value),
        };
      }

      await context.saveSingleSend(_singleSendData, props.data?._id);
      props.onClose();
    } catch (error: any) {
      if (i18n.exists(`error-states.${error.mesage}`)) {
        _setError(error.message);
      }
      setLoading(false);
    }
    context.getAll();

    setLoading(false);
  }

  /* Render */
  function _onPropertyChange(data: Partial<FISingleSendPostData>) {
    _setError(null);
    _setData({
      ..._data,
      ...data,
    });
  }

  function _onPlayersSelectChange(
    data: ITypedOption<string> | ITypedOption<string>[] | null,
  ) {
    _setError(null);

    _setSelectedPlayerOptions((data as ITypedOption<string>[]) || []);
  }

  function _positionsGroupHeaderKey(text: string): string {
    return capitalize(t(`playerPosition.${text}s`));
  }

  const _buttonLabel = props.data?._id
    ? 'general.save'
    : 'singleSends.create.sharePlaylist';

  return (
    <Dialog
      open={props.isOpened}
      maxWidth="sm"
      fullWidth
      scroll="body"
      onClose={props.onClose}>
      <DialogTitle sx={{m: 0, p: 2}}>
        {t('singleSends.create.sharePlaylist')}
        <IconButton
          aria-label="close"
          onClick={props.onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {_error && (
          <Box sx={{color: 'red', marginBottom: '10px'}}>
            <p>{t([`error-states.${_error}`, 'error-states.default'])}</p>
          </Box>
        )}
        <FormControl fullWidth sx={{mt: 1}}>
          <TextField
            id="outlined-basic"
            label={t('singleSends.create.createTitle')}
            variant="outlined"
            sx={{width: '100%'}}
            onChange={(e) => _onPropertyChange({title: e.target.value})}
            value={_data.title || ''}
          />
        </FormControl>

        <FormControl sx={{mt: 4}} fullWidth>
          <CustomAutocomplete<string, true>
            disableCloseOnSelect
            placeholder={t('sentenceForm.observing.playersPlaceholder')}
            property={'shared_with_players'}
            options={_playerOptions}
            value={_selectedPlayerOptions}
            onChange={_onPlayersSelectChange}
            groupHeader={_positionsGroupHeaderKey}
            multiple={true}
            size="medium"
            // value={props}
            label={t('sentenceForm.share.shareWith')}
            showDefaultColor
          />
        </FormControl>

        <FormControl fullWidth sx={{mt: 4}}>
          <TextField
            id="outlined-multiline-flexible"
            label={t('singleSends.create.comments')}
            multiline
            minRows={4}
            onChange={(e) => _onPropertyChange({comment: e.target.value})}
            value={_data.comment || ''}
          />
        </FormControl>
      </DialogContent>

      <DialogActions>
        {props.data && (
          <ModalDeleteButton
            onConfirm={_onDeleteClick}
            title={t('singleSends.delete.title')}
            description={t('singleSends.delete.body')}
          />
        )}

        <LoadingButton
          loading={loading}
          type="submit"
          variant="contained"
          onClick={_saveSingleSend}>
          {t(_buttonLabel)}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default SingleSendCreatorModal;
