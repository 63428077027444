import React, {useEffect, useState} from 'react';
import {ICommonSentenceProps} from '@/types/sentence-form.types';

import Segment from '../../segment';
import {useTranslation} from 'react-i18next';
import AutomationTypeDropdown from '../../type-dropdown/type-dropdown.view';
import Sentence from '../../sentence.view';
import {
  OPPONENT_AUTOMATION_TRACKKING_OBSERVING_OPTIONS,
  TRACKER_TRACKKING_OBSERVING_OPTIONS,
  TRACKING_DATA_ACTION_OPTIONS,
} from '@/config/event-automation-builder.config';
import {
  ACTION,
  ACTIONS_CONFIG,
  FIEventCondition,
  isTrackingAction,
} from '@my-game-plan/types';
import {IObservingDropdownOptionGroup} from '../../segment/dropdown/observing-dropdown/observing-dropdown-content.view';

function AutomationBaseLineTrackingData(
  props: ICommonSentenceProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_shouldDisplayRuleSeconds, _setShouldDisplayRuleSeconds] =
    useState<boolean>(false);
  const [_observingOptions, _setObservingOptions] = useState<
    IObservingDropdownOptionGroup[]
  >([]);
  const {t} = useTranslation();

  useEffect(() => {
    let _newShouldDisplaySeconds = true;
    if (
      props.data.calculation === 'rule' &&
      props.data.action &&
      isTrackingAction(props.data.action)
    ) {
      const _actionConfig = ACTIONS_CONFIG[props.data.action];

      if (!_actionConfig?.tracking?.entersOnly) {
        _newShouldDisplaySeconds = false;
      }
    }
    _setShouldDisplayRuleSeconds(_newShouldDisplaySeconds);
  }, [props.data.action, props.data.calculation]);

  const _commonProps: ICommonSentenceProps = {
    ...props,
  };

  const _isRule = props.data.calculation === 'rule';
  const _shouldHideSubject = props.hideSubject && !_isRule;

  useEffect(() => {
    const _newOptions =
      props.automationType === 'opponent-automation'
        ? OPPONENT_AUTOMATION_TRACKKING_OBSERVING_OPTIONS
        : TRACKER_TRACKKING_OBSERVING_OPTIONS;

    _setObservingOptions(_newOptions);
  }, [props.automationType, props.data.calculation]);

  /*
   * Handlers
   */
  function _onRuleConditionSecondsChange(value: Partial<FIEventCondition>) {
    if (props.onChange && props.data.rule_condition) {
      props.onChange({
        rule_condition: {
          ...props.data.rule_condition,
          ...value,
        },
      });
    }
  }

  function _onTrackingActionChange(value: ACTION) {
    if (props.onChange) {
      props.onChange({
        action: value,
      });
    }
  }

  /*
   * Render
   */

  {
    /* Sentence Start */
  }
  let _countText = t('sentenceForm.count');
  if (_shouldHideSubject) {
    _countText += ` ${t('sentenceForm.tracking.visitsTo')}`;
  } else {
    _countText += ` ${t('sentenceForm.howOften')}`;
  }
  const _CountSegment = (
    <Sentence.Segment>
      <Segment.Text {..._commonProps} text={_countText} capitalize />
    </Sentence.Segment>
  );

  {
    /* Players */
  }

  let _ObservingSegment = (
    <Segment.Observing
      {..._commonProps}
      property="observing_players"
      value={props.data.observing_players}
      onChange={props.onChange}
      maxItemsToShow={3}
      options={_observingOptions}
      defaultSubject="positions"
      error={props.errors?.observing_players}
      required
      requiredField="positions"
    />
  );
  if (props.data.calculation === 'rule') {
    _ObservingSegment = (
      <Segment.Players
        {..._commonProps}
        property="observing_players"
        value={props.data.observing_players}
        placeholder={t('sentenceForm.observing.anyPlayer')}
      />
    );
  }

  {
    /* Verb - "Must visit" */
  }
  const _MustVisitSegment = (
    <>
      <Sentence.Segment>
        <Segment.Text {..._commonProps} translationKey={'sentenceForm.must'} />
      </Sentence.Segment>
      <Sentence.Segment>
        <Segment.Dropdown
          {..._commonProps}
          value={props.data.action}
          options={TRACKING_DATA_ACTION_OPTIONS}
          onChange={_onTrackingActionChange}
        />
      </Sentence.Segment>
    </>
  );

  {
    /* Zone */
  }

  const _ZoneInputSegment = (
    <Sentence.Segment>
      <Segment.Zones
        {..._commonProps}
        required
        property="pitch_zone"
        value={props.data.pitch_zone}
        error={props.errors?.pitch_zone}
      />
    </Sentence.Segment>
  );

  {
    /* Visits - noun */
  }
  const _VisitsNounSegment = (
    <Sentence.Segment>
      <Segment.Text
        {..._commonProps}
        translationKey={'sentenceForm.tracking.isIn'}
        translationKeyOptions={{
          count: props.data.observing_players?.positions?.length || 1,
        }}
      />
    </Sentence.Segment>
  );

  {
    /* "Within" + "N" + "seconds" */
  }
  const _WithinTextSegment = (
    <Sentence.Segment>
      <Segment.Text
        {..._commonProps}
        translationKey={'sentenceForm.conditions.occurringWithin'}
      />
    </Sentence.Segment>
  );

  const _NumbersInputSegment = (
    <Sentence.Segment>
      <Segment.NumberInput<FIEventCondition>
        {..._commonProps}
        property="seconds"
        value={props.data.rule_condition?.seconds}
        onChange={_onRuleConditionSecondsChange}
      />
    </Sentence.Segment>
  );

  const _SecondsTextSegment = (
    <Sentence.Segment>
      <Segment.Text
        {..._commonProps}
        translationKey={'sentenceForm.conditions.seconds'}
        translationKeyOptions={{count: props.data.rule_condition?.seconds || 0}}
      />
    </Sentence.Segment>
  );

  // const _perMatchText = _isRule
  //   ? t('sentenceForm.timesPerMatch', {count: props.data.target || 0})
  //   : t('sentenceForm.perMatch');
  // const _PerMatchWordSegment = (
  //   <Sentence.Segment>
  //     <Segment.Text {..._commonProps} text={_perMatchText} />
  //   </Sentence.Segment>
  // );

  return (
    <>
      {!_isRule && _CountSegment}
      {!_shouldHideSubject && (
        <>
          {_ObservingSegment}
          {_isRule ? _MustVisitSegment : _VisitsNounSegment}
        </>
      )}

      {_ZoneInputSegment}

      {_shouldDisplayRuleSeconds && _isRule && (
        <>
          {_WithinTextSegment}
          {_NumbersInputSegment}
          {_SecondsTextSegment}
        </>
      )}

      <AutomationTypeDropdown {..._commonProps} action={props.data.action} />
    </>
  );
}

export default AutomationBaseLineTrackingData;
