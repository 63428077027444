import {FIFileUploadPostData} from '@my-game-plan/types';

export function uploadPostDataToFormData(
  postData: Partial<FIFileUploadPostData>,
): FormData {
  const _formData = new FormData();
  Object.keys(postData).forEach((key) => {
    const _key = key as keyof FIFileUploadPostData;

    if (postData[_key]) {
      const _value = postData[_key] as any;
      if (Array.isArray(_value)) {
        if (_value.length) {
          _value.forEach((val) => {
            _formData.append(`${_key}[]`, val);
          });
        }
      } else {
        _formData.append(_key, _value);
      }
    }
  });
  return _formData;
}

export function generateAWSKeyString(teamId: string, id: string) {
  return `${teamId}/${id}`;
}

export function removeExtensionFromFileName(fileName: string): string {
  // Search for latest "." in string and remove the extension
  const _lastDotIndex = fileName.lastIndexOf('.');
  return fileName.slice(0, _lastDotIndex);
}
