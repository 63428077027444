const UPLOAD_TRANSLATIONS = {
  library: 'Library',
  title: 'Shared files',
  empty: 'No files or videos have been uploaded yet',
  filesCount_one: '{{count}} upload',
  filesCount_other: '{{count}} uploads',

  fields: {
    title: 'Title',
    description: 'Short description',
    category: 'Category',
    type: 'Type',
    expirationDate: 'Expiration date',
    expirationDateShort: 'Expires',
    sharedWithPlayers: 'Shared with players',
    sharedWithPlayersShort: 'Shared with',
    uploadedAt: 'Date',
    optional: 'optional',
  },
  actions: {
    title: 'Actions',
    watchVideo: 'Watch video',
    viewFile: 'View file',

    edit: {
      title: 'Edit file',
      error:
        'Something went wrong while editing the file. Please try again later',
    },
    delete: {
      title: 'Delete file',
      text: 'Are you sure you want to delete this file?',
      description: 'This will also remove linked files.',
      error:
        'Something went wrong while deleting the file. Please try again later',
    },
  },
  categories: {
    training: 'Training',
    'match-prep': 'Match prep',
    'ad-hoc': 'Ad hoc',
    idp: 'IDP',
    club: 'Club',
  },
  values: {
    notShared: 'Not shared',
    noExpiration: "Doesn't expire",
    allCategories: 'All categories',
  },
  uploadForm: {
    title: 'Upload file or video',
    editTitle: 'Edit file or video',

    file: {
      placeholder: 'Select file',
      browse: 'Browse files',
      dragAndDrop: 'Drag and drop a file here',
      invalid: 'Please upload a valid file',
      allowedFiles: '{{formats}} files are allowed',
      uploadPending:
        'Something went wrong while uploading this file, please try again.',
    },

    submit: 'Upload file',
    update: 'Update file',
    uploading: 'Uploading file...',

    error: {
      default:
        'Something went wrong while uploading the file. Please try again later',
    },

    closeWarning: {
      text: 'Cancel upload',
      title: 'Are you sure you want to cancel the upload?',
      description: 'All changes will be lost.',
    },
  },
  filters: {
    showExpired: 'Show expired files',
  },

  matchVideos: {
    title: 'Match videos',
  },
};

export default UPLOAD_TRANSLATIONS;
