const PLAYER_TRANSLATIONS = {
  players_one: 'Player',
  players_other: 'Players',
  error: {
    default: {
      header: 'Something went wrong loading player.',
      description: 'Please try again later.',
    },
  },

  info: {
    ga: 'G/A',
    minutesPlayed: 'Min. played',
    age: 'Age',
    height: 'Height',
    weight: 'Weight',
    foot: 'Foot',
    position: 'Position',
    nA: 'N/A',
    country: 'Country',
    competition: 'Competition',

    noClub: 'No club',
  },

  performance: {
    title: 'Performance',
    other: 'General',
    noData: {
      short: 'No trackers',
      header: 'No trackers are assigned to this player',
      description:
        "To calculate a player's score across categories, assign a performance tracker to them and add it to the right category.",
    },
    mirrorring: {
      mirrorringPlayer: 'Mirroring {{player}}',
      lastSyncAt: 'Last synced {{date}}',
      today: 'today',
      resync: 'Resync trackers and get new data',
      changePlayer: 'Change player',
      unlinkPlayer: 'Unlink player',
      moreOptions: 'Sync data or change player',
      builder: {
        applyTrackers: 'Apply trackers',
        syncing: {
          header: 'Applying trackers',
          description:
            'Depending on the amount of trackers, this may take a while.',
        },
        waiting: 'Calculation starting soon - waiting to be picked up.',
        selectPlayer: {
          title: 'Select player to calculate performance.',
          description:
            'Select a player from your squad to mirror.\n Trackers assigned to selected player will be applied to {{name}}.',
          placeholder: 'Select a player from your squad',
        },
        selectTrackers: {
          title: 'Select trackers',
          description:
            'Select trackers used to measure performance. Using all trackers by default.',
        },
        error: {
          header:
            'Something went wrong applying trackers. Please try again later.',
        },
      },
    },
  },
  physical: {
    title: 'Physical',
    possessionBreakdown: 'Possession breakdown',
    per90: "Per 90'",

    benchmark_average: 'Benchmark',
    max: 'Max',
    benchmark: 'Benchmark',
    average: 'Average',
    average_tip: 'Average (TIP)',
    average_otip: 'Average (OTIP)',

    total: 'Total',
    tip: 'In possession',
    otip: 'Out of possession',

    noData: {
      header: "Couldn't find physical data for player",
    },
  },

  benchmark: {
    overallPosBenchmark: 'Overal {{pos}} benchmark',
  },

  cta: {
    viewPlayer: 'View player',
  },
  actions: {
    title: 'Player actions',
    removePlayer: {
      title: 'Remove player from history',
      description:
        'Removing the player from your search history will also remove trackers assigned to them.',
      cta: 'Remove player',
    },
  },
};

export default PLAYER_TRANSLATIONS;
