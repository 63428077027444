import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';
import {
  Dimensions,
  IPitchVisualEventGroup,
} from '@/types/dashboard/dashboard-pitch-visual.types';

import Logo from '../../common/Logo/Logo';
import {Heatmap} from './Heatmap';
import styles from './pitch-visual.module.scss';
import ScatterPlot from './ScatterPlot';

import PITCH_IMAGE from '../../../assets/images/pitch-visual/pitch.svg';
import HALF_PITCH_IMAGE from '../../../assets/images/pitch-visual/pitch-half.svg';
import GOAL_IMAGE from '../../../assets/images/pitch-visual/goal.svg';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import SouthIcon from '@mui/icons-material/South';
import NorthIcon from '@mui/icons-material/North';
import ShieldIcon from '@mui/icons-material/Shield';

import {useTeams} from '@/context/team.context';
import {
  ACTION,
  ACTIONS_CONFIG,
  FICompactMatchInfo,
  FIConditionalEventFilters,
  FIEventFilters,
  FIMatch,
  FIPitchZone,
  PitchVisualFocusType,
  PitchVisualType,
} from '@my-game-plan/types';

const PITCH_RATIO = 1.4625;
const HALF_PITCH_RATIO = 0.73;
const GOAL_RATIO = 0.45;

interface PitchProps {
  type: PitchVisualType;
  eventGroups: IPitchVisualEventGroup[];
  showPath: boolean;
  defaultFocusType: PitchVisualFocusType;
  linkedTable: string;
  action: ACTION;
  details?: FIConditionalEventFilters;
  metric?: keyof FIEventFilters;
  zones?: FIPitchZone[];
  teamLogo?: string;
  isOwnTeam?: boolean;
  fullWidth?: boolean;
  isObservingOpponent?: boolean;
  displayDirectionIcon?: boolean;
  shouldDifferentiateSuccessfulEvents?: boolean;
  matches?: FIMatch[] | FICompactMatchInfo[];
  isShowingSequences?: boolean;
}

const HALF_PITCH_TYPES = [
  PitchVisualType.HEATMAP_HALF_PITCH,
  PitchVisualType.HEATMAP_GOAL,
  PitchVisualType.SCATTER_HALF_PITCH,
  PitchVisualType.SCATTER_GOAL,
];

function Pitch({
  type,
  eventGroups,
  showPath,
  defaultFocusType,
  linkedTable,
  action,
  details,
  metric,
  zones,
  teamLogo = '',
  isOwnTeam = false,
  fullWidth = false,
  isObservingOpponent,
  displayDirectionIcon = false,
  shouldDifferentiateSuccessfulEvents = false,
  matches,
  isShowingSequences,
}: PitchProps): JSX.Element | null {
  /* Hooks n State */
  const _containerRef = useRef<HTMLDivElement>(null);
  const _teamsContext = useTeams();

  const [_goalDimensions, _setGoalDimensions] = useState<Dimensions | null>(
    null,
  );
  const [_dimensions, _setDimensions] = useState<Dimensions | null>(null);

  const [_isOpponentAction, _setIsOpponentAction] = useState<boolean>(false);
  const [_shouldDisplayOnOwnHalf, _setShouldDisplayOnOwnHalf] =
    useState<boolean>(false);

  useEffect(() => {
    window.addEventListener('resize', _handleResize);

    _handleResize();
    return () => {
      window.removeEventListener('resize', _handleResize);
    };
  }, [_containerRef.current]);

  useEffect(() => {
    const _actionConfig = ACTIONS_CONFIG[action];

    const _shouldSetIsOpponentAction =
      isObservingOpponent || _actionConfig?.isOpponentAction || false;
    _setIsOpponentAction(_shouldSetIsOpponentAction);

    if (
      _actionConfig?.definition.event_type?.includes('keeper_defensive_action')
    ) {
      _setShouldDisplayOnOwnHalf(true);
    } else {
      _setShouldDisplayOnOwnHalf(false);
    }
  }, [action, isObservingOpponent]);

  const _isHalf = HALF_PITCH_TYPES.includes(type);

  /* Handlers */
  function _handleResize() {
    if (_containerRef.current) {
      let _ratio = PITCH_RATIO;
      if (_isHalf) {
        _ratio = HALF_PITCH_RATIO;
      }

      _setDimensions({
        width: _containerRef.current.clientWidth,
        height: _containerRef.current.clientWidth * _ratio,
      });

      _setGoalDimensions({
        width: _containerRef.current.clientWidth,
        height: _containerRef.current.clientWidth * GOAL_RATIO,
      });
    }
  }

  /* Render */
  let _PitchOverlayNode = null;
  let _GoalNode = null;
  let _GoalOverlayNode = null;
  const _FullPitchNode = (
    <img
      className={styles.pitchImage}
      src={PITCH_IMAGE}
      alt="Pitch"
      width={_dimensions?.width || '100%'}
      height={_dimensions?.height || 1}
    />
  );

  const _HalfPitchNode = (
    <img
      className={classNames(styles.pitchImage, styles.pitchImageHalf)}
      src={HALF_PITCH_IMAGE}
      alt="Pitch"
      width={_dimensions?.width || '100%'}
      height={_dimensions?.height || 1}
    />
  );

  const _GoalImageNode = (
    <img
      className={styles.goal}
      src={GOAL_IMAGE}
      alt="Goal"
      width={_goalDimensions?.width || '100%'}
      height={_goalDimensions?.height || 1}
    />
  );
  const _PitchNode = _isHalf ? _HalfPitchNode : _FullPitchNode;

  // This could be done more efficiently, feel free to refactor this switch case
  switch (type) {
    case PitchVisualType.DOMINANCE:
      _PitchOverlayNode = <div>{'dominance view'}</div>;
      break;

    case PitchVisualType.HEATMAP:
      if (_dimensions) {
        _PitchOverlayNode = (
          <Heatmap
            action={action}
            eventGroups={eventGroups}
            dimensions={_dimensions}
            linkedTable={linkedTable}
            showPath={showPath}
            defaultFocusType={defaultFocusType}
            defending={_isOpponentAction}
          />
        );
      }
      break;

    case PitchVisualType.HEATMAP_GOAL:
      if (_dimensions && _goalDimensions) {
        _GoalNode = _GoalImageNode;
        // Should be a separate component. Reuse heatmap?
        _GoalOverlayNode = (
          <Heatmap
            action={action}
            eventGroups={eventGroups}
            dimensions={_goalDimensions}
            linkedTable={linkedTable + '_goal'}
            defaultFocusType={PitchVisualFocusType.END}
            defending={_isOpponentAction}
            isGoal
          />
        );
        _PitchOverlayNode = (
          <Heatmap
            action={action}
            eventGroups={eventGroups}
            dimensions={_dimensions}
            linkedTable={linkedTable}
            defaultFocusType={defaultFocusType}
            defending={_isOpponentAction}
            isHalf={_isHalf}
          />
        );
      }
      break;

    case PitchVisualType.HEATMAP_HALF_PITCH:
      if (_dimensions) {
        _PitchOverlayNode = (
          <Heatmap
            action={action}
            dimensions={_dimensions}
            eventGroups={eventGroups}
            linkedTable={linkedTable}
            showPath={showPath}
            defaultFocusType={defaultFocusType}
            defending={_isOpponentAction}
            isHalf={_isHalf}
            shouldDisplayOnOwnHalf={_shouldDisplayOnOwnHalf}
          />
        );
      }
      break;

    case PitchVisualType.SCATTER:
      if (_dimensions) {
        _PitchOverlayNode = (
          <ScatterPlot
            dimensions={_dimensions}
            eventGroups={eventGroups}
            type={type}
            showPath={showPath}
            defaultFocusType={defaultFocusType}
            defending={_isOpponentAction}
            action={action}
            details={details}
            zones={zones}
            shouldDifferentiateSuccessfulEvents={
              shouldDifferentiateSuccessfulEvents
            }
            matches={matches}
            isShowingSequences={isShowingSequences}
          />
        );
      }
      break;

    case PitchVisualType.SCATTER_GOAL:
      _GoalNode = _GoalImageNode;
      if (_dimensions && _goalDimensions) {
        _GoalOverlayNode = (
          // Move this to a separate component
          <ScatterPlot
            dimensions={_goalDimensions}
            eventGroups={eventGroups}
            type={type}
            showPath={false}
            defaultFocusType={PitchVisualFocusType.END}
            defending={_isOpponentAction}
            isGoal
            action={action}
            details={details}
            metric={metric}
            matches={matches}
            shouldDifferentiateSuccessfulEvents={
              shouldDifferentiateSuccessfulEvents
            }
          />
        );
        _PitchOverlayNode = (
          <ScatterPlot
            dimensions={_dimensions}
            eventGroups={eventGroups}
            type={type}
            showPath={showPath}
            defaultFocusType={defaultFocusType}
            isHalf={_isHalf}
            defending={_isOpponentAction}
            action={action}
            details={details}
            metric={metric}
            matches={matches}
            isShowingSequences={isShowingSequences}
            shouldDifferentiateSuccessfulEvents={
              shouldDifferentiateSuccessfulEvents
            }
          />
        );
      }
      break;

    case PitchVisualType.SCATTER_HALF_PITCH:
      if (_dimensions) {
        _PitchOverlayNode = (
          <ScatterPlot
            dimensions={_dimensions}
            eventGroups={eventGroups}
            type={type}
            showPath={showPath}
            defaultFocusType={defaultFocusType}
            isHalf={_isHalf}
            shouldDisplayOnOwnHalf={_shouldDisplayOnOwnHalf}
            defending={_isOpponentAction}
            action={action}
            details={details}
            metric={metric}
            matches={matches}
            isShowingSequences={isShowingSequences}
            shouldDifferentiateSuccessfulEvents={
              shouldDifferentiateSuccessfulEvents
            }
          />
        );
      }
      break;

    default:
      _PitchOverlayNode = null;
      break;
  }

  let _logoPath = '';

  if (teamLogo) {
    _logoPath = teamLogo;
  } else if (isOwnTeam && _teamsContext.ownTeam) {
    _logoPath = _teamsContext.ownTeam.image_url;
  } else if (_teamsContext.current) {
    _logoPath = _teamsContext.current.image_url;
  }

  // Arrow
  let _ArrowNode = null;
  if (displayDirectionIcon) {
    const _ArrowIcon = isObservingOpponent ? NorthIcon : SouthIcon;
    _ArrowNode = (
      <Stack
        direction="row"
        spacing={2}
        alignItems={'center'}
        justifyContent={'center'}
        sx={{
          position: 'absolute',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%,-50%)',
        }}>
        {[0, 1, 2].map((i) => {
          return (
            <Box key={i} pt={i === 1 ? 0 : 6}>
              <_ArrowIcon
                sx={{
                  fontSize: 32,
                  opacity: 0.8,

                  color: 'secondary.light',
                }}
              />
            </Box>
          );
        })}
      </Stack>
    );
  }

  const _containerStyles = classNames(styles.container, {
    [styles.containerDefending]: _isOpponentAction,
    [styles.containerOwnHalf]: _shouldDisplayOnOwnHalf,
    [styles.containerFullWidth]: fullWidth,
  });

  return (
    <div className={_containerStyles} ref={_containerRef}>
      {_GoalNode && (
        <div className={styles.goalWrapper}>
          {_GoalNode}
          {_GoalOverlayNode}
        </div>
      )}
      <div className={styles.pitchWrapper}>
        {_PitchNode}
        {_ArrowNode}
        {_logoPath ? (
          <div
            className={classNames(styles.teamLogo, styles.teamLogoTransparent)}>
            <Logo src={_logoPath} size={fullWidth ? 'medium' : 'large'} />
          </div>
        ) : (
          <div className={styles.teamLogo}>
            <ShieldIcon color="secondary" />
          </div>
        )}
        {_PitchOverlayNode}
      </div>
    </div>
  );
}

export default Pitch;
