import {
  SimplePaletteColorOptions,
  TypeBackground,
  TypeText,
  createTheme,
} from '@mui/material';

export const PALETTE_PRIMARY: SimplePaletteColorOptions = {
  main: '#5CA58B', // ACCENT
  light: '#8DC0AE',
  dark: '#407361',
};

export const PALETTE_SECONDARY: SimplePaletteColorOptions = {
  main: '#4c6273', // CAPTIONS
  light: '#6B7C89', // NEW - CAPTIONS LIGHT
  dark: '#192B39', // SEPARATOR
};

export const PALETTE_SUCCESS: SimplePaletteColorOptions = {
  main: '#00875a',
  light: '#13bb80',
  dark: '#016743',
};

export const PALETTE_ERROR: SimplePaletteColorOptions = {
  main: '#AD2929',
  light: '#CA2D2D',
  dark: '#992626',
};

export const PALETTE_WARNING: SimplePaletteColorOptions = {
  main: '#ffa726',
  light: '#ffb74d',
  dark: '#f57c00',
};

export const PALETTE_INFO: SimplePaletteColorOptions = {
  main: '#0288D1',
  light: '#03A9F4',
  dark: '#01579B',
};

export const PALETTE_TEXT: Partial<TypeText> = {
  primary: 'rgba(255, 255, 255, 0.87)',
  secondary: 'rgba(255, 255, 255, 0.54)',
  disabled: 'rgba(255, 255, 255, 0.38)',
};

export const PALETTE_BACKGROUND: Partial<TypeBackground> = {
  default: '#0e1d28', // BACKGROUND
  paper: '#162632', // SURFACE
};

export const PALETTE_DIVIDER = 'rgba(255, 255, 255, 0.08)';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: PALETTE_PRIMARY,
    secondary: PALETTE_SECONDARY,
    background: PALETTE_BACKGROUND,
    divider: PALETTE_DIVIDER,
    success: PALETTE_SUCCESS,
    error: PALETTE_ERROR,
    text: PALETTE_TEXT,
    warning: PALETTE_WARNING,
    info: PALETTE_INFO,
  },
  typography: {
    fontFamily: 'Inter',
    caption: {
      textTransform: 'uppercase',
      color: '#4c6273',
      fontSize: 12,
      fontWeight: '500',
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'unset',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        dashed: {
          visibility: 'hidden',
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: ({theme}) => ({
          '& .MuiTableCell-root': {
            textTransform: 'uppercase',
            fontSize: 12,
            color: theme.palette.secondary.light,
            fontWeight: '500',
          },
        }),
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: ({theme}) => ({
          borderBottomColor: theme.palette.divider,
        }),
      },
    },
  },
});
