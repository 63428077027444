import TrackerDetailView from '@/views/automations/tracker_detail.view';
import TrackersView from '@/views/automations/trackers.view';
import PlayerView from '@/views/player.view';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Route, Routes} from 'react-router-dom';

function PlayersRoutes() {
  const {t} = useTranslation();
  return (
    <Routes>
      <Route
        path=""
        element={<TrackersView title={t('playerPerformance.title')} />}
      />

      <Route path="trackers/:id" element={<TrackerDetailView />} />
      <Route path=":id" element={<PlayerView />} />
    </Routes>
  );
}

export default PlayersRoutes;
