import React from 'react';

import {
  FICompactMatchInfo,
  FIEventFilters,
  FIMatch,
  FIMatchEvent,
} from '@my-game-plan/types';
import PlaylistItemEvent from './PlaylistItemEvent';
import PlaylistItemSequence from './PlaylistItemSequence';

export interface PlaylistItemProps {
  playlistItem: FIMatchEvent;
  isActive?: boolean;
  playClip: (clip: FIMatchEvent) => void;
  canSelect?: boolean;
  isSelected?: boolean;
  toggleSelect?: (
    e: React.ChangeEvent<HTMLInputElement>,
    clips: FIMatchEvent[],
  ) => void;
  isRatio?: boolean;
  observingMetric?: keyof FIEventFilters;
  match: FIMatch | FICompactMatchInfo;
  isShowingSequences?: boolean;
  sequenceEvents?: FIMatchEvent[];
  eventNumber?: number;
  disableCollapse?: boolean;
}

function PlaylistItemGeneral({
  playlistItem,
  isActive,
  isSelected,
  playClip,
  toggleSelect,
  canSelect = false,
  isRatio,
  observingMetric,
  match,
  isShowingSequences,
  sequenceEvents,
  disableCollapse,
}: PlaylistItemProps) {
  // If showing sequences, render PlaylistItemSequence, otherwise render PlaylistItem
  if (isShowingSequences) {
    return (
      <PlaylistItemSequence
        playlistItem={playlistItem}
        key={playlistItem._id}
        isActive={isActive}
        isSelected={isSelected}
        isRatio={isRatio}
        playClip={playClip}
        toggleSelect={toggleSelect}
        canSelect={canSelect}
        observingMetric={observingMetric}
        match={match}
        isShowingSequences={isShowingSequences}
        sequenceEvents={sequenceEvents}
        disableCollapse={disableCollapse}
      />
    );
  } else {
    return (
      <PlaylistItemEvent
        playlistItem={playlistItem}
        key={playlistItem._id}
        isActive={isActive}
        isSelected={isSelected}
        isRatio={isRatio}
        playClip={playClip}
        toggleSelect={toggleSelect}
        canSelect={canSelect}
        observingMetric={observingMetric}
        match={match}
        isShowingSequences={isShowingSequences}
        sequenceEvents={sequenceEvents}
      />
    );
  }
}

export default PlaylistItemGeneral;
