import React from 'react';
import {FIEventAutomationSubject} from '@my-game-plan/types';
import FormLabel from '@mui/material/FormLabel';
import AvatarGroup from '@/components/common/avatar-group/avatar-group.view';
import Box from '@mui/material/Box';

import {useTranslation} from 'react-i18next';

import {getSelectedPositionOptions} from '@/helpers/automation/automation-form.helper';
import {capitalize} from 'lodash';

export interface IOverviewCardField {
  label?: string;
  type: 'text' | 'players' | 'score';
  value?: string | FIEventAutomationSubject | number;
  valueDiff?: number;
  relative?: boolean;
}

interface IOverviewCardFieldProps {
  data: IOverviewCardField;
  fullWidth?: boolean;
}

function OverviewCardField(props: IOverviewCardFieldProps): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Handlers
   */

  /*
   * Render
   */
  if (
    props.data.type !== 'players' &&
    !props.data.value &&
    props.data.value !== 0
  ) {
    return null;
  }

  let _Value = null;

  if (props.data.type === 'text') {
    /* String */
    _Value = props.data.value;
  } else if (props.data.type === 'players') {
    /* Players */
    const _typedPlayersValue = props.data.value as FIEventAutomationSubject;

    if (!_typedPlayersValue) {
      _Value = capitalize(t('sentenceForm.share.placeholder'));
    } else if (_typedPlayersValue.players?.length) {
      _Value = <AvatarGroup players={_typedPlayersValue.players} max={5} />;
    } else if (_typedPlayersValue.positions?.length) {
      const _selected = getSelectedPositionOptions(
        _typedPlayersValue.positions,
        false,
      );
      _Value = _selected.join(', ');
    } else if (_typedPlayersValue.team) {
      _Value = t('eventAutomations.allPlayers');
    }
  }
  // else if (props.data.type === 'score') {
  //   /* Scores */
  //   _Value = (
  //     <ScoreTag
  //       score={props.data.value as number}
  //       difference={props.data.valueDiff}
  //       relative={props.data.relative}
  //     />
  //   );
  // }

  return (
    <Box sx={{flex: 1}}>
      {props.data.label && (
        <FormLabel
          color="secondary"
          sx={{
            textTransform: 'uppercase',
            fontSize: 10,
            fontWeight: '600',
            marginBottom: 1,
            opacity: 0.6,
            display: 'block',
          }}>
          {props.data.label}
        </FormLabel>
      )}
      <div>{_Value}</div>
    </Box>
  );
}

export default OverviewCardField;
