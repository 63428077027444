import {
  ACTION,
  ACTIONS_CONFIG,
  FIMatchEvent,
  FPass,
  FShot,
  FIEventFilters,
} from '@my-game-plan/types';
import {capitalize} from 'lodash';
import {TFunction} from 'react-i18next';
import i18n from '../localization';

const OPPONENT_ACTION_PREFIX = 'opponent_';
const GENERAL_MATCH_FILTER_KEYS: (keyof FIEventFilters)[] = [
  'game_states',
  'position_in_match',
  'team.formation',
  'opponent_team.defence',
  'pass.receiving_player.position',
  'time_block',
];
const CAPITALIZED_FIELDS: (keyof FIEventFilters)[] = [
  'player._id',
  'pass.receiving_player._id',
];
const UPPERCASED_FIELDS: (keyof FIEventFilters)[] = [
  'player.position',
  'pass.receiving_player.position',
];

export function translateAction(action: string, count = 2): string {
  const _strippedAction = action.replace(OPPONENT_ACTION_PREFIX, '');
  const _actionKey = `event.action.all.${_strippedAction}`;
  const _actionTranslation = i18n.t(_actionKey, {
    count: count,
  });
  if (action.startsWith(OPPONENT_ACTION_PREFIX)) {
    return i18n.t('event.action.opponent', {action: _actionTranslation});
  }
  if (i18n.exists(_actionKey, {count: count})) {
    return _actionTranslation;
  }

  return action;
}

export function translateValue(
  value: string,
  key?: keyof FIEventFilters,
  shouldCapitalize?: boolean,
): string {
  let _translationToReturn = value;
  if (key && GENERAL_MATCH_FILTER_KEYS.includes(key)) {
    _translationToReturn = translateGeneralFilterValue(key, value);
  } else if (i18n.exists(`event.general.${value}`)) {
    /* Check if translation exists in general translations */
    _translationToReturn = i18n.t(`event.general.${value}`);
  } else if (i18n.exists(`event.values.${value}`)) {
    /* Check if translation exists in values */
    _translationToReturn = i18n.t(`event.values.${value}`);
  } else {
    /* Check if translation is actually an action */
    _translationToReturn = translateAction(value, 1);
  }

  if (
    !shouldCapitalize &&
    (!key ||
      (!CAPITALIZED_FIELDS.includes(key) &&
        (!key || !UPPERCASED_FIELDS.includes(key))))
  ) {
    return _translationToReturn.toLowerCase();
  } else if (shouldCapitalize && (!key || !UPPERCASED_FIELDS.includes(key))) {
    return capitalize(_translationToReturn);
  }

  return _translationToReturn;
}

export function translateEventProperty(key: keyof FIEventFilters): string {
  return i18n.t(`event.properties.${key}`);
}

export function translateGeneralFilterValue(
  key: keyof FIEventFilters,
  value: string,
): string {
  let _label = '';

  switch (key) {
    case 'time_block':
      _label = translateValue(`time_blocks.${value}`);
      break;

    case 'opponent_team.defence':
    case 'team.formation':
    case 'pass.receiving_player._id':
    case 'pass.receiving_player.position':
      _label = value;
      break;

    default:
      _label = translateValue(value);
      break;
  }

  return _label;
}

export function getTranslatedSuccessColumn(
  t: TFunction<'translation', undefined>,
  label: string,
  action?: string,
) {
  // If the action has a custom success column, return it
  if (action && label === 'success') {
    const _actionConfig = ACTIONS_CONFIG[action as ACTION];
    if (_actionConfig?.definition.event_type?.length) {
      const _eventType = _actionConfig.definition.event_type[0];
      if (i18n.exists(`event.success.${_eventType}`)) {
        return t(`event.success.${_eventType}`);
      }
    }

    return t('event.success.default');
  }

  // if (i18n.exists(`event.values.${label}`)) {
  //   return t(`event.values.${label}`);
  // }

  if (i18n.exists(`event.general.${label}`)) {
    return t(`event.general.${label}`);
  }

  if (i18n.exists(`event.action.all.${label}`, {count: 2})) {
    return translateAction(label);
  }

  // Else return the default success column
  if (label === 'success') return t('event.success.default');

  // If column is not 'success', return the column
  return t(`event.values.${label}`);
}

export const getTranslatedSubscoreProperty = (
  action: string,
  property: string,
) => {
  const translatedAction = translateAction(action, 2).toLowerCase();
  const propertyTranslated = i18n
    .t(
      [
        `event.properties.${property}`,
        `event.values.${property}`,
        `event.action.all.${property}`,
        `event.general.${property}`,
      ],
      {count: 2},
    )
    .toLowerCase();

  if (property === 'success' && i18n.exists(`event.success_values.${action}`))
    return i18n.t('dashboard.scores.table.goals');

  if (property === 'success') return i18n.t('dashboard.scores.table.success');

  return property === 'total'
    ? i18n.t('dashboard.scores.table.total', {action: translatedAction})
    : i18n.t('dashboard.scores.table.total', {action: propertyTranslated});
};

export function startsWithVowel(string?: string): boolean {
  const _regex = new RegExp('^[aeiou].*', 'i');
  if (!string) return false;

  return _regex.test(string);
}

export function toggleActionSnakeCase(
  action: string,
  shouldConvertToURL = false,
) {
  if (shouldConvertToURL) {
    return action.replace(/_/g, '-');
  }
  return action.replace(/-/g, '_');
}

export function getVerbKey(action?: ACTION, third?: boolean): string {
  const _prefix = third ? 'event.verbs' : 'event.verbsThird';
  if (i18n.exists(`${_prefix}.${action}`)) {
    return `${_prefix}.${action}`;
  }

  // Return event type if possible
  if (action) {
    const _definition = ACTIONS_CONFIG[action]?.definition;
    if (_definition?.event_type?.length) {
      const _eventTypeKey = _definition?.event_type[0];
      if (i18n.exists(`${_prefix}.${_eventTypeKey}`)) {
        return `${_prefix}.${_eventTypeKey}`;
      }
    }
  }

  // Return "perform" by default
  return `${_prefix}.default`;
}

export function hashtagify(key: string, shouldTranslate = true): string {
  let _translatedKey = key;
  if (shouldTranslate) {
    _translatedKey = translateValue(key);
  }
  const _words = _translatedKey.split(' ');

  // Capitalize the first letter of each word and join them without spaces
  const hashed = _words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join('');
  return hashed;
}

export function generateTagsForEvent(matchEvent: FIMatchEvent): string[] {
  const tags: string[] = [];
  if (!matchEvent.event_type) return tags;
  tags.push(hashtagify(matchEvent.event_type));

  if (matchEvent.result) tags.push(hashtagify(matchEvent.result));
  if (matchEvent.action_result) tags.push(hashtagify(matchEvent.action_result));
  if (matchEvent.secondary_event_types)
    tags.push(
      ...matchEvent.secondary_event_types.map((type) =>
        hashtagify(type as string),
      ),
    );

  if (matchEvent.pass) {
    const _passKeys: (keyof FPass)[] = [
      'set_piece_type',
      'direction_group',
      'length_group',
    ];

    _passKeys.forEach((key) => {
      if (matchEvent.pass?.[key]) {
        tags.push(hashtagify(matchEvent.pass[key] as string));
      }
    });
  }
  if (matchEvent.shot) {
    const _shotKeys: (keyof FShot)[] = ['set_piece_type'];

    _shotKeys.forEach((key) => {
      if (matchEvent.shot?.[key]) {
        tags.push(hashtagify(matchEvent.shot[key] as string));
      }
    });
  }
  return tags;
}
