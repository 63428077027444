import {IScreenProps} from '@/types/navigation.types';
import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {Link} from 'react-router-dom';
import ScreenHeaderTabs from './screen-header-tabs.view';

function ScreenHeader(props: IScreenProps): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Side Effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  let _Header = null;
  if (props.HeaderContent) {
    _Header = props.HeaderContent;
  } else if (props.title) {
    _Header = (
      <Typography fontSize={24} lineHeight={1.4} fontWeight={600}>
        {props.title}
      </Typography>
    );
  }

  let _paddingBottom = 2;
  if (props.tabs) {
    _paddingBottom = 0;
  } else if (props.HeaderBottomContent) {
    _paddingBottom = 1;
  }
  return (
    <Stack
      gap={0}
      pt={props.backLink ? 2 : 4}
      pb={_paddingBottom}
      px={3}
      alignItems="flex-start"
      bgcolor={props.coloredHeader ? 'background.paper' : undefined}
      sx={{
        borderBottomColor: 'divider',
        borderBottomStyle: 'solid',
        borderBottomWidth: props.displayHeaderBorder ? 1 : 0,
      }}>
      {props.backLink && (
        <Button
          variant="text"
          color="secondary"
          component={Link}
          size="small"
          to={props.backLink.route}
          startIcon={<ArrowBackIcon />}
          sx={{mb: 2}}>
          {props.backLink.label}
        </Button>
      )}
      <Stack direction="row" width="100%" gap={3} alignItems="center">
        <Box flex={1}>{_Header}</Box>
        {props.HeaderRightContent && props.HeaderRightContent}
      </Stack>

      {props.HeaderBottomContent && (
        <Stack width="100%" mt={1}>
          {props.HeaderBottomContent}
        </Stack>
      )}

      <ScreenHeaderTabs tabs={props.tabs} />
    </Stack>
  );
}

export default ScreenHeader;
