import {FIPlayerObjective} from '@my-game-plan/types';
import React, {useState} from 'react';

import PlayerObjectivesForm from './player-objectives-form/player-objectives-form.view';

interface IPlayerObjectiveCreateButtonProps {
  Button: React.ReactElement;
  data?: FIPlayerObjective;
  playerId: string;
}

function PlayerObjectiveCreateButton(
  props: IPlayerObjectiveCreateButtonProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_isModalOpen, _setIsModalOpen] = useState<boolean>(false);

  /*
   * Handlers
   */
  function _onModalOpen() {
    _setIsModalOpen(true);
  }

  function _onModalClose() {
    _setIsModalOpen(false);
  }

  function _onButtonClick(e: React.MouseEvent<HTMLButtonElement>) {
    _onModalOpen();
    if (props.Button?.props.onClick) {
      props.Button.props.onClick(e);
    }
  }

  /*
   * Render
   */
  return (
    <>
      {React.cloneElement(props.Button, {onClick: _onButtonClick})}
      <PlayerObjectivesForm
        isOpen={_isModalOpen}
        onClose={_onModalClose}
        data={props.data}
        playerId={props.playerId}
      />
    </>
  );
}

export default PlayerObjectiveCreateButton;
