import React, {useEffect} from 'react';
import IconButton from '@mui/material/IconButton';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Drawer from '@mui/material/Drawer';
import {useTranslation} from 'react-i18next';

import {IWidgetPlaceholder} from '@/types/dashboard/overview-dashboards.types';
import {useOverviewDashboards} from '@/context/dashboard/overview-dashboards.context';
import {translateValue} from '@/helpers/translation.helper';
import styles from './WidgetSelector.module.scss';
import ListItemText from '@mui/material/ListItemText';

export const WidgetSelector = () => {
  const {t} = useTranslation();

  const {
    placeholderWidgets,
    updateSelectedPlaceholderWidget,
    setSelectedPlaceholderWidgets,
    canAddPlaceholderWidget,
    selectedPlaceholderWidgets,
  } = useOverviewDashboards();

  const isWidgetSelected = (key: string) => {
    return selectedPlaceholderWidgets.some((widget) => widget.key === key);
  };

  const [filteredPlaceholderWidgets, setFilteredPlaceholderWidgets] =
    React.useState<IWidgetPlaceholder[]>([]);

  useEffect(() => {
    setFilteredPlaceholderWidgets(placeholderWidgets);
  }, [placeholderWidgets]);

  const handleSearch = (query: string) => {
    if (query) {
      const keyWords = query.split(' ').filter((word) => word.length > 0);

      const res = placeholderWidgets.filter((widget) =>
        keyWords.every((keyWord) => widget.key.includes(keyWord)),
      );

      setFilteredPlaceholderWidgets(res);
    }
  };

  return (
    <Drawer
      anchor="right"
      sx={{width: 320, position: 'relative'}}
      PaperProps={{style: {position: 'absolute', left: 0, width: '100%'}}}
      BackdropProps={{style: {position: 'absolute'}}}
      ModalProps={{
        container: document.getElementById('drawer-container'),
        style: {position: 'absolute'},
      }}
      variant="permanent">
      <div className={styles.input}>
        <TextField
          fullWidth
          sx={{pl: 2}}
          id="outlined"
          placeholder={t('overview-dashboards.add.search')}
          onChange={(e: any) => handleSearch(e.target.value)}
        />
        <IconButton
          sx={{mr: 2}}
          title={t('overview-dashboards.add.deleteAll')}
          color="secondary"
          onClick={() => setSelectedPlaceholderWidgets([])}>
          <RestartAltIcon />
        </IconButton>
      </div>
      <List>
        {filteredPlaceholderWidgets.map((widget, index) => {
          const isChecked = isWidgetSelected(widget.key);

          return (
            <ListItem key={index} className={styles.item}>
              <ListItemText>{translateValue(widget.key)}</ListItemText>
              <Switch
                checked={isChecked}
                disabled={!isChecked && !canAddPlaceholderWidget}
                onChange={() => updateSelectedPlaceholderWidget(widget)}
              />
            </ListItem>
          );
        })}
      </List>
    </Drawer>
  );
};
