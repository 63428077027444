import React from 'react';
import {useTranslation} from 'react-i18next';
import styles from './AllTeams.module.scss';
import GroupsIcon from '@mui/icons-material/Groups';
import Box from '@mui/material/Box';

export const AllTeams = () => {
  const {t} = useTranslation();

  const body = t('dashboard.player-statistics.table.all-teams');

  return (
    <div className={styles.container}>
      <Box
        sx={{
          height: 24,
          width: 24,
          bgcolor: 'background.default',
          borderRadius: '50%',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
        }}>
        <GroupsIcon
          color="secondary"
          sx={{
            height: 16,
            width: 16,
          }}
        />
      </Box>
      <span className={styles.body}>{body}</span>
    </div>
  );
};
