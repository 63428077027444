import React from 'react';

import Dialog from '@mui/material/Dialog';
import {useVideo} from '@/context/video/video.context';
import VideoPlayer from './video-player.view';

function VideoPlayerModal(): JSX.Element {
  const _videoContext = useVideo();

  return (
    <Dialog
      open={_videoContext.isVideoPlayerOpen}
      onClose={_videoContext.closeVideoPlayer}
      maxWidth={false}>
      <VideoPlayer />
    </Dialog>
  );
}

export default VideoPlayerModal;
