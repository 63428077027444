import React from 'react';
import {AutocompleteRenderGroupParams} from '@mui/material/Autocomplete';
import {styled, darken} from '@mui/material/styles';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const GroupHeader = styled('div')(({theme}) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 16px 4px 4px',
  color: theme.palette.secondary.light,
  backgroundColor: darken(theme.palette.background.default, 0.1),
  fontSize: 12,
  zIndex: 1,
  fontWeight: '500',
  minHeight: 24,
  display: 'flex',
  alignItems: 'center',
  textTransform: 'uppercase',
}));

const GroupItems = styled('ul')({
  padding: 0,
  fontSize: 14,
});

interface IAutocompleteGroupHeaderProps {
  params: AutocompleteRenderGroupParams;
  groupHeader: (key: string) => string;
  selected: boolean;
  onCheckboxChange: (checked: boolean, group: string) => void;
  multiple?: boolean;
}

function AutocompleteGroupHeader(props: IAutocompleteGroupHeaderProps) {
  function _onCheckboxChange(
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) {
    props.onCheckboxChange(checked, props.params.group);
  }

  const _Icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const _CheckedIcon = <CheckBoxIcon fontSize="small" />;

  return (
    <li key={props.params.key}>
      <GroupHeader>
        {props.multiple && (
          <Checkbox
            icon={_Icon}
            checkedIcon={_CheckedIcon}
            style={{marginRight: 4}}
            size="small"
            checked={props.selected}
            onChange={_onCheckboxChange}
          />
        )}
        {props.groupHeader(props.params.group)}
      </GroupHeader>
      <GroupItems>{props.params.children}</GroupItems>
    </li>
  );
}

export default AutocompleteGroupHeader;
