import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {ITypedOption} from '@/types/option.types';
import {LOADING_STATE} from '@/types/screen.types';
import {
  FICompetition,
  FICompetitionSeason,
  FIMatch,
  FIMatchVideoUploadPostData,
  formatSeason,
  TVideoSourceType,
} from '@my-game-plan/types';
import {PropsWithChildren, useEffect, useState} from 'react';
import {useCompetitions} from './competitions.context';
import {useAuth} from './auth.context';
import {
  getMatchesByCompetitionSeason,
  getTeamsByCompetitionSeason,
} from '@/controllers/competitions.controllers';
import moment from 'moment';
import {
  updateMatchVideo,
  updateMatchVideoOffsets,
} from '@/controllers/matches.controller';
import {
  generateFileUploader,
  getUploadCredentials,
} from '@/controllers/file-upload.controller';
import {useAnalytics} from './analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

interface IMatchesByMatchDay {
  matchDay: number;
  matches: FIMatch[];
}

export interface MatchVideosAPI {
  loadingState: LOADING_STATE;
  matches: IMatchesByMatchDay[];

  selectedCompetition: FICompetition | null;
  onCompetitionChange: (competitionId: number) => void;

  seasonOptions: ITypedOption<number>[];
  selectedSeasonId: number;
  onSeasonChange: (seasonId: number) => void;

  teamOptions: ITypedOption<string>[];
  selectedTeamIds: string[];
  onTeamChange: (teamIds: string[]) => void;

  onMatchVideoUpload: (
    matchId: string,
    file: File,
    videoData: Partial<FIMatchVideoUploadPostData>,
  ) => Promise<void>;
  uploadProgress: number;
  setUploadProgress: (progress: number) => void;

  onOffsetsChange: (
    matchId: string,
    sourceType: TVideoSourceType,
    offsets: number[],
  ) => Promise<void>;
}

const context = createCustomContext<MatchVideosAPI>();
export const useMatchVideos = createContextHook(context);

export function MatchVideosProvider(
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const _competitionsContext = useCompetitions();
  const _authContext = useAuth();
  const _analyticsContext = useAnalytics();

  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );
  const [_loadedMatches, _setLoadedMatches] = useState<FIMatch[]>([]);
  const [_filteredMatches, _setFilteredMatches] = useState<
    IMatchesByMatchDay[]
  >([]);

  const [_selectedCompetition, _setSelectedCompetition] =
    useState<FICompetition | null>(null);

  const [_seasonOptions, _setSeasonOptions] = useState<ITypedOption<number>[]>(
    [],
  );
  const [_selectedSeasonId, _setSelectedSeasonId] = useState<number | null>(
    null,
  );

  const [_teamOptions, _setTeamOptions] = useState<ITypedOption<string>[]>([]);
  const [_selectedTeamIds, _setSelectedTeamIds] = useState<string[]>([]);

  const [_selectedCompetitionSeason, _setSelectedCompetitionSeason] =
    useState<FICompetitionSeason | null>(null);
  const [_uploadProgress, _setUploadProgress] = useState<number>(0);

  /*
   * Side effects
   */
  // Select own competition by default
  useEffect(() => {
    if (!_competitionsContext.domesticCompetition || !_authContext.user) {
      return;
    }

    _setSelectedCompetition(_competitionsContext.domesticCompetition);
    _setSelectedSeasonId(_authContext.user.current_season_id);

    const _initialCompetitionSeason =
      _competitionsContext.competitionSeasons.find(
        (competitionSeason) =>
          competitionSeason.competition_id ===
            _competitionsContext.domesticCompetition?._id &&
          competitionSeason.season_id === _authContext.user?.current_season_id,
      );
    if (_initialCompetitionSeason) {
      _setSelectedCompetitionSeason(_initialCompetitionSeason);
    }
  }, [
    _competitionsContext.domesticCompetition,
    _authContext.user?.current_season_id,
    _competitionsContext.competitionSeasons,
  ]);

  // Set season options based on selected competition
  useEffect(() => {
    const _competitionSeasons = _competitionsContext.competitionSeasons.filter(
      (competitionSeason) =>
        competitionSeason.competition_id === _selectedCompetition?._id,
    );

    const _generatedSeasonOptions: ITypedOption<number>[] = _competitionSeasons
      .map((competitionSeason) => {
        return {
          value: competitionSeason.season_id,
          label: formatSeason(
            competitionSeason.season_id,
            _competitionsContext.displayMultiYear,
          ),
        };
      })
      .sort((a, b) => b.value - a.value);

    _setSeasonOptions(_generatedSeasonOptions);
  }, [
    _selectedCompetition,
    _competitionsContext.competitionSeasons,
    _competitionsContext.displayMultiYear,
  ]);

  useEffect(() => {
    const _seasonsOfCompetition = _competitionsContext.competitionSeasons
      .filter(
        (competitionSeason) =>
          competitionSeason.competition_id === _selectedCompetition?._id,
      )
      .sort((a, b) => b.season_id - a.season_id);

    let _competitionSeasonToFetch = _seasonsOfCompetition.find(
      (competitionSeason) => competitionSeason.season_id === _selectedSeasonId,
    );

    // If season doesnt exist for selected competition, set latest season
    if (!_competitionSeasonToFetch && _seasonsOfCompetition.length) {
      _competitionSeasonToFetch = _seasonsOfCompetition[0];
      _setSelectedSeasonId(_competitionSeasonToFetch.season_id);
      return;
    }

    _setSelectedCompetitionSeason(_competitionSeasonToFetch || null);
  }, [
    _selectedCompetition,
    _selectedSeasonId,
    _competitionsContext.competitionSeasons,
  ]);

  useEffect(() => {
    async function _fetchMatchesAndTeams() {
      try {
        //Set loading state and return if not in INITING state. Will be handled in next render

        if (!_selectedCompetitionSeason) {
          _setTeamOptions([]);
          _setLoadedMatches([]);
          return;
        }

        if (_loadingState !== LOADING_STATE.INITING) {
          _setLoadingState(LOADING_STATE.LOADING);
        }

        // Fetch teams if competition season exists
        const _fetchedTeams = await getTeamsByCompetitionSeason(
          _selectedCompetitionSeason.season_id,
          _selectedCompetitionSeason.competition_id,
        );

        const _generatedTeamOptions: ITypedOption<string>[] = _fetchedTeams.map(
          (team) => {
            return {
              value: team._id,
              label: team.name,
              image_url: team.image_url,
            };
          },
        );

        _setTeamOptions(_generatedTeamOptions);
        _setSelectedTeamIds(_fetchedTeams.map((team) => team._id));

        // Fetch matches
        const _fetchedMatches = await getMatchesByCompetitionSeason(
          _selectedCompetitionSeason.season_id,
          _selectedCompetitionSeason.competition_id,
        );

        // Filter matches, loop and convert to imatchbymatchday array
        const _playedMatches = _fetchedMatches.filter(
          (match) => moment(match.date).isBefore(moment()),
          // match.event_data_available,
        );

        _setLoadedMatches(_playedMatches);
        _setLoadingState(LOADING_STATE.SUCCESS);
      } catch (error) {
        _setLoadingState(LOADING_STATE.ERROR);
      }
    }

    _fetchMatchesAndTeams();
  }, [_selectedCompetitionSeason]);

  // Filter loaded matches
  useEffect(() => {
    const _matchesOfSelectedTeams = _loadedMatches.filter(
      (match) =>
        _selectedTeamIds.includes(match.home_team._id) ||
        _selectedTeamIds.includes(match.away_team._id),
    );
    const _matchesByMatchDay: IMatchesByMatchDay[] = [];
    _matchesOfSelectedTeams.forEach((match) => {
      const _matchDay = match.match_day;
      const _matchDayIndex = _matchesByMatchDay.findIndex(
        (matchDay) => matchDay.matchDay === _matchDay,
      );

      if (_matchDayIndex === -1) {
        _matchesByMatchDay.push({
          matchDay: _matchDay,
          matches: [match],
        });
      } else {
        _matchesByMatchDay[_matchDayIndex].matches.push(match);
      }
    });

    _setFilteredMatches(_matchesByMatchDay);
  }, [_loadedMatches, _selectedTeamIds]);

  /*
   * Handlers
   */
  function _onCompetitionChange(competitionId: number) {
    const _matchingCompetition = _competitionsContext.all.find(
      (comp) => comp._id === competitionId,
    );

    _setSelectedCompetition(_matchingCompetition || null);
  }

  function _onSeasonChange(seasonId: number) {
    _setSelectedSeasonId(seasonId);
  }

  function _onTeamChange(teamIds: string[]) {
    _setSelectedTeamIds(teamIds);
  }

  async function _onMatchVideoUpload(
    matchId: string,
    file: File,
    data: Partial<FIMatchVideoUploadPostData>,
  ) {
    /* Upload file to S3 */
    const _extension = file.name.split('.').pop();
    const _fileName = `${matchId}.${_extension}`;
    const _uploadCredentials = await getUploadCredentials();
    const _fileUploader = generateFileUploader(
      _uploadCredentials,
      'match_video_upload',
      _fileName,
      file,
    );

    _fileUploader.on('httpUploadProgress', (progress) => {
      if (progress?.loaded && progress?.total) {
        const _progress = Math.round((progress.loaded / progress.total) * 100);
        _setUploadProgress(_progress);
      }
    });

    await _fileUploader.done();

    const _updatedMatch = await updateMatchVideo(matchId, data);

    const _newLoadedMatches = [..._loadedMatches];
    const _indexInLoadedMatches = _newLoadedMatches.findIndex(
      (match) => match._id === matchId,
    );
    if (_indexInLoadedMatches !== -1) {
      _newLoadedMatches[_indexInLoadedMatches] = _updatedMatch;
    }

    _analyticsContext.trackEvent(ANALYTICS_EVENT.UPLOADED_MATCH_VIDEO, {
      match_id: matchId,
      source_type: data.source_type || 'tactical',
    });
    _setLoadedMatches(_newLoadedMatches);
  }

  async function _onOffsetsChange(
    matchId: string,
    sourceType: TVideoSourceType,
    offsets: number[],
  ) {
    const _updatedMatch = await updateMatchVideoOffsets(
      matchId,
      sourceType,
      offsets,
    );

    const _newLoadedMatches = [..._loadedMatches];
    const _indexInLoadedMatches = _newLoadedMatches.findIndex(
      (match) => match._id === matchId,
    );

    if (_indexInLoadedMatches !== -1) {
      _newLoadedMatches[_indexInLoadedMatches] = _updatedMatch;
    }
    _setLoadedMatches(_newLoadedMatches);
  }

  /*
   * Return provider with MatchVideosAPI
   */
  const _apiValue: MatchVideosAPI = {
    loadingState: _loadingState,
    matches: _filteredMatches,

    selectedCompetition: _selectedCompetition,
    onCompetitionChange: _onCompetitionChange,

    seasonOptions: _seasonOptions,
    selectedSeasonId: _selectedSeasonId ?? 0,
    onSeasonChange: _onSeasonChange,

    teamOptions: _teamOptions,
    selectedTeamIds: _selectedTeamIds,
    onTeamChange: _onTeamChange,

    onMatchVideoUpload: _onMatchVideoUpload,
    uploadProgress: _uploadProgress,
    setUploadProgress: _setUploadProgress,

    onOffsetsChange: _onOffsetsChange,
  };
  return createProvider(context, props, _apiValue);
}
