const MATCHES_TRANSLATIONS = {
  match: 'Match',
  matchesCount_one: '{{count}} match',
  matchesCount_other: '{{count}} matches',

  matchDayCount: 'Match day {{matchDay}}',

  properties: {
    date: 'Date',
    video: {
      source: {
        source: 'Angle',
        tactical: 'Tactical video',
        broadcast: 'Broadcast video',
      },
      status: {
        status: 'Status',
        finished: 'Finished',
        processing: 'Processing',
        noVideo: 'No video',
      },
    },
  },

  uploadVideo: {
    upload: 'Upload',
    uploadMatchVideo: 'Upload match video',
    updateOffsets: 'Update offsets',
    noOffsets: 'No offsets have been set for this video',
    cta: 'Upload video',
    timerOnDisplay: {
      title: 'This video has a timer displayed',
      automaticOffsets: 'Offsets will be set automatically',
    },

    firstHalfVideoStart: 'First half video start',
    secondHalfVideoStart: 'Second half video start',
    seconds_one: 'second',
    seconds_other: 'seconds',
    error: 'Something went wrong uploading video. Please try again.',
  },
};

export default MATCHES_TRANSLATIONS;
