import {
  getScoutedPlayers,
  searchPlayers,
  stopScoutingPlayer,
} from '@/controllers/players.controller';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {
  FIPlayer,
  FIPlayerSearchFilters,
  FITrackersOverview,
  MAX_AGE,
  MAX_HEIGHT,
  MAX_WEIGHT,
  MIN_AGE,
  MIN_HEIGHT,
  MIN_WEIGHT,
} from '@my-game-plan/types';
import React, {PropsWithChildren, useState} from 'react';
import {useAuth} from './auth.context';

import {HTTPResponse, IPagination} from '@/types/api.types';
import {useNavigate} from 'react-router-dom';
import {NAV_LEVEL_1_ROUTE} from '@/config/navigation.config';

type TSearchType = 'name' | 'properties';
export interface IScoutingAPI {
  searchFilters: FIPlayerSearchFilters;

  clearHistory: () => Promise<void>;
  getScoutedPlayers: (
    filters?: FIPlayerSearchFilters,
  ) => Promise<HTTPResponse<FITrackersOverview>>;
  search: (
    filters: FIPlayerSearchFilters,
    type: TSearchType,
  ) => Promise<HTTPResponse<FIPlayer[]>>;
}

const context = createCustomContext<IScoutingAPI>();
export const useScouting = createContextHook(context);

export const DEFAULT_PLAYER_SEARCH_FILTERS: FIPlayerSearchFilters = {
  height: [MIN_HEIGHT, MAX_HEIGHT],
  weight: [MIN_WEIGHT, MAX_WEIGHT],
  age: [MIN_AGE, MAX_AGE],

  page: 1,
};

export const DEFAULT_PAGINATION: IPagination = {
  current_page: 1,
  total_pages: 1,
  total_results: 0,
};

function ScoutingProvider(
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const _authContext = useAuth();
  const _navigate = useNavigate();
  const [_searchFilters, _setSearchFilters] = useState<FIPlayerSearchFilters>(
    DEFAULT_PLAYER_SEARCH_FILTERS,
  );

  /*
   * Handlers
   */
  async function _searchHandler(
    filters: FIPlayerSearchFilters,
    type: TSearchType,
  ): Promise<HTTPResponse<FIPlayer[]>> {
    if (!_authContext.user) {
      return {
        data: [],
      };
    }

    if (type === 'properties') {
      _setSearchFilters(filters);
    }

    try {
      const _playersResponse = await searchPlayers(
        _authContext.user.team,
        filters,
      );

      return _playersResponse;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }
  async function _getScoutedPlayers(
    filters?: FIPlayerSearchFilters,
  ): Promise<HTTPResponse<FITrackersOverview>> {
    if (!_authContext.user) {
      throw new Error('No user');
    }

    try {
      const _playersResponse = await getScoutedPlayers(
        _authContext.user.team,
        filters,
      );

      return _playersResponse;
    } catch (error) {
      throw new Error((error as Error).message);
    }
  }

  async function _clearHistory() {
    if (!_authContext.user) {
      return;
    }

    await stopScoutingPlayer(_authContext.user.team, 'all');
    _navigate(`${NAV_LEVEL_1_ROUTE.SCOUTING}#deleted`);
  }

  /*
   * Return Context
   */
  const _contextValue: IScoutingAPI = {
    searchFilters: _searchFilters,
    getScoutedPlayers: _getScoutedPlayers,
    search: _searchHandler,
    clearHistory: _clearHistory,
  };

  return createProvider<IScoutingAPI>(context, props, _contextValue);
}

export default ScoutingProvider;
