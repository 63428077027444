import React from 'react';
import Stack from '@mui/material/Stack';
import DashboardTeamPicker from './dashboard-team-picker.view';
import DashboardActionPicker from './dashboard-action-picker.view';

function DashboardTitle(): JSX.Element {
  /*
   * Hooks n State
   */

  /*
   * Side effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <Stack direction="row" gap={1}>
      <DashboardTeamPicker />
      <DashboardActionPicker />
    </Stack>
  );
}

export default DashboardTitle;
