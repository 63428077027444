import React from 'react';
import {useParams} from 'react-router-dom';

import AutomationDetail from '@/components/automations/automation-detail/automation-detail.view';

// import {Tab, Tabs} from '@mui/material';

function OpponentAutomationDetail() {
  /* Hooks n State */
  const _params = useParams();

  /* Render */
  return (
    <AutomationDetail automationType="opponent-automation" id={_params.id} />
  );
}

export default OpponentAutomationDetail;
