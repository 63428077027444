const SHOT_ANALYSIS_TRANSLATIONS = {
  title: 'Where goals come from',
  result: {
    shots_other: 'Shots',
    shots_one: 'Shot',
    shotsCount_other: '{{countFormatted}} shots',
    shotsCount_one: '{{countFormatted}} shot',
    goals_other: 'Goals',
    goals_one: 'Goal',
    goalsCount_other: '{{countFormatted}} goals',
    goalsCount_one: '{{countFormatted}} goal',
  },

  total_shots: 'All shots',
  total_goals: 'All goals',

  benchmark: {
    title: 'Benchmark',
    benchmarkingAgainst: 'Benchmarking',
    league: 'entire league',

    average: {
      league: 'League average',
    },

    belowAverage: 'below average',
    aboveAverage: 'above average',
  },

  shotType: 'Shot type',
  team: 'Team',
  goalRate: 'Goal rate',

  details: {
    cta: 'Details',
    general: {title: 'General'},
    players: {
      title: 'Shot takers',
      player: 'Player',
    },
    scatter: {title: 'Compare'},
  },
  insight: {
    title: 'insight',
    loading: 'Generating insights...',
    previousInsight: 'Previous insight',
    nextInsight: 'Next insight',
    aiGenerated: 'This insight was generated by AI.',
    shareFeedback: 'Share feedback',
    empty: 'No insights available',
  },

  total: 'Total',

  typeExplanation: {
    basic_pass:
      'Open-play passes that advance the ball less than 25% towards the goal.',
    individual_play:
      'Shots arising from individual player actions, not originating from a pass.',
    progressive_pass:
      'Open-play passes advancing the ball by at least 25% towards the goal.',
    set_piece_pass:
      'Passes from or immediately following a dead ball situation.',
    set_piece_kick: 'Shots from direct free kicks or penalty kicks.',
  },
};

export default SHOT_ANALYSIS_TRANSLATIONS;
