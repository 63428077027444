import React from 'react';
import cn from 'classnames';

import {ICommonSentenceProps} from '@/types/sentence-form.types';

import styles from './sequence-actions.module.scss';

import {FISequenceAction} from '@my-game-plan/types';
import AddActionButton from './add-action-button.view';
import SingleSequenceAction from './single-sequence-action.view';
import Segment from '../segment';
import {useTranslation} from 'react-i18next';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
} from 'react-beautiful-dnd';
import {translateAction} from '@/helpers/translation.helper';

function SequenceActions(props: ICommonSentenceProps): JSX.Element | null {
  /* Hooks n State */
  const {t} = useTranslation();

  function _onActionChange(index: number, data: Partial<FISequenceAction>) {
    if (props.data?.sequence_actions && props.onChange) {
      const _sequenceActionsList: FISequenceAction[] = [
        ...(props.data.sequence_actions || []),
      ];
      _sequenceActionsList[index] = data;

      props.onChange({
        sequence_actions: _sequenceActionsList,
      });
    }
  }

  function _onActionDelete(index: number) {
    if (props.onChange && props.data.sequence_actions) {
      const _sequenceActionsList: FISequenceAction[] = [
        ...(props.data.sequence_actions || []),
      ];
      _sequenceActionsList.splice(index, 1);

      props.onChange({
        sequence_actions: _sequenceActionsList,
      });
    }
  }

  function onSelectAddActiondata() {
    const _newSequenceActions = [...(props.data?.sequence_actions || [])];
    _newSequenceActions.push({});
    if (props.onChange) {
      props.onChange({
        sequence_actions: _newSequenceActions,
      });
    }
  }

  function onDragEnd(result: any) {
    const {source, destination} = result;
    if (!destination) return;

    const items = [...(props.data?.sequence_actions || [])];
    const [reorderedItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, reorderedItem);

    props.onChange && props.onChange({sequence_actions: items});
  }

  /* Render */
  if (!props.isValid && !props.data.sequence_actions?.length) return null;

  const _containerClasses = cn(
    styles.container,
    styles[`container-${props.size}`],
    {
      [styles.containerReadonly]: props.readonly,
    },
  );

  let _ActionsList = null;
  if (props.data.sequence_actions) {
    const _size = props.size;
    const _conditionsClasses = cn(
      styles.conditions,
      styles[`conditions-${_size}`],
    );
    if (props.readonly) {
      _ActionsList = (
        <>
          {props.data.sequence_actions.map((sequenceAction, index) => {
            if (!sequenceAction.action) {
              return null;
            }

            return (
              <React.Fragment key={`${sequenceAction.action}-${index}`}>
                <Segment.Text
                  key={`${sequenceAction.action}-${index}-action`}
                  {...props}
                  text={translateAction(sequenceAction.action, 1)}
                  type="value"
                />
                {index + 1 !== props.data.sequence_actions?.length && (
                  <>
                    <Segment.Text
                      key={`${sequenceAction.action}-${index}-sep`}
                      {...props}
                      text={t(
                        'sentenceForm.sequence.sequence_action_separator',
                      )}
                    />{' '}
                  </>
                )}
              </React.Fragment>
            );
          })}
        </>
      );
    } else {
      _ActionsList = (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="sequenceActions">
            {(provided: DroppableProvided) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={_conditionsClasses}>
                {props.data.sequence_actions?.map((action, index) => {
                  let _canDelete = true;
                  if (
                    (!props.data.sequence_actions?.length ||
                      props.data.sequence_actions?.length <= 1) &&
                    index === 0
                  ) {
                    _canDelete = false;
                  }
                  return (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            ...provided.draggableProps.style,
                            opacity: snapshot.isDragging ? 0.8 : 1, // Optional: adding a visual cue when dragging
                          }}>
                          <SingleSequenceAction
                            {...props}
                            data={props.data}
                            sequenceAction={action}
                            size={_size}
                            actionIndex={index}
                            onChange={_onActionChange}
                            onDelete={_canDelete ? _onActionDelete : undefined}
                            errors={props.errors?.sequence_actions?.[index]}
                            observingPlayers={props.data.observing_players}
                          />
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    }
  }

  return (
    <div className={_containerClasses}>
      {_ActionsList}

      {props.isValid && (
        <div className={styles.addCondition}>
          <AddActionButton onChange={onSelectAddActiondata} />
        </div>
      )}
    </div>
  );
}

export default SequenceActions;
