import {
  FIShotAnalysisCategory,
  FIShotAnalysisInsight,
  FITeam,
  ShotCategory,
  TShotAnalysisTeam,
} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Stack from '@mui/material/Stack';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {useTranslation} from 'react-i18next';
import InsightDetailsPlayers from './insight-details-players.view';
import InsightDetailsScatter from './insight-details-scatter.view';

import InsightDetailsGeneral from './insight-details-general.view';

import ANALYTICS_EVENT from '@/config/analytics/event-names.config';
import {ANALYTICS_CONFIG} from '@/config/analytics/events.config';

interface IInsightDetailsPopupProps {
  team: TShotAnalysisTeam;
  teamId: string;
  currentTeam?: FITeam;
  benchmarkLabel: string;
  data: FIShotAnalysisCategory | null;
  insight?: FIShotAnalysisInsight;
  shotCategory?: ShotCategory;
  onPlayVideo?: (shotCategory?: ShotCategory) => void;
  trackEvent: <T extends keyof ANALYTICS_CONFIG>(
    event: T,
    params?: ANALYTICS_CONFIG[T],
  ) => void;
}

type TDetailsTab = 'general' | 'players' | 'scatter';
const TABS: TDetailsTab[] = ['general', 'players', 'scatter'];

function InsightDetailsPopup(
  props: IInsightDetailsPopupProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  const [_activeTab, _setActiveTab] = useState<number>(
    props.team === 'conceded' ? 1 : 0,
  );
  //   const [_data, _setData] = useState<FIShotAnalysisCategory | null>(null);

  /* When tab changed, send mixpanel event */
  useEffect(() => {
    props.trackEvent(ANALYTICS_EVENT.VIEWED_SHOT_ANALYSIS_DETAILS, {
      team_id: props.teamId,
      tab: TABS[_activeTab],
      type: props.team,
      shot_category: props.data?.shot_category || 'total',
    });
  }, [_activeTab, props.team, props.teamId, props.data, props.trackEvent]);

  /*
   * Handlers
   */
  function _onTabChange(event: React.SyntheticEvent, newValue: number) {
    _setActiveTab(newValue);
  }

  function _onPlayVideo() {
    if (props.onPlayVideo) {
      props.onPlayVideo(props.shotCategory);
    }
  }

  /*
   * Render
   */

  if (!props.data) {
    return null;
  }

  const _TabsContent = [
    <InsightDetailsGeneral
      key="general"
      insight={props.insight}
      data={props.data}
      team={props.currentTeam}
      benchmarkLabel={props.benchmarkLabel}
      onPlayVideo={_onPlayVideo}
    />,
    <InsightDetailsPlayers key="players" players={props.data.players || []} />,
    <InsightDetailsScatter
      key="scatter"
      team={props.team}
      scores={props.data.ranking}
    />,
  ];

  return (
    <Stack gap={3}>
      {/* <Box>
        <Typography>
          Shot creation after individual play is X above benchmark [Some extra
          text about this statistic. Not sure if we need to support such large
          insights, but I’m providing this just in case.]
        </Typography>
      </Box> */}

      {props.team === 'created' && (
        <Tabs
          value={_activeTab}
          onChange={_onTabChange}
          sx={{
            borderBottomWidth: 1,
            borderBottomStyle: 'solid',
            borderBottomColor: (theme) => theme.palette.divider,
          }}>
          {TABS.map((tab) => {
            return (
              <Tab key={tab} label={t(`shotAnalysis.details.${tab}.title`)} />
            );
          })}
        </Tabs>
      )}
      {_TabsContent[_activeTab]}
    </Stack>
  );
}

export default InsightDetailsPopup;
