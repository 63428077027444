const TEAM_TRANSLATIONS = {
  teams_one: 'Team',
  teams_other: 'Teams',
  teamsCount_one: '{{count}} team',
  teamsCount_other: '{{count}} teams',
  performance: 'Team performance',
  cta: {
    viewTeam: 'View team',
  },
};

export default TEAM_TRANSLATIONS;
