import moment from 'moment/moment';

import {truncate as lodashTruncate, snakeCase} from 'lodash';
import {FMatch} from '@my-game-plan/types';
import {ITypedOption} from '@/types/option.types';

export const capitalize = (str: string | undefined) => {
  if (!str) return '';

  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export function zeroPad(num: number, places: number) {
  const zero = places - num.toString().length + 1;
  return Array(+(zero > 0 && zero)).join('0') + num;
}

export function formatMatchTime(minute: number, seconds: number) {
  return `${zeroPad(minute, 2)}'${zeroPad(seconds, 2)}"`;
}

export function formatMatchTimeFromTimestamp(
  period: number,
  timestamp: number,
) {
  const minutes = (period - 1) * 45 + Math.floor(timestamp / 60); // Convert timestamp (seconds) to minutes
  const seconds = timestamp % 60; // Get remaining seconds after converting to minutes
  return formatMatchTime(minutes, seconds); // Use the formatMatchTime function
}

export const formatDate = (date: string | Date, format = 'LL') => {
  return moment(date).format(format);
};
export const dateToTimestampFilename = (date: string | Date) => {
  return moment(date).format('MMMM Do YYYY, h-mm-ss a');
};
export const deepCopy = (obj: unknown) => {
  return JSON.parse(JSON.stringify(obj));
};

/* export function getOpponentId(teamId: string | undefined, match: FMatch) {
  if (!teamId) return 0;

  return match.home_team._id === teamId
    ? match.away_team._id
    : match.home_team._id;
} */

export function getOpponentImage(teamId: string | undefined, match: FMatch) {
  if (!teamId) return 0;

  return match.home_team._id === teamId
    ? match.away_team.image_url
    : match.home_team.image_url;
}

export function getOpponent(teamId: string | undefined, match: FMatch) {
  if (!teamId) return null;
  return match.home_team._id === teamId ? match.away_team : match.home_team;
}

// TODO should be removed i think...
export const hoverEffect = (
  e: React.MouseEvent,
  cellColor: string,
  rowColor: string,
): void => {
  const playerCell = e.currentTarget.parentElement;

  if (playerCell) {
    const row = playerCell.parentElement;

    playerCell.style.backgroundColor = cellColor;

    if (row) {
      row.style.background = rowColor;
    }
  }
};

// TODO bad naming/should be removed if possible
export const includesObject = (
  obj: Record<string, unknown>,
  array: unknown[],
) => {
  const object = JSON.stringify(obj);
  return array
    .map((value) => JSON.stringify(value))
    .some((value) => value == object);
};
export const normalize = (value: number, min: number, max: number): number => {
  return (value - min) / (max - min);
};

export function roundNumber(value: number, decimals = 2): number {
  return (
    Math.round((value + Number.EPSILON) * Math.pow(10, decimals)) /
    Math.pow(10, decimals)
  );
}

export const toPercentage = (tag: number, decimals = 0) => {
  let res: string | number = tag * 100;
  if (decimals) {
    res = roundNumber(res, decimals);
  } else {
    res = res.toFixed(0);
  }

  return `${res}%`;
};

export function roundAndFormatNumber(
  value: number,
  decimals = 2,
  localeString = 'fr',
): string {
  const _roundedValue = roundNumber(value, decimals);
  return _roundedValue.toLocaleString(localeString);
}

export const truncate = (str: string, length = 15) => {
  return lodashTruncate(str, {length: length});
};

// TODO seems like a bad function
export const createKey = (input: string) => {
  return input.replace(/\s/g, '');
};

export function getPlayerInitials(name: string): string {
  const _splitted = name.split('. ');

  if (_splitted.length < 2) {
    return name.charAt(0);
  }

  return `${_splitted[0][0]}${_splitted[1][0]}`;
}

export function isNullOrUndefined(value: unknown): boolean {
  return typeof value === 'undefined' || value === null;
}

export function getKeyForTypedOption<T = any>(option: ITypedOption<T>): string {
  if (typeof option.value === 'string') {
    return option.value;
  } else if (typeof option.value === 'number') {
    return option.value.toString();
  } else {
    return snakeCase(option.label);
  }
}
