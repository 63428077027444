import HeatMap from 'heatmap-ts';
import React, {useEffect, useState} from 'react';

import styles from './pitch-visual.module.scss';

import {
  HeatmapProps,
  generateHeatmapData,
  transformDefaultFocusTypeForHeatmap,
} from '@/helpers/heatmap.helper';
import {PitchVisualFocusType, isTrackingAction} from '@my-game-plan/types';
import FocusDropdown from './focus-dropdown/FocusDropdown';

export const Heatmap = (props: HeatmapProps) => {
  /* Hooks n State */
  const [_activeFocusType, _setActiveFocusType] =
    useState<PitchVisualFocusType>(
      transformDefaultFocusTypeForHeatmap(props.defaultFocusType),
    );

  // Init heatmap when data or action changes
  useEffect(() => {
    const _heatMapElement = document.querySelector(
      `#heatmap-${props.linkedTable}`,
    ) as HTMLElement;
    _heatMapElement.innerHTML = '';

    const _isTrackingAction = isTrackingAction(props.action);

    const _heatMap = new HeatMap({
      container: _heatMapElement,
      maxOpacity: 0.6,
      radius: _isTrackingAction ? 10 : 30,
      blur: 1,
      height: props.dimensions.height,
      width: props.dimensions.width,
    });

    const _events = generateHeatmapData({
      ...props,
      defaultFocusType: _activeFocusType,
    });

    _heatMap.setData({data: _events});
  }, [props.eventGroups, props.action, _activeFocusType]);

  /* Handlers */
  function _onDropdownChange(type: PitchVisualFocusType) {
    _setActiveFocusType(type);
  }

  /* Render */
  return (
    <div className={styles.pitchOverlay}>
      {props.dimensions && (
        <div className={styles.canvasWrapper}>
          <div
            id={`heatmap-${props.linkedTable}`}
            style={{
              width: props.dimensions.width,
              height: props.dimensions.height,
            }}></div>
          {/* End/Start Dropdown */}
          {props.showPath && (
            <div className={styles.dropdown}>
              <FocusDropdown
                visual="heatmap"
                value={_activeFocusType}
                onChange={_onDropdownChange}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
