import {ZONES_API_CONFIG} from '@/config/api.config';
import {REQUEST_ERRORS} from '@/config/errors.config';
import {useAPI} from '@/hooks/api.hooks';
import {Resource} from '@/types/api.types';
import {FIPitchZone} from '@my-game-plan/types';

const api = useAPI();

export async function getZonesByTeam(_team: string): Promise<FIPitchZone[]> {
  try {
    const {data} = await api.get<FIPitchZone[]>({
      resource: Resource.zones,
      url: ZONES_API_CONFIG.getZones(_team),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    return [];
  }
}
