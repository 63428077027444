import {PLAYER_OBJECTIVES_API_CONFIG} from '@/config/api.config';
import {REQUEST_ERRORS} from '@/config/errors.config';
import {useAPI} from '@/hooks/api.hooks';
import {Resource} from '@/types/api.types';
import {
  FIPlayerObjective,
  FIPlayerObjectiveComment,
  FIPlayerObjectivePostData,
} from '@my-game-plan/types';

const api = useAPI();

/*
 * Player Objectives
 */
export async function getPlayerObjectives(
  teamId: string,
  playerId: string,
): Promise<FIPlayerObjective[]> {
  try {
    const {data} = await api.get<FIPlayerObjective[]>({
      resource: Resource.player_objectives,
      url: PLAYER_OBJECTIVES_API_CONFIG.getAllForPlayer(teamId, playerId),
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function createPlayerObjective(
  postData: Partial<FIPlayerObjectivePostData>,
): Promise<FIPlayerObjective> {
  try {
    const {data} = await api.post<FIPlayerObjective>({
      resource: Resource.player_objectives,
      data: postData,
      url: PLAYER_OBJECTIVES_API_CONFIG.createObjective(),
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function editPlayerObjective(
  id: string,
  postData: Partial<FIPlayerObjectivePostData>,
): Promise<FIPlayerObjective> {
  try {
    const {data} = await api.put<FIPlayerObjective>({
      resource: Resource.player_objectives,
      data: postData,
      url: PLAYER_OBJECTIVES_API_CONFIG.singleObjective(id),
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function deletePlayerObjective(id: string): Promise<void> {
  try {
    await api.del({
      resource: Resource.player_objectives,
      url: PLAYER_OBJECTIVES_API_CONFIG.singleObjective(id),
    });
  } catch (error) {
    throw new Error((error as any).message);
  }
}

/*
 * Comments
 */
export async function createObjectiveComment(
  objectiveId: string,
  comment: Partial<FIPlayerObjectiveComment>,
): Promise<FIPlayerObjective> {
  try {
    const {data} = await api.post<FIPlayerObjective>({
      resource: Resource.player_objectives,
      data: comment,
      url: PLAYER_OBJECTIVES_API_CONFIG.comment(objectiveId),
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function editObjectiveComment(
  objectiveId: string,
  commentId: string,
  postData: Partial<FIPlayerObjectiveComment>,
): Promise<FIPlayerObjective> {
  try {
    const {data} = await api.put<FIPlayerObjective>({
      resource: Resource.player_objectives,
      url: PLAYER_OBJECTIVES_API_CONFIG.singleComment(objectiveId, commentId),
      data: postData,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}

export async function deleteObjectiveComment(
  objectiveId: string,
  commentId: string,
): Promise<void> {
  try {
    await api.del({
      resource: Resource.player_objectives,
      url: PLAYER_OBJECTIVES_API_CONFIG.singleComment(objectiveId, commentId),
    });
  } catch (error) {
    throw new Error((error as any).message);
  }
}
