import React from 'react';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import {useTranslation} from 'react-i18next';
import {translateAction} from '@/helpers/translation.helper';
import {IDashboardScore} from '@/types/dashboard/dashboard-score.types';
import {IndicatorInformationModalTable} from './IndicatorInformationModalTable';
import {useConfirm} from 'material-ui-confirm';
import {capitalize} from 'lodash';
import {DEFAULT_MODAL_CONFIG} from '@/config/modal.config';

interface IndicatorInformationModalProps {
  score: IDashboardScore;
  action?: string;
}

export const IndicatorInformationModal = ({
  score,
  action,
}: IndicatorInformationModalProps) => {
  const {t} = useTranslation();
  const _confirm = useConfirm();

  const translatedAction = action ? translateAction(action) : '';
  const title = t('dashboard.scores.information.title');
  const body = t('dashboard.scores.information.body');

  function _onClick() {
    _confirm({
      ...DEFAULT_MODAL_CONFIG,
      title: `${capitalize(translatedAction)} ${title}`,
      content: (
        <>
          <p>{body}</p>
          <br />
          <IndicatorInformationModalTable score={score} action={action} />
        </>
      ),
    });
  }

  return (
    <>
      <IconButton
        color="secondary"
        title={t('dashboard.scores.information.moreInfo')}
        onClick={_onClick}>
        <InfoIcon />
      </IconButton>
    </>
  );
};
