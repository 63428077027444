import React from 'react';

import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

import {alpha} from '@mui/material';
import {useTheme} from '@mui/system';

interface LoaderProps {
  isVisible: boolean;
  size?: number;
  fixed?: boolean;
  translucent?: boolean;
}

function Loader(props: LoaderProps): JSX.Element {
  const _theme = useTheme();
  const _size = props.size || 24;
  const _fixed = typeof props.fixed === 'undefined' ? true : props.fixed;
  return (
    <Fade in={props.isVisible} unmountOnExit appear={false}>
      <Box
        sx={{
          bottom: 0,

          left: 0,
          position: _fixed ? 'fixed' : 'absolute',
          right: 0,
          top: 0,
          zIndex: 10,
          bgcolor: props.translucent
            ? alpha(_theme.palette.background.default, 0.6)
            : 'background.default',
          minHeight: _fixed ? 'auto' : 320,
        }}>
        <Box
          maxHeight="60vh"
          sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center',
            height: '100%',
          }}>
          <CircularProgress size={_size} />
        </Box>
      </Box>
    </Fade>
  );
}

export default Loader;
