import React, {useEffect, useState} from 'react';
import HeaderFilterButton, {
  IFilterButtonValueSubSegment,
} from '../header-filter-button.view';
import {ENTIRE_TEAM_LABEL, FIPlayer} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';
import {useTeams} from '@/context/team.context';

import Avatar from '@mui/material/Avatar';
import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Popover from '@mui/material/Popover';
import GroupIcon from '@mui/icons-material/Group';
import {IFilterOption} from '@/types/filter.types';
import {lighten, useTheme} from '@mui/material';

interface IPlayersFilterButtonProps {
  selectedPlayerIds: string[];
  onPlayerSelect: (playerId: string) => void;
}

function PlayersFilterButton(props: IPlayersFilterButtonProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const _theme = useTheme();
  const [_labelValues, _setLabelValues] = useState<
    IFilterButtonValueSubSegment[][]
  >([]);
  const [_anchorEl, _setAnchorEl] = useState<HTMLElement | null>(null);
  const [_playerOptions, _setPlayerOptions] = useState<IFilterOption[]>([]);

  /*
   * Side effects
   */
  useEffect(() => {
    const _generatedPlayerOptions: IFilterOption[] =
      _teamsContext.ownPlayers.map((player: FIPlayer) => {
        return {
          label: player.display_name,
          value: player._id,
          image_url: player.image_url,
        };
      });

    _generatedPlayerOptions.unshift({
      value: ENTIRE_TEAM_LABEL,
      label: t('eventAutomations.filter.entireTeam'),
    });
    _setPlayerOptions(_generatedPlayerOptions);

    const _generatedLabelValues: IFilterButtonValueSubSegment[][] = [];
    _generatedLabelValues.push([
      {
        type: 'value',
        value: props.selectedPlayerIds.length.toString(),
      },
      {
        type: 'default',
        value: t('eventAutomations.filter.players', {
          count: props.selectedPlayerIds.length,
        }),
      },
    ]);

    _setLabelValues(_generatedLabelValues);
  }, [_teamsContext.ownPlayers, props.selectedPlayerIds]);

  /*
   * Handlers
   */
  function _onPopupOpen(event: React.MouseEvent<HTMLElement>) {
    _setAnchorEl(event.currentTarget);
  }
  function _onPopupClose() {
    _setAnchorEl(null);
  }

  function _onCheckboxToggle(value: string) {
    props.onPlayerSelect(value);
  }

  /*
   * Render
   */
  return (
    <HeaderFilterButton
      isOpen={Boolean(_anchorEl)}
      buttonValues={_labelValues}
      onPopupOpen={_onPopupOpen}
      PopupContent={
        <Popover
          anchorEl={_anchorEl}
          open={Boolean(_anchorEl)}
          onClose={_onPopupClose}
          slotProps={{
            paper: {
              sx: {
                maxHeight: 400,
                bgcolor: lighten(_theme.palette.background.paper, 0.03),
              },
            },
          }}
          elevation={4}
          anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
          <List dense>
            {_playerOptions.map((option) => {
              return (
                <ListItem disablePadding key={option.value}>
                  <ListItemButton
                    onClick={() => _onCheckboxToggle(option.value)}>
                    <ListItemIcon sx={{minWidth: 40}}>
                      <Checkbox
                        edge="start"
                        checked={props.selectedPlayerIds.includes(option.value)}
                      />
                    </ListItemIcon>
                    {option.image_url && (
                      <ListItemAvatar sx={{minWidth: 32}}>
                        <Avatar
                          alt={option.label}
                          src={option.image_url}
                          sx={{
                            height: 24,
                            width: 24,
                            bgcolor: 'secondary.main',
                          }}
                        />
                      </ListItemAvatar>
                    )}
                    <ListItemText>{option.label}</ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Popover>
      }
      ButtonIcon={GroupIcon}
    />
  );
}

export default PlayersFilterButton;
