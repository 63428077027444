import React, {useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {useTranslation} from 'react-i18next';

import styles from './focus-dropdown.module.scss';
import classNames from 'classnames';
import {PitchVisualFocusType} from '@my-game-plan/types';

/* Option */
interface FocusDropdownOptionProps {
  type: PitchVisualFocusType;
}
function FocusDropdownOption({type}: FocusDropdownOptionProps): JSX.Element {
  const _startClasses = classNames(styles.optionStart);
  const _lineClasses = classNames(styles.optionLine, {
    [styles.optionLineActive]:
      type === PitchVisualFocusType.START_END ||
      type === PitchVisualFocusType.START_FOCUS ||
      type === PitchVisualFocusType.END_FOCUS,
  });
  const _endClasses = classNames(styles.optionEnd);

  const _showStart =
    type === PitchVisualFocusType.START_END ||
    type === PitchVisualFocusType.START_FOCUS ||
    type === PitchVisualFocusType.START;

  const _showEnd =
    type === PitchVisualFocusType.START_END ||
    type === PitchVisualFocusType.END_FOCUS ||
    type === PitchVisualFocusType.END;

  return (
    <div className={styles.option}>
      {_showStart && <div className={_startClasses}></div>}
      <div className={_lineClasses}></div>
      {_showEnd && <div className={_endClasses}></div>}
    </div>
  );
}

/* Dropdown */
interface FocusDropdownProps {
  visual: 'heatmap' | 'scatter';
  value: PitchVisualFocusType;
  onChange: (type: PitchVisualFocusType) => void;
}

function FocusDropdown(props: FocusDropdownProps): JSX.Element {
  /*
   * Hooks n State
   */
  const [_anchorEl, _setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [_options, _setOptions] = useState<PitchVisualFocusType[]>([]);
  const {t} = useTranslation();

  useEffect(() => {
    // Set options
    if (props.visual === 'heatmap') {
      _setOptions([PitchVisualFocusType.START, PitchVisualFocusType.END]);
    } else {
      const _allOptions = (
        Object.keys(
          PitchVisualFocusType,
        ) as (keyof typeof PitchVisualFocusType)[]
      ).map((key) => PitchVisualFocusType[key]);
      _setOptions(_allOptions);
    }
  }, [props.visual]);

  /*
   * Handlers
   */
  function _onTriggerClick(event: React.MouseEvent<HTMLButtonElement>) {
    _setAnchorEl(event.currentTarget);
  }

  function _onClose() {
    _setAnchorEl(null);
  }

  function _onChange(type: PitchVisualFocusType) {
    props.onChange(type);
    _onClose();
  }

  /*
   * Render
   */
  const _isOpened = Boolean(_anchorEl);
  const _Icon = _isOpened ? ExpandLessIcon : ExpandMoreIcon;
  return (
    <>
      <Button
        onClick={_onTriggerClick}
        variant="contained"
        color="secondary"
        endIcon={<_Icon />}
        size="small"
        sx={{backgroundColor: 'rgba(76, 98, 115, .5)'}}>
        <div className={styles.triggerVisual}>
          <FocusDropdownOption type={props.value} />
        </div>
      </Button>
      <Menu anchorEl={_anchorEl} open={_isOpened} onClose={_onClose}>
        {_options.map((type) => {
          return (
            <MenuItem
              sx={{fontSize: 14}}
              key={type}
              onClick={() => _onChange(type)}
              selected={type === props.value}>
              <div className={styles.dropdownItemInner}>
                <FocusDropdownOption type={type} />
                <div className={styles.dropdownItemInnerLabel}>
                  {t(`dashboard.pitchVisual.focusTypes.${type}`)}
                </div>
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </>
  );
}

export default FocusDropdown;
