import React, {PropsWithChildren, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {IDashboardScore} from '@/types/dashboard/dashboard-score.types';
import {useTeams} from '@/context/team.context';
import {useFilters} from '@/context/filters.context';
import {useDashboard} from '@/context/dashboard/dashboard.context';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {getDashboardScores} from '@/controllers/dashboard/dashboard.controller';
import {DASHBOARD_STATUS} from '@/types/dashboard/dashboard.types';
import {toggleActionSnakeCase} from '@/helpers/translation.helper';
import {useAuth} from '../auth.context';

export interface DashboardScoresAPI {
  loading: boolean;
  all: IDashboardScore[];
  current: IDashboardScore | undefined;
  matchLimit: number;
}

const _defaultState: DashboardScoresAPI = {
  loading: false,
  all: [],
  current: undefined,
  matchLimit: 30,
};

const context = createCustomContext<DashboardScoresAPI>();
export const useDashboardScores = createContextHook(context);

export const DashboardScoresProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  const [_loading, _setLoading] = useState(_defaultState.loading);
  const [_all, _setAll] = useState<IDashboardScore[]>(_defaultState.all);
  const [_current, _setCurrent] = useState<IDashboardScore | undefined>(
    _defaultState.current,
  );
  const [_matchLimit, _setMatchLimit] = useState(30);

  const _teamsContext = useTeams();
  const _params = useParams();
  const _filters = useFilters();
  const _dashboard = useDashboard();
  const _auth = useAuth();

  useEffect(() => {
    const _getScores = async () => {
      _setLoading(true);
      const team_id = _teamsContext.current?._id;

      const action = toggleActionSnakeCase(_params.dashboard || '');
      const matches_limit = _filters.filters['match._id'].length;

      if (team_id && action && _auth.user) {
        const scoresData = await getDashboardScores(
          team_id,
          action,
          matches_limit,
          _auth.user.team,
        );

        if (scoresData) {
          const {scores, match_limit} = scoresData;

          _setAll(scores);
          _setMatchLimit(match_limit);

          // Set current
          const current = scores.find((score) => score.team._id === team_id);
          if (current) _setCurrent(current);
        }
      }

      _setLoading(false);
    };

    if (
      _params.dashboard &&
      _dashboard.current !== null &&
      _dashboard.current.status === DASHBOARD_STATUS.LIVE
    ) {
      _getScores();
    }
  }, [_dashboard.current, _auth.user]);

  return createProvider(context, props, {
    loading: _loading,
    all: _all,
    current: _current,
    matchLimit: _matchLimit,
  });
};
