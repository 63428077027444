import React, {MouseEvent, useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {FIBenchmarkObservingOptions} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';
import BenchmarkSubjectCompetitionsList from './benchmark-subject-competitions-list.view';
import BenchmarkSubjectTeamsSelector from './benchmark-subject-teams-selector.view';

interface IBenchmarkSubjectPopupContentProps {
  onChange: (value: FIBenchmarkObservingOptions) => void;
  value?: FIBenchmarkObservingOptions;
}

const OBSERVING_OPTIONS: (keyof FIBenchmarkObservingOptions)[] = [
  'competition_id',
  'team_id',
];

function BenchmarkSubjectPopupContent(
  props: IBenchmarkSubjectPopupContentProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_activeTab, _setActiveTab] =
    useState<keyof FIBenchmarkObservingOptions>('competition_id');

  /*
   * Side Effects
   */
  useEffect(() => {
    if (props.value?.competition_id?.length) {
      _setActiveTab('competition_id');
    } else if (props.value?.team_id?.length) {
      _setActiveTab('team_id');
    }
  }, [props.value]);

  /*
   * Handlers
   */
  function _onTabChange(
    event: MouseEvent<HTMLElement>,
    newTab: [keyof FIBenchmarkObservingOptions],
  ) {
    _setActiveTab(newTab[0]);
  }

  function _onCompetitionIdChange(value: number[]) {
    props.onChange({
      competition_id: value,
    });
  }

  function _onTeamIdChange(value: string[]) {
    props.onChange({
      team_id: value,
    });
  }

  /*
   * Render
   */
  let _TabContent = null;
  if (_activeTab === 'competition_id') {
    _TabContent = (
      <BenchmarkSubjectCompetitionsList
        onChange={_onCompetitionIdChange}
        value={props.value?.competition_id}
      />
    );
  } else if (_activeTab === 'team_id') {
    _TabContent = (
      <BenchmarkSubjectTeamsSelector
        onChange={_onTeamIdChange}
        value={props.value?.team_id}
      />
    );
  }

  return (
    <Stack sx={{minWidth: 240}}>
      <Box p={1}>
        <ToggleButtonGroup onChange={_onTabChange} size="small" fullWidth>
          {OBSERVING_OPTIONS.map((tab) => {
            return (
              <ToggleButton key={tab} value={tab} selected={_activeTab === tab}>
                {t(`eventAutomations.filter.observing.${tab}`)}
              </ToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </Box>
      {_TabContent}
    </Stack>
  );
}

export default BenchmarkSubjectPopupContent;
