import React, {useCallback, useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import {IDashboardScore} from '@/types/dashboard/dashboard-score.types';
import Logo from '../../common/Logo/Logo';
import {IndicatorModal} from './indicator-modal/IndicatorModal';
import {IndicatorPopup} from './indicator-popup/IndicatorPopup';
import {
  normalize,
  roundAndFormatNumber,
} from '@/helpers/general/general.helper';

import {useTeams} from '@/context/team.context';
import styles from './Indicator.module.scss';
import {isOpponentAction} from '@/helpers/action.helper';
import {SxProps, Theme, alpha, useTheme} from '@mui/material';

type IndicatorPropsT = {
  value?: number;
  min?: number;
  max?: number;
  teamId?: string;
  scores?: IDashboardScore[];
  action?: string;
  matchLimit?: number;
  showInfo?: boolean;
  shouldFlipSubscore?: boolean;
  coloredValue?: boolean;
  bigValue?: boolean;
  hideValue?: boolean;
};

const Indicator = ({
  value,
  min = 0,
  max = 10,
  teamId,
  scores = [],
  action,
  matchLimit,
  showInfo,
  shouldFlipSubscore,
  coloredValue,
  bigValue,
  hideValue,
}: IndicatorPropsT) => {
  /*
   * Hooks n State
   */
  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);
  const _teamsContext = useTeams();
  const _theme = useTheme();

  const [_formattedValue, _setFormattedValue] = useState<
    string | number | undefined
  >(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [_imageURL, _setImageURL] = useState<string>('');
  const [_leftOffset, _setLeftOffset] = useState<number>(0);

  useEffect(() => {
    const _team = _teamsContext.all.find((team) => team._id === teamId);
    if (_team) {
      _setImageURL(_team.image_url);
    }
  }, [teamId, _teamsContext.all]);

  useEffect(() => {
    if (typeof value === 'undefined') {
      _setLeftOffset(0);
      return;
    }
    const _isOpponentAction = action && isOpponentAction(action);
    const _shouldFlip = _isOpponentAction && shouldFlipSubscore;

    let _min = min;
    let _max = max;

    if (_shouldFlip) {
      _min = max;
      _max = min;
    }
    const _left = Math.max(0, normalize(value, _min, _max) * 100 - 2.5);
    _setLeftOffset(_left);
  }, [value, min, max, action, shouldFlipSubscore]);

  useEffect(() => {
    if (typeof value !== 'undefined') {
      const _newFormattedValue = roundAndFormatNumber(value, 2);
      _setFormattedValue(_newFormattedValue);
    } else {
      _setFormattedValue(undefined);
    }
  }, [value]);

  //TODO: -5 because of weird CSS

  /*
   * Handlers
   */
  const getShowInfo = () => {
    return (
      <>
        <IndicatorModal
          isOpen={isModalOpen}
          closeModal={closeModal}
          scores={scores}
          action={action}
          matchLimit={matchLimit}
        />
        <IndicatorPopup openModal={openModal} scores={scores} />
      </>
    );
  };

  /*
   * Render
   */

  /* Define color */
  let _endColor = _theme.palette.error.main;
  if (typeof value !== 'undefined' && value > 6.66) {
    _endColor = _theme.palette.success.main;
  } else if (typeof value !== 'undefined' && value > 3.33) {
    _endColor = _theme.palette.warning.main;
  }
  const _startColor = alpha(_endColor, 0);

  /* Define handle */
  let _Handle = null;
  if (_imageURL && typeof value !== 'undefined') {
    _Handle = (
      <div className={styles.icon} style={{left: `${_leftOffset}%`}}>
        <Logo src={_imageURL} size="xsmall" />
      </div>
    );
  } else if (!teamId && typeof value !== 'undefined') {
    _Handle = (
      // <div
      //   className={classNames(styles.icon, styles.iconDummy)}
      //   style={{left: `${_leftOffset}%`}}>
      //   <div className={styles.handle} />
      // </div>
      <Box
        position="absolute"
        bgcolor={_endColor}
        height={12}
        width={4}
        borderRadius={2}
        top={'50%'}
        left={`${_leftOffset}%`}
        sx={{
          transform: 'translateY(-50%)',
        }}
      />
    );
  }

  /* Define value style */
  const _valueStyle: SxProps<Theme> = {
    fontSize: bigValue ? 16 : 14,
    fontWeight: 600,
    color: coloredValue ? _endColor : 'text.secondary',
  };

  let _ValueContent = null;

  if (!hideValue && typeof _formattedValue !== 'undefined') {
    _ValueContent = <Typography sx={_valueStyle}>{_formattedValue}</Typography>;
  } else if (!hideValue) {
    _ValueContent = <Skeleton variant="text" width={12} height={16} />;
  }
  // const _bgColor = alpha(_theme.palette.common.white, 0.08);
  return (
    <Stack alignItems="center" direction="row" gap={2} minHeight={16}>
      <Box
        // sx={{
        //   backgroundColor: _bgColor,
        // }}
        bgcolor="divider"
        height={6}
        borderRadius={3}
        width={'100%'}
        position="relative">
        {typeof value !== 'undefined' && (
          <Box
            // bgcolor="primary.main"
            height={'100%'}
            width={`${_leftOffset}%`}
            sx={{
              borderTopLeftRadius: 3,
              borderBottomLeftRadius: 3,
              background: `linear-gradient(90deg, ${_startColor} 0%, ${_endColor} 100%)`,
            }}
          />
        )}
        {_Handle}
      </Box>

      {_ValueContent}
      {showInfo && getShowInfo()}
    </Stack>
  );
};

export default Indicator;
