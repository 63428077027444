import React, {useState} from 'react';

import styles from './automation-type-info.module.scss';
import InlineFiltersSentence from '../../../sentence/builder-sentence.view';
import {TRACKER_TYPE_INFO_EXAMPLES} from '@/config/trackers.config';
import Tabs from '@/components/material-customised/tabs/tabs.view';
import Tab from '@mui/material/Tab';
import {useTranslation} from 'react-i18next';
import {TEventAutomationType} from '@my-game-plan/types';

interface IAutomationTypeInfoProps {
  automationType?: TEventAutomationType;
}

function TrackerTypeInfo(props: IAutomationTypeInfoProps): JSX.Element {
  const [_activeTabIndex, _setActiveTabIndex] = useState<number>(0);
  const {t} = useTranslation();

  const _activeTab = TRACKER_TYPE_INFO_EXAMPLES[_activeTabIndex];
  return (
    <div className={styles.container}>
      <Tabs
        value={_activeTabIndex}
        onChange={(event, index) => _setActiveTabIndex(index)}
        id="tabs-tracker-info">
        {TRACKER_TYPE_INFO_EXAMPLES.map((trackerType) => {
          if (
            props.automationType === 'opponent-automation' &&
            trackerType.calculation === 'rule'
          ) {
            return null;
          }
          return (
            <Tab
              key={trackerType.calculation}
              label={t(
                `eventAutomations.calculationType.${trackerType.calculation}`,
              )}
            />
          );
        })}
      </Tabs>
      <div className={styles.content}>
        <div className={styles.section}>
          <div className={styles.sectionHead}>
            {t('eventAutomations.examples.description')}
          </div>
          <div>{_activeTab.description}</div>
        </div>
        <div className={styles.section}>
          <div className={styles.sectionHead}>
            {t('eventAutomations.examples.examples')}
          </div>
          <div className={styles.section}>
            {_activeTab.examples.map((example) => {
              return (
                <div className={styles.example} key={example._id}>
                  <InlineFiltersSentence
                    automationType={props.automationType}
                    data={example}
                    readonly
                    size="small"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TrackerTypeInfo;
