import React from 'react';
import {useTranslation} from 'react-i18next';
import {getTranslatedSubscoreProperty} from '@/helpers/translation.helper';
import {IDashboardScore} from '@/types/dashboard/dashboard-score.types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

interface IndicatorInformationModalTableProps {
  score: IDashboardScore;
  action?: string;
}

export const IndicatorInformationModalTable = ({
  score,
  action,
}: IndicatorInformationModalTableProps) => {
  const {t} = useTranslation();

  return (
    <div>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{t('dashboard.scores.table.component')}</TableCell>
              <TableCell align="center">
                {t('dashboard.scores.table.weight')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {score.subscores?.map((subscore) => (
              <TableRow key={score.team.name}>
                <TableCell component="th" scope="row">
                  {getTranslatedSubscoreProperty(
                    action || '',
                    subscore.property,
                  )}
                </TableCell>
                <TableCell component="th" scope="row" align="center">
                  {`${subscore.weight}%`}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
