import React, {ReactNode} from 'react';

import MuiCard from '@mui/material/Card';
import {SxProps, Theme} from '@mui/material';

interface CardProps {
  children: ReactNode;
  padding?: boolean;
  sx?: SxProps<Theme>;
}

export const Card = ({children, padding = true, sx}: CardProps) => {
  return <MuiCard sx={{p: padding ? 2 : 0, ...sx}}>{children}</MuiCard>;
};
