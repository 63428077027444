import React from 'react';
import {ICommonSentenceProps} from '@/types/sentence-form.types';

import Segment from '../segment';
import {NOTIFICATION_OFFSET} from '@/config/event-automation-builder.config';
import Sentence from '../sentence.view';

function SentenceNotificationLine(props: ICommonSentenceProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _commonProps: ICommonSentenceProps = {
    ...props,
  };

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <>
      <Sentence.Segment>
        <Segment.Text
          translationKey="sentenceForm.notify.notify"
          {..._commonProps}
        />
      </Sentence.Segment>
      <Sentence.Segment>
        <Segment.NumberInput
          property="notification_offset"
          placeholder={NOTIFICATION_OFFSET.toString()}
          value={props.data.notification_offset}
          {..._commonProps}
        />
      </Sentence.Segment>
      <Sentence.Segment>
        <Segment.Text
          translationKey="sentenceForm.notify.daysBefore"
          translationKeyOptions={{count: props.data.notification_offset || 0}}
          {..._commonProps}
        />
      </Sentence.Segment>
    </>
  );
}

export default SentenceNotificationLine;
