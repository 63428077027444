import Screen from '@/components/screen/screen.view';
import {LIBRARY_ROUTES} from '@/config/navigation.config';
import LibraryRoutes from '@/routes/library.routes';
import React from 'react';
import {useTranslation} from 'react-i18next';

function LibraryView(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Side Effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <Screen title={t('uploads.library')} tabs={LIBRARY_ROUTES} coloredHeader>
      <LibraryRoutes />
    </Screen>
  );
}

export default LibraryView;
