import React from 'react';
import TeamView from '@/views/team.view';
import {Route, Routes} from 'react-router-dom';
import TrackerDetailView from '@/views/automations/tracker_detail.view';

function TeamRoutes() {
  return (
    <Routes>
      <Route path="" element={<TeamView />} />

      <Route path="trackers/:id" element={<TrackerDetailView />} />
    </Routes>
  );
}

export default TeamRoutes;
