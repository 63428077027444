import React, {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell, {TableCellProps} from '@mui/material/TableCell';
import {FIBenchmarkRankingItem} from '@my-game-plan/types';
import {SxProps, Theme} from '@mui/material';

import {useTranslation} from 'react-i18next';
import {
  roundAndFormatNumber,
  toPercentage,
} from '@/helpers/general/general.helper';
import Logo from '@/components/common/Logo/Logo';
import {
  getColorForScore,
  getRankingIndicesToDisplay,
} from '@/helpers/benchmark.helper';

interface IBenchmarkTableProps {
  compact?: boolean;
  ranking: FIBenchmarkRankingItem[];
  isRatio?: boolean;
  isTeamTracker?: boolean;
  positionInRank?: number;
  perNinety?: boolean;
  isShowingSequences?: boolean;
}

type TRankingCol =
  | 'rank'
  | 'player'
  | 'team'
  | 'matchesPlayed'
  | 'minutesPlayed'
  | 'successfulEvents'
  | 'totalEvents'
  | 'averageRate'
  | 'averagePerMatch'
  | 'averagePer90'
  | 'benchmarkedScore';
interface IRankingColConfig {
  col: TRankingCol;
  align: TableCellProps['align'];
  label: string;
  bold?: boolean;
  // render: (
  //   item: FIBenchmarkRankingItem,
  //   index: number,
  //   isTeamTracker?: boolean,
  // ) => React.ReactNode;
}
// const COLS: TRankingCol[] = ['rank', 'player', 'events', 'average'];

function BenchmarkTable(props: IBenchmarkTableProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_columns, _setColumns] = useState<IRankingColConfig[]>([]);
  const [_indicesToShow, _setIndicesToShow] = useState<number[]>([]);

  useEffect(() => {
    const _playerCol: TRankingCol = props.isTeamTracker ? 'team' : 'player';
    const _eventsCol: TRankingCol = props.isRatio
      ? 'successfulEvents'
      : 'totalEvents';

    const _averageCol: TRankingCol = props.isRatio
      ? 'averageRate'
      : 'averagePerMatch';

    if (props.compact) {
      const _cols: IRankingColConfig[] = [
        {
          col: 'rank',
          align: 'left',
          // render: (item, index, )
          label: '#',
        },
        {
          col: _playerCol,
          align: 'left',
          // render: (item, index, )
          label: t(`eventAutomations.benchmark.ranking.${_playerCol}`),
        },
        {
          col: _averageCol,
          align: 'right',
          label:
            props.perNinety && !props.isRatio
              ? t('eventAutomations.benchmark.ranking.averagePer90')
              : t(`eventAutomations.benchmark.ranking.${_averageCol}`),
          bold: true,
        },
      ];
      _setColumns(_cols);
    } else {
      const _cols: IRankingColConfig[] = [
        {
          col: 'rank',
          align: 'left',
          // render: (item, index, )
          label: '#',
        },
        {
          col: _playerCol,
          align: 'left',
          // render: (item, index, )
          label: t(`eventAutomations.benchmark.ranking.${_playerCol}`),
        },
        {
          col: 'benchmarkedScore',
          align: 'right',
          label: t('eventAutomations.properties.score'),
          bold: true,
        },

        {
          col: _averageCol,
          align: 'right',
          label:
            props.perNinety && !props.isRatio
              ? t('eventAutomations.benchmark.ranking.averagePer90')
              : t(`eventAutomations.benchmark.ranking.${_averageCol}`),
          bold: true,
        },
        {
          col: 'matchesPlayed',
          align: 'right',
          label: t('eventAutomations.benchmark.ranking.matchesPlayed'),
        },
        {
          col: 'minutesPlayed',
          align: 'right',
          label: t('eventAutomations.benchmark.ranking.minutesPlayed'),
        },
        {
          col: _eventsCol,
          align: 'right',
          label: props.isShowingSequences
            ? t('eventAutomations.benchmark.ranking.sequences')
            : t(`eventAutomations.benchmark.ranking.${_eventsCol}`),
        },
      ];
      _setColumns(_cols);
    }
  }, [
    props.compact,
    props.ranking,
    props.isRatio,
    props.isTeamTracker,
    props.perNinety,
  ]);

  useEffect(() => {
    const _indexes = getRankingIndicesToDisplay(
      props.positionInRank || -1,
      props.ranking.length,
    );
    _setIndicesToShow(_indexes);
  }, [props.ranking, props.compact, props.positionInRank]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  function _renderCell(
    col: IRankingColConfig,
    item: FIBenchmarkRankingItem,
    index: number,
  ): JSX.Element {
    let _value = null;
    const _cellStyle: SxProps<Theme> = {
      ...(col.bold && {
        fontWeight: '600',
      }),
    };

    switch (col.col) {
      case 'rank':
        _value = index + 1;
        _cellStyle.color = 'text.secondary';
        break;

      case 'player':
        if (item.player) {
          const _avatarSize = props.compact ? 32 : 32;
          let _Avatar = (
            <Avatar
              title={item.player.display_name}
              src={item.player.image_url}
              sx={{
                height: _avatarSize,
                width: _avatarSize,
                bgcolor: 'background.default',
              }}
            />
          );

          if (!props.compact) {
            _Avatar = (
              <Badge
                overlap="circular"
                anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                badgeContent={
                  <Logo src={item.team.image_url} size="xsmall" isBadge />
                }>
                {_Avatar}
              </Badge>
            );
          }
          _value = (
            // <PlayerDetails player={item.player} size="small" shouldTruncate />
            <Box display="flex" alignItems="center" flexDirection="row" gap={1}>
              {_Avatar}
              {item.player?.display_name}
            </Box>
          );
        }
        break;
      case 'team':
        if (props.compact) {
          _cellStyle.pl = 0;
        }
        _value = (
          <Box display="flex" alignItems="center" flexDirection="row" gap={1}>
            <Logo
              src={item.team.image_url}
              size={props.compact ? 'small' : 'medium'}
            />
            {item.team.name}
          </Box>
        );
        break;

      case 'minutesPlayed':
        _value = roundAndFormatNumber(item.minutes_played || 0);
        break;

      case 'matchesPlayed':
        _value = item.matches_played;

        break;

      case 'totalEvents':
        _value = _value = roundAndFormatNumber(item.total_events, 0);
        break;

      case 'successfulEvents':
        _value = `${item.total_successful_events}/${roundAndFormatNumber(
          item.total_events || 0,
          0,
        )}`;
        break;

      case 'averageRate':
        _value = toPercentage(item.average, 2);
        _cellStyle.color = getColorForScore(item.benchmarked_score || 0);

        break;

      case 'averagePerMatch':
        _value = roundAndFormatNumber(item.average, 2);
        _cellStyle.color = getColorForScore(item.benchmarked_score || 0);
        break;

      case 'benchmarkedScore':
        _value = roundAndFormatNumber(item.benchmarked_score || 0, 2);
        _cellStyle.color = getColorForScore(item.benchmarked_score || 0);
        break;
      default:
        break;
    }

    return (
      <TableCell
        align={col.align}
        key={`${col.col}-${item.player?._id || item.team._id}`}
        sx={_cellStyle}>
        {_value}
      </TableCell>
    );
  }
  return (
    <Table
      stickyHeader
      sx={{
        '& .MuiTableCell-root': {
          '&:first-of-type': {
            pl: props.compact ? 2 : undefined,
          },
          '&:last-of-type': {
            pr: props.compact ? 2 : undefined,
          },
          ...(props.compact && {
            borderBottom: 0,
            px: 0.5,
          }),
        },
      }}>
      {!props.compact && (
        <TableHead>
          <TableRow>
            {_columns.map((col) => (
              <TableCell
                key={col.col}
                align={col.align}
                sx={{fontSize: 12, bgcolor: 'background.paper'}}>
                {col.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
      )}
      <TableBody>
        {props.ranking.map((rankingItem, rankingIndex) => {
          // if (props.compact && rankingIndex > 2) return null;
          if (props.compact && !_indicesToShow.includes(rankingIndex)) {
            return null;
          }
          return (
            <TableRow
              key={rankingItem.player?._id || rankingItem.team._id}
              selected={rankingIndex === props.positionInRank}>
              {_columns.map((col) => {
                return _renderCell(col, rankingItem, rankingIndex);
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
}

export default BenchmarkTable;
