const SINGLE_SENDS_TRANSLATIONS = {
  title: 'Single Sends',
  empty: {
    header: "Couldn't find any playlists!",
    description:
      'You can create a playlist when watching video and selecting the videos you would like to share.',
  },
  create: {
    title: 'Single send playlist',
    intro: 'Share selected videos once',
    createTitle: 'Title',
    players: 'Players',
    comments: 'Comments',
    sharePlaylist: 'Share playlist',
    share: 'Share',
    success: 'Playlist shared successfully',
  },
  edit: {
    success: 'Playlist has been edited successfully',
  },
  delete: {
    title: 'Delete playlist',
    body: 'Are you sure you want to delete this playlist?',
    success: 'Playlist was deleted',
    'success-body': 'The playlist has been deleted successfully.',
    'error-title': 'Error deleting the playlist',
    'error-body': 'There was an error deleting the playlist. Please try again.',
  },
};

export default SINGLE_SENDS_TRANSLATIONS;
