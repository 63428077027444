const COMPETITIONS_TRANSLATIONS = {
  competitions: 'Competitions',
  domestic: 'Domestic',
  international: 'International',
  other: 'Other',

  seasons: {
    seasons: 'Seasons',
    // currentSeason: 'Current season',
  },
};

export default COMPETITIONS_TRANSLATIONS;
