import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {
  FIMatchEvent,
  FIEventFilters,
  FIFormattedAutomation,
  FIAutomationDataQueryParams,
} from '@my-game-plan/types';
import {PropsWithChildren, useState} from 'react';
import {useAuth} from '../auth.context';
import {
  fetchEventsForAutomation,
  getAllAutomations,
} from '@/controllers/event-automations.controller';
import {LOADING_STATE} from '@/types/screen.types';

export interface IOpponentAutomationsAPI {
  opponentAutomations: FIFormattedAutomation[];
  getAll: (
    automationFilters?: FIAutomationDataQueryParams,
    optionalEventFilters?: FIEventFilters,
  ) => void;
  // isLoading: boolean;
  // hasError: boolean;
  loadingState: LOADING_STATE;
  isInitialised: boolean;
  setIsInitialised: (isInitialised: boolean) => void;
  fetchEvents: (id: string, teamID?: string) => Promise<FIMatchEvent[]>;
  filters: FIAutomationDataQueryParams | null;
  setFilters: (filters: FIAutomationDataQueryParams | null) => void;
}

const context = createCustomContext<IOpponentAutomationsAPI>();
export const useOpponentAutomations = createContextHook(context);

function OpponentAutomationsProvider(
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element {
  /*
   * Init
   */
  const _auth = useAuth();
  const [_opponentAutomations, _setOpponentAutomations] = useState<
    FIFormattedAutomation[]
  >([]);

  const [_loadingState, _setloadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );
  const [_isInitialised, _setIsInitialised] = useState<boolean>(false);
  const [_filters, _setFilters] = useState<FIAutomationDataQueryParams | null>(
    null,
  );

  /*
   * Side effects
   */

  /*
   * Handlers
   */
  async function _getAll(
    automationFilters?: FIAutomationDataQueryParams,
    optionalEventFilters?: FIEventFilters,
  ) {
    if (_auth.user) {
      try {
        if (_loadingState !== LOADING_STATE.INITING) {
          _setloadingState(LOADING_STATE.LOADING);
        }

        const _fetchedData = await getAllAutomations(
          'opponent-automation',
          _auth.user.team,
          automationFilters,
          optionalEventFilters,
        );
        _setOpponentAutomations(_fetchedData.trackers);
        _setFilters(_fetchedData.filters);
        _setloadingState(LOADING_STATE.SUCCESS);
      } catch (error) {
        _setloadingState(LOADING_STATE.ERROR);
      } finally {
        _setIsInitialised(true);
      }
    }
  }

  async function _fetchEvents(id: string, teamID?: string) {
    const _filterParams: FIEventFilters = {};
    if (teamID) {
      _filterParams['team._id'] = [teamID];
    }
    return await fetchEventsForAutomation(
      'opponent-automation',
      id,
      _filterParams,
    );
  }

  return createProvider(context, props, {
    opponentAutomations: _opponentAutomations,
    getAll: _getAll,
    loadingState: _loadingState,
    isInitialised: _isInitialised,
    setIsInitialised: _setIsInitialised,

    fetchEvents: _fetchEvents,

    filters: _filters,
    setFilters: _setFilters,
  });
}

export default OpponentAutomationsProvider;
