import React from 'react';
import FilterTags from '../../components/dashboard/filter/tags/FilterTags';
import {OverviewDashboard} from '@/components/dashboard/overview-dashboards/OverviewDashboard';

import Stack from '@mui/material/Stack';

export function CustomDashboardView(): JSX.Element {
  /* Hooks n State */

  return (
    <Stack gap={3}>
      <FilterTags />
      <OverviewDashboard />
    </Stack>
  );
}
