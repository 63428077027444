import {t} from 'i18next';
import React from 'react';

import styles from './TrackerDetailFooter.module.scss';
import {formatDate} from '@/helpers/general/general.helper';

import {FIFormattedAutomation} from '@my-game-plan/types';

interface TrackerDetailFooterProps {
  tracker: FIFormattedAutomation;
}

export const TrackerDetailFooter = ({tracker}: TrackerDetailFooterProps) => {
  const getCreated = () => {
    return (
      <p>
        {t('eventAutomations.footer.created', {
          // name: _name,
          date: formatDate(tracker.created_at),
        })}
      </p>
    );
  };

  const getUpdated = () => {
    if (tracker.created_at !== tracker.modified_at)
      return (
        <span>
          {t('eventAutomations.footer.update', {
            date: formatDate(tracker.modified_at),
          })}
        </span>
      );
  };

  return (
    <div className={`${styles.container} table-text`}>
      {getCreated()}
      {getUpdated()}
    </div>
  );
};
