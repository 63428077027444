import {HTTPResponse, RequestOptions} from '@/types/api.types';
import axios from 'axios';
import QueryString from 'qs';

const SERVER_URL = process.env.REACT_APP_MY_GAME_PLAN_SERVER_URL;

export async function makeRequest<T>(
  options: RequestOptions,
): Promise<HTTPResponse<T>> {
  let _url = options.url;
  if (options.resource) {
    _url = `${options.resource}/${options.url}`;
  }
  const _response: HTTPResponse<T> = await axios({
    url: `${SERVER_URL}${_url}`,
    method: options.method,
    data: options.data,
    params: options.params,
    withCredentials: true,
    paramsSerializer: options.params ? serializeParams : undefined,
    ...options.options,
  });

  return _response;
}

export function serializeParams(params: any) {
  return QueryString.stringify(params, {
    arrayFormat: 'repeat',
    allowDots: true,
  });
}
