import {KonvaEventObject} from 'konva/lib/Node';
import React, {useRef, useState} from 'react';
import {Circle, Group, Layer, Line, Rect, Stage} from 'react-konva';

import {DOT_SIZE} from '@/config/pitch-visual.config';

import {
  Dimensions,
  IPitchVisualEventGroup,
  XYPos,
} from '@/types/dashboard/dashboard-pitch-visual.types';
import {
  ACTION,
  FICompactMatchInfo,
  FIConditionalEventFilters,
  FIEventFilters,
  FIMatch,
  FIMatchEvent,
  FIPitchZone,
  PitchVisualFocusType,
  PitchVisualType,
  TPolygon,
} from '@my-game-plan/types';

import Popover from '@mui/material/Popover';
import EventPopup from '../event-popup/EventPopup';
import FocusDropdown from './focus-dropdown/FocusDropdown';
import styles from './pitch-visual.module.scss';

import {
  calculateFinishingPosition,
  calculatePolygonDimensions,
  calculatePosition,
  calculateZoneDimensions,
} from '@/helpers/pitch-visual.helper';
import {useVideo} from '@/context/video/video.context';
import {useTheme} from '@mui/material';

interface ScatterPlotProps {
  dimensions: Dimensions;
  eventGroups: IPitchVisualEventGroup[];
  type: PitchVisualType;
  showPath: boolean;
  defaultFocusType: PitchVisualFocusType;
  isHalf?: boolean;
  defending: boolean;
  isGoal?: boolean;
  action: ACTION;
  details?: FIConditionalEventFilters;
  metric?: keyof FIEventFilters;
  zones?: FIPitchZone[];
  shouldDisplayOnOwnHalf?: boolean;
  shouldDifferentiateSuccessfulEvents?: boolean;
  matches?: FIMatch[] | FICompactMatchInfo[];
  isShowingSequences?: boolean;
}

interface HoveredEvent {
  event: FIMatchEvent;
  coordinates: XYPos;
}

function ScatterPlot(props: ScatterPlotProps): JSX.Element {
  /* Hooks n State */
  const _video = useVideo();
  const _theme = useTheme();
  const _containerRef = useRef<HTMLDivElement>(null);

  const [_hoveredEvent, _setHoveredEvent] = useState<HoveredEvent | null>(null);
  const [_hoveredSequenceId, _setHoveredSequenceId] = useState<string | null>(
    null,
  );

  const [_activeFocusType, _setActiveFocusType] =
    useState<PitchVisualFocusType>(props.defaultFocusType);

  /* Handlers */
  function _onMouseEnter(e: KonvaEventObject<MouseEvent>, event: FIMatchEvent) {
    const container = e.target.getStage()?.container();
    if (container) container.style.cursor = 'pointer';
    _setHoveredEvent({
      event,
      coordinates: {
        x: e.evt.clientX,
        y: e.evt.clientY,
      },
    });
    if (!_hoveredSequenceId && props.isShowingSequences && event.sequence?._id)
      _setHoveredSequenceId(event.sequence._id);
  }

  function _onMouseLeave(e: KonvaEventObject<MouseEvent>) {
    const container = e.target.getStage()?.container();
    if (container) container.style.cursor = 'default';
    _setHoveredEvent(null);
  }

  const _onPitchClick = () => {
    _setHoveredSequenceId(null);
  };

  function _onMouseDown(event: FIMatchEvent) {
    const _matches =
      props.matches?.filter((match) => match._id === event.match._id) || [];
    let _sequenceEvents = [event];
    if (props.isShowingSequences && _hoveredSequenceId)
      _sequenceEvents = props.eventGroups
        .map((group) => group.events)
        .flat()
        .filter((event) => event.sequence?._id === _hoveredSequenceId);

    _video.openVideoPlayer(
      _sequenceEvents,
      _matches,
      undefined,
      true,
      {
        action: props.action,
        details: props.details,
        metric: props.metric,
      },
      undefined,
      props.isShowingSequences,
    );
  }

  function _onDropdownChange(type: PitchVisualFocusType) {
    _setActiveFocusType(type);
  }

  /* Render */
  const _shouldShowStart =
    _activeFocusType === PitchVisualFocusType.START_END ||
    _activeFocusType === PitchVisualFocusType.START_FOCUS ||
    _activeFocusType === PitchVisualFocusType.START;

  const _shouldShowLine =
    props.showPath &&
    (_activeFocusType === PitchVisualFocusType.START_END ||
      _activeFocusType === PitchVisualFocusType.START_FOCUS ||
      _activeFocusType === PitchVisualFocusType.END_FOCUS);

  const _shouldShowEnd =
    _activeFocusType === PitchVisualFocusType.START_END ||
    _activeFocusType === PitchVisualFocusType.END_FOCUS ||
    _activeFocusType === PitchVisualFocusType.END;

  const focusTypesWithEndCoordinates = new Set([
    PitchVisualFocusType.START_END,
    PitchVisualFocusType.END,
    PitchVisualFocusType.END_FOCUS,
    PitchVisualFocusType.START_FOCUS,
  ]);

  const _positionCalculator = props.isGoal
    ? calculateFinishingPosition
    : calculatePosition;

  return (
    <div className={styles.pitchOverlay} onClick={_onPitchClick}>
      {props.dimensions && (
        // Canva
        <div className={styles.canvasWrapper} ref={_containerRef}>
          <Stage
            height={props.dimensions.height}
            width={props.dimensions.width}>
            {/* Zones */}
            <Layer>
              {props.zones?.map((zone) => {
                if (zone.box) {
                  const _rect = calculateZoneDimensions(
                    zone.box,
                    props.dimensions,
                    props.defending,
                  );
                  return (
                    <Rect
                      key={zone._id}
                      x={_rect.x}
                      y={_rect.y}
                      width={_rect.width}
                      height={_rect.height}
                      fill={`${_theme.palette.primary.main}1A`}
                      dash={[5, 5]}
                      stroke={_theme.palette.primary.main}
                      opacity={0.8}
                      strokeWidth={1}
                    />
                  );
                } else if (zone.polygon) {
                  const _polygonPoints = calculatePolygonDimensions(
                    zone.polygon as TPolygon,
                    props.dimensions,
                    props.defending,
                  );
                  return (
                    <Line
                      key={zone._id}
                      points={_polygonPoints.flat()} // Flatten the points array into a single array
                      fill={`${_theme.palette.primary.main}1A`}
                      stroke={_theme.palette.primary.main}
                      opacity={0.8}
                      strokeWidth={1}
                      closed={true} // Ensure the polygon is closed
                    />
                  );
                }
              })}
            </Layer>

            {/* Events */}
            <Layer>
              {props.eventGroups.map((eventGroup) => {
                let _currentSequenceId: string;
                let _currentLocation: {X: number; Y: number};
                return eventGroup.events.map((event) => {
                  // Transition action from opponent
                  // = for example a clearance from opponent in your sequence that does not start a new sequence
                  const _transitionActionFromOpponent =
                    props.isShowingSequences &&
                    event.sequence?.team_id !== event.team._id;
                  const {startX, endX, startY, endY} = _positionCalculator(
                    event,
                    props.dimensions,
                    props.isHalf,
                    (props.defending && !_transitionActionFromOpponent) ||
                      (!props.defending && _transitionActionFromOpponent),
                    props.shouldDisplayOnOwnHalf,
                  );

                  const _hasEndCoordinates = endY !== null && endX !== null;

                  let _groupColor = eventGroup.color;
                  let _isFaded =
                    props.isShowingSequences && event.event_type === 'carry'; // in sequences: show carry with a dashed line
                  //let _fillStyle: Konva.NodeConfig = {
                  //  fill: eventGroup.color,
                  //};
                  let _fillStyle = {};
                  let _lineStyle = {};

                  // Set fill style and line style based on hovered sequence
                  if (_hoveredSequenceId) {
                    if (event.sequence?._id === _hoveredSequenceId) {
                      // Highlight the specific sequence
                      _fillStyle = {fill: eventGroup.color, opacity: 1};
                      _lineStyle = {
                        stroke: eventGroup.color,
                        opacity: 1,
                        strokeWidth: 3,
                      };
                    } else {
                      // Make other sequences transparent
                      _fillStyle = {fill: eventGroup.color, opacity: 0.1};
                      _lineStyle = {stroke: eventGroup.color, opacity: 0.1};
                    }
                  } else {
                    _fillStyle = {
                      fill: eventGroup.color,
                    };
                  }
                  let _extraLine;
                  if (
                    props.isShowingSequences &&
                    event.sequence?._id &&
                    _currentSequenceId === event.sequence._id &&
                    _currentLocation?.X &&
                    _currentLocation?.Y
                  ) {
                    const isEndFocus =
                      _activeFocusType === PitchVisualFocusType.END &&
                      _hasEndCoordinates;
                    const _plotLocation = isEndFocus
                      ? {X: endX, Y: endY}
                      : {X: startX, Y: startY};

                    _extraLine = (
                      <Line
                        strokeWidth={1}
                        stroke={_groupColor}
                        dash={[5, 10]}
                        points={[
                          _currentLocation.X,
                          _currentLocation.Y,
                          _plotLocation.X,
                          _plotLocation.Y,
                        ]}
                        {..._lineStyle}
                      />
                    );
                  } else if (event.sequence?._id) {
                    _currentSequenceId = event.sequence?._id;
                  }

                  const useEndCoordinates =
                    focusTypesWithEndCoordinates.has(_activeFocusType) &&
                    _hasEndCoordinates;

                  _currentLocation = useEndCoordinates
                    ? {X: endX, Y: endY}
                    : {X: startX, Y: startY};

                  if (
                    props.shouldDifferentiateSuccessfulEvents &&
                    !event.successful
                  ) {
                    _groupColor = `${eventGroup.color}57`;
                    _isFaded = true;
                    const STROKE_WIDTH = 1;
                    const SIZE = DOT_SIZE - STROKE_WIDTH;
                    _fillStyle = {
                      fill: `${_theme.palette.background.paper}`,
                      strokeWidth: STROKE_WIDTH,
                      stroke: _groupColor,
                      // fill: eventGroup.color,
                      // opacity: 0.1,
                      height: SIZE,
                      width: SIZE,
                    };
                  }
                  return (
                    <Group key={event._id}>
                      {_shouldShowStart && props.showPath && (
                        <Rect
                          width={DOT_SIZE}
                          height={DOT_SIZE}
                          x={startX - DOT_SIZE / 2}
                          y={startY - DOT_SIZE / 2}
                          onMouseEnter={(e) => _onMouseEnter(e, event)}
                          onMouseDown={() => _onMouseDown(event)}
                          onMouseLeave={(e) => _onMouseLeave(e)}
                          {..._fillStyle}
                        />
                      )}
                      {_shouldShowStart && !props.showPath && (
                        <Circle
                          width={DOT_SIZE}
                          height={DOT_SIZE}
                          x={startX}
                          y={startY}
                          onMouseEnter={(e) => _onMouseEnter(e, event)}
                          onMouseDown={() => _onMouseDown(event)}
                          onMouseLeave={(e) => _onMouseLeave(e)}
                          {..._fillStyle}
                        />
                      )}
                      {_shouldShowLine && _hasEndCoordinates && (
                        <Line
                          strokeWidth={1}
                          stroke={_groupColor}
                          dash={_isFaded ? [5, 10] : undefined}
                          points={[startX, startY, endX, endY]}
                          {..._lineStyle}
                        />
                      )}
                      {_shouldShowEnd && _hasEndCoordinates && (
                        <Circle
                          width={DOT_SIZE}
                          height={DOT_SIZE}
                          x={endX}
                          y={endY}
                          onMouseEnter={(e) => _onMouseEnter(e, event)}
                          onMouseDown={() => _onMouseDown(event)}
                          onMouseLeave={(e) => _onMouseLeave(e)}
                          {..._fillStyle}
                        />
                      )}
                      {_extraLine && _extraLine}
                    </Group>
                  );
                });
              })}
            </Layer>
          </Stage>

          {/* End/Start Dropdown */}
          {props.showPath && (
            <div
              className={styles.dropdown}
              onClick={(e) => e.stopPropagation()} // Prevents the click from bubbling up
            >
              <FocusDropdown
                visual="scatter"
                value={_activeFocusType}
                onChange={_onDropdownChange}
              />
            </div>
          )}

          {/* Event popup */}
          <Popover
            sx={{pointerEvents: 'none'}}
            PaperProps={{
              sx: {
                bgcolor: 'background.default',
              },
            }}
            disableAutoFocus
            anchorEl={_containerRef.current}
            open={Boolean(_hoveredEvent)}
            anchorReference="anchorPosition"
            anchorPosition={{
              left: _hoveredEvent?.coordinates.x || 0,
              top: _hoveredEvent?.coordinates.y || 0,
            }}>
            {_hoveredEvent && (
              <EventPopup event={_hoveredEvent.event} metric={props.metric} />
            )}
          </Popover>
        </div>
      )}
    </div>
  );
}

export default ScatterPlot;
