const PLAYER_POSITIONS_TRANSLATIONS = {
  transferredPlayer: '[Transferred Player]',
  transferredPlayers: 'Transferred Players',
  transferredPlayersAny: 'Transferred Players',

  any: 'any position',
  goalkeeper: 'a goalkeeper',
  goalkeepers: 'goalkeepers',
  goalkeepersAny: 'any goalkeeper',

  defender: 'a defender',
  defenders: 'defenders',
  defendersAny: 'any defender',

  fullback: 'a fullback',
  fullbacks: 'fullbacks',
  fullbacksAny: 'any fullback',

  midfielder: 'midfielder',
  midfielders: 'midfielders',
  midfieldersAny: 'any midfielder',

  winger: 'a winger',
  wingers: 'wingers',
  wingersAny: 'any winger',

  attacker: 'an attacker',
  attackers: 'attackers',
  attackersAny: 'any attacker',
};

export default PLAYER_POSITIONS_TRANSLATIONS;
