import React from 'react';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import {capitalize} from '@/helpers/general/general.helper';
import {useTranslation} from 'react-i18next';

interface CustomChipSelectProps {
  label: string;
  placeholder?: string;
  options: string[];
  selectedOptions: string[];
  onChange: (selected: string[]) => void;
  onAddOption?: (option: string) => void;
}

const CustomChipSelect: React.FC<CustomChipSelectProps> = ({
  label,
  placeholder,
  options,
  selectedOptions,
  onChange,
  onAddOption,
}) => {
  const {t} = useTranslation();
  const handleInputChange = (
    event: React.SyntheticEvent,
    newValue: string[],
    reason: string,
  ) => {
    if (reason === 'createOption') {
      const lowerOption = newValue[newValue.length - 1].toLowerCase();
      if (onAddOption) {
        onAddOption(lowerOption);
      }
      // Replace the newly created option with its lowercase equivalent
      newValue[newValue.length - 1] = lowerOption;
    }
    onChange(newValue);
  };

  return (
    <Autocomplete
      multiple
      options={options}
      value={selectedOptions}
      freeSolo // Allow free solo (for custom input)
      forcePopupIcon
      onChange={(event, newValue, reason) =>
        handleInputChange(event, newValue as string[], reason)
      }
      componentsProps={{
        paper: {
          sx: {bgcolor: 'background.default'},
        },
      }}
      renderTags={(value: string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip
            {...getTagProps({index})}
            key={`${option}-${index}`}
            variant="outlined"
            label={capitalize(option)}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          // endAdornment={<ArrowDropDownIcon />}
          type="text"
          label={label}
          placeholder={placeholder}
          helperText={t('preferences.tags.create.helperText')}
        />
      )}
    />
  );
};

export default CustomChipSelect;
