const OVERVIEW_DASHBOARDS_TRANSLATIONS = {
  nav: 'widgets',
  error: 'Something went wrong fetching the overview dashboard!',
  empty: {
    header: 'This overview dashboard is empty!',
    description:
      "You can add some widgets by clicking the 'Customize dashboard' button.",
  },
  widgets: {
    scores: {
      league_one: '{{count}}st in the league',
      league_two: '{{count}}nd in the league',
      league_few: '{{count}}rd in the league',
      league_other: '{{count}}th in the league',
    },
    formations: {
      consistency: 'Consistency',
      'time-in-formation': 'Time in formation',
    },
    labels: {
      'team-strength': 'Team strength',
    },
    keys: {
      formations: 'Formations',
    },
  },
  add: {
    deleteAll: 'Remove all widgets',
    title: 'Add overview dashboard',
    button: 'Add Dashboard',
    search: 'Search widgets',
    'title-placeholder': 'Dashboard title',
    error: 'Title is required',
    flags: {
      success: {
        title: 'Overview dashboard added',
        body: 'The overview dashboard has successfully been added.',
      },
      error: {
        title: 'Error adding overview dashboard',
        body: 'There was an error while trying to add the overview dashboard. Please try again.',
      },
    },
  },
  edit: {
    title: 'Customize overview dashboard',
    button: 'Customize Dashboard',
    flags: {
      success: {
        title: 'Overview dashboard edited',
        body: 'The overview dashboard has successfully been edited.',
      },
      error: {
        title: 'Error editing overview dashboard',
        body: 'There was an error while trying to edit the overview dashboard. Please try again.',
      },
    },
  },
  delete: {
    title: 'Delete overview dashboard',
    body: 'Are you sure you want to delete the overview dashboard?',
    flags: {
      success: {
        title: 'Overview dashboard deleted',
        body: 'The overview dashboard has successfully been deleted.',
      },
      error: {
        title: 'Error deleting overview dashboard',
        body: 'There was an error while trying to deleting the overview dashboard. Please try again.',
      },
    },
  },
};

export default OVERVIEW_DASHBOARDS_TRANSLATIONS;
