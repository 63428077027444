import React, {useEffect, useState} from 'react';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import TextField from '@mui/material/TextField';
import {useTranslation} from 'react-i18next';
import CustomChipSelect from '@/components/common/CustomChipSelect';
import {useTeams} from '@/context/team.context';
import {ExtraAutomationActionsProps} from '@/types/sentence-form.types';
import Divider from '@mui/material/Divider';

function ExtraAutomationActions(
  props: ExtraAutomationActionsProps,
): JSX.Element {
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const [tags, setTags] = useState(
    props.data.tags?.map((tag) => {
      const foundTag = _teamsContext.preferences?.tags?.find(
        (t) => t._id == tag,
      );
      return foundTag ? foundTag.label : tag;
    }),
  );

  useEffect(() => {
    const updatedTags = props.data.tags?.map((tag) => {
      const foundTag = _teamsContext.preferences?.tags?.find(
        (t) => t._id == tag,
      );
      return foundTag ? foundTag.label : tag;
    });
    setTags(updatedTags);
  }, [props.data.tags, _teamsContext.preferences]);

  return (
    <Stack mt={8} gap={3}>
      <Divider />
      {/* <Typography variant="h6" color="secondary" fontWeight={'light'}>
        {t('sentenceForm.extraSettings.sectionHeader')}
      </Typography> */}

      <Grid container spacing={2} pt={2}>
        <Grid item xs={6}>
          <CustomChipSelect
            label={t('sentenceForm.extraSettings.tags')}
            options={[
              ...new Set([
                ...(_teamsContext?.preferences?.tags
                  ? _teamsContext.preferences.tags.map((t) => t.label)
                  : []),
                ...(tags ? tags : []),
              ]),
            ]}
            selectedOptions={tags || []}
            onChange={(newSelected) => props.onChange?.({tags: newSelected})}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            fullWidth
            label={t('sentenceForm.extraSettings.title')}
            value={props.data.title || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              props.onChange?.({title: event.currentTarget.value})
            }
          />
        </Grid>
        {props.automationType == 'opponent-automation' && (
          <Grid item xs={6}>
            <TextField
              fullWidth
              multiline
              minRows={2}
              label={t('sentenceForm.extraSettings.comment')}
              value={props.data.comment}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                props.onChange?.({comment: event.currentTarget.value})
              }
            />
          </Grid>
        )}
      </Grid>
    </Stack>
  );
}

export default ExtraAutomationActions;
