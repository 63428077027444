import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import {t} from 'i18next';
import React from 'react';
import styles from './PlayerDetails.module.scss';
import {FIDisplayPlayer} from '@my-game-plan/types';

export type SizeType =
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge';
type PlayerDetailsProps = {
  player: FIDisplayPlayer;
  shouldTruncate?: boolean;
  status?: 'approved' | 'declined';
  size?: SizeType;
};

const SIZES_MAP: Record<SizeType, number> = {
  xsmall: 16,
  small: 24,
  medium: 32,
  large: 40,
  xlarge: 48,
  xxlarge: 56,
};

export const PlayerDetails = ({
  player,
  shouldTruncate = false,
  status,
  size = 'medium',
}: PlayerDetailsProps) => {
  const {name, display_name, image_url} = player;

  const getStatusText = () => {
    if (status === 'approved') return t('player-details.approved');
    if (status === 'declined') return t('player-details.declined');
  };

  return (
    <Tooltip title={name} enterDelay={1000}>
      <div className={styles.container}>
        <Avatar
          src={image_url}
          title={display_name}
          sx={{
            height: SIZES_MAP[size],
            width: SIZES_MAP[size],
            bgcolor: 'background.default',
          }}
        />
        <div className={styles.info}>
          <span
            className={`${styles.playerName} ${
              shouldTruncate ? styles.playerNameTruncated : ''
            }`}>
            {display_name}
          </span>
          {status && (
            <span className="table-text" style={{textTransform: 'initial'}}>
              {getStatusText()}
            </span>
          )}
        </div>
      </div>
    </Tooltip>
  );
};
