import React, {useEffect, useState} from 'react';
import {searchTeams} from '@/controllers/teams.controller';
import {LOADING_STATE} from '@/types/screen.types';
import {FITeam} from '@my-game-plan/types';
import {debounce} from 'lodash';
import {useTranslation} from 'react-i18next';

import Autocomplete from '@mui/material/Autocomplete';
import Textfield from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Logo from '../common/Logo/Logo';

interface ITeamSearchInputProps {
  //   value: string;
  onTeamSelect: (value: FITeam | null) => void;
  availableTeams?: FITeam[];
  selectedTeam?: FITeam | null;
  size?: 'small' | 'medium';
  clearOnSelect?: boolean;
}

function TeamSearchInput(props: ITeamSearchInputProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_options, _setOptions] = useState<FITeam[]>([]);
  const [_searchLoadingState, _setSearchLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );

  const [_inputValue, _setInputValue] = useState<string>('');

  const _debouncedSearchHandler = debounce(_searchHandler, 500);

  /*
   * Side effects
   */
  useEffect(() => {
    return () => {
      _debouncedSearchHandler.cancel();
    };
  }, []);

  // Set own teams as default
  useEffect(() => {
    if (props.availableTeams?.length) {
      _setOptions(props.availableTeams);
      return;
    }
    _setOptions([]);
  }, [props.availableTeams]);

  /*
   * Handlers
   */
  function _onInputChange(event: React.SyntheticEvent, newValue: string) {
    _debouncedSearchHandler(newValue);
    // _searchHandler(newValue);
  }

  async function _searchHandler(query: string) {
    if (props.availableTeams?.length) {
      _setOptions(props.availableTeams);
    } else {
      _setInputValue(query);
      if (!query) {
        _setOptions([]);
        return;
      }

      try {
        _setSearchLoadingState(LOADING_STATE.LOADING);

        const _fetchedTeams = await searchTeams({q: query});
        _setOptions(_fetchedTeams || []);
        _setSearchLoadingState(LOADING_STATE.SUCCESS);
      } catch (error) {
        _setSearchLoadingState(LOADING_STATE.ERROR);
      }
    }
  }

  function _onTeamSelect(event: React.SyntheticEvent, value: FITeam | null) {
    if (value && !_options.some((option) => option._id === value._id)) {
      _setOptions([value, ..._options]);
    }

    props.onTeamSelect(value);
  }

  /*
   * Render
   */
  return (
    <Autocomplete
      size={props.size}
      value={props.selectedTeam || null}
      clearOnBlur={false}
      options={_options}
      getOptionLabel={(option) => option.name}
      onChange={_onTeamSelect}
      onInputChange={_onInputChange}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      renderInput={(textFieldProps) => (
        <Textfield
          {...textFieldProps}
          // value={_inputValue}
          placeholder={t('preferences.switchTeam.search')}
        />
      )}
      noOptionsText={_inputValue ? t('preferences.switchTeam.empty') : ''}
      loading={_searchLoadingState === LOADING_STATE.LOADING}
      componentsProps={{
        popper: {
          sx: {boxShadow: 4},
        },
        paper: {
          sx: {bgcolor: 'background.default'},
        },
      }}
      renderOption={(optionProps, option) => {
        const _name =
          option.gender === 'female' ? `${option.name} (F)` : `${option.name}`;
        return (
          <Stack
            component="li"
            direction="row"
            alignItems="center"
            gap={2}
            {...optionProps}>
            <Logo src={option.image_url} size="xsmall" />
            {_name}
          </Stack>
        );
      }}
    />
  );
}

export default TeamSearchInput;
