import React, {PropsWithChildren, useEffect, useState} from 'react';

import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {getMatchesByTeam} from '@/controllers/matches.controller';
import {FIMatch} from '@my-game-plan/types';
import {LOGIN_ERRORS} from '@/config/errors.config';
import {useTeams} from './team.context';

import {getOpponent} from '@/helpers/general/general.helper';
import {uniqBy} from 'lodash';
import {ITypedOption} from '@/types/option.types';

export interface MatchesAPI {
  all: FIMatch[];
  ownMatches: FIMatch[];
  getMatchesByTeam: (team: string | undefined, isOwnTeam?: boolean) => void;
  error: string | null;
}
const context = createCustomContext<MatchesAPI>();
export const useMatches = createContextHook(context);

export const MatchesProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  const _teamsContext = useTeams();
  const [_matches, _setMatches] = useState<FIMatch[]>([]);
  const [_ownMatches, _setOwnMatches] = useState<FIMatch[]>([]);
  const [_error, _setError] = useState<string | null>(null);

  /*
   * Side effects
   */
  useEffect(() => {
    const _opponents = _ownMatches
      .sort((a, b) => {
        const _opponentA = getOpponent(_teamsContext.ownTeam?._id, a);
        const _opponentB = getOpponent(_teamsContext.ownTeam?._id, b);

        return _opponentA?.name.localeCompare(_opponentB?.name || '') || 0;
      })
      .map((match) => getOpponent(_teamsContext.ownTeam?._id, match))
      .filter((opp) => opp);
    const _filteredOpponents = uniqBy(_opponents, '_id').filter((opp) => opp);

    const _options: ITypedOption<string>[] = [];

    _filteredOpponents.forEach((team) => {
      if (!team) {
        return;
      }
      _options.push({
        value: team._id,
        label: team.name,
        name: team.name,
        group: 'all',
      });
    });
    _teamsContext.setOpponentsOptions(_options);
  }, [_teamsContext.ownTeam, _teamsContext.all, _ownMatches]);

  /* Handlers */
  async function _getMatchesByTeam(
    team: string | undefined,
    isOwnTeam?: boolean,
  ) {
    if (isOwnTeam) {
      _setOwnMatches([]);
    } else {
      _setMatches([]);
    }
    _setError(null);
    const _matchesData = await getMatchesByTeam(team);
    if (!_matchesData.length) {
      _setError(LOGIN_ERRORS.TEAM_NO_MATCHES);
    }
    if (isOwnTeam) {
      _setOwnMatches(_matchesData);
    } else {
      _setMatches(_matchesData);
    }
    return _matchesData;
  }

  return createProvider(context, props, {
    all: _matches,
    ownMatches: _ownMatches,
    getMatchesByTeam: _getMatchesByTeam,
    error: _error,
  });
};
