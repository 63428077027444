import {useTranslation} from 'react-i18next';
import classNames from 'classnames';

import Stack from '@mui/material/Stack';
import DashboardBlock from '../../components/dashboard/block/Block';
import {EmailButton} from '@/components/buttons/EmailButton';

import FilterTags from '../../components/dashboard/filter/tags/FilterTags';
import Loader from '../../components/common/Loader/Loader.view';
import {
  DASHBOARD_STATE,
  useDashboard,
} from '@/context/dashboard/dashboard.context';
import React, {useEffect, useState} from 'react';
import {useFilters} from '@/context/filters.context';
import {DASHBOARD_STATUS} from '@/types/dashboard/dashboard.types';
import CustomEmptyState from '@/components/error-states/CustomEmptyState';
import {useMatches} from '@/context/matches.context';
import {LOGIN_ERRORS} from '@/config/errors.config';
import styles from '../../components/dashboard/stylesheet/dashboard.module.scss';

export const NormalDashboardView = () => {
  /* Hooks n State */

  const _dashboard = useDashboard();
  const _matchContext = useMatches();
  const _filters = useFilters();
  const {t} = useTranslation();
  const [_isLoading, _setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (_dashboard.current && !_filters.isLoading) {
      _setIsLoading(false);
    } else {
      _setIsLoading(true);
    }
  }, [_dashboard.current, _filters.isLoading]);

  /* Render */
  const _contentClasses = classNames(styles.content, {
    [styles.contentHalf]:
      _dashboard.current?.blocks.length &&
      _dashboard.current.blocks.length % 2 === 0,
    [styles.contentEmpty]:
      _dashboard.current?.status === DASHBOARD_STATUS.IN_BUILD,
  });

  let _Content = null;

  const _isLoaded = !_filters.isLoading && _dashboard.current;

  if (_isLoaded && _dashboard.current?.status === DASHBOARD_STATUS.LIVE) {
    _Content = (
      <>
        {_dashboard.rows.map((row, index) => {
          return (
            <div className={styles.row} key={index}>
              {row &&
                row.map((block, blockIndex) => (
                  <DashboardBlock
                    key={`${_dashboard.current?.action}-${block.type}-${blockIndex}`}
                    data={block}
                  />
                ))}
            </div>
          );
        })}
      </>
    );
  } else if (
    _isLoaded &&
    _dashboard.current?.status === DASHBOARD_STATUS.IN_BUILD
  ) {
    _Content = (
      <CustomEmptyState
        header={t('empty.dashboard.title')}
        description={t('empty.dashboard.description')}
        imageType="default"
        primaryAction={<EmailButton />}
      />
    );
  } else if (_dashboard.state === DASHBOARD_STATE.ERROR) {
    const _title =
      _matchContext.error === LOGIN_ERRORS.TEAM_NO_MATCHES
        ? 'error-states.matches.header'
        : 'error-states.not-found.header';
    const _description =
      _matchContext.error === LOGIN_ERRORS.TEAM_NO_MATCHES
        ? 'error-states.matches.description'
        : 'error-states.not-found.description';
    const _Action =
      _matchContext.error === LOGIN_ERRORS.TEAM_NO_MATCHES ? undefined : (
        <EmailButton />
      );
    _Content = (
      <CustomEmptyState
        header={t(_title)}
        description={t(_description)}
        imageType="default"
        primaryAction={_Action}
      />
    );
  }

  return (
    <Stack gap={3}>
      <FilterTags />
      <div className={_contentClasses}>
        {_Content}
        <Loader
          isVisible={_isLoading && _dashboard.state !== DASHBOARD_STATE.ERROR}
        />
      </div>
    </Stack>
  );
};
