import React, {useState} from 'react';
import {FISingleSend} from '@my-game-plan/types';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SendIcon from '@mui/icons-material/Send';
import CreateIcon from '@mui/icons-material/Create';
import SingleSendCreatorModal from '../single-send-modal/single-send-modal.view';
import {useTranslation} from 'react-i18next';

interface ISingleSendCreateButtonProps {
  variant: 'icon' | 'menu-item';
  data?: FISingleSend;
  onClose?: () => void;
  events: string[];
  disabled?: boolean;
}

function SingleSendCreateButton(props: ISingleSendCreateButtonProps) {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_isModalOpen, _setIsModalOpen] = useState<boolean>(false);
  /*
   * Handlers
   */
  function _onTriggerClick() {
    if (props.onClose) {
      props.onClose();
    }
    _setIsModalOpen(true);
  }

  function _onModalClose() {
    _setIsModalOpen(false);
  }

  /*
   * Render
   */
  let _Trigger = null;

  if (props.data && props.variant === 'icon') {
    _Trigger = (
      <IconButton color="secondary" onClick={_onTriggerClick}>
        <CreateIcon />
      </IconButton>
    );
  } else if (props.variant === 'menu-item') {
    _Trigger = (
      <MenuItem
        onClick={_onTriggerClick}
        sx={{whiteSpace: 'normal'}}
        disabled={props.disabled}>
        <ListItemIcon>
          <SendIcon />
        </ListItemIcon>
        <ListItemText
          primary={t('singleSends.create.title')}
          secondary={t('singleSends.create.intro')}
        />
      </MenuItem>
    );
  }

  return (
    <>
      {_Trigger}
      <SingleSendCreatorModal
        isOpened={_isModalOpen}
        onClose={_onModalClose}
        data={props.data}
        events={props.events}
      />
    </>
  );
}

export default SingleSendCreateButton;
