import React from 'react';
import classNames from 'classnames';

import styles from './logo.module.scss';

type LogoSize = 'xsmall' | 'small' | 'medium' | 'large' | 'xlarge' | 'xxlarge';

interface LogoProps {
  size?: LogoSize;
  src: string;
  isBadge?: boolean;
}

function Logo({size = 'small', src, isBadge}: LogoProps): JSX.Element {
  // console.log(src);

  let _Content = null;
  if (src) {
    _Content = (
      <div
        className={styles.image}
        style={{backgroundImage: `url('${src}')`}}></div>
    );
  }
  // else {
  //   _Content = (
  //     <div>
  //       <ShieldIcon />
  //     </div>
  //   );
  // }

  return (
    <div
      className={classNames(styles.container, styles[`container-${size}`], {
        [styles.containerBadge]: isBadge,
      })}>
      {_Content}
    </div>
  );
}

export default Logo;
