export enum IExplorerType {
  MATCHES = 'matches',
  FORMATIONS = 'formations',
  TIME_BLOCKS = 'time-blocks',
  PLAYERS = 'players',
  GAME_POSITION = 'game-position',
}

export interface IExplorerGroup {
  type: IExplorerType;
  data: IExplorerGroupData[];
  fields: ExplorerGroupField[];
}

export interface IExplorerGroupData {
  _id: number | string;
  label: number | string;
  sublabel?: number | string | Date;
}

export interface ExplorerGroupField {
  label: string;
  color: string;
}

export interface IExplorerTab {
  linkedTable: string;
  groups: IExplorerGroup[];
}

export interface IDataExplorer {
  linkedBlock: string;
  tabs: IExplorerTab[];
}
