import React from 'react';
import Logo from '../Logo/Logo';
import styles from './TeamDetails.module.scss';

type TeamDetailsProps = {
  _id: string;
  imageURL: string;
  name: string;
  shouldTruncate?: boolean;
  width?: number;
};

export const TeamDetails = ({
  // _id,
  name,
  imageURL,
  shouldTruncate = false,
  width,
}: TeamDetailsProps) => {
  const _style: React.CSSProperties = {};
  if (width) {
    _style.width = width;
    _style.maxWidth = 'none';
  }
  return (
    <div className={styles.container}>
      <Logo src={imageURL} size="small" />
      <span
        className={`${styles.teamName} ${
          shouldTruncate ? styles.teamNameTruncated : ''
        }`}
        style={_style}>
        {name}
      </span>
    </div>
  );
};
