import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {IPagination} from '@/types/api.types';
import {FIUploadedFile, IFileUploadFilters} from '@my-game-plan/types';
import {useEffect, useState} from 'react';
import {useAuth} from './auth.context';
import {getAllFiles} from '@/controllers/file-upload.controller';
import {LOADING_STATE} from '@/types/screen.types';
import {REQUEST_ERRORS} from '@/config/errors.config';
import {useLocation} from 'react-router-dom';

export interface IFileUploadsAPI {
  files: FIUploadedFile[];
  pagination: IPagination;
  loadingState: LOADING_STATE;
  setLoadingState: (state: LOADING_STATE) => void;
  getAllFiles: () => void;
  filters: IFileUploadFilters;
  setFilters: (filters: IFileUploadFilters) => void;
}

const context = createCustomContext<IFileUploadsAPI>();
export const useFileUploads = createContextHook(context);

function FileUploadsProvider(props: {children: React.ReactNode}): JSX.Element {
  /* Hooks n State */
  const _authContext = useAuth();
  const _location = useLocation();
  const [_files, _setFiles] = useState<FIUploadedFile[]>([]);
  const [_filters, _setFilters] = useState<IFileUploadFilters>({});

  const [_pagination, _setPagination] = useState<IPagination>({
    total_pages: 0,
    current_page: 0,
    total_results: 0,
  });
  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );

  /*
   * Side effects
   */
  useEffect(() => {
    if (!_authContext.user) {
      return;
    }

    _getAllFiles();
  }, [_authContext.user, _location.search, _filters]);

  async function _getAllFiles() {
    const _searchParams = new URLSearchParams(_location.search);
    const _page = Number(_searchParams.get('page') || '1');

    if (!_authContext.user) {
      return;
    }
    try {
      if (_loadingState !== LOADING_STATE.INITING) {
        _setLoadingState(LOADING_STATE.LOADING);
      }
      const _fetchedFiles = await getAllFiles(_authContext.user.team, {
        ..._filters,
        page: _page,
      });

      if (!_fetchedFiles.data || !_fetchedFiles.pagination) {
        throw new Error(REQUEST_ERRORS.NO_DATA);
      }

      _setFiles(_fetchedFiles.data);
      _setPagination(_fetchedFiles.pagination);
      _setLoadingState(LOADING_STATE.SUCCESS);
    } catch (error) {
      _setLoadingState(LOADING_STATE.ERROR);
    }
  }

  return createProvider(context, props, {
    files: _files,
    pagination: _pagination,
    loadingState: _loadingState,
    setLoadingState: _setLoadingState,
    getAllFiles: _getAllFiles,
    filters: _filters,
    setFilters: _setFilters,
  });
}

export default FileUploadsProvider;
