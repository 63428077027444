import React from 'react';
import {ICommonSentenceProps} from '@/types/sentence-form.types';

import Segment from '../segment';

import {useTranslation} from 'react-i18next';

import {OPONNENT_AUTOMATION_SHARE_OPTIONS} from '@/config/event-automation-builder.config';
import Sentence from '../sentence.view';

function SentenceOpponentAutomationShareLine(
  props: ICommonSentenceProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _commonProps: ICommonSentenceProps = {
    ...props,
  };

  /*
   * Handlers
   */

  /*
   * Render
   */
  return (
    <>
      <Sentence.Segment>
        <Segment.Text
          translationKey="sentenceForm.share.with"
          {..._commonProps}
        />
      </Sentence.Segment>

      <Segment.Observing
        property="shared_with_players"
        placeholder={t('sentenceForm.share.placeholder')}
        value={props.data.shared_with_players}
        options={OPONNENT_AUTOMATION_SHARE_OPTIONS}
        {..._commonProps}
        canSelectOpponent={false}
      />
    </>
  );
}

export default SentenceOpponentAutomationShareLine;
