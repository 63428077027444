import React, {useEffect, useState} from 'react';
import Select, {SelectChangeEvent} from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';

import MenuItem from '@mui/material/MenuItem';
import {useTranslation} from 'react-i18next';
import {ITypedOption} from '@/types/option.types';
import {ICommonSentenceInputProps} from '@/types/sentence-form.types';
import Segment from '..';

interface IDefaultDropdownProps<T>
  extends Omit<ICommonSentenceInputProps<T>, 'property' | 'onChange'> {
  options: ITypedOption<T>[];
  onChange: (value: T) => void;
  displayReadonlyValueAsRegularText?: boolean;
  isBold?: boolean;
}

function DefaultDropdown<
  T extends string | number | readonly string[] | undefined,
>(props: IDefaultDropdownProps<T>): JSX.Element | null {
  /* Hooks n State */
  const {t} = useTranslation();
  const [_value, _setValue] = useState<ITypedOption<T> | null>(null);

  useEffect(() => {
    if (props.value) {
      const _matchingValueOption = props.options.find(
        (option) => option.value === props.value,
      );
      if (_matchingValueOption) {
        _setValue(_matchingValueOption);
      }
    }
  }, [props.value, props.options]);

  /* Handlers */
  function _onChange(event: SelectChangeEvent<T>) {
    const _typedValue = event.target.value as T;

    if (_typedValue) {
      props.onChange(_typedValue);
    }
  }

  /* Render */
  const _translatedPlaceholder = t(props.placeholder || '');

  let _fontSize = 24;
  if (props.size === 'small') {
    _fontSize = 16;
  }

  if (props.readonly) {
    return (
      <Segment.Text
        data={props.data}
        size={props.size}
        text={_value?.label || _translatedPlaceholder}
        type={props.displayReadonlyValueAsRegularText ? 'default' : 'value'}
      />
    );
  }

  return (
    <FormControl variant="standard">
      <Select<T>
        placeholder={_translatedPlaceholder}
        value={props.options.length ? props.value || '' : ''}
        onChange={_onChange}
        sx={{
          fontSize: _fontSize,
          fontWeight: props.isBold ? '700' : '500',

          '&:before': {
            borderBottomColor: 'rgba(255,255,255, .1)',
          },
        }}
        inputProps={{
          sx: {paddingLeft: 0, paddingRight: '0 !important', py: 0.3},
          MenuProps: {
            MenuListProps: {
              sx: {bgcolor: 'background.default'},
            },
          },
        }}
        IconComponent={() => null}>
        {props.options.map((option) => {
          return (
            <MenuItem
              key={`${option.value}`}
              value={option.value as T}
              // sx={{fontSize: props.size === 'small' ? 14 : undefined}}
            >
              {option.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}

export default DefaultDropdown;
