import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

import React from 'react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import styles from './TrackerDetailVideoIcons.module.scss';
import {toPercentage} from '@/helpers/general/general.helper';

interface TrackerDetailVideoIconsProps {
  relative: boolean;
  totalEvents: number;
  totalSuccessfulEvents?: number;
  rate?: number;
  minutesPlayed?: number | string;
}

export const TrackerDetailVideoIcons = ({
  relative,
  totalEvents,
  totalSuccessfulEvents,
  minutesPlayed,
  rate,
}: TrackerDetailVideoIconsProps) => {
  let _shouldDisplayMinutesPlayed = true;
  let _minutesPlayed = null;

  if (minutesPlayed) {
    _minutesPlayed = minutesPlayed;
  } else {
    _shouldDisplayMinutesPlayed = false;
  }

  let _successFullText = '';
  let _percentText = null;
  if (relative && typeof totalSuccessfulEvents !== 'undefined') {
    if (typeof rate !== 'undefined') {
      _percentText = (
        <span style={{marginLeft: 4, opacity: 0.6}}>{`(${toPercentage(
          rate,
        )})`}</span>
      );
    }
    _successFullText = `${totalSuccessfulEvents}`;
  }

  return (
    <div className={styles.icons}>
      <div>
        <PlayArrowIcon color="secondary" />
        <span>{totalEvents}</span>
      </div>
      {relative && typeof totalSuccessfulEvents !== 'undefined' && (
        <div>
          <CheckCircleOutlineIcon color="success" />
          <span>
            {_successFullText}
            {_percentText}
          </span>
        </div>
      )}
      {_shouldDisplayMinutesPlayed && (
        <div>
          <AccessTimeIcon color="secondary" />
          <span>{`${_minutesPlayed}’`}</span>
        </div>
      )}
    </div>
  );
};
