import React from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import {
  ICommonSentenceProps,
  TAutomationErrors,
  TError,
} from '@/types/sentence-form.types';
import SentenceLine from '../line';
import ConditionBaseLine from '../line/condition-base-line.view';

import styles from './conditions.module.scss';
import {useTranslation} from 'react-i18next';
import {
  ACTION,
  FIEventCondition,
  TEventObservingTeam,
} from '@my-game-plan/types';
import Sentence from '../sentence.view';

interface IConditionProps
  extends Omit<ICommonSentenceProps<FIEventCondition>, 'onChange' | 'errors'> {
  condition: FIEventCondition;
  conditionIndex: number;
  onChange?: (index: number, data: Partial<FIEventCondition>) => void;
  onDelete: (index: number) => void;
  errors?: TError<FIEventCondition>;
  action?: ACTION;
  observingTeam?: TEventObservingTeam;
}

function Condition(props: IConditionProps): JSX.Element {
  /* Hooks n State */
  const {condition, conditionIndex, errors, onChange, ..._commonProps} = props;
  const _typedCommonProps =
    _commonProps as ICommonSentenceProps<FIEventCondition>;

  const {t} = useTranslation();

  /* Handlers */
  function _onChange(data: Partial<FIEventCondition>) {
    if (onChange) {
      onChange(conditionIndex, data);
    }
  }

  function _onDeleteClick() {
    props.onDelete(conditionIndex);
  }

  /* Render */
  const _shouldHideDetails =
    condition.type === 'when' ||
    (props.readonly && !props.condition.details?.filters.length);

  return (
    <div className={styles.condition}>
      <Sentence.Sentence size={props.size}>
        <Sentence.Line>
          <ConditionBaseLine
            {..._typedCommonProps}
            errors={errors}
            condition={condition}
            onChange={_onChange}
            observingTeam={props.observingTeam}
          />
        </Sentence.Line>
        {!_shouldHideDetails && (
          <Sentence.Line>
            <SentenceLine.Details<FIEventCondition>
              {..._typedCommonProps}
              errors={errors as TAutomationErrors}
              withTarget={false}
              action={condition.action}
              property="details"
              value={condition.details}
              onChange={_onChange}
              count={1}
              observingPlayers={condition.observing_players}
            />
          </Sentence.Line>
        )}
      </Sentence.Sentence>
      {!props.readonly && (
        <div className={styles.conditionDelete}>
          <IconButton
            color="secondary"
            onClick={_onDeleteClick}
            title={t('sentenceForm.conditions.delete')}
            size="small">
            <DeleteIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default Condition;
