import React, {PropsWithChildren, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';

import {IFilters} from '@/types/filter.types';
import {
  IOverviewDashboard,
  IOverviewDashboardIntial,
  IWidgetPlaceholder,
} from '@/types/dashboard/overview-dashboards.types';
import {useTeams} from '@/context/team.context';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {useAuth} from '@/context/auth.context';
import {
  addOverviewDashboard,
  deleteOverviewDashboard,
  editOverviewDashboard,
  getOverviewDashboard,
  getOverviewDashboards,
  getPlaceholderWidgets,
} from '@/controllers/dashboard/overview-dashboards.controller';
import {useSnackbar} from 'notistack';
import {useMatches} from '../matches.context';
import {useAnalytics} from '../analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';
import {NAV_LEVEL_1_ROUTE} from '@/config/navigation.config';

export interface IOverviewDashboardsAPI {
  isLoading: boolean;
  hasError: boolean;
  getAll: () => void;
  all: IOverviewDashboardIntial[] | undefined;
  getCurrent: (id: string, filters?: IFilters, isInitialLoad?: boolean) => void;
  current: IOverviewDashboard | undefined;
  isEditing: boolean;
  setIsEditing: (isEditing: boolean) => void;
  placeholderWidgets: IWidgetPlaceholder[];
  canAddPlaceholderWidget: boolean;
  updateSelectedPlaceholderWidget: (widget: IWidgetPlaceholder) => void;
  setSelectedPlaceholderWidgets: (widgets: IWidgetPlaceholder[]) => void;
  selectedPlaceholderWidgets: IWidgetPlaceholder[];
  getSelectedPlaceholderWidgets: () => (IWidgetPlaceholder | undefined)[];
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  submitOverviewDashboard: (title: string) => Promise<void>;
  deleteOverviewDashboard: () => Promise<void>;
  isCustomDashboard: (dashboard: string) => boolean;
  selectedDashboard: string; // TODO should be somewhere else
  setSelectedDashboard: (newDashboard: string) => void; // TODO should be somewhere else
}

const context = createCustomContext<IOverviewDashboardsAPI>();
export const useOverviewDashboards = createContextHook(context);

export const OverviewDashboardsProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();
  const _navigate = useNavigate();
  const _auth = useAuth();
  const _teamsContext = useTeams();
  const _analyticsContext = useAnalytics();

  const [_isLoading, _setIsLoading] = React.useState(true);
  const [_hasError, _setHasError] = React.useState(true);
  const [_all, _setAll] = React.useState<
    IOverviewDashboardIntial[] | undefined
  >([]);
  const [_current, _setCurrent] = React.useState<
    IOverviewDashboard | undefined
  >();
  const [_isEditing, _setIsEditing] = React.useState(false);
  const [_placeholderWidgets, _setPlaceholderWidgets] = React.useState<
    IWidgetPlaceholder[]
  >([]);
  const [_canAddPlaceholderWidget, _setCanAddPlaceholderWidget] =
    React.useState(true);
  const [_selectedPlaceholderWidgets, _setSelectedPlaceholderWidgets] =
    React.useState<IWidgetPlaceholder[]>([]);
  const [_isModalOpen, _setIsModalOpen] = React.useState(false);

  const [_selectedDashboard, _setSelectedDashboard] = React.useState('');

  const _customDashboardIds = useMemo(
    () => (_all ? _all.map((dashboard) => dashboard._id) : []),
    [_all],
  );
  const _matches = useMatches();

  const WIDGETS_LIMIT = 6;

  useEffect(() => {
    _getAll();
  }, [_auth.user]);

  useEffect(() => {
    if (_matches.error) {
      _setHasError(true);
    }
  }, [_matches.error]);

  useEffect(() => {
    const fetchPlaceholderWidgets = async () => {
      if (_teamsContext.ownTeam) {
        await getPlaceholderWidgets(_teamsContext.ownTeam.data_provider)
          .then((placeholderWidgets) => {
            if (placeholderWidgets) {
              _setPlaceholderWidgets(placeholderWidgets);
            }
          })
          .catch((error) => console.log(error));
      }
    };
    fetchPlaceholderWidgets();
  }, [_teamsContext.ownTeam]);

  useEffect(() => {
    if (_selectedPlaceholderWidgets.length >= WIDGETS_LIMIT)
      _setCanAddPlaceholderWidget(false);
    else _setCanAddPlaceholderWidget(true);
  }, [_selectedPlaceholderWidgets]);

  /* Handlers */
  async function _getAll() {
    _setIsLoading(true);
    _setHasError(false);

    if (_auth.user) {
      await getOverviewDashboards(_auth.user.team)
        .then((overviewDashboards) => _setAll(overviewDashboards))
        .catch(() => _setHasError(true));
    }

    _setIsLoading(false);
  }

  function _isCustomDashboard(dashboard: string) {
    return _customDashboardIds.includes(dashboard);
  }

  async function _getCurrent(
    id: string,
    filters?: IFilters,
    isInitialLoad?: boolean,
  ) {
    _setIsLoading(true);
    _setHasError(false);

    if (_auth.user && _teamsContext.current) {
      const isOpponentRoute = _teamsContext.current?._id !== _auth.user.team;
      const opponent_id =
        isOpponentRoute && _teamsContext.current
          ? _teamsContext.current._id
          : undefined;

      await getOverviewDashboard(
        _teamsContext.current._id,
        id,
        opponent_id,
        filters,
      )
        .then((overviewDashboard) => {
          _setCurrent(overviewDashboard);
          if (overviewDashboard && isInitialLoad) {
            _analyticsContext.trackEvent(
              ANALYTICS_EVENT.VIEWED_OVERVIEW_DASHBOARD,
              {
                id: overviewDashboard._id,
                title: overviewDashboard.title,
                widgets: overviewDashboard.widgets.map((widget) => widget.key),
                team_id: _teamsContext.current?._id,
                filters: filters,
              },
            );
          }
        })
        .catch(() => _setHasError(true));
    }

    _setIsLoading(false);
  }

  function _updateSelectedPlaceholderWidget(widget: IWidgetPlaceholder) {
    let widgetsCopy = [..._selectedPlaceholderWidgets];

    const keys = widgetsCopy.map((widget) => widget.key);

    if (keys.includes(widget.key)) {
      widgetsCopy = widgetsCopy.filter(
        (widgetCopy) => widgetCopy.key !== widget.key,
      );
    } else {
      widgetsCopy.push(widget);
    }

    _setSelectedPlaceholderWidgets(widgetsCopy);
  }

  function _getSelectedPlaceholderWidgets() {
    const widgets: (IWidgetPlaceholder | undefined)[] = [
      ..._selectedPlaceholderWidgets,
    ];

    if (widgets.length < WIDGETS_LIMIT) {
      for (let i = widgets.length; i < WIDGETS_LIMIT; i++) {
        widgets.push(undefined);
      }
    }

    return widgets;
  }

  function _setIsModalOpenHandler(isOpen: boolean) {
    _setSelectedPlaceholderWidgets([]);
    _setIsModalOpen(isOpen);
  }

  async function _submitOverviewDashboard(title: string) {
    if (_auth.user) {
      const team_id = _auth.user.team;
      const widgets = _selectedPlaceholderWidgets.map((widget) => widget.key);

      if (_isEditing && _current)
        await _editOverviewDashboard(team_id, _current._id, title, widgets);
      else await _createOverviewDashboard(team_id, title, widgets);
    }
  }

  async function _createOverviewDashboard(
    team_id: string,
    title: string,
    widgets: string[],
  ) {
    await addOverviewDashboard(team_id, title, widgets)
      .then(async (overviewDashboard) => {
        if (overviewDashboard) {
          _analyticsContext.trackEvent(
            ANALYTICS_EVENT.CREATED_OVERVIEW_DASHBOARD,
            {
              id: overviewDashboard._id,
              title: overviewDashboard.title,
              widgets: overviewDashboard.widgets.map((widget) => widget.key),
              team_id: team_id,
            },
          );
        }
        enqueueSnackbar(t('overview-dashboards.add.flags.success.title'), {
          variant: 'success',
        });

        // _setCurrent(undefined);
        await _getAll();

        setTimeout(() => {
          _navigate(
            `${NAV_LEVEL_1_ROUTE.DASHBOARDS}/${_teamsContext.current?._id}/${overviewDashboard?._id}`,
          );
        }, 200);
      })
      .catch(() => {
        enqueueSnackbar(t('overview-dashboards.add.flags.error.title'), {
          variant: 'error',
        });
      });
  }

  async function _editOverviewDashboard(
    team_id: string,
    id: string,
    title: string,
    widgets: string[],
  ) {
    await editOverviewDashboard(team_id, id, title, widgets)
      .then(() => {
        enqueueSnackbar(t('overview-dashboards.add.flags.success.title'), {
          variant: 'success',
        });
        _setIsEditing(false);
      })
      .then(() => _getAll())
      .then(() => _getCurrent(id))
      .catch(() => {
        enqueueSnackbar(t('overview-dashboards.add.flags.error.title'), {
          variant: 'error',
        });
      });
  }

  async function _deleteOverviewDashboard() {
    if (_auth.user && _current) {
      _setIsLoading(true);
      _setCurrent(undefined);
      await deleteOverviewDashboard(_auth.user.team, _current?._id)
        .then(() => {
          enqueueSnackbar(t('overview-dashboards.add.flags.success.title'), {
            variant: 'success',
          });
          _getAll();
          _navigate(NAV_LEVEL_1_ROUTE.DASHBOARDS);
        })
        .catch(() => {
          enqueueSnackbar(t('overview-dashboards.add.flags.error.title'), {
            variant: 'error',
          });
        })
        .finally(() => {
          _setIsLoading(false);
        });
    }
  }

  return createProvider(context, props, {
    isLoading: _isLoading,
    hasError: _hasError,
    getAll: _getAll,
    all: _all,
    getCurrent: _getCurrent,
    current: _current,
    isEditing: _isEditing,
    setIsEditing: _setIsEditing,
    placeholderWidgets: _placeholderWidgets,
    canAddPlaceholderWidget: _canAddPlaceholderWidget,
    updateSelectedPlaceholderWidget: _updateSelectedPlaceholderWidget,
    setSelectedPlaceholderWidgets: _setSelectedPlaceholderWidgets,
    selectedPlaceholderWidgets: _selectedPlaceholderWidgets,
    getSelectedPlaceholderWidgets: _getSelectedPlaceholderWidgets,
    isModalOpen: _isModalOpen,
    setIsModalOpen: _setIsModalOpenHandler,
    submitOverviewDashboard: _submitOverviewDashboard,
    deleteOverviewDashboard: _deleteOverviewDashboard,
    isCustomDashboard: _isCustomDashboard,
    selectedDashboard: _selectedDashboard,
    setSelectedDashboard: _setSelectedDashboard,
  });
};
