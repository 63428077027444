import React from 'react';
import {useTranslation} from 'react-i18next';

import {EmailButton} from '../buttons/EmailButton';
import styles from './ErrorState.module.scss';
import CustomEmptyState from './CustomEmptyState';

export const NotFoundState = () => {
  const {t} = useTranslation();

  return (
    <div className={styles.container} style={{height: '100vh'}}>
      <CustomEmptyState
        imageType="error"
        header={t('error-states.not-found.header')}
        description={t('error-states.not-found.description')}
        primaryAction={<EmailButton />}
      />
    </div>
  );
};
