import {NAV_LEVEL_1_ROUTE} from '@/config/navigation.config';

export function getRoutePath(
  path: string,
  shouldRemoveSlash?: boolean,
): string {
  if (shouldRemoveSlash) {
    return path.replace('/', '');
  }
  return path;
}

export function getPlayerDeepDivePath(
  playerId: string,
  isScouting?: boolean,
): string {
  let _url = `${NAV_LEVEL_1_ROUTE.PLAYERS}/${playerId}`;
  if (isScouting) {
    _url = `${NAV_LEVEL_1_ROUTE.SCOUTING}${_url}`;
  }
  return _url;
}
