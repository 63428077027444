import {PlayerPosition} from '@my-game-plan/types';

export interface IPlayerPositionMap {
  line:
    | 'goalkeepers'
    | 'defenders'
    | 'fullbacks'
    | 'midfielders'
    | 'wingers'
    | 'attackers';
  positions: PlayerPosition[];
}

export const POSITIONS_MAP: IPlayerPositionMap[] = [
  {
    line: 'goalkeepers',
    positions: ['GK'],
  },
  {
    line: 'defenders',
    positions: ['LCB', 'CB', 'RCB'],
  },
  {
    line: 'fullbacks',
    positions: ['LB', 'RB'],
  },
  {
    line: 'midfielders',
    positions: [
      'LDM',
      'CDM',
      'RDM',
      'LCM',
      'CM',
      'RCM',
      'LM',
      'RM',
      'LAM',
      'CAM',
      'RAM',
    ],
  },
  {
    line: 'wingers',
    positions: ['LM', 'LW', 'RM', 'RW'],
  },
  {
    line: 'attackers',
    positions: ['LF', 'RF', 'ST'],
  },
];
