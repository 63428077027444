import React, {useEffect, useState} from 'react';

import Button from '@mui/material/Button';

import CustomEmptyState from '@/components/error-states/CustomEmptyState';
import {useTranslation} from 'react-i18next';

import {useTeams} from '@/context/team.context';

import {
  TEventAutomationType,
  FIFormattedAutomation,
  FIMatch,
} from '@my-game-plan/types';

import {IAutomationsGroup} from '@/types/event-automation-groups.types';
import {
  groupAutomationsByPlayerAndPosition,
  groupAutomationsByTag,
} from '@/helpers/automation/automation-groups.helper';
import AutomationsGroup from './automations-accordion-group.view';

import Stack from '@mui/material/Stack';

import AutomationCreateButton from '@/components/automations/automation-create-button/automation-create-button.view';

import {IAutomationFilters} from '@/types/event-automations-misc.types';
import {useMatches} from '@/context/matches.context';
import {getMatchesByTeam} from '@/controllers/matches.controller';

interface IOverviewTableScreenProps {
  title: string;
  data: FIFormattedAutomation[];
  isLoading?: boolean;
  isInitialised?: boolean;
  error?: boolean;
  automationType: TEventAutomationType;
  selectedFilters: IAutomationFilters;
  shouldIncludeTeamPerformance: boolean;
}

const NAMESPACE_MAP: Record<TEventAutomationType, string> = {
  'opponent-automation': 'eventAutomations.empty.opponentAutomations',
  tracker: 'eventAutomations.empty.trackers',
  'shadow-tracker': 'eventAutomations.empty.trackers',
};

function OverviewTableScreen(
  props: IOverviewTableScreenProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const _matchesContext = useMatches();

  const [_groups, _setGroups] = useState<IAutomationsGroup[]>([]);
  const [_matches, _setMatches] = useState<FIMatch[]>([]);

  useEffect(() => {
    // Trackers
    if (
      props.automationType === 'tracker' &&
      _teamsContext.ownTeam &&
      _teamsContext.ownPlayers.length &&
      props.data.length
    ) {
      const _formattedData = groupAutomationsByPlayerAndPosition(
        props.data,
        props.selectedFilters,
        _teamsContext.ownTeam,
        _teamsContext.ownPlayers,
        props.shouldIncludeTeamPerformance,
      );
      _setGroups(_formattedData);
    } else if (
      props.automationType === 'opponent-automation' &&
      _teamsContext.ownTeam
    ) {
      // FORMAT AUTOMATIONS DATA
      const _formattedData = groupAutomationsByTag(
        props.data,
        props.selectedFilters,
        _teamsContext.preferences?.tags,
      );
      _setGroups(_formattedData);
    }
  }, [
    _teamsContext.preferences,
    _teamsContext.ownTeam,
    _teamsContext.all,
    _teamsContext.nextOpponentId,
    _teamsContext.ownPlayers,
    props.data,
    props.automationType,
    props.selectedFilters,
    props.shouldIncludeTeamPerformance,
  ]);

  useEffect(() => {
    async function _fetchMatches() {
      if (props.automationType === 'tracker') {
        _setMatches(_matchesContext.ownMatches);
      } else if (_teamsContext.nextOpponentId) {
        const _fetchedMatches = await getMatchesByTeam(
          _teamsContext.nextOpponentId,
        );
        _setMatches(_fetchedMatches);
      }
    }

    _fetchMatches();
  }, [
    _matchesContext.ownMatches,
    _teamsContext.nextOpponentId,
    props.automationType,
  ]);

  /*
   * Render
   */
  const _createButtonTitle = t(
    `eventAutomations.${
      props.automationType === 'opponent-automation'
        ? 'automations'
        : 'trackers'
    }New`,
  );
  let _Content = null;

  if (_groups.length) {
    _Content = (
      <Stack gap={6} mb={10}>
        {_groups.map((group, index) => {
          return (
            <AutomationsGroup
              key={group.key}
              group={group}
              isFirstGroup={index === 0}
              automationType={props.automationType}
              matches={_matches}
            />
          );
        })}
      </Stack>
    );
  } else if (props.error) {
    _Content = (
      <CustomEmptyState
        imageType="error"
        header={t('error-states.not-found.header')}
        description={t('error-states.not-found.description')}
      />
    );
  } else if (!props.isLoading && !props.data.length) {
    _Content = (
      <CustomEmptyState
        header={t(`${NAMESPACE_MAP[props.automationType]}.header`)}
        description={t(`${NAMESPACE_MAP[props.automationType]}.description`)}
        primaryAction={
          <AutomationCreateButton
            type={props.automationType}
            Button={
              <Button color="primary" variant="contained">
                {_createButtonTitle}
              </Button>
            }
          />
        }
      />
    );
  }

  return _Content;
}

export default OverviewTableScreen;
