import React, {useEffect, useState} from 'react';

import IconButton from '@mui/material/IconButton';
import NumbersIcon from '@mui/icons-material/Numbers';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SettingsIcon from '@mui/icons-material/Settings';

import Menu from '@mui/material/Menu';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import {
  IAutomationTypeOption,
  ICommonSentenceProps,
} from '@/types/sentence-form.types';

import {
  ACTION,
  FIEventAutomationPostData,
  isTrackingAction,
} from '@my-game-plan/types';
import {useTranslation} from 'react-i18next';
import {useAutomationForm} from '@/context/event-automations/automations-form.context';
import {useCompetitions} from '@/context/competitions.context';

interface IAutomationTypeDropdownProps extends ICommonSentenceProps {
  property?: 'action' | 'rule_condition_action';
  action?: ACTION;
}

function AutomationTypeDropdown(
  props: IAutomationTypeDropdownProps,
): JSX.Element | null {
  /*
   * Hooks n State
   */
  const [_anchorEl, _setAnchorEl] = useState<null | HTMLElement>(null);
  const [_options, _setOptions] = useState<IAutomationTypeOption[]>([]);
  const {t} = useTranslation();
  const _formContext = useAutomationForm();
  const _competitionsContext = useCompetitions();
  const _property = props.property || 'action';

  useEffect(() => {
    let _prefix = 'default';
    if (props.data.calculation === 'rule') {
      _prefix = 'rule';

      if (props.property === 'rule_condition_action') {
        _prefix = 'rule_condition';
      }
    }
    const _tempOptions: IAutomationTypeOption[] = [
      {
        key: `sentenceForm.automationType.${_prefix}.event.title`,
        textKey: `sentenceForm.automationType.${_prefix}.event.text`,
        action: null,
        icon: NumbersIcon,
        isActive:
          !props.action ||
          (!isTrackingAction(props.action) && props.action != 'sequence'),
        // isDisabled: false,
      },
      ...(_competitionsContext.isTrackingDataSupported
        ? [
            {
              key: `sentenceForm.automationType.${_prefix}.zone_visit.title`,
              textKey: `sentenceForm.automationType.${_prefix}.zone_visit.text`,
              action: 'zone_visit' as ACTION,
              icon: MyLocationIcon,
              isActive: props.action === 'zone_visit',
              // isDisabled: props.data.calculation === 'ratio',
            },
          ]
        : []),
      ...(props.data.calculation === 'occurrences'
        ? [
            {
              key: `sentenceForm.automationType.${_prefix}.sequence.title`,
              textKey: `sentenceForm.automationType.${_prefix}.sequence.text`,
              action: 'sequence' as ACTION,
              icon: NumbersIcon,
              isActive: props.action === 'sequence',
              // isDisabled: false,
            },
          ]
        : []),
    ];

    _setOptions(_tempOptions);
  }, [
    props.action,
    props.data.calculation,
    props.property,
    _competitionsContext.isTrackingDataSupported,
  ]);

  /*
   * Handlers
   */
  function _onMenuOpen(event: React.MouseEvent<HTMLElement>) {
    _setAnchorEl(event.currentTarget);
  }

  function _onMenuClose() {
    _setAnchorEl(null);
  }

  function _onOptionClick(action: ACTION | null) {
    let _postData: Partial<FIEventAutomationPostData> = {
      action: action || undefined,
    };

    if (_property === 'action') {
      // Action
      if (
        // When changing from a ratio tracker, calculation needs to be reset
        _formContext.data.calculation === 'ratio' &&
        action &&
        isTrackingAction(action)
      ) {
        // _formContext
        _postData.calculation = 'occurrences';
      }

      // Sequences
      if (action === 'sequence') {
        _postData.sequence_actions = _postData.sequence_actions?.length
          ? _postData.sequence_actions
          : [{}];
      }
    } else if (_formContext.data.rule_condition) {
      // Rule condition
      _postData = {
        rule_condition: {
          ..._formContext.data.rule_condition,
          action: action || undefined,
        },
      };

      if (props.data.action && isTrackingAction(props.data.action)) {
        _postData.action = undefined;
        _postData.pitch_zone = undefined;
      }
    }

    if (_formContext.data.action === 'sequence') {
      // When changing from a sequence action, must clear the sequence actions
      _postData.sequence_actions = undefined;
    }

    _formContext.setData(_postData);
  }

  /*
   * Render
   */
  if (props.readonly || _options.length < 2) return null;

  const _isOpened = Boolean(_anchorEl);
  return (
    <>
      <IconButton onClick={_onMenuOpen} color="secondary" size="small">
        <SettingsIcon fontSize="small" />
      </IconButton>
      <Menu
        open={_isOpened}
        anchorEl={_anchorEl}
        onClose={_onMenuClose}
        // sx={{paper: {maxWidth: 80}}}
        PaperProps={{
          sx: {bgcolor: 'background.default', maxWidth: 320},
        }}>
        {_options.map((option) => {
          return (
            <ListItemButton
              selected={option.isActive}
              key={option.key}
              onClick={() => _onOptionClick(option.action)}>
              <ListItemIcon>
                <option.icon />
              </ListItemIcon>
              <ListItemText
                primary={t(option.key)}
                secondary={t(option.textKey)}
              />
            </ListItemButton>
          );
        })}
      </Menu>
    </>
  );
}

export default AutomationTypeDropdown;
