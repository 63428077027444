import {translateAction} from '@/helpers/translation.helper';
import {IEventAutomationExampleInfo} from '@/types/event-automations-misc.types';
import {DEFENDING_HALF_ZONE_ID} from '@my-game-plan/types';
import i18n from 'i18next';
import {capitalize} from 'lodash';

export const OPPONENT_AUTOMATION_EXAMPLES: IEventAutomationExampleInfo[] = [
  {
    section: i18n.t('dashboard.categories.attacking.subcategories.build-up'),
    examples: [
      {
        _id: '1',
        action: 'goal_kick',
        shared_with_players: {positions: ['ST', 'LF', 'RF', 'CAM']},
        match_limit: 5,
        notification_offset: 3,
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'pass.length_group',
              values: ['short'],
            },
          ],
        },
      },
      {
        _id: '2',
        action: 'pass',
        shared_with_players: {positions: ['ST', 'LF', 'RF']},
        match_limit: 5,
        notification_offset: 3,
        details: {
          operator: 'and',
          filters: [
            {
              operator: 'or',
              property: 'coordinates',
              values: [DEFENDING_HALF_ZONE_ID],
            },
            {
              operator: 'or',
              property: 'action_result',
              values: ['shot_ending', 'goal_ending'],
            },
          ],
        },
      },
      {
        _id: '3',
        action: 'goal_kick',
        shared_with_players: {positions: ['LCB', 'RCB', 'CB']},
        match_limit: 5,
        notification_offset: 3,
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'pass.length_group',
              values: ['long'],
            },
          ],
        },
      },
      {
        _id: '4',
        action: 'pass',
        shared_with_players: {positions: ['LCB', 'RCB', 'CB']},
        match_limit: 5,
        notification_offset: 3,
        details: {
          operator: 'and',
          filters: [
            {
              operator: 'or',
              property: 'pass.length_group',
              values: ['long'],
            },
            {
              operator: 'or',
              property: 'result',
              values: ['successful'],
            },
          ],
        },
      },
    ],
  },
  {
    section: i18n.t(
      'dashboard.categories.attacking.subcategories.chance-creation',
    ),
    examples: [
      {
        _id: '5',
        action: 'shot',
        shared_with_players: {positions: ['GK', 'CB', 'LCB', 'RCB']},
        match_limit: 5,
        notification_offset: 3,
        details: {
          operator: 'and',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['goal', 'saved'],
            },
          ],
        },
      },
      {
        _id: '6',
        action: 'cross',
        shared_with_players: {positions: ['CB', 'LCB', 'RCB', 'LB', 'RB']},
        match_limit: 5,
        notification_offset: 3,
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['successful'],
            },
          ],
        },
      },
      {
        _id: '7',
        action: 'corner_kick',
        shared_with_players: {positions: ['GK', 'CB', 'LCB', 'RCB']},
        match_limit: 5,
        notification_offset: 3,
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'action_result',
              values: ['shot_ending', 'goal_ending'],
            },
          ],
        },
      },
      {
        _id: '8',
        action: 'wide_free_kick',
        shared_with_players: {positions: ['GK', 'CB', 'LCB', 'RCB']},
        match_limit: 5,
        notification_offset: 3,
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'action_result',
              values: ['shot_ending', 'goal_ending'],
            },
          ],
        },
      },
    ],
  },
  {
    section: i18n.t('dashboard.categories.defending.label'),
    examples: [
      {
        _id: '9',
        action: 'aerial_duel',
        shared_with_players: {positions: ['ST', 'LW', 'RW']},
        match_limit: 5,
        notification_offset: 3,
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['successful'],
            },
          ],
        },
      },
      {
        _id: '10',
        action: 'interception',
        shared_with_players: {positions: ['ST', 'LW', 'RW']},
        match_limit: 5,
        notification_offset: 3,
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['successful'],
            },
          ],
        },
      },
      {
        _id: '11',
        action: 'recovery',
        shared_with_players: {positions: ['ST', 'LW', 'RW']},
        match_limit: 5,
        notification_offset: 3,
        details: {
          operator: 'or',
          filters: [
            {
              operator: 'or',
              property: 'result',
              values: ['successful'],
            },
          ],
        },
      },
      {
        _id: '12',
        action: 'pressure_action',
        match_limit: 5,
        notification_offset: 3,
        shared_with_players: {positions: ['LCB', 'RCB', 'CB']},
      },
    ],
  },
  {
    section: i18n.t('dashboard.categories.attacking.subcategories.runs'),
    examples: [
      {
        _id: '13',
        match_limit: 5,
        notification_offset: 5,
        action: 'deep_run',
        shared_with_players: {positions: ['LB', 'RB', 'LCB', 'RCB', 'CB']},
      },
      {
        _id: '14',
        match_limit: 5,
        notification_offset: 5,
        action: 'diagonal_run',
        shared_with_players: {positions: ['LB', 'RB']},
      },
      {
        _id: '15',
        match_limit: 5,
        notification_offset: 5,
        action: 'lateral_run',
        shared_with_players: {
          positions: [
            'LDM',
            'CDM',
            'RDM',
            'LCM',
            'CM',
            'RCM',
            'LM',
            'RM',
            'LAM',
            'CAM',
            'RAM',
          ],
        },
      },
      {
        _id: '16',
        match_limit: 5,
        notification_offset: 5,
        action: 'run',
      },
    ],
  },
];

export const OPPONENT_AUTOMATION_TRACKING_EXAMPLES: IEventAutomationExampleInfo =
  {
    section: capitalize(translateAction('zone_visit')),
    examples: [
      {
        _id: '17',
        match_limit: 5,
        notification_offset: 5,
        action: 'zone_visit',
        pitch_zone: '64b900a1005a96b81e099c92',
        observing_players: {
          positions: ['LF', 'RF', 'ST'],
        },
      },
      {
        _id: '18',
        match_limit: 5,
        notification_offset: 5,
        action: 'zone_visit',
        pitch_zone: '64b900a1005a96b81e099c93',
        observing_players: {
          positions: ['CDM', 'CAM', 'ST'],
        },
      },
    ],
  };
