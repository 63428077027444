import {IExplorerGroup} from './dashboard-explorer.types';
import {IPlayerStats} from './dashboard-player-stats.types';
import {IDashboardScore, IDashboardScoreData} from './dashboard-score.types';

export interface IOverviewDashboardIntial {
  _id: string;
  title: string;
  widgets: string[];
}

export interface IOverviewDashboard {
  _id: string;
  title: string;
  widgets: IWidget[];
}

export interface IWidgetPlaceholder {
  key: string;
  type: IWidgetType;
  action: string;
  withScores?: boolean;
}

export enum IWidgetType {
  PLAYER_STATISTICS = 'player-statistics',
  SCORES = 'scores',
  FORMATIONS = 'formations',
}

interface IWidget {
  key: string;
  type: IWidgetType;
  action: string;
  data:
    | IPlayerStatisticsWidgetData
    | IDashboardScoreData
    | IFormationsWidgetData;
}

// Player statistics
export interface IPlayerStatisticsWidgetData {
  playerStatistics: IPlayerStats;
  scores?: IDashboardScoreData;
}

// Scores
// The scores widget data is the same as IDashboardScoreData

// Formations
export interface IFormationsWidgetData {
  consistencyScores: IDashboardScore[];
  formations: IExplorerGroup;
}
