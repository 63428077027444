import React from 'react';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {IExplorerGroup} from '@/types/dashboard/dashboard-explorer.types';
import {getTooltip, getXAxisTick} from './CustomBarChart.helper';

import styles from './CustomBarChart.module.scss';
import {translateValue} from '@/helpers/translation.helper';

interface Props {
  group: IExplorerGroup;
}

export const CustomBarChart = ({group}: Props) => {
  const getSublabel = (id: string | number) => {
    return group.data.find((item) => item._id === id)?.sublabel;
  };
  const getLabel = (id: string | number) => {
    return group.data.find((item) => item._id === id)?.label;
  };

  // console.log(group.data);

  return (
    <div>
      <ResponsiveContainer width="99%" height={250}>
        <BarChart data={group.data}>
          {/* Legend */}
          <Legend
            verticalAlign="top"
            align="left"
            iconSize={8}
            iconType="circle"
            height={56}
            formatter={(value) => (
              <span className={styles.legendLabel}>
                {translateValue(value)}
              </span>
            )}
            wrapperStyle={{fontSize: 12, color: '#fff'}}
          />

          {/* Scales */}
          <CartesianGrid
            stroke="var(--hover)"
            vertical={false}
            strokeDasharray={8}
          />
          <YAxis
            stroke="var(--captions)"
            axisLine={false}
            tickLine={false}
            tickMargin={16}
          />
          <XAxis
            dataKey="_id"
            stroke="var(--captions)"
            height={60}
            axisLine={false}
            interval={0}
            tickLine={false}
            tickMargin={8}
            tick={(tick) => {
              return getXAxisTick(
                group.type,
                tick,
                getLabel(tick.payload.value),
                getSublabel(tick.payload.value),
              );
            }}
          />

          {/* Tooltip */}
          <Tooltip
            cursor={{fill: 'var(--hover)', opacity: 0.5}}
            content={getTooltip}
          />

          {/* Bars */}
          {group.fields.map((field) => (
            <Bar
              key={field.label}
              dataKey={field.label}
              stackId="label"
              fill={field.color}
              maxBarSize={40}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
