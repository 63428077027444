import React, {memo} from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import LoginIcon from '@mui/icons-material/Login';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import {useTranslation} from 'react-i18next';
import styles from './loginForm.module.scss';
import {Privacy} from './Privacy';

import IMAGE from '../../assets/images/logo/mygameplan-logo.png';
import {useAuth} from '@/context/auth.context';
import {AUTH_STATE} from '@/types/user/user.types';

function LoginForm(): JSX.Element | null {
  const {t} = useTranslation();

  const [_error, _setError] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const _auth = useAuth();

  async function handleSubmit(
    //data: {name: string; password: string}
    event: React.FormEvent<HTMLFormElement>,
  ) {
    //const {name, password} = data;
    setLoading(!loading);
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    const name = data.get('name') as string;
    const password = data.get('password') as string;
    try {
      await _auth.loginUser(name, password);
    } catch (error: any) {
      _setError(error.message);
      setLoading(false);
    }
  }

  if (_auth.authState === AUTH_STATE.LOADING) return null;

  return (
    <Box position="fixed" top={0} height="100%" width="100%">
      <div className={styles.container}>
        <img alt="" src={IMAGE} className={styles.logo} />

        <div className={styles.form}>
          <Container component="main" maxWidth="xs">
            <Box>
              <Typography component="h1" variant="h5">
                {t('login.title')}
              </Typography>
              {_error && (
                <p className={styles.error}>
                  {t([`error-states.${_error}`, 'error-states.default'])}
                </p>
              )}
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{mt: 1}}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="name"
                  label={t('login.form.name')}
                  name="name"
                  autoComplete="name"
                  autoFocus
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t('login.form.password')}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                />
                <LoadingButton
                  sx={{mt: 2}}
                  loading={loading}
                  type="submit"
                  variant="contained"
                  size="large"
                  startIcon={<LoginIcon />}
                  fullWidth>
                  {t('login.form.button')}
                </LoadingButton>
              </Box>
            </Box>
          </Container>

          <div className={styles.privacy}>
            <Privacy />
          </div>
        </div>
      </div>
    </Box>
  );
}

export default memo(LoginForm);
