import React from 'react';
import {useParams} from 'react-router-dom';

import AutomationDetail from '@/components/automations/automation-detail/automation-detail.view';

// import {Tab, Tabs} from '@mui/material';

function TrackerDetailView() {
  /* Hooks n State */
  const _params = useParams();

  /* Render */
  return <AutomationDetail automationType="tracker" id={_params.id} />;
}

export default TrackerDetailView;
