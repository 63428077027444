import React, {useEffect, useState} from 'react';

import AddIcon from '@mui/icons-material/Add';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import Stack from '@mui/material/Stack';
import PlayerDetailBlock from './player-detail-block.view';
import {useTranslation} from 'react-i18next';
import ScoredTabs, {IScoredTab} from '../scored-tabs.view';
import {usePlayerDetail} from '@/context/player-detail.context';
import {
  FIDisplayPlayer,
  FIEventAutomationPostData,
  FIFormattedAutomation,
} from '@my-game-plan/types';
import {capitalize} from 'lodash';
import OverviewSimpleCard from '@/components/overview-screen/single-sends/single-sends-card/overview-simple-card.view';

import CustomEmptyState from '@/components/error-states/CustomEmptyState';
import AutomationCreateButton from '@/components/automations/automation-create-button/automation-create-button.view';

import PlayerMirrorInfo from './player-mirror-info.view';
import PlayerMirrorBuilder from './player-mirror-builder.view';
import {useAnalytics} from '@/context/analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';
import {ILinkState} from '@/types/navigation.types';
import {NAV_LEVEL_1_ROUTE} from '@/config/navigation.config';

function PlayerDetailPerformance() {
  /*
   * Hooks n State
   */
  const [_tabs, _setTabs] = useState<IScoredTab[]>([]);
  const [_automations, _setAutomations] = useState<FIFormattedAutomation[]>([]);
  const [_isEmpty, _setIsEmpty] = useState<boolean>(false);
  // const [_activeTab, _setActiveTab] = useState<string>('');
  const [_activeTab, _setActiveTab] = useState<IScoredTab | null>(null);
  const [_cardBackLinkState, _setCardBackLinkState] =
    useState<ILinkState | null>(null);
  const {t} = useTranslation();
  const [_additionalPlayerInfo, _setAdditionalPlayerInfo] = useState<
    FIDisplayPlayer[]
  >([]);
  const _playerDetailContext = usePlayerDetail();
  const _analyticsContext = useAnalytics();

  /* Set tabs on player load */
  useEffect(() => {
    if (!_playerDetailContext.performanceStats?.automations_count) {
      _setTabs([]);
      _setActiveTab(null);
      _setIsEmpty(true);
      return;
    }

    const _generatedTabs: IScoredTab[] =
      _playerDetailContext.performanceStats.categories.map((category) => {
        return {
          value: category._id,
          label: capitalize(category.name) || t('players.performance.other'),
          benchmarked_score: category.benchmarked_score,
          isOtherCat: !category.name,
        };
      });
    _setTabs(_generatedTabs);

    if (_generatedTabs.length) {
      _setIsEmpty(false);
      _setActiveTab(_generatedTabs[0]);
    }
  }, [_playerDetailContext.performanceStats]);

  useEffect(() => {
    if (_activeTab && _playerDetailContext.performanceStats) {
      const _tab = _playerDetailContext.performanceStats.categories.find(
        (cat) => cat._id === _activeTab?.value,
      );
      _setAutomations(_tab?.automations || []);
    }
  }, [_activeTab, _playerDetailContext.performanceStats]);

  useEffect(() => {
    let _newLinkState: ILinkState = {
      route: `${NAV_LEVEL_1_ROUTE.PLAYERS}/${_playerDetailContext.player?._id}`,
      label:
        _playerDetailContext.player?.display_name ||
        t('players.players', {count: 1}),
    };
    if (
      _playerDetailContext.player &&
      _playerDetailContext.playerSummary?.scouting
    ) {
      _newLinkState.route = `${NAV_LEVEL_1_ROUTE.SCOUTING}${NAV_LEVEL_1_ROUTE.PLAYERS}/${_playerDetailContext.player._id}`;
    } else if (_playerDetailContext.isTeamPage) {
      _newLinkState = {
        route: NAV_LEVEL_1_ROUTE.TEAM,
        label: _playerDetailContext.team?.name || t('team.teams', {count: 1}),
      };
    }

    _setCardBackLinkState(_newLinkState);
  }, [
    _playerDetailContext.playerSummary,
    _playerDetailContext.player,
    _playerDetailContext.team,
    _playerDetailContext.isTeamPage,
  ]);

  // Set additional player info - used to display name in scouted player's performance cards
  useEffect(() => {
    if (
      !_playerDetailContext.player ||
      _playerDetailContext.playerSummary?.is_own_player
    ) {
      _setAdditionalPlayerInfo([]);
      return;
    }

    const _displayPlayerInfo: FIDisplayPlayer[] = [
      {
        _id: _playerDetailContext.player._id,
        display_name: _playerDetailContext.player.display_name,
        name: _playerDetailContext.player.name,
        image_url: _playerDetailContext.player.image_url,
      },
    ];

    _setAdditionalPlayerInfo(_displayPlayerInfo);
  }, [
    _playerDetailContext.player,
    _playerDetailContext.playerSummary?.is_own_player,
  ]);

  /*
   * Handlers
   */
  function _onTabChange(value: string) {
    const _matchingTab = _tabs.find((tab) => tab.value === value);
    _setActiveTab(_matchingTab || null);

    _analyticsContext.trackEvent(ANALYTICS_EVENT.PLAYER_PAGE_SWITCHED_TAB, {
      team_id: _playerDetailContext.team?._id || '',
      player_id: _playerDetailContext.player?._id || undefined,
      tab: value,
      type: 'tactical',
    });
  }

  /*
   * Render
   */

  const _newTrackerButtonLabel = t('eventAutomations.trackersNew');
  const _optionalActionInfo: Partial<FIEventAutomationPostData> = {
    observing_players: {
      players: _playerDetailContext.player
        ? [_playerDetailContext.player._id]
        : [],
    },
  };

  const _isOpponentPage =
    !_playerDetailContext.isTeamPage &&
    !_playerDetailContext.playerSummary?.is_own_player;

  let _Content = null;
  if (_playerDetailContext.isSettingMirrorInfo) {
    _Content = <PlayerMirrorBuilder />;
  } else if (_activeTab && !_isEmpty) {
    _Content = (
      <Stack gap={3}>
        <ScoredTabs
          tabs={_tabs}
          activeTabId={_activeTab?.value}
          onTabChange={_onTabChange}
        />

        <Grid container spacing={2}>
          {_automations.map((automation) => {
            return (
              <Grid item lg={3} md={6} xs={12} key={automation._id}>
                <OverviewSimpleCard
                  automation={automation}
                  automationType={
                    _isOpponentPage ? 'shadow-tracker' : 'tracker'
                  }
                  teamId={
                    _isOpponentPage
                      ? _playerDetailContext.player?.teams?.[0]?._id
                      : undefined
                  }
                  additionalPlayerInfo={_additionalPlayerInfo}
                  linkState={_cardBackLinkState || undefined}
                />
              </Grid>
            );
          })}

          {/* "NEW TRACKER" button */}
          {!_isOpponentPage && (
            <Grid item lg={3} md={6} xs={12} sx={{flexGrow: 1}}>
              <Box
                height="100%"
                borderRadius={1}
                width="100%"
                border={1}
                borderColor="secondary.dark"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{borderStyle: 'dashed'}}
                minHeight={80}>
                <AutomationCreateButton
                  type="tracker"
                  optionalActionInfo={{
                    ..._optionalActionInfo,
                    tags: !_activeTab.isOtherCat
                      ? [_activeTab.value]
                      : undefined,
                  }}
                  Button={
                    <Button color="secondary" startIcon={<AddIcon />}>
                      {_newTrackerButtonLabel}
                    </Button>
                  }
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Stack>
    );
  } else if (_isEmpty) {
    _Content = (
      <CustomEmptyState
        header={t('players.performance.noData.header')}
        description={t('players.performance.noData.description')}
        primaryAction={
          <AutomationCreateButton
            type="tracker"
            optionalActionInfo={_optionalActionInfo}
            Button={
              <Button variant="contained" startIcon={<AddIcon />}>
                {_newTrackerButtonLabel}
              </Button>
            }
          />
        }
      />
    );
  }
  return (
    <PlayerDetailBlock
      title={t('players.performance.title')}
      HeaderRight={<PlayerMirrorInfo />}>
      {_Content}
    </PlayerDetailBlock>
  );
}

export default PlayerDetailPerformance;
