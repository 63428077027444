import React from 'react';
import {useTranslation} from 'react-i18next';
import {getTranslatedSubscoreProperty} from '@/helpers/translation.helper';
import {IDashboardScoreData} from '@/types/dashboard/dashboard-score.types';
import {Card} from '../../../../common/card/Card';
import {Divider} from '../../../../common/divider/Divider';
import Indicator from '../../../Indicator/Indicator';
import {WidgetHeader} from '../widget-header/WidgetHeader';
import {WidgetSubtitle} from '../widget-subtitle/WidgetSubtitle';
import styles from './ScoresWidget.module.scss';
import {
  getCurrentTeamScoreAndPosition,
  getCurrentTeamScoreData,
  getMinMaxScores,
  getMinMaxSubscores,
} from '@/helpers/dashboard/dashboard.helper';
import {useTeams} from '@/context/team.context';
import {roundAndFormatNumber} from '@/helpers/general/general.helper';

interface IScoresWidgetProps {
  action: string;
  data: IDashboardScoreData;
  label?: string;
}

export const ScoresWidget = ({action, data, label}: IScoresWidgetProps) => {
  const {t} = useTranslation();
  const _teamsContext = useTeams();

  if (!_teamsContext.current) return <></>;

  // Data
  const {scores, match_limit} = data;
  const {min, max} = getMinMaxScores(scores);
  const {score, position} = getCurrentTeamScoreAndPosition(
    scores,
    _teamsContext.current._id,
  );

  const leaguePosisition = t('overview-dashboards.widgets.scores.league', {
    count: position + 1,
    ordinal: true,
  });

  // Subscores
  const getSubscores = () => {
    if (scores[0]?.subscores && _teamsContext.current) {
      const subscoreData = getCurrentTeamScoreData(
        scores,
        _teamsContext.current._id,
      );
      const subscoresMinMax = getMinMaxSubscores(scores);

      if (subscoreData && subscoreData.subscores) {
        return subscoreData.subscores.map((subscore, index) => {
          if (_teamsContext.current) {
            return (
              <div key={index}>
                <WidgetSubtitle
                  text={getTranslatedSubscoreProperty(
                    action,
                    subscore.property,
                  )}
                />
                <Indicator
                  value={subscore.events}
                  min={subscoresMinMax[subscore.property].min}
                  max={subscoresMinMax[subscore.property].max}
                  teamId={_teamsContext.current._id}
                  scores={scores}
                  action={action}
                />
              </div>
            );
          }
        });
      }
    }
  };

  return (
    <Card>
      <WidgetHeader action={action} label={label} />

      {Boolean(scores?.length) && (
        <>
          <WidgetSubtitle
            text={t('overview-dashboards.widgets.labels.team-strength')}
          />
          <div className={styles.container}>
            <h1 className={styles.score}>{roundAndFormatNumber(score)}</h1>
            <span>{leaguePosisition}</span>
          </div>
          <Indicator
            value={score}
            min={min}
            max={max}
            teamId={_teamsContext.current._id}
            scores={scores}
            action={action}
            matchLimit={match_limit}
            showInfo={true}
          />
        </>
      )}

      {Boolean(scores.length) && scores?.[0].subscores && (
        <>
          <Divider />
          <div className={styles.scores}>{getSubscores()}</div>
        </>
      )}

      {!scores.length && (
        <div style={{textAlign: 'center', opacity: 0.8}}>
          <p>{t('dashboard.player-statistics.no-data')}</p>
        </div>
      )}
    </Card>
  );
};
