import React from 'react';
import {default as MUITabs, TabsProps} from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import {TABS_HEIGHT} from '@/config/mui-overrides.config';

function Tabs(props: TabsProps): JSX.Element {
  return (
    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
      <MUITabs
        sx={{
          height: TABS_HEIGHT,
          minHeight: TABS_HEIGHT,
          '& .MuiTab-root': {
            px: 1,
            minWidth: 0,
            height: TABS_HEIGHT,
            minHeight: TABS_HEIGHT,
            fontSize: 12,
          },
        }}
        {...props}>
        {props.children}
      </MUITabs>
    </Box>
  );
}

export default Tabs;
