export const DEFAULT_CLIP_BEFORE_OFFSET = 5;
export const DEFAULT_CLIP_AFTER_OFFSET = 10;
export const MAX_CLIP_OFFSET = 60;
export const MAX_EDIT_OFFSET = 60;

export const editVideoConstants = {
  editLeftTrimPos: 0,
  editRightTrimPos: 1,
  videoDurationIncrease: 10,
  videoEditorDurationIncrease: 20,
  videoMaxBufferTime: 60,
  endLoadTime: 0.99,
  startForwardIncreaseTime: 20,
  startBackwardIncreaseTime: 25,
  handleWidth: 15,
};

export const DOWNLOAD_LIMIT = 25;
