import React, {useEffect, useState} from 'react';
import {useTeams} from '@/context/team.context';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import {capitalize} from '@/helpers/general/general.helper';
import EditIcon from '@mui/icons-material/Edit';
import {useTranslation} from 'react-i18next';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider'; // <-- Import Divider
import Dialog from '@mui/material/Dialog'; // <-- Import Dialog and related components
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {CountTagUsageBody, F, FITag} from '@my-game-plan/types';
import {LOADING_STATE} from '@/types/screen.types';
import Loader from '../common/Loader/Loader.view';
import CustomEmptyState from '../error-states/CustomEmptyState';

export function TagPreferences(): JSX.Element {
  /*
   * Hooks n State
   */
  const _teamsContext = useTeams();
  const {t} = useTranslation();

  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentTag, setCurrentTag] = useState<FITag | null>(null);
  const [editedTag, setEditedTag] = useState('');
  const [tagCountData, setTagCountData] = useState<F<CountTagUsageBody>>();

  /*
   * Side effects
   */
  useEffect(() => {
    async function _fetchData() {
      const data = await _teamsContext.getTagCountData();

      setTagCountData(data);
      _setLoadingState(LOADING_STATE.SUCCESS);
    }
    _fetchData();
  }, []);

  useEffect(() => {
    if (!tagCountData || !_teamsContext.preferences?.tags) return;
    const newTagCountData: any = {};
    _teamsContext.preferences.tags.forEach((tag) => {
      newTagCountData[tag._id] = tagCountData[tag._id];
      newTagCountData[tag._id].tag = tag; // in case the label changes
    });
    setTagCountData(newTagCountData);
  }, [_teamsContext.preferences?.tags]);

  /*
   * Handlers
   */
  const handleOpenModal = (tag: FITag) => {
    setCurrentTag(tag);
    setEditedTag(tag.label);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setCurrentTag(null);
    setEditedTag('');
  };

  /*
   * Render
   */

  const isEmpty = (obj: object) => Object.keys(obj).length === 0;
  return (
    <div style={{position: 'relative'}}>
      {!tagCountData || isEmpty(tagCountData) ? (
        <CustomEmptyState
          header={t('preferences.tags.noTags.title')}
          description={t('preferences.tags.noTags.text')}
        />
      ) : (
        <List>
          {Object.entries(tagCountData).map(([tag_id, info], index, arr) => (
            <div key={tag_id}>
              <ListItem>
                <ListItemText
                  primary={capitalize(info.tag.label)}
                  secondary={`${info.trackersWithTagCount} ${t(
                    'eventAutomations.trackers',
                    {count: info.trackersWithTagCount},
                  )} - ${info.opponentAutomationsWithTagCount} ${t(
                    'eventAutomations.opponentAutomations',
                    {count: info.opponentAutomationsWithTagCount},
                  )}`}
                />
                <Box marginRight={4}>
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    color={'secondary'}
                    onClick={() => handleOpenModal(info.tag)}>
                    <EditIcon />
                  </IconButton>
                </Box>
                <IconButton
                  edge="end"
                  aria-label="delete"
                  color={'error'}
                  onClick={() => _teamsContext.deleteTag(info.tag)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
              {index !== arr.length - 1 && <Divider />}{' '}
              {/* Conditionally render divider except for the last item */}
            </div>
          ))}
        </List>
      )}

      {/* Render the Dialog */}
      <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>{t('preferences.tags.edit')}</DialogTitle>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (!currentTag) return;
            _teamsContext.editTag(currentTag._id, editedTag);
            handleCloseModal();
          }}>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              type="text"
              fullWidth
              value={editedTag}
              onChange={(e) => setEditedTag(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} color="primary">
              {t('preferences.tags.cancel')}
            </Button>
            <Button type="submit" color="primary">
              {t('preferences.tags.save')}
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <Loader
        fixed={false}
        isVisible={
          _loadingState === LOADING_STATE.LOADING ||
          _loadingState === LOADING_STATE.INITING
        }
      />
    </div>
  );
}
