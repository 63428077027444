import React, {SyntheticEvent, useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import PlayerDetailBlock from './player-detail-block.view';
import {useTranslation} from 'react-i18next';
import {usePlayerDetail} from '@/context/player-detail.context';
import ScoredTabs, {IScoredTab} from '../scored-tabs.view';
import {
  IPhysicalCategoryData,
  PHYSICAL_CATEGORIES_ABSOLUTE,
  TPhysicalCategoryAbsolute,
} from '@my-game-plan/types';

import Stat from '@/components/common/stat/stat.view';
import {getPhysicalStatsTrendLines} from '@/helpers/player-physical.helper';
import HistoricalTrend, {
  IHistoricalTrendLine,
} from '@/components/common/charts/historical-trend.view';
import {useTheme} from '@mui/material';
import {renderPhysicalDataTooltip} from '@/components/common/charts/historyical-trend.helpers';

import CustomEmptyState from '@/components/error-states/CustomEmptyState';
import {useAnalytics} from '@/context/analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

function PlayerDetailPhysical(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const {palette} = useTheme();
  const _playerDetailContext = usePlayerDetail();
  const _analyticsContext = useAnalytics();

  const [_tabs, _setTabs] = useState<IScoredTab[]>([]);
  const [_activeTabKey, _setActiveTabKey] = useState<string>('');
  const [_activeCategory, _setActiveCategory] =
    useState<IPhysicalCategoryData | null>(null);
  const [_isBreakingDownPossession, _setIsBreakingDownPossession] =
    useState<boolean>(false);
  // const [_stats, _setStats] = useState<FIFormattedAutomationPlayerScore[][]>(
  //   [],
  // );
  const [_observingMatchIds, _setObservingMatchIds] = useState<string[]>([]);
  const [_trendLines, _setTrendLines] = useState<IHistoricalTrendLine[]>([]);
  const [_canBreakdownPossession, _setCanBreakdownPossession] =
    useState<boolean>(false);

  /* Generate tabs on data load */
  useEffect(() => {
    if (!_playerDetailContext.physicalData) {
      _setTabs([]);
      _setActiveTabKey('');
      return;
    }

    let _generatedTabs: IScoredTab[] =
      _playerDetailContext.physicalData.categories.map((category) => {
        return {
          value: category.category,
          label: t(`physical.${category.category}`),
          benchmarked_score: category.benchmarked_score,
        };
      });

    // Do not show the max speed tab when on team page
    if (_playerDetailContext.isTeamPage) {
      _generatedTabs = _generatedTabs.filter((tab) => {
        return !PHYSICAL_CATEGORIES_ABSOLUTE?.includes(
          tab.value as TPhysicalCategoryAbsolute,
        );
      });
    }

    _setTabs(_generatedTabs);

    if (_generatedTabs.length) {
      _setActiveTabKey(_generatedTabs[0].value);
    }

    _setObservingMatchIds(
      _playerDetailContext.physicalData.history.map(
        (historyEntry) => historyEntry.match._id,
      ),
    );
  }, [_playerDetailContext.physicalData]);

  /* Set active category on tab change */
  useEffect(() => {
    if (_activeTabKey && _playerDetailContext.physicalData) {
      const _tab = _playerDetailContext.physicalData.categories.find(
        (cat) => cat.category === _activeTabKey,
      );

      _setActiveCategory(_tab || null);

      if (_tab) {
        const _newLinesConfig = getPhysicalStatsTrendLines(
          _tab,
          _isBreakingDownPossession,
          palette,
        );
        _setTrendLines(_newLinesConfig);

        if (
          PHYSICAL_CATEGORIES_ABSOLUTE.includes(
            _tab.category as TPhysicalCategoryAbsolute,
          )
        ) {
          _setCanBreakdownPossession(false);
          _setIsBreakingDownPossession(false);
        } else {
          _setCanBreakdownPossession(true);
        }
      }
    }
  }, [
    _activeTabKey,
    _playerDetailContext.physicalData,
    _isBreakingDownPossession,
  ]);

  /*
   * Handlers
   */
  function _onTabChange(tab: string) {
    _setActiveTabKey(tab);

    _analyticsContext.trackEvent(ANALYTICS_EVENT.PLAYER_PAGE_SWITCHED_TAB, {
      team_id: _playerDetailContext.team?._id || '',
      player_id: _playerDetailContext.player?._id || undefined,
      tab: tab,
      type: 'physical',
    });
  }

  function _onBreakdownToggle(event: SyntheticEvent, checked: boolean) {
    _setIsBreakingDownPossession(checked);
  }

  /*
   * Render
   */

  if (!_playerDetailContext.hasPhysicalData) {
    return null;
  }

  let _Content = null;
  if (_playerDetailContext.physicalData?.history?.length) {
    let _unit: string | undefined = undefined;
    if (_activeCategory?.unit && _activeCategory.unit !== 'occ_match') {
      _unit = t(`physical.units.${_activeCategory.unit}`);
    }

    _Content = (
      <Stack gap={8} direction="row">
        {/* Chart */}
        <Box flex={1}>
          {_playerDetailContext.physicalData?.history && _activeCategory && (
            <HistoricalTrend
              data={_playerDetailContext.physicalData.history}
              dataLabel={t(`physical.${_activeCategory.category}`)}
              observingMatchIds={_observingMatchIds}
              observingTeamId={
                _playerDetailContext.isTeamPage
                  ? _playerDetailContext.team?._id
                  : _playerDetailContext.player?.teams?.[0]._id
              }
              lines={_trendLines}
              handleTooltip={(data) =>
                renderPhysicalDataTooltip(data, undefined, _unit)
              }
              valueUnit={_unit}
            />
          )}
        </Box>

        {/* Stats */}
        <Stack gap={3} sx={{minWidth: 200}}>
          {_canBreakdownPossession && (
            <FormControlLabel
              label={
                <Typography color="text.secondary" fontSize={14}>
                  {t('players.physical.possessionBreakdown')}
                </Typography>
              }
              control={
                <Checkbox size="small" checked={_isBreakingDownPossession} />
              }
              value={_isBreakingDownPossession}
              onChange={_onBreakdownToggle}
            />
          )}

          {/* <Stack gap={6}> */}
          {_trendLines.map((statGroup, index) => {
            let _titleKey = '';
            if (_isBreakingDownPossession && index === 0) {
              _titleKey = 'tip';
            } else if (_isBreakingDownPossession) {
              _titleKey = 'otip';
            }
            return (
              <React.Fragment key={index}>
                <Stack gap={2}>
                  {_titleKey && (
                    <Typography fontSize={14} color="text.secondary">
                      {t(`players.physical.${_titleKey}`)}
                    </Typography>
                  )}
                  {statGroup.scores.map((stat) => {
                    if (!stat.formattedValue) {
                      return null;
                    }
                    const _isBenchmarkStat = stat.key.includes('benchmark');

                    let _color = statGroup.color;
                    if (
                      _isBenchmarkStat &&
                      typeof statGroup.benchmark !== 'undefined'
                    ) {
                      _color = statGroup.benchmark.color;
                    }

                    return (
                      <Stat
                        key={`${index}-${stat.key}`}
                        // label={
                        //   stat.label == t('players.physical.benchmark')
                        //     ? stat.label
                        //     : t('players.physical.max')
                        // }
                        label={
                          _activeTabKey === 'psv-99' && !_isBenchmarkStat
                            ? t('players.physical.max')
                            : stat.label
                        }
                        value={stat.formattedValue}
                        valueSuffix={stat.valueSuffix}
                        // valueSuffixColor={_color}
                        diffValue={stat.formattedDiff}
                        diffColor={stat.diffColor}
                        legendInfo={{
                          color: _color,
                          shape: _isBenchmarkStat ? 'line' : 'dot',
                        }}
                      />
                    );
                  })}
                </Stack>
                {_trendLines.length > 1 && index < _trendLines.length - 1 && (
                  <Divider />
                )}
              </React.Fragment>
            );
          })}
          {/* </Stack> */}
        </Stack>
      </Stack>
    );
  } else {
    _Content = (
      <CustomEmptyState header={t('players.physical.noData.header')} />
    );
  }

  return (
    <PlayerDetailBlock title={t('players.physical.title')}>
      <Stack gap={4}>
        {/* TABS */}
        <ScoredTabs
          tabs={_tabs}
          activeTabId={_activeTabKey}
          onTabChange={_onTabChange}
        />

        {/* CONTENT */}
        {_Content}
      </Stack>
    </PlayerDetailBlock>
  );
}

export default PlayerDetailPhysical;
