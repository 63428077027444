import React from 'react';
import {useTranslation} from 'react-i18next';
import PlayerDetailBlock from './player-detail-block.view';
import {usePlayerDetail} from '@/context/player-detail.context';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import AddIcon from '@mui/icons-material/Add';

import PlayerObjectiveCard from '@/components/player-objectives/player-objective-card.view';
import PlayerObjectiveCreateButton from '@/components/player-objectives/player-objective-create-button.view';
import {LOADING_STATE} from '@/types/screen.types';
import CustomEmptyState from '@/components/error-states/CustomEmptyState';

function PlayerDetailObjectives(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _playerDetailContext = usePlayerDetail();

  /*
   * Side effects
   */

  /*
   * Handlers
   */

  /*
   * Render
   */
  let _Content = null;
  if (_playerDetailContext.objectivesLoadingState === LOADING_STATE.ERROR) {
    _Content = (
      <CustomEmptyState
        imageType="error"
        header={t('error-states.not-found.header')}
        description={t('error-states.not-found.description')}
      />
    );
  } else if (
    _playerDetailContext.objectivesLoadingState === LOADING_STATE.SUCCESS &&
    !_playerDetailContext.objectives.length
  ) {
    _Content = <CustomEmptyState header={t('playerObjectives.noObjectives')} />;
  } else {
    _Content = (
      <Grid container spacing={2} alignItems="stretch">
        {_playerDetailContext.objectives.map((objective) => {
          return (
            <Grid item lg={4} md={6} xs={12} key={objective._id} display="flex">
              <PlayerObjectiveCard objective={objective} />
            </Grid>
          );
        })}
        <Grid item lg={4} md={6} xs={12} sx={{flexGrow: 1}}>
          <Box
            height="100%"
            borderRadius={1}
            width="100%"
            border={1}
            borderColor="secondary.dark"
            display="flex"
            alignItems="center"
            justifyContent="center"
            sx={{borderStyle: 'dashed'}}
            minHeight={80}>
            <PlayerObjectiveCreateButton
              playerId={_playerDetailContext.player?._id || ''}
              Button={
                <Button color="secondary" startIcon={<AddIcon />}>
                  {t('playerObjectives.new')}
                </Button>
              }
            />
          </Box>
        </Grid>
      </Grid>
    );
  }
  return (
    <PlayerDetailBlock title={t('playerObjectives.objectives')}>
      {_Content}
    </PlayerDetailBlock>
  );
}

export default PlayerDetailObjectives;
