import {TCompetitionType} from '@my-game-plan/types';
import React from 'react';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import {useTheme} from '@mui/material';

interface ICompetitionIconProps {
  elevation?: number;
  competition_type: TCompetitionType;
  size?: 'small' | 'medium';
}

function CompetitionIcon(props: ICompetitionIconProps): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {palette} = useTheme();

  /*
   * Handlers
   */

  /*
   * Render
   */
  if (props.competition_type === 'domestic_league') {
    return null;
  }

  let _color = palette.warning.main;

  if (props.competition_type === 'international_cup') {
    _color = palette.primary.main;
  }

  const _size = props.size === 'small' ? 12 : 16;

  return (
    <EmojiEventsIcon
      elevation={props.elevation}
      sx={{color: _color, fontSize: 16}}
      height={_size}
      width={_size}
      fill={_color}
    />
  );
}

export default CompetitionIcon;
