import {useTranslation} from 'react-i18next';
import {getTranslatedSuccessColumn} from '@/helpers/translation.helper';
import React from 'react';
import {
  ICell,
  IColumn,
  IPlayerStat,
  IPlayerStatsTableColumn,
  THighlightedCell,
  TRowClickHandler,
  TSetHighlightedCell,
} from '@/types/dashboard/dashboard-player-stats.types';
import {eventOutcome} from '@/types/types';
import {PlayerCell} from '@/components/dashboard/players';
import {
  hoverBackgroundColor,
  hoverRowBackgroundColor,
} from '@/config/general.config';
import classNames from 'classnames';
import styles from '@/components/dashboard/players/stylesheet/players-list.module.scss';
import {createKey, hoverEffect} from '@/helpers/general/general.helper';
import {useDashboard} from '@/context/dashboard/dashboard.context';

const Column = ({
  cellName,
  columnIndex,
  onColumnClick,
}: IColumn): JSX.Element => {
  return (
    <div
      onClick={() => {
        onColumnClick(columnIndex);
      }}
      className={cellName !== 'Player' ? 'center' : ''}>
      {cellName}
    </div>
  );
};

interface PlayerStatsHeadProps {
  columns: IPlayerStatsTableColumn[];
  onColumnClick: (arg0: number) => void;
}

export const createPlayersStatsHead = ({
  columns,
  onColumnClick,
}: PlayerStatsHeadProps) => {
  const {t} = useTranslation();
  const _dashboard = useDashboard();

  const headCells: string[] = [
    'Player',
    ...columns.map((column) =>
      getTranslatedSuccessColumn(t, column.label, _dashboard.current?.action),
    ),
  ];

  return {
    cells: headCells.map((cellName, columnIndex) => ({
      key: cellName,
      content: (
        <Column
          cellName={cellName}
          columnIndex={columnIndex - 1} // Because players header is at index 0, and not in this map
          onColumnClick={onColumnClick}
        />
      ),
      shouldTruncate: true,
      isSortable: false,
    })),
  };
};
const Cell = ({
  isCellSelected,
  cellValue,
  onCellClick,
  rowIndex,
  columnIndex,
  isRowSelected,
  isInteractive,
}: ICell) => {
  const rowBackgroundColor = isRowSelected
    ? hoverBackgroundColor
    : hoverRowBackgroundColor;
  const cellBackgroundColor = isCellSelected ? hoverBackgroundColor : '';

  return (
    <div
      className={classNames(styles.playersList, {
        [styles.playersListInteractive]: isInteractive,
      })}
      style={{backgroundColor: cellBackgroundColor}}
      onClick={() => {
        if (isInteractive) {
          onCellClick({column: columnIndex, row: rowIndex});
        }
      }}
      onMouseOver={(e) => {
        if (isInteractive) {
          hoverEffect(e, hoverBackgroundColor, rowBackgroundColor);
        }
      }}
      onMouseLeave={(e) => {
        if (isInteractive) {
          hoverEffect(e, '', '');
        }
      }}>
      {cellValue}
    </div>
  );
};

interface PlayerStatsRowCellsProps {
  columns: IPlayerStatsTableColumn[];
  row: IPlayerStat;
  onRowClick: TRowClickHandler;
  index: number;
  onCellClick: TSetHighlightedCell;
  highlightedCell: THighlightedCell;
  highlightedColumnIndex: number | undefined;
  isRowSelected: boolean;
  tablesCount: number;
  isInteractive?: boolean;
}

const createPlayerStatsRowCells = ({
  columns,
  row,
  onRowClick,
  index,
  onCellClick,
  highlightedCell,
  highlightedColumnIndex,
  isRowSelected,
  tablesCount,
  isInteractive,
}: PlayerStatsRowCellsProps) => {
  const {_id} = row;
  const cells: {key: string; content: number | string | JSX.Element}[] = [];
  let eventOutcomeName: keyof eventOutcome;

  const playerBasicInfo = {
    key: createKey(_id.toString()),
    content: (
      <PlayerCell
        player={row.player}
        receivingPlayer={row.receiving_player}
        onRowClick={onRowClick}
        rowIndex={index}
        tablesCount={tablesCount}
        columnsCount={columns.length}
        isInteractive={isInteractive}
      />
    ),
  };

  const checkSelected = (columnIndex: number) => {
    if (highlightedColumnIndex !== -1) {
      return columnIndex === highlightedColumnIndex;
    } else {
      const {column, row} = highlightedCell;
      return row === index && column === columnIndex;
    }
  };

  cells.push(playerBasicInfo);

  columns.forEach((column, columnIndex) => {
    const _key = column.key || column.label;
    if (row[_key] || row[_key] === 0) {
      cells.push({
        key: eventOutcomeName,
        content: (
          <Cell
            isCellSelected={checkSelected(columnIndex)}
            cellValue={row[_key] || 0}
            onCellClick={onCellClick}
            rowIndex={index}
            columnIndex={columnIndex}
            isRowSelected={isRowSelected}
            isInteractive={isInteractive}
          />
        ),
      });
    }
  });

  return cells;
};

interface RowsProps {
  columns: IPlayerStatsTableColumn[];
  playersList: IPlayerStat[];
  onRowClick: TRowClickHandler;
  onCellClick: TSetHighlightedCell;
  highlightedCell: THighlightedCell;
  highlightedColumnIndex: number | undefined;
  isRowSelected: boolean;
  tablesCount: number;
  isInteractive?: boolean;
}

export const rows = ({
  columns,
  playersList,
  onRowClick,
  onCellClick,
  highlightedCell,
  highlightedColumnIndex,
  isRowSelected,
  tablesCount,
  isInteractive,
}: RowsProps) =>
  playersList
    .map((row: IPlayerStat, index: number) => ({
      key: `row-${index}`,
      isHighlighted: false,
      cells: createPlayerStatsRowCells({
        columns,
        row,
        onRowClick,
        index,
        onCellClick,
        highlightedCell,
        highlightedColumnIndex,
        isRowSelected,
        tablesCount,
        isInteractive,
      }),
    }))
    .slice(0, 10);
