import {ADMIN_API_CONFIG} from '@/config/api.config';
import {REQUEST_ERRORS} from '@/config/errors.config';
import {useAPI} from '@/hooks/api.hooks';
import {Resource} from '@/types/api.types';
import {ISwitchAdminUserTeamPostData} from '@/types/user/user.types';
import {FIUserFormatted} from '@my-game-plan/types';

const api = useAPI();

export async function switchTeam(
  postData: ISwitchAdminUserTeamPostData,
): Promise<FIUserFormatted> {
  try {
    const {data} = await api.post<FIUserFormatted>({
      resource: Resource.admin,
      url: ADMIN_API_CONFIG.switchTeam,
      data: postData,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch (error) {
    throw new Error((error as any).message);
  }
}
