import React, {useEffect, useState} from 'react';

import {useTranslation} from 'react-i18next';
import Sentence from '@/components/automations/sentence/sentence.view';
import Segment from '@/components/automations/sentence/segment';
import {ICommonSentenceProps} from '@/types/sentence-form.types';
import {
  FIBenchmarkOptions,
  PLAYER_POSITIONS,
  PlayerPosition,
  TBenchmarkCalculation,
} from '@my-game-plan/types';
import {BENCHMARK_AVERAGE_OPTIONS} from '@/config/trackers-benchmark.config';
import {ITypedOption} from '@/types/option.types';
import {
  getPositionOptions,
  getSelectedPositionOptions,
  handlePositionOptionChange,
} from '@/helpers/automation/automation-form.helper';

import Autocomplete from '@/components/material-customised/autocomplete/autocomplete.view';
import {capitalize} from 'lodash';

interface IBenchmarkSentenceProps {
  benchmarkOptions: FIBenchmarkOptions;
  onChange?: (data: Partial<FIBenchmarkOptions>) => void;
  readonly?: boolean;
  isObservingPlayer?: boolean;
  isRatioOrRule?: boolean;
}

const TAGS_LIMIT = 3;
const TAGS_LIMIT_READONLY = 1;

function BenchmarkSentence(props: IBenchmarkSentenceProps) {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  const [_positionOptions, _setPositionOptions] = useState<
    ITypedOption<string>[]
  >([]);
  const [_selectedPositionOptions, _setSelectedPositionOptions] = useState<
    ITypedOption<string>[]
  >([]);
  const [_positionsValueLabel, _setPositionsValueLabel] = useState<string>('');

  /*
   * Side effects
   */
  // Det position options
  useEffect(() => {
    const _groupedPositionOptions = getPositionOptions();
    _setPositionOptions(_groupedPositionOptions);
  }, []);

  useEffect(() => {
    const _value = props.benchmarkOptions?.position;
    const _selectedOptions = getSelectedPositionOptions(_value || [], true);
    const _selectedOptionsWithGroups = getSelectedPositionOptions(
      _value || [],
      false,
    );
    _setSelectedPositionOptions(_selectedOptions as ITypedOption<string>[]);

    /* Format "defenders, midfielders + 3"  label */
    const _limit = props.readonly ? TAGS_LIMIT_READONLY : TAGS_LIMIT;
    let _tagsLabel = _selectedOptionsWithGroups.slice(0, _limit).join(',');
    let _hiddenTagsCount = 0;
    if (_selectedOptionsWithGroups.length > _limit) {
      _hiddenTagsCount = _selectedOptionsWithGroups.length - _limit;
      _tagsLabel += `+${_hiddenTagsCount}`;
    }
    _setPositionsValueLabel(_tagsLabel);
  }, [props.readonly, _positionOptions, props.benchmarkOptions?.position]);

  /*
   * Handlers
   */

  function _onAverageTypeChange(value: TBenchmarkCalculation) {
    if (props.onChange) {
      props.onChange({
        calculation: value,
      });
    }
  }

  function _onPositionsSelectChange(
    data: ITypedOption<string> | ITypedOption<string>[] | null,
  ) {
    if (props.onChange) {
      let _selectedPositions: PlayerPosition[] | undefined = undefined;
      if (data && Array.isArray(data)) {
        _selectedPositions = handlePositionOptionChange(
          data,
        ) as PlayerPosition[];

        if (!_selectedPositions.length) {
          _selectedPositions = PLAYER_POSITIONS;
        }
        props.onChange({
          position: _selectedPositions,
        });
      }
    }
  }

  /*
   * Render
   */
  const _commonProps: ICommonSentenceProps<FIBenchmarkOptions> = {
    size: props.readonly ? 'xsmall' : 'small',
    data: props.benchmarkOptions || {},
    readonly: props.readonly,
  };

  /* "Compare To" */
  const _IntroTextSegment = (
    <Sentence.Segment>
      <Segment.Text
        {..._commonProps}
        translationKey="eventAutomations.benchmark.baseline.compareTo"></Segment.Text>
    </Sentence.Segment>
  );

  /* League filter */
  const _OfTextSegment = (
    <Sentence.Segment>
      <Segment.Text
        {..._commonProps}
        translationKey="eventAutomations.benchmark.of"
      />{' '}
    </Sentence.Segment>
  );
  const _LeagueDropdownSegment = (
    <Sentence.Segment>
      {/* <Segment.Competitions
          {..._commonProps}
          property="competition_id"
          value={props.benchmarkOptions?.competition_id}
          onChange={props.onChange}
        /> */}
      <Segment.BenchmarkSubject
        {..._commonProps}
        property="observing"
        value={props.benchmarkOptions?.observing}
        onChange={props.onChange}
        required
      />
    </Sentence.Segment>
  );

  /* Observing Segment */
  let _ObservingSegment = null;
  if (props.isObservingPlayer && !props.readonly) {
    _ObservingSegment = (
      <Sentence.Segment>
        <Autocomplete<string, true>
          {..._commonProps}
          placeholder={t('eventAutomations.benchmark.players.placeholder')}
          options={_positionOptions}
          onChange={_onPositionsSelectChange}
          value={_selectedPositionOptions}
          isInlineInput
          multiple
          disableCloseOnSelect
          groupHeader={_positionsGroupHeaderKey}
          tagsSentenceValue={_positionsValueLabel}
        />
      </Sentence.Segment>
    );
  } else if (props.isObservingPlayer && props.readonly) {
    _ObservingSegment = (
      <Sentence.Segment>
        <Segment.Text
          {..._commonProps}
          text={_positionsValueLabel}
          type="value"
        />
      </Sentence.Segment>
    );
  } else {
    _ObservingSegment = (
      <Sentence.Segment>
        <Segment.Text
          {..._commonProps}
          translationKey="eventAutomations.benchmark.teams"
        />
      </Sentence.Segment>
    );
  }

  /* Time range */
  const _inTextSegmentLabel = props.benchmarkOptions.time?.since_date
    ? 'eventAutomations.filter.time.since'
    : 'eventAutomations.benchmark.in';
  const _InTextSegment = (
    <Sentence.Segment>
      <Segment.Text {..._commonProps} translationKey={_inTextSegmentLabel} />
    </Sentence.Segment>
  );

  const _TimeRangeDropdownSegment = (
    <Sentence.Segment>
      <Segment.TimeRange
        {..._commonProps}
        property="time"
        required
        onChange={props.onChange}
        value={props.benchmarkOptions.time}
        selectedCompetitions={props.benchmarkOptions.observing?.competition_id}
      />
    </Sentence.Segment>
  );

  /* Minimum minutes Played  */
  const _AtLeastTextSegment = (
    <Sentence.Segment>
      <Segment.Text
        {..._commonProps}
        translationKey="eventAutomations.benchmark.includePlayers"
      />{' '}
    </Sentence.Segment>
  );
  const _MinEventsInputSegment = (
    <Sentence.Segment>
      <Segment.NumberInput<FIBenchmarkOptions>
        {..._commonProps}
        property="min_minutes_played"
        onChange={props.onChange}
        value={props.benchmarkOptions?.min_minutes_played}
      />
    </Sentence.Segment>
  );
  const _MinutesTextSegment = (
    <Sentence.Segment>
      <Segment.Text
        {..._commonProps}
        translationKey="eventAutomations.benchmark.minutes"
      />
    </Sentence.Segment>
  );

  /* Average / 90 min average */
  const _AverageTextPrefixSegment = (
    <Sentence.Segment>
      <Segment.Text
        {..._commonProps}
        translationKey="eventAutomations.benchmark.benchmark"
      />
    </Sentence.Segment>
  );
  let _AverageSegment = (
    <Sentence.Segment>
      <Segment.Text
        {..._commonProps}
        translationKey="eventAutomations.benchmark.average"
      />
    </Sentence.Segment>
  );

  if (!props.isRatioOrRule) {
    _AverageSegment = (
      <Sentence.Segment>
        <Segment.Dropdown<TBenchmarkCalculation>
          {..._commonProps}
          data={{}}
          options={BENCHMARK_AVERAGE_OPTIONS}
          onChange={_onAverageTypeChange}
          value={props.benchmarkOptions?.calculation}
        />
      </Sentence.Segment>
    );
  }

  function _positionsGroupHeaderKey(text: string): string {
    return capitalize(t(`playerPosition.${text}s`));
  }

  return (
    <Sentence.Sentence size={_commonProps.size}>
      <Sentence.Section>
        {/* Top line - "Compare to" */}
        <Sentence.Line>
          {_IntroTextSegment}
          {/* {_AverageSegment} */}
          {_ObservingSegment}
          {_OfTextSegment}
          {_LeagueDropdownSegment}
        </Sentence.Line>

        {/* Time range */}
        <Sentence.Line>
          {_InTextSegment}
          {_TimeRangeDropdownSegment}
        </Sentence.Line>

        {/* Min minutes */}
        {props.isObservingPlayer && (
          <Sentence.Line>
            {_AtLeastTextSegment}
            {_MinEventsInputSegment}
            {_MinutesTextSegment}
          </Sentence.Line>
        )}
      </Sentence.Section>

      <Sentence.Section>
        <Sentence.Line>
          {_AverageTextPrefixSegment}
          {_AverageSegment}
        </Sentence.Line>
      </Sentence.Section>
    </Sentence.Sentence>
  );
}

export default BenchmarkSentence;
