import React from 'react';
import Segment from '../..';
import {ICommonSentenceInputProps} from '@/types/sentence-form.types';

import Box from '@mui/material/Box';
import {useTeams} from '@/context/team.context';

function ObservingDropdownOpponentSelector<T>(
  props: Omit<ICommonSentenceInputProps<T>, 'property' | 'onChange'>,
): JSX.Element | null {
  /*
   * Hooks n State
   */

  const _teamsContext = useTeams();

  /*
   * Handlers
   */
  function _onChange(teamId: string) {
    if (props.onObservingTeamIdChange) {
      props.onObservingTeamIdChange(teamId);
    }
    //
  }
  /*
   * Render
   */

  if (!_teamsContext.opponentsOptions?.length) return null;

  return (
    <Box ml={1}>
      <Segment.Dropdown
        {...props}
        readonly={false}
        options={_teamsContext.opponentsOptions}
        value={props.observingTeamId}
        onChange={_onChange}
        isBold
      />
    </Box>
  );
}

export default ObservingDropdownOpponentSelector;
