import Button from '@mui/material/Button';
import EmailIcon from '@mui/icons-material/Email';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const EmailButton = () => {
  const {t} = useTranslation();

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<EmailIcon />}
      href="mailto:info@mygameplan.ai?subject=MyGamePlan Platform">
      {t('general.contact')}
    </Button>
  );
};
