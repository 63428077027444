import {ITypedOption} from '@/types/option.types';

import i18n from '../localization';
import {translateValue} from '@/helpers/translation.helper';

import {
  GAME_STATES,
  FIConditionalEventFilters,
  FIEventAutomationPostData,
  TEventAutomationType,
  TEventCalculationType,
  TEventConditionType,
  TQueryOperatorType,
  TEventObservingTeam,
  ACTION,
} from '@my-game-plan/types';
import {IObservingDropdownOptionGroup} from '@/components/automations/sentence/segment/dropdown/observing-dropdown/observing-dropdown-content.view';

export const TRACKER_EMPTY_DETAILS: FIConditionalEventFilters = {
  operator: 'and',
  filters: [],
};

export const TRANSFERED_PLAYER = 'transferred-player';
export const NOTIFICATION_OFFSET = 3;
export const OPPONENT_MATCH_LIMIT = 5;
export const EVENT_AUTOMATION_SKELETONS: Record<
  TEventAutomationType,
  Partial<FIEventAutomationPostData>
> = {
  tracker: {
    calculation: 'occurrences',
    details: TRACKER_EMPTY_DETAILS,
    success_details: TRACKER_EMPTY_DETAILS,

    // action: 'pass',
    // conditions: {
    //   operator: 'and',
    //   conditions: [
    //     {
    //       type: 'before',
    //       action: 'pass',
    //     },
    //   ],
    // },
  },
  'shadow-tracker': {
    calculation: 'occurrences',
    details: TRACKER_EMPTY_DETAILS,
    success_details: TRACKER_EMPTY_DETAILS,
  },
  'opponent-automation': {
    calculation: 'occurrences',
    details: TRACKER_EMPTY_DETAILS,
    // notification_offset: NOTIFICATION_OFFSET,
    // match_limit: OPPONENT_MATCH_LIMIT,
  },
};

export const TRACKER_CALCULATION_OPTIONS: ITypedOption<TEventCalculationType>[] =
  [
    {
      label: i18n.t('eventAutomations.calculationType.occurrences'),
      value: 'occurrences',
    },
    {
      label: i18n.t('eventAutomations.calculationType.ratio'),
      value: 'ratio',
    },
    {
      label: i18n.t('eventAutomations.calculationType.rule'),
      value: 'rule',
    },
  ];

export const OPERATOR_OPTIONS: ITypedOption<TQueryOperatorType>[] = [
  {
    label: i18n.t('sentenceForm.and'),
    value: 'and',
  },
  {
    label: i18n.t('sentenceForm.or'),
    value: 'or',
  },
];

export const DETAIL_OPERATOR_OPTIONS: ITypedOption<TQueryOperatorType>[] = [
  {
    label: i18n.t('sentenceForm.details.isAllOf'),
    value: 'and',
  },
  {
    label: i18n.t('sentenceForm.details.isOneOf'),
    value: 'or',
  },
];

export const CONDITION_TYPE_OPTIONS: ITypedOption<TEventConditionType>[] = [
  {
    label: i18n.t('sentenceForm.conditions.when'),
    value: 'when',
  },
  {
    label: i18n.t('sentenceForm.conditions.before'),
    value: 'before',
  },
  {
    label: i18n.t('sentenceForm.conditions.after'),
    value: 'after',
  },
];

export const GAME_STATE_VALUE_OPTIONS: ITypedOption<string>[] = GAME_STATES.map(
  (game_state) => {
    return {
      label: translateValue(game_state),
      value: game_state,
    };
  },
);

/*
 * OBSERVING PLAYERS
 */
export const EVENT_CONDITION_OBSERVING_OPTIONS: IObservingDropdownOptionGroup[] =
  [
    {
      label: 'sentenceForm.observing.own',
      team: 'own',
      withSuffix: true,
      suffixTeamName: 'sentenceForm.observing.own',
      options: [
        {
          label: 'sentenceForm.observing.anyPlayer',
          subject: 'all',
        },
        {
          label: 'sentenceForm.observing.playersByPosition',
          subject: 'positions',
        },
        {
          label: 'sentenceForm.observing.playersByName',
          subject: 'players',
        },
      ],
    },
    {
      label: 'sentenceForm.observing.opponent',
      team: 'opponent',
      placeholder: 'sentenceForm.observing.anyOpponent',
      withSuffix: true,
      suffixTeamName: 'sentenceForm.observing.opponent',
      options: [
        {
          label: 'sentenceForm.observing.anyPlayer',
          subject: 'all',
        },
        {
          label: 'sentenceForm.observing.playersByPosition',
          subject: 'positions',
        },
      ],
    },
  ];

export const OPPONENT_AUTOMATION_OBSERVING_OPTIONS: IObservingDropdownOptionGroup[] =
  [
    {
      label: 'sentenceForm.observing.nextOpponent',
      team: 'own',
      placeholder: 'sentenceForm.observing.nextOpponent',
      // positionsPrefix: 'sentenceForm.observing.nextOpponent_by',
      withSuffix: true,
      suffixTeamName: 'sentenceForm.observing.nextOpponent',
      options: [
        {
          label: 'sentenceForm.observing.anyPlayer',
          subject: 'all',
        },
        {
          label: 'sentenceForm.observing.playersByPosition',
          subject: 'positions',
        },
        // {
        //   label: 'sentenceForm.observing.playersByName',
        //   subject: 'players',
        // },
      ],
    },
    {
      label: 'sentenceForm.observing.nextOpponentOpponent',
      team: 'opponent',
      placeholder: 'sentenceForm.observing.nextOpponentOpponent',
      withSuffix: true,
      suffixTeamName: 'sentenceForm.observing.nextOpponent',
      suffixOpponentDescription: 'sentenceForm.observing.teamPlaying',
      // positionsPrefix: 'sentenceForm.observing.nextOpponentOpponent_by',
      options: [
        {
          label: 'sentenceForm.observing.anyPlayer',
          subject: 'all',
        },
        {
          label: 'sentenceForm.observing.playersByPosition',
          subject: 'positions',
        },
      ],
    },
  ];

export const OPPONENT_AUTOMATION_TRACKKING_OBSERVING_OPTIONS: IObservingDropdownOptionGroup[] =
  [
    {
      label: 'sentenceForm.observing.nextOpponent',
      placeholder: 'sentenceForm.observing.nextOpponent',
      // positionsPrefix: 'sentenceForm.observing.nextOpponent_by',
      withSuffix: true,
      suffixTeamName: 'sentenceForm.observing.nextOpponent',
      team: 'own',
      options: [
        {
          label: 'sentenceForm.observing.playersByPosition',
          subject: 'positions',
        },
      ],
    },
    {
      label: 'sentenceForm.observing.nextOpponentOpponent',
      placeholder: 'sentenceForm.observing.nextOpponentOpponent',
      // positionsPrefix: 'sentenceForm.observing.nextOpponentOpponent_by',
      withSuffix: true,
      suffixTeamName: 'sentenceForm.observing.nextOpponent',
      suffixOpponentDescription: 'sentenceForm.observing.teamPlaying',
      team: 'opponent',
      options: [
        {
          label: 'sentenceForm.observing.playersByPosition',
          subject: 'positions',
        },
      ],
    },
  ];

export const TRACKER_TRACKKING_OBSERVING_OPTIONS: IObservingDropdownOptionGroup[] =
  [
    {
      label: 'sentenceForm.observing.own',
      placeholder: 'sentenceForm.observing.player',
      // positionsPrefix: 'sentenceForm.observing.nextOpponent_by',
      team: 'own',
      options: [
        {
          label: 'sentenceForm.observing.playersByName',
          subject: 'players',
          singleSelect: true,
        },
      ],
    },
    {
      label: 'sentenceForm.observing.opponent',
      placeholder: 'sentenceForm.observing.anyOpponent',
      // positionsPrefix: 'sentenceForm.observing.nextOpponentOpponent_by',
      withSuffix: true,
      suffixTeamName: 'sentenceForm.observing.opponent',
      team: 'opponent',
      options: [
        {
          label: 'sentenceForm.observing.playersByPosition',
          subject: 'positions',
        },
      ],
    },
  ];

export const OPONNENT_AUTOMATION_SHARE_OPTIONS: IObservingDropdownOptionGroup[] =
  [
    {
      label: '',
      team: 'own',
      placeholder: 'sentenceForm.share.placeholder',
      options: [
        {
          label: 'sentenceForm.share.placeholder',
          subject: '',
        },
        {
          label: 'sentenceForm.players.all',
          subject: 'all',
        },
        {
          label: 'sentenceForm.observing.playersByPosition',
          subject: 'positions',
        },
        {
          label: 'sentenceForm.observing.playersByName',
          subject: 'players',
        },
      ],
    },
  ];

export const RULE_OBSERVING_OPTIONS_EVENT: IObservingDropdownOptionGroup[] = [
  {
    label: 'sentenceForm.observing.entireTeam',
    team: 'own',
    withSuffix: false,
    placeholder: 'sentenceForm.observing.entireTeam',
    // suffixTeamName: 'sentenceForm.observing.own',
    options: [
      {
        label: 'sentenceForm.observing.anyPlayer',
        subject: 'all',
      },
      {
        label: 'sentenceForm.observing.playersByPosition',
        subject: 'positions',
      },
    ],
  },
  {
    label: 'sentenceForm.observing.opponent',
    team: 'opponent',
    placeholder: 'sentenceForm.observing.anyOpponent',
    withSuffix: true,
    suffixTeamName: 'sentenceForm.observing.opponent',
    options: [
      {
        label: 'sentenceForm.observing.anyPlayer',
        subject: 'all',
      },
      {
        label: 'sentenceForm.observing.playersByPosition',
        subject: 'positions',
      },
    ],
  },
];

export const TRACKER_RULE_TRACKKING_OBSERVING_OPTIONS: IObservingDropdownOptionGroup[] =
  [
    {
      label: 'sentenceForm.observing.own',
      placeholder: 'sentenceForm.observing.player',
      // positionsPrefix: 'sentenceForm.observing.nextOpponent_by',
      team: 'own',
      options: [
        {
          label: 'sentenceForm.observing.playersByName',
          subject: 'positions',
        },
      ],
    },
  ];

export const TRACKER_TEAM_SELECTOR_OPTIONS: ITypedOption<TEventObservingTeam>[] =
  [
    {
      label: i18n.t('sentenceForm.by'),
      value: 'own',
    },
    {
      label: i18n.t('sentenceForm.against'),
      value: 'opponent',
    },
  ];

export const OPPONENT_AUTOMATION_TEAM_SELECTOR_OPTIONS: ITypedOption<TEventObservingTeam>[] =
  [
    {
      label: i18n.t('sentenceForm.of'),
      value: 'own',
    },
    {
      label: i18n.t('sentenceForm.against'),
      value: 'opponent',
    },
  ];

/*
 * ACTIONS
 */
export const TRACKING_DATA_ACTION_OPTIONS: ITypedOption<ACTION>[] = [
  {
    label: i18n.t('event.verbs.zone_enter'),
    name: i18n.t('event.verbs.zone_enter'),
    value: 'zone_enter',
  },
  {
    label: i18n.t('event.verbs.zone_visit'),
    name: i18n.t('event.verbs.zone_visit'),
    value: 'zone_visit',
  },
];
export const TRACKING_DATA_ACTION_OPTIONS_THIRD: ITypedOption<ACTION>[] = [
  {
    label: i18n.t('event.verbsThird.zone_enter'),
    name: i18n.t('event.verbsThird.zone_enter'),
    value: 'zone_enter',
  },
  {
    label: i18n.t('event.verbsThird.zone_visit'),
    name: i18n.t('event.verbsThird.zone_visit'),
    value: 'zone_visit',
  },
];
