import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Segment from '..';
import {ITypedOption} from '@/types/option.types';
import {ICommonSentenceInputProps} from '@/types/sentence-form.types';

import {TRACKER_EMPTY_DETAILS} from '@/config/event-automation-builder.config';
import {ACTION, FIEventAutomationPostData} from '@my-game-plan/types';
import {actionConfigsToFormOptions} from '@/helpers/automation/automation-form.helper';
import Autocomplete from '@/components/material-customised/autocomplete/autocomplete.view';
import {translateAction} from '@/helpers/translation.helper';
import {capitalize} from 'lodash';
import {useCompetitions} from '@/context/competitions.context';

interface IActionsDropdownProps<DataType>
  extends ICommonSentenceInputProps<ACTION, DataType> {
  count: number;
  isConditionInput?: boolean;
}

function ActionsDropdown<DataType = FIEventAutomationPostData>(
  props: IActionsDropdownProps<DataType>,
): JSX.Element {
  /* Hooks n State */
  const [_actionsOptions, _setActionsOptions] = useState<
    ITypedOption<ACTION>[]
  >([]);
  const [_valueOption, _setValueOption] = useState<ITypedOption<ACTION> | null>(
    null,
  );
  const {t} = useTranslation();
  const _competitionsContext = useCompetitions();

  useEffect(() => {
    if (props.value) {
      const _activeActionOption = _actionsOptions.find(
        (option) => option.value === props.value,
      );

      if (_activeActionOption) {
        _setValueOption(_activeActionOption);
      }
    } else {
      _setValueOption(null);
    }
  }, [_actionsOptions, props.value]);

  useEffect(() => {
    _setActionsOptions(
      actionConfigsToFormOptions(
        _competitionsContext.domesticCompetition?.event_provider,
        _competitionsContext.domesticCompetition?.available_metrics,
        true,
        props.count,
        props.capitalize,
      ),
    );
  }, [
    _competitionsContext.domesticCompetition?.event_provider,
    _competitionsContext.domesticCompetition?.available_metrics,
    props.count,
  ]);

  /* Handlers */
  function _onChange(
    value: ITypedOption<ACTION> | ITypedOption<ACTION>[] | null,
  ) {
    if (props.onChange) {
      if (Array.isArray(value)) {
        // Handle multiselect
      } else {
        const _value = value?.value || null;
        props.onChange({
          [props.property]: _value,
          details: TRACKER_EMPTY_DETAILS,
          ...(!props.isConditionInput && {
            success_details: TRACKER_EMPTY_DETAILS,
          }),
          metric: undefined,
        } as unknown as Partial<DataType>);
        return;
      }
    }
  }

  /* Render */
  const _translatedPlaceholder = `${t('sentenceForm.action.placeholder', {
    count: props.count,
  })}*`;

  function _groupHeaderKey(text: string): string {
    return capitalize(translateAction(text));
  }

  if (props.readonly)
    return (
      <Segment.Text
        size={props.size}
        data={props.data}
        text={_valueOption?.label}
        type="value"
      />
    );

  return (
    <Autocomplete
      blurOnSelect
      isInlineInput
      options={_actionsOptions}
      value={_valueOption}
      placeholder={_translatedPlaceholder}
      onChange={_onChange}
      groupHeader={_groupHeaderKey}
      error={props.error}
      required
    />
  );
}

export default ActionsDropdown;
