import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import React, {useEffect, useState} from 'react';

import {useTeams} from '@/context/team.context';

import {getPlayerInitials} from '@/helpers/general/general.helper';
import {FIPlayer} from '@my-game-plan/types';

type FilterPlayerOptionProps = {
  value: string;
  label: string;
};

export const FilterPlayerOption = ({value, label}: FilterPlayerOptionProps) => {
  const _teamsContext = useTeams();
  const [_player, _setPlayer] = useState<FIPlayer | null>(null);
  const [_initials, _setInitials] = useState<string>('');

  useEffect(() => {
    const _matchingPlayer = _teamsContext.players.find(
      (player) => player._id.toString() === value,
    );

    if (_matchingPlayer) {
      const _playerInitials = getPlayerInitials(_matchingPlayer.display_name);
      _setInitials(_playerInitials);
    } else {
      _setInitials('');
    }

    _setPlayer(_matchingPlayer || null);
  }, [_teamsContext.players]);
  // const minutesPlayed = _player?.minutes_played;

  return (
    <>
      <ListItemAvatar sx={{minWidth: 32}}>
        <Avatar
          alt={label}
          src={_player?.image_url}
          sx={{height: 24, width: 24, bgcolor: 'secondary.main'}}>
          {_initials}
        </Avatar>
      </ListItemAvatar>
      <ListItemText>{label}</ListItemText>
    </>
  );
};
