import React from 'react';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import {ICommonSentenceProps, TError} from '@/types/sentence-form.types';

import styles from './sequence-actions.module.scss';
import {useTranslation} from 'react-i18next';
import {FIEventAutomationSubject, FISequenceAction} from '@my-game-plan/types';
import Sentence from '../sentence.view';
import Segment from '../segment';
import SentenceLine from '../line';
import {startsWithVowel} from '@/helpers/translation.helper';

interface IActionProps
  extends Omit<ICommonSentenceProps<FISequenceAction>, 'onChange' | 'errors'> {
  actionIndex: number;
  onChange?: (index: number, data: Partial<FISequenceAction>) => void;
  onDelete?: (index: number) => void;
  errors?: TError<FISequenceAction>;
  sequenceAction: FISequenceAction;
  observingPlayers?: FIEventAutomationSubject;
}

function SingleSequenceAction(props: IActionProps): JSX.Element {
  /* Hooks n State */
  const {errors, onChange, ..._commonProps} = props;

  const {t} = useTranslation();

  /* Handlers */
  function _onChange(data: Partial<FISequenceAction>) {
    if (onChange) {
      onChange(props.actionIndex, data);
    }
  }

  function _onDeleteClick() {
    if (!props.onDelete) {
      return;
    }
    props.onDelete(props.actionIndex);
  }

  /* Render */
  let _articleKey = 'sentenceForm.an';
  if (!startsWithVowel(props.sequenceAction.action)) {
    _articleKey = 'sentenceForm.a';
  }

  return (
    <div className={styles.conditionWrapper}>
      <div className={styles.actionIndexWrapper}>
        <div className={styles.actionIndex}>{props.actionIndex + 1}</div>
      </div>
      {/* Display the index with a circle */}
      <div className={styles.condition}>
        <Sentence.Line>
          <Sentence.Segment>
            <Segment.Text {..._commonProps} translationKey={_articleKey} />
          </Sentence.Segment>
          <Sentence.Segment>
            <Segment.Action
              {..._commonProps}
              property="action"
              required
              count={1}
              onChange={_onChange}
              value={props.sequenceAction.action}
              error={props.errors?.action}
            />
          </Sentence.Segment>
          <SentenceLine.Details
            {..._commonProps}
            data={props.data}
            onChange={(data) => {
              if (props.onChange)
                props.onChange(props.actionIndex, {
                  action: props.sequenceAction.action,
                  details: data.details,
                });
            }}
            action={props.sequenceAction.action}
            property={'details'}
            value={props.sequenceAction.details}
            required={false}
            count={1}
            observingPlayers={props.observingPlayers}
          />
        </Sentence.Line>
      </div>

      {!props.readonly && props.onDelete && (
        <div className={styles.conditionDelete}>
          <IconButton
            color="secondary"
            onClick={_onDeleteClick}
            title={t('sentenceForm.conditions.delete')}
            size="small">
            <DeleteIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default SingleSequenceAction;
