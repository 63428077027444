const SCOUTING_TRANSLATIONS = {
  title: 'Scouting',

  search: 'Search players',

  empty: {
    'scouted-players': {
      header: 'You haven’t searched any players yet',
      description:
        "After you've searched players and benchmarked their performance, you can find them here.",
      cta: 'Search players',
    },
    search: {
      header: 'No players found',
      description: 'Try searching by different criteria',
      cta: 'Search again',
    },
  },

  searchForm: {
    name: 'Search by name',
    namePlaceholder: "Start typing a player's name",
    or: 'or search by characteristics',
    anyX: 'Any {{field}}',
    restoreDefaults: 'Restore defaults',
    noResults: {},
  },

  showingResults_one: 'Showing 1 player',
  showingResults_other: 'Showing {{value}} of {{count}} players',

  actions: {
    clearSearches: {
      title: 'Clear list of scouted players',
      description:
        "Clearing your team's list of scouted players will also remove all trackers assigned to them.",
      cta: 'Remove player',
    },
  },
};

export default SCOUTING_TRANSLATIONS;
