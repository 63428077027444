import React from 'react';
import styles from './WidgetSubtitle.module.scss';

interface WidgetSubtitleProps {
  text: string;
}

export const WidgetSubtitle = ({text}: WidgetSubtitleProps) => {
  return <span className={styles.captions}>{text}</span>;
};
