import React from 'react';
import PlayerObjectiveCommentCreateForm from './player-objective-comment-form.view';
import {FIPlayerObjectiveComment} from '@my-game-plan/types';

interface IPlayerObjectiveCommentCreateButtonProps {
  Button: React.ReactElement;
  objectiveId: string;
  data?: FIPlayerObjectiveComment;
}

function PlayerObjectiveCommentCreateButton(
  props: IPlayerObjectiveCommentCreateButtonProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const [_isModalOpen, _setIsModalOpen] = React.useState<boolean>(false);

  /*
   * Handlers
   */
  function _onModalOpen() {
    _setIsModalOpen(true);
  }

  function _onModalClose() {
    _setIsModalOpen(false);
  }

  /*
   * Render
   */

  return (
    <>
      {React.cloneElement(props.Button, {onClick: _onModalOpen})}
      <PlayerObjectiveCommentCreateForm
        data={props.data}
        isOpen={_isModalOpen}
        onClose={_onModalClose}
        objectiveId={props.objectiveId}
      />
    </>
  );
}

export default PlayerObjectiveCommentCreateButton;
