import React, {useEffect, useState} from 'react';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Checkbox from '@mui/material/Checkbox';
import {ITypedOption} from '@/types/option.types';
import {useCompetitions} from '@/context/competitions.context';
import {capitalize, groupBy} from 'lodash';
import {useTranslation} from 'react-i18next';
import {darken, useTheme} from '@mui/material';

interface IBenchmarkSubjectCompetitionsListProps {
  value?: number[];
  onChange: (value: number[]) => void;
}

interface ICompetitionGroup {
  group: string;
  label: string;
  competitionOptions: ITypedOption<number>[];
}

function BenchmarkSubjectCompetitionsList(
  props: IBenchmarkSubjectCompetitionsListProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _theme = useTheme();
  const _competitionsContext = useCompetitions();
  const [_competitionGroups, _setCompetitionGroups] = useState<
    ICompetitionGroup[]
  >([]);

  /*
   * Side effects
   */
  useEffect(() => {
    const _generatedGroupsObject = groupBy(
      _competitionsContext.competitionDropdownOptions,
      (option) => option.group,
    );

    const _generatedGroups: ICompetitionGroup[] = Object.keys(
      _generatedGroupsObject,
    ).map((group) => {
      return {
        group: group,
        label: capitalize(t(`competitions.${group}`)),
        competitionOptions: _generatedGroupsObject[group].sort((a, b) =>
          a.label.localeCompare(b.label),
        ),
      };
    });
    _setCompetitionGroups(_generatedGroups);
  }, [_competitionsContext.competitionDropdownOptions]);

  /*
   * Handlers
   */
  function _onCompetitionSelect(competitionId: number) {
    const _currentIndex =
      typeof props.value !== 'undefined'
        ? props.value.indexOf(competitionId)
        : -1;
    const _newValue = [...(props.value || [])];

    if (_currentIndex === -1) {
      _newValue.push(competitionId);
    } else {
      _newValue.splice(_currentIndex, 1);
    }

    props.onChange(_newValue);
  }

  /*
   * Render
   */
  return (
    <List>
      {_competitionGroups.map((group) => {
        return (
          <React.Fragment key={group.group}>
            <ListSubheader
              sx={{
                paddingHorizontal: 16,
                paddingvertical: 8,
                color: 'secondary.light',
                backgroundColor: darken(_theme.palette.background.default, 0.1),
              }}>
              {group.label}
            </ListSubheader>
            {group.competitionOptions.map((option) => {
              return (
                <ListItem key={option.value} disablePadding>
                  <ListItemButton
                    dense
                    onClick={() => _onCompetitionSelect(option.value)}>
                    <ListItemIcon sx={{minWidth: 0}}>
                      <Checkbox
                        checked={props.value?.includes(option.value) || false}
                        edge="start"
                        tabIndex={-1}
                        disableRipple
                      />
                    </ListItemIcon>
                    <ListItemText>{option.label}</ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
          </React.Fragment>
        );
      })}
    </List>
  );
}

export default BenchmarkSubjectCompetitionsList;
