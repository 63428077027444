import {MuuriComponent} from 'muuri-react';
import {DecoratedItem} from 'muuri-react/dist/types/interfaces/item';
import React from 'react';
import {IWidgetPlaceholder} from '@/types/dashboard/overview-dashboards.types';
import {EmptyPlaceholderWidget} from './placeholder-widget/EmptyPlaceholderWidget';
import {PlaceholderWidget} from './placeholder-widget/PlaceholderWidget';
import styles from './PlaceholderWidgets.module.scss';
import {useOverviewDashboards} from '@/context/dashboard/overview-dashboards.context';

interface IGridProps {
  widget?: IWidgetPlaceholder;
}

export const PlaceholderWidgets = () => {
  const {setSelectedPlaceholderWidgets, getSelectedPlaceholderWidgets} =
    useOverviewDashboards();

  const children = getSelectedPlaceholderWidgets().map((widget, index) => {
    if (!widget) return <EmptyPlaceholderWidget key={`key-${index}`} />;
    return <PlaceholderWidget key={widget.key} widget={widget} />;
  });

  const handleDragEnd = (item: DecoratedItem) => {
    const items = item.getGrid().getItems();
    const widgets: IWidgetPlaceholder[] = [];

    items.forEach((item) => {
      const props = item._component.props as IGridProps;
      // eslint-disable-next-line react/prop-types
      if (props.widget) widgets.push(props.widget);
    });

    setSelectedPlaceholderWidgets(widgets);
  };

  return (
    <MuuriComponent
      dragEnabled
      onDragEnd={handleDragEnd}
      containerClass={styles.container}>
      {children}
    </MuuriComponent>
  );
};
