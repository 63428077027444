import React, {useEffect, useState} from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';

import styles from './playlist-bar-share.module.scss';
import {useTranslation} from 'react-i18next';
import {DOWNLOAD_LIMIT} from '@/config/clips.config';
import {useVideoPlaylist} from '@/context/video/video-playlist.context';
import {useVideo} from '@/context/video/video.context';

import SingleSendCreateButton from '@/components/single-sends/buttons/single-send-create-button.view';
import AutomationCreateButton from '@/components/automations/automation-create-button/automation-create-button.view';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

import {Alert, Snackbar} from '@mui/material';

function PlaylistBarOptions() {
  /* Hooks n State */
  const [_shareMenuAnchorEl, _setShareMenuAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const {t} = useTranslation();

  const _videoPlaylist = useVideoPlaylist();
  const _video = useVideo();

  const _downloadError =
    _videoPlaylist.isDownloadingDisabled &&
    _videoPlaylist.checkedList.length > 0
      ? styles.red
      : '';
  const [open, setOpen] = React.useState(false);
  const [_selectedEventIDs, _setSelectedEventIDs] = useState<string[]>([]);

  useEffect(() => {
    _setSelectedEventIDs(_videoPlaylist.checkedList.map((event) => event._id));
  }, [_videoPlaylist.checkedList]);

  /*
   * Handlers
   */
  // const _onCreatePlaylistClick = useCallback(() => {
  //   const _type = _videoPlaylist.checkedList.length ? 'default' : 'recurring';
  //   _playlistsContext.openCreatePlaylistModal(_type);
  // }, [_videoPlaylist.checkedList]);

  function _handleShareMenuOpen(event: React.MouseEvent<HTMLButtonElement>) {
    _video.setPlaying(false);
    _setShareMenuAnchorEl(event.currentTarget);
  }
  function _handleShareMenuClose() {
    _setShareMenuAnchorEl(null);
  }

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  /*
   * Render
   */
  const _canDownload = true;

  if (!_video.canShare && !_canDownload) return null;

  const _isShareMenuOpen = Boolean(_shareMenuAnchorEl);

  return (
    <div className={styles.playlistBarOptions}>
      {_video.canShare && (
        <div className={styles.playlistBarShareIcon}>
          {/* <IconButton
            color="primary"
            onClick={_onCreatePlaylistClick}
            disabled={!_video.canShare}
            title={t('assigned.add.title')}>
            <ShareIcon />
          </IconButton> */}
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
            <Alert
              onClose={handleClose}
              severity="success"
              sx={{width: '100%'}}>
              {t('singleSends.create.succes')}
            </Alert>
          </Snackbar>
          <Button
            variant="text"
            color="primary"
            startIcon={<SendIcon />}
            onClick={_handleShareMenuOpen}>
            {t('video-player.share')}
          </Button>
          <Menu
            keepMounted
            anchorOrigin={{horizontal: 'left', vertical: 'top'}}
            open={_isShareMenuOpen}
            anchorEl={_shareMenuAnchorEl}
            onClose={_handleShareMenuClose}
            PaperProps={{
              sx: {
                bgcolor: 'background.default',
                maxWidth: 320,
              },
            }}>
            <SingleSendCreateButton
              disabled={!_videoPlaylist.checkedList.length}
              variant="menu-item"
              onClose={_handleShareMenuClose}
              events={_selectedEventIDs}
            />
            <AutomationCreateButton
              type="opponent-automation"
              onClose={_handleShareMenuClose}
              optionalActionInfo={_video.actionInfo || undefined}
              Button={
                <MenuItem sx={{whiteSpace: 'normal'}}>
                  <ListItemIcon>
                    <ScheduleSendIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={t('eventAutomations.opponentAutomationsNew')}
                    secondary={t('eventAutomations.opponentAutomationsIntro')}
                  />
                </MenuItem>
              }
            />
          </Menu>
        </div>
      )}
      {_canDownload && (
        <div className={styles.playlistBarDownloadIcon}>
          {_videoPlaylist.downloading ? (
            <div className={styles.downloadSpinner}>
              <CircularProgress size={16} />
            </div>
          ) : (
            <IconButton
              color="secondary"
              onClick={_videoPlaylist.downloadSelected}
              disabled={
                _videoPlaylist.isDownloadingDisabled ||
                _videoPlaylist.downloading
              }
              title={t('video-player.button.download.title', {
                count: DOWNLOAD_LIMIT,
              })}>
              <DownloadIcon />
            </IconButton>
          )}
          <span className={styles.playlistBarSelectedVideos}>
            (
            <span className={_downloadError}>
              {_videoPlaylist.checkedList.length}
            </span>
            /{DOWNLOAD_LIMIT})
          </span>
        </div>
      )}
    </div>
  );
}

export default PlaylistBarOptions;
