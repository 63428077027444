import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  DEFAULT_VIDEO_SOURCE,
  FICompactMatchInfo,
  FIEventAutomationPostData,
  FIMatch,
  FIMatchEvent,
  getVideoSourceInfoForMatch,
  isTrackingAction,
  TActionType,
  TMatchVideo,
  TVideoSourceType,
} from '@my-game-plan/types';

import ReactPlayer from 'react-player';
import screenfull from 'screenfull';
import {findDOMNode} from 'react-dom';
import {IClipResponse, TClipRequestMode} from '@/types/clips.types';
import {
  DEFAULT_CLIP_AFTER_OFFSET,
  DEFAULT_CLIP_BEFORE_OFFSET,
  MAX_CLIP_OFFSET,
  MAX_EDIT_OFFSET,
} from '@/config/clips.config';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {useAuth} from '@/context/auth.context';
import {getVideo} from '@/controllers/video/video.controller';
import {useSnackbar} from 'notistack';
import {useAnalytics} from '../analytics.context';
import {IWatchedVideoProps} from '@/config/analytics/events.config';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';
import {getAvailableSourcesForMatch} from '@/helpers/video.helper';

export interface IClipConfig {
  isHLSEnabled: boolean;
  clipUrl: string;
}

export interface Played {
  played: number;
  playedSeconds: number;
  loaded: number;
}

export interface VideoAPI {
  videoInstance: ReactPlayer | null;
  setVideoInstance: (state: ReactPlayer | null) => void;
  videoElement: HTMLDivElement | null;
  setVideoElement: (state: HTMLDivElement | null) => void;
  title: string;
  playlist: FIMatchEvent[];
  matches: FIMatch[] | FICompactMatchInfo[];
  currentClip: FIMatchEvent;
  videoSource: TVideoSourceType | null;
  changeVideoSource: (source: TVideoSourceType) => void;
  videoSourceConfig: TMatchVideo | null;
  openPlaylistBar: boolean;
  relative: boolean;
  canShare: boolean;
  setTitle: (title: string) => void;
  setOpenPlaylistBar: (openPlaylistBar: boolean) => void;
  toggleAutoPlay: (e: React.ChangeEvent<HTMLInputElement>) => void;
  autoplay: boolean;
  playClip: (clip: FIMatchEvent) => void;
  previousClip: () => void;
  nextClip: () => void;

  actionInfo: Partial<FIEventAutomationPostData> | null;
  setActionInfo: (state: Partial<FIEventAutomationPostData> | null) => void;
  flipVideoState: () => void;
  playing: boolean;
  fullscreen: () => void;
  volume: number;
  setVolume: (state: number) => void;
  muted: boolean;
  setMuted: (state: boolean) => void;
  played: Played;
  setPlayed: (state: Played) => void;
  setTime: (time: number) => void;
  duration: number;
  setDuration: (time: number) => void;
  isEditMenuOpen: boolean;
  setIsEditMenuOpen: (time: boolean) => void;
  setPlaying: (playing: boolean) => void;
  openVideoPlayer: (
    events: FIMatchEvent[],
    matches: FIMatch[] | FICompactMatchInfo[],
    relative?: boolean,
    canShare?: boolean,
    actionInfo?: Partial<FIEventAutomationPostData>,
    startEvent?: FIMatchEvent,
    isShowingSequences?: boolean,
  ) => void;
  closeVideoPlayer: () => void;
  isVideoPlayerOpen: boolean;
  videoBufferingStatus: 'complete' | 'buffering' | 'settingTime';
  setVideoBufferingStatus: (
    bufferingStatus: 'complete' | 'buffering' | 'settingTime',
  ) => void;
  error: any;
  isVideoReady: boolean;
  setIsVideoReady: (isReady: boolean) => void;
  videoOffsets: [number, number];
  setVideoOffsets: (newOffsets: [number, number]) => void;
  fetchNewClip: (
    offsets: [number, number] | null,
    clip: FIMatchEvent,
    requestMode: TClipRequestMode,
  ) => Promise<IClipResponse | null>;
  setError: (error: string) => void;
  clipConfig: IClipConfig;
  setDefaultVideoSource: (source: TVideoSourceType) => void;
  isShowingSequences: boolean;
  eventsPerSequence: Record<string, FIMatchEvent[]>;
}

const context = createCustomContext<VideoAPI>();
export const useVideo = createContextHook(context);

export const VideoContextProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  const {enqueueSnackbar} = useSnackbar();
  const _analyticsContext = useAnalytics();

  const [_videoInstance, _setVideoInstance] = useState<ReactPlayer | null>(
    null,
  );
  const [_videoElement, _setVideoElement] = useState<HTMLDivElement | null>(
    null,
  );
  const [_title, _setTitle] = useState<string>('');
  const [_playing, _setPlaying] = useState<boolean>(false);
  const [_playlist, _setPlaylist] = useState<FIMatchEvent[]>([]);
  const [_initialEvent, _setInitialEvent] = useState<FIMatchEvent | null>(null);
  const [_isInitialised, _setIsInitialised] = useState<boolean>(false);
  const [_matches, _setMatches] = useState<FIMatch[] | FICompactMatchInfo[]>(
    [],
  );
  const [_currentClip, _setCurrentClip] = useState<FIMatchEvent>(_playlist[0]);
  const [_eventsPerSequence, _setEventsPerSequence] = useState<
    Record<string, FIMatchEvent[]>
  >({});
  const [_currentClipSources, _setCurrentClipSources] =
    useState<TMatchVideo | null>(null);

  const [_autoPlay, _setAutoPlay] = useState<boolean>(true);
  const [_openPlaylistBar, _setOpenPlaylistBar] = React.useState(false);
  const [_relative, _setRelative] = React.useState(false);
  const [_canShare, _setCanShare] = React.useState(false);
  const [_actionInfo, _setActionInfo] =
    useState<Partial<FIEventAutomationPostData> | null>(null);
  const [_volume, _setVolume] = useState<number>(1);
  const [_muted, _setMuted] = useState<boolean>(true);
  const [_played, _setPlayed] = useState<Played>({
    played: 0,
    playedSeconds: 0,
    loaded: 0,
  });
  const [_duration, _setDuration] = useState<number>(0);
  const [_isEditMenuOpen, _setIsEditMenuOpen] = useState<boolean>(false);
  const _auth = useAuth();
  const [_videoPlayerOpen, _setVideoPlayerOpen] = useState<boolean>(false);
  const [_videoBufferingStatus, _setVideoBufferingStatus] = React.useState<
    'complete' | 'buffering' | 'settingTime'
  >('complete');
  const [_error, _setError] = React.useState<any>(null);
  const [_isVideoReady, _setIsVideoReady] = React.useState<boolean>(false);
  const [_isShowingSequences, _setIsShowingSequences] =
    React.useState<boolean>(false);

  // the video offsets stored in the db or [5, 10]
  const [_videoOffsets, _setVideoOffsets] = useState<[number, number]>([0, 0]);
  const [_videoSource, _setVideoSource] = useState<TVideoSourceType | null>(
    null,
  );
  const [_defaultVideoSource, _setDefaultVideoSource] =
    useState<TVideoSourceType>(DEFAULT_VIDEO_SOURCE);

  const [_clipConfig, _setClipConfig] = useState<IClipConfig>({
    isHLSEnabled: false,
    clipUrl: '',
  });

  useEffect(() => {
    if (_isVideoReady) {
      _setPlaying(true);
    }
  }, [_isVideoReady]);

  useEffect(() => {
    if (
      !_isInitialised &&
      _initialEvent &&
      _matches.length &&
      _playlist.length &&
      _actionInfo
    ) {
      _setIsInitialised(true);
      _playClip(_initialEvent, _actionInfo);
    }
  }, [_initialEvent, _isInitialised, _matches, _playlist, _actionInfo]);

  /* Functions */
  async function _prepareVideoPlayer(
    events: FIMatchEvent[],
    matches: FIMatch[] | FICompactMatchInfo[],
    relative: boolean,
    canShare: boolean,
    actionInfo?: Partial<FIEventAutomationPostData>,
    startEvent?: FIMatchEvent,
    isShowingSequences?: boolean,
  ) {
    if (events.length <= 0) {
      throw new Error('No events to play');
    }

    if (actionInfo) {
      _setActionInfo(actionInfo);
    }
    _setOpenPlaylistBar(false);
    _setMatches(matches);
    if (isShowingSequences) {
      const _newEventsPerSequence: Record<string, FIMatchEvent[]> = {};

      events.forEach((event) => {
        if (event.sequence) {
          if (!_newEventsPerSequence[event.sequence._id]) {
            _newEventsPerSequence[event.sequence._id] = [];
          }

          _newEventsPerSequence[event.sequence._id].push(event);
        }
      });
      _setEventsPerSequence(_newEventsPerSequence);
      const _onePerSequence: FIMatchEvent[] = [];
      const _includedSequences = new Set<string>();

      // Assuming _events is an array of objects with a 'sequence' property
      events.forEach((event) => {
        if (
          event.sequence?._id &&
          !_includedSequences.has(event.sequence?._id)
        ) {
          _onePerSequence.push(event);
          _includedSequences.add(event.sequence._id);
        }
      });
      _setPlaylist(_onePerSequence);
    } else {
      _setPlaylist(events);
    }
    _setRelative(relative);
    _setCanShare(canShare);

    /* Determine first event */
    /* If startEvent is provided, play that event */
    /* If video for startEvent is not available, play the first event with video */
    // /* If no startEvent is provided, play the first event with video */

    const _eventsWithVideo = events.filter((event) => {
      return matches.some(
        (match) => match._id === event.match._id && match.video,
      );
    });

    if (!_eventsWithVideo.length) {
      throw new Error('No events with video');
    }

    let _firstEvent = _eventsWithVideo[0];
    if (
      startEvent &&
      _eventsWithVideo.some((event) => event._id === startEvent._id)
    ) {
      _firstEvent = startEvent;
    }

    _setInitialEvent(_firstEvent);
  }

  function _flipVideoState() {
    _setPlaying(!_playing);
  }

  function _toggleAutoPlay(e: React.ChangeEvent<HTMLInputElement>) {
    _setAutoPlay(e.target.checked);
  }

  const _fetchNewClip = useCallback(
    async (
      offsets: [number, number] | null,
      clip: FIMatchEvent,
      requestMode: TClipRequestMode = 'initial',
      actionInfo?: Partial<FIEventAutomationPostData>,
      videoSource?: TVideoSourceType,
    ): Promise<IClipResponse | null> => {
      if (!_auth.user) return null;
      _setIsVideoReady(false);
      _setError(null);
      _setClipConfig({isHLSEnabled: false, clipUrl: ''});

      try {
        /* Set max timestamps if usre still manages to fetch more data than possible */
        let _offsetsToRequest: [number, number] | null = offsets
          ? [...offsets]
          : null;
        /* Set maxOffsets. We shouldn't allow more than [60,60] */
        const _maxOffset =
          requestMode === 'add-time' ? MAX_CLIP_OFFSET : MAX_EDIT_OFFSET;
        if (_offsetsToRequest) {
          _offsetsToRequest.forEach((offset, index) => {
            if (_offsetsToRequest && _offsetsToRequest[index] > _maxOffset) {
              _offsetsToRequest[index] = _maxOffset;
            }
          });
        }
        /* If event timestamp is below Minimum offset, offsets[0] should be 0 */
        if (clip.timestamp < DEFAULT_CLIP_BEFORE_OFFSET) {
          if (!_offsetsToRequest) {
            _offsetsToRequest = [
              DEFAULT_CLIP_BEFORE_OFFSET,
              DEFAULT_CLIP_AFTER_OFFSET,
            ];
          }
          _offsetsToRequest[0] = 0;
        }

        /* Actually fetch video */

        const _actionInfoForClip = actionInfo || _actionInfo;

        const _actionType: TActionType =
          _actionInfoForClip?.action &&
          isTrackingAction(_actionInfoForClip.action)
            ? 'tracking'
            : 'event';

        const _match = _matches.find((match) => match._id === clip.match._id);

        const _videoConfig = getVideoSourceInfoForMatch(
          _match?.video,
          videoSource || _videoSource || undefined,
        );

        /* Enable HLS for MGP streaming service */
        const _shouldEnableHLS = _videoConfig?.stream_service === 'mygameplan';

        const _fetchedClip = await getVideo(clip._id, _auth.user.team, {
          offsets: _offsetsToRequest,
          request_mode: requestMode,
          action_type: _actionType,
          source: videoSource || _videoSource || undefined,
        });

        if (_fetchedClip.clip_url) {
          if (requestMode === 'initial' || requestMode === 'done-editing')
            _setVideoOffsets(_fetchedClip.offsets);

          const _previousClipUrl = _clipConfig.clipUrl;

          /* Set clip for video player */
          _setClipConfig({
            isHLSEnabled: _shouldEnableHLS,
            clipUrl: _fetchedClip.clip_url,
          });

          if (_previousClipUrl === _fetchedClip.clip_url) {
            _setVideoBufferingStatus('complete');
            _setIsVideoReady(true);
          }
        } else {
          // TODO - If Handle error if clip url is empty.
          // - Something went wrong AKA we messed up
          // - User reached limit

          _setError(_fetchedClip?.error || new Error('Something went wrong'));
        }

        // if (requestMode === 'edit') {
        //   _setTime(0);
        // }

        /* Stop loading */
        return _fetchedClip;
      } catch (e: any) {
        _setError(e.message);
        throw new Error(e.message);
      }
    },
    [
      _auth.user,

      _setError,
      _setVideoOffsets,
      _clipConfig,
      _videoSource,
      _matches,
    ],
  );

  const _playClip = useCallback(
    async (
      clip: FIMatchEvent,
      actionInfo?: Partial<FIEventAutomationPostData>,
      videoSource?: TVideoSourceType,
    ) => {
      if (
        _isInitialised &&
        _currentClip?.sequence &&
        _isShowingSequences &&
        clip.sequence &&
        _currentClip.sequence?._id === clip.sequence?._id
      ) {
        let _seconds = clip.sequence?.start
          ? clip.timestamp - clip.sequence?.start - 5
          : 0;
        let _total;
        if (_videoOffsets) {
          _seconds += _videoOffsets[0];
          _total = _videoOffsets[0] + _videoOffsets[1];
        } else {
          _total = clip.sequence?.end - clip.sequence?.start;
        }
        _setTime(Math.max(0, _seconds / _total));
        return;
      }
      const _observingMatch = _matches.find(
        (match) => match._id === clip.match._id,
      );

      if (clip == null || !_observingMatch) return;

      let _videoSourceToFetch = videoSource || _defaultVideoSource;

      // If _videoSource hasnt been set before
      // and DEFAULT_VIDEO_SOURCE (= tactical) is not available,
      // set the first available video source
      if (!_observingMatch.video) {
        return;
      }
      const _sourcesWithVideo = getAvailableSourcesForMatch(
        _observingMatch.video,
      );
      if (
        _observingMatch.video &&
        _sourcesWithVideo.length &&
        !_videoSource &&
        !_sourcesWithVideo.includes(_videoSourceToFetch)
      ) {
        _videoSourceToFetch = _sourcesWithVideo[0];
      }

      _setVideoSource(_videoSourceToFetch);
      _setCurrentClipSources(_observingMatch.video || null);

      /* Set clip info and fetch actual video */
      _setCurrentClip(clip);
      let _offsets: [number, number] | null = null;
      if (_isShowingSequences && clip.sequence) {
        const _min = Math.max(0, 5 + clip.timestamp - clip.sequence.start);
        const _plus = Math.max(0, 5 + clip.sequence.end - clip.timestamp);
        _offsets = [_min, _plus];
        _setVideoOffsets(_offsets);
      }
      const _fetchedClip = await _fetchNewClip(
        _offsets,
        clip,
        'initial',
        actionInfo,
        _videoSourceToFetch,
      );

      const _mixpanelData: IWatchedVideoProps = {
        wyscout_id: clip.match._id,
        match_id: clip.match._id,
        minute: clip.minute,
        second: clip.second,
        video_source: _videoSourceToFetch,
      };

      if (_fetchedClip?.error) {
        _analyticsContext.trackEvent(ANALYTICS_EVENT.WATCHED_VIDEO_ERROR, {
          ..._mixpanelData,
          error: _fetchedClip?.error || null,
        });
      }

      if (!(_fetchedClip && _fetchedClip.clip_url)) return; //TODO maybe error message

      const _videoDuration = Math.round(
        _fetchedClip.offsets[0] + _fetchedClip.offsets[1],
      );

      _analyticsContext.trackEvent(ANALYTICS_EVENT.WATCHED_VIDEO, {
        ..._mixpanelData,
        duration: _videoDuration,
      });
    },
    [_currentClip, _fetchNewClip, _matches],
  );

  function _fullscreen() {
    if (screenfull.isFullscreen) screenfull.exit();
    else if (screenfull.isEnabled && _videoInstance) {
      // eslint-disable-next-line react/no-find-dom-node,@typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line react/no-find-dom-node
      screenfull.request(findDOMNode(_videoElement));
    }
  }

  function _setTime(time: number) {
    _videoInstance?.seekTo(time, 'fraction');
  }

  const _openVideoPlayer = (
    events: FIMatchEvent[],
    matches: FIMatch[] | FICompactMatchInfo[],
    relative = false,
    canShare = true,
    actionInfo?: Partial<FIEventAutomationPostData>,
    startEvent?: FIMatchEvent,
    isShowingSequences?: boolean,
  ) => {
    _setIsShowingSequences(!!isShowingSequences);

    // _setCurrentClip()

    _setClipConfig({
      isHLSEnabled: false,
      clipUrl: '',
    });

    if (events.length === 0) {
      enqueueSnackbar('No videos to display', {
        variant: 'info',
      });
      return;
    }
    _setVideoPlayerOpen(true);
    _prepareVideoPlayer(
      events,
      matches,
      relative,
      canShare,
      actionInfo,
      startEvent,
      isShowingSequences,
    ).catch(() => {
      enqueueSnackbar('Videos cannot be shown', {
        variant: 'info',
      });
      _setVideoPlayerOpen(false);
    });
  };
  function _closeVideoPlayer() {
    _setInitialEvent(null);
    _setIsInitialised(false);
    _setClipConfig({
      isHLSEnabled: false,
      clipUrl: '',
    });
    _setVideoPlayerOpen(false);
    _setVideoOffsets([0, 0]);
    _setActionInfo(null);
    _setError(null);
    _setIsEditMenuOpen(false);
    _setPlayed({played: 0, playedSeconds: 0, loaded: 0});
    _setVideoBufferingStatus('complete');
    _setIsVideoReady(false);
  }

  function _previousClip() {
    const currentIndex = _playlist.indexOf(_currentClip);
    let nextIndex = currentIndex - 1;
    if (nextIndex < 0) {
      nextIndex = _playlist.length - 1;
    }
    const nextClip = _playlist[nextIndex];
    _playClip(nextClip);
  }

  function _nextClip() {
    if (_playlist.length > 1) {
      const currentIndex = _playlist.indexOf(_currentClip);
      let nextIndex = currentIndex + 1;
      if (nextIndex >= _playlist.length) {
        nextIndex = 0;
      }
      const nextClip = _playlist[nextIndex];
      _playClip(nextClip);
    }
  }

  function _changeVideoSource(source: TVideoSourceType) {
    _analyticsContext.trackEvent(ANALYTICS_EVENT.SWITCHED_VIDEO_SOURCE, {
      video_source: source,
    });
    _playClip(_currentClip, undefined, source);
  }

  /* Render */
  return createProvider(context, props, {
    title: _title,
    playlist: _playlist,
    matches: _matches,
    currentClip: _currentClip,
    videoSource: _videoSource,
    changeVideoSource: _changeVideoSource,
    videoSourceConfig: _currentClipSources,
    openPlaylistBar: _openPlaylistBar,
    relative: _relative,
    canShare: _canShare,
    playing: _playing,
    setTitle: _setTitle,
    setOpenPlaylistBar: _setOpenPlaylistBar,
    toggleAutoPlay: _toggleAutoPlay,
    autoplay: _autoPlay,
    playClip: _playClip,
    actionInfo: _actionInfo,
    setActionInfo: _setActionInfo,
    flipVideoState: _flipVideoState,
    videoElement: _videoElement,
    setVideoElement: _setVideoElement,
    videoInstance: _videoInstance,
    setVideoInstance: _setVideoInstance,
    fullscreen: _fullscreen,
    volume: _volume,
    setVolume: _setVolume,
    muted: _muted,
    setMuted: _setMuted,
    played: _played,
    setPlayed: _setPlayed,
    setTime: _setTime,
    duration: _duration,
    setDuration: _setDuration,
    isEditMenuOpen: _isEditMenuOpen,
    setIsEditMenuOpen: _setIsEditMenuOpen,
    setPlaying: _setPlaying,
    openVideoPlayer: _openVideoPlayer,
    closeVideoPlayer: _closeVideoPlayer,
    isVideoPlayerOpen: _videoPlayerOpen,
    videoBufferingStatus: _videoBufferingStatus,
    setVideoBufferingStatus: _setVideoBufferingStatus,
    error: _error,
    setError: _setError,
    isVideoReady: _isVideoReady,
    setIsVideoReady: _setIsVideoReady,
    videoOffsets: _videoOffsets,
    setVideoOffsets: _setVideoOffsets,
    fetchNewClip: _fetchNewClip,
    previousClip: _previousClip,
    nextClip: _nextClip,
    clipConfig: _clipConfig,
    setDefaultVideoSource: _setDefaultVideoSource,
    isShowingSequences: _isShowingSequences,
    eventsPerSequence: _eventsPerSequence,
  });
};
