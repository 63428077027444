import React from 'react';
import {useTranslation} from 'react-i18next';
import {IDashboardScore} from '@/types/dashboard/dashboard-score.types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Indicator from '../Indicator';
import {getMinMaxSubscores} from '@/helpers/dashboard/dashboard.helper';
import {getTranslatedSubscoreProperty} from '@/helpers/translation.helper';

import {TeamDetails} from '@/components/common/team-details/TeamDetails';

interface IndicatorTableProps {
  scores: IDashboardScore[];
  action?: string;
}

export const IndicatorTable = ({scores, action}: IndicatorTableProps) => {
  const {t} = useTranslation();
  const subscoresMinMax = getMinMaxSubscores(scores);

  return (
    <TableContainer>
      <Table aria-label="simple table" sx={{tableLayout: 'fixed'}}>
        <TableHead>
          <TableRow>
            <TableCell sx={{width: 160}}>
              {t('dashboard.scores.table.team')}
            </TableCell>
            <TableCell align="center" sx={{width: 40}}>
              {t('dashboard.scores.table.score')}
            </TableCell>
            {scores[0].subscores?.map((subscore) => (
              <TableCell key={subscore.property} sx={{width: '100%'}}>
                {getTranslatedSubscoreProperty(action || '', subscore.property)}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {scores.map((score) => (
            <TableRow key={score.team.name}>
              <TableCell component="th" scope="row">
                <TeamDetails
                  _id={score.team._id}
                  name={score.team.name}
                  imageURL={score.team.image_url}
                  shouldTruncate
                  width={160}
                />
              </TableCell>
              <TableCell align="center" sx={{width: 40}}>
                {score.score}
              </TableCell>
              {score.subscores &&
                score.subscores.map((subscore) => (
                  <TableCell key={subscore.property} sx={{width: '100%'}}>
                    <Indicator
                      action={action}
                      value={subscore.events}
                      teamId={score.team._id}
                      min={subscoresMinMax[subscore.property].min}
                      max={subscoresMinMax[subscore.property].max}
                      scores={scores}
                      shouldFlipSubscore
                    />
                  </TableCell>
                ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
