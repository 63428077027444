import React, {useEffect} from 'react';

import {FISingleSend} from '@my-game-plan/types';

import {useAuth} from '@/context/auth.context';
import {useTranslation} from 'react-i18next';

import {useSingleSends} from '@/context/single-sends.context';

import SingleSendCard from '@/components/single-sends/single-send-card/single-send-card.view';
import OverviewCardsScreen from '@/components/overview-screen/single-sends/single-sends-cards-screen.view';
import {useAnalytics} from '@/context/analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

function SingleSendsView(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _auth = useAuth();
  const _analyticsContext = useAnalytics();
  const _singlesendsContext = useSingleSends();

  useEffect(() => {
    if (_auth.user) {
      _analyticsContext.trackEvent(ANALYTICS_EVENT.VIEWED_SINGLE_SENDS, {
        team_id: _auth.user.team,
      });

      _singlesendsContext.getAll();
    }

    return () => {
      _singlesendsContext.setIsInitialised(false);
    };
  }, [_auth.user]);

  /*
   * Handlers
   */
  function _keyExtractor(singleSend: FISingleSend): string {
    return singleSend._id;
  }

  function _renderCard(singleSend: FISingleSend): JSX.Element {
    return <SingleSendCard data={singleSend} />;
  }

  /*
   * Render
   */
  return (
    <OverviewCardsScreen
      data={_singlesendsContext.all}
      title={t('singleSends.title')}
      keyExtractor={_keyExtractor}
      renderCard={_renderCard}
      isLoading={_singlesendsContext.isLoading}
      isInitialised={_singlesendsContext.isInitialised}
      namespace="single-send"
      error={_singlesendsContext.hasError}
    />
  );
}

export default SingleSendsView;
