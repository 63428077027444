import {
  FIShotAnalysisCategory,
  FIShotAnalysisInsight,
  ShotCategory,
} from '@my-game-plan/types';
import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import {translateValue} from '@/helpers/translation.helper';
import {capitalize} from 'lodash';
import {useTranslation} from 'react-i18next';
import InsightDetailsGeneral from '../details/insight-details-general.view';
import {useShotAnalysis} from '@/context/shot-analysis.context';
import {useTeams} from '@/context/team.context';
import {CenteredSpinner} from '@/components/common/centered-spinner/CenteredSpinner';

interface InsightCardProps {
  shotCategory?: ShotCategory;
  insight?: FIShotAnalysisInsight;
  onViewDetails?: (shotCategory?: ShotCategory) => void;
  onPlayVideo?: (shotCategory?: ShotCategory) => void;
  onInsightChange: (direction: 'next' | 'previous') => void;
}

function InsightCard(props: InsightCardProps): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const [_data, _setData] = useState<FIShotAnalysisCategory | null>(null);
  const _shotAnalysisContext = useShotAnalysis();
  const _teamsContext = useTeams();

  useEffect(() => {
    let _newData: FIShotAnalysisCategory | null = null;
    if (props.shotCategory) {
      _newData =
        _shotAnalysisContext.analysis?.shot_categories.find(
          (cat) => cat.shot_category === props.shotCategory,
        ) || null;
    } else {
      _newData = _shotAnalysisContext.analysis?.total || null;
    }

    _setData(_newData);
  }, [props.shotCategory]);

  /*
   * Handlers
   */
  function _next() {
    props.onInsightChange('next');
  }

  function _prev() {
    props.onInsightChange('previous');
  }

  function _onDetailsClick() {
    if (props.onViewDetails) {
      props.onViewDetails(props.insight?.shot_category);
    }
  }

  function _onPlayVideo() {
    if (props.onPlayVideo) {
      props.onPlayVideo(props.insight?.shot_category);
    }
  }

  /*
   * Render
   */
  let _label = '';
  if (props.insight?.shot_category) {
    _label = capitalize(translateValue(props.insight.shot_category));
  } else if (props.insight) {
    _label = t(`shotAnalysis.total_${props.insight.result}`);
  }

  let _Content = null;

  if (props.insight && !_shotAnalysisContext.isLoadingInsights) {
    _Content = (
      <Stack gap={3} flex={1}>
        {/* Header */}
        <Stack direction="row" gap={1} alignItems="center">
          <LightbulbIcon color="warning" />
          <Typography flex={1}>{_label}</Typography>
          <Button size="small" onClick={_onDetailsClick}>
            {t('shotAnalysis.details.cta')}
          </Button>
        </Stack>
        {/* Main content */}
        <Stack flex={1}>
          {_data && (
            <InsightDetailsGeneral
              compact
              insight={props.insight}
              data={_data}
              team={_teamsContext.ownTeam || undefined}
              benchmarkLabel={_shotAnalysisContext.benchmarkLabel}
              onPlayVideo={_onPlayVideo}
            />
          )}
        </Stack>

        {/* Footer */}
        <Stack direction="row" justifyContent="space-between">
          <IconButton
            title={t('shotAnalysis.insight.previousInsight')}
            color="secondary"
            onClick={_prev}>
            <KeyboardArrowLeftIcon />
          </IconButton>
          <IconButton
            title={t('shotAnalysis.insight.nextInsight')}
            color="secondary"
            onClick={_next}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </Stack>
      </Stack>
    );
  } else if (_shotAnalysisContext.isLoadingInsights) {
    _Content = (
      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        gap={3}>
        <CenteredSpinner />
        <Typography sx={{color: 'secondary.main'}} fontSize={12}>
          {t('shotAnalysis.insight.loading')}
        </Typography>
      </Box>
    );
  } else if (!props.insight) {
    _Content = (
      <Box
        width="100%"
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Typography sx={{color: 'text.disabled'}}>
          {t('shotAnalysis.insight.empty')}
        </Typography>
      </Box>
    );
  }
  return <Card sx={{width: 320, p: 2, display: 'flex'}}>{_Content}</Card>;
}

export default InsightCard;
