export enum LOGIN_ERRORS {
  DEFAULT = 'default',
  AUTH_INCORRECT_CREDENTIALS = 'auth-incorrect-credentials',
  NOT_ANALYST = 'not-analyst',
  USER_NOT_ACTIVE = 'user-not-active',
  TEAM_NOT_ACTIVE = 'team-not-active',
  TEAM_NO_MATCHES = 'team-no-matches',
}

export const REQUEST_ERRORS = {
  NO_DATA: 'no-data',
  NO_SESSION: 'no-session',
};

export const HLS_ERROR = 'hlsError';
