import React from 'react';
import {PlayerDetails, SizeType} from '../player-details/PlayerDetails';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import styles from './ReceivingPlayerDetails.module.scss';
import {FIDisplayPlayer} from '@my-game-plan/types';

interface ReceivingPlayersDetailsProps {
  player: FIDisplayPlayer;
  receivingPlayer: FIDisplayPlayer;
  shouldTruncate?: boolean;
  size?: SizeType;
}

export const ReceivingPlayersDetails = ({
  player,
  receivingPlayer,
  shouldTruncate = false,
  size,
}: ReceivingPlayersDetailsProps) => {
  return (
    <div className={styles.container}>
      <PlayerDetails
        player={player}
        shouldTruncate={shouldTruncate}
        size={size}
      />
      <KeyboardArrowRightIcon fontSize="small" color="secondary" />
      <PlayerDetails
        player={receivingPlayer}
        shouldTruncate={shouldTruncate}
        size={size}
      />
    </div>
  );
};
