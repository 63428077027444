import {DynamicTableStateless} from '@atlaskit/dynamic-table';
import _ from 'lodash';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {translateValue} from '@/helpers/translation.helper';
import {
  IPlayerStat,
  IPlayerStatsTableColumn,
  THighlightedCell,
  TRowClickPlayers,
} from '@/types/dashboard/dashboard-player-stats.types';
import styles from './stylesheet/players-list.module.scss';
import {
  createPlayersStatsHead,
  rows,
} from '@/helpers/dashboard/create_dashboard.helper';
import {extendRows} from '@/helpers/dashboard/dashboard.helper';
import {useDashboard} from '@/context/dashboard/dashboard.context';

const RegularTable = ({
  tableKey,
  playersList,
  columns,
  tablesCount,
  isInteractive,
}: {
  tableKey: string;
  playersList: IPlayerStat[];
  columns: IPlayerStatsTableColumn[];
  tablesCount: number;
  isInteractive?: boolean;
}) => {
  const {t} = useTranslation();
  const {
    updateFilters,
    clearFilters,
    highlighted,
    updateHighlightedRows,
    clearHighlightedRows,
    updateHighlightedColumn,
    updateHighlightedCell,
  } = useDashboard();

  const highlightedRows = highlighted[tableKey]?.rows;
  const highlightedColumnIndex = highlighted[tableKey]?.column;
  const highlightedCell = highlighted[tableKey]?.cell;

  function isCellHighlighted() {
    const {column, row} = highlightedCell;
    return column !== -1 && row !== -1;
  }

  const onRowClick = (
    rowIndex: number,
    players: TRowClickPlayers,
    labels: string[],
  ) => {
    let keepPlayers = true;
    let keepFilters = false;

    // If cell was selected before selecting row, do not keep the players selected
    if (isCellHighlighted()) {
      updateHighlightedCell(tableKey, {column: -1, row: -1});
      keepPlayers = false;
    }

    // If column selected before selecting row, keep the current filters
    if (highlightedColumnIndex !== -1) {
      keepFilters = true;
    }

    // Set row and filter
    updateHighlightedRows(tableKey, rowIndex);
    updateFilters(
      tableKey,
      {players: players},
      {players: labels},
      keepPlayers,
      keepFilters,
    );
  };

  const onColumnClick = (columnIndex: number) => {
    // If same column is selected
    if (columnIndex === highlightedColumnIndex) {
      updateHighlightedColumn(tableKey, -1);
      clearFilters(tableKey, true);
      return;
    }

    // If cell was selected before selecting row, do not keep the players selected
    let keepPlayers = true;
    if (isCellHighlighted()) {
      updateHighlightedCell(tableKey, {column: -1, row: -1});
      keepPlayers = false;
    }

    // Set column and filter
    updateHighlightedColumn(tableKey, columnIndex);
    if (columnIndex !== -1) {
      const filters = columns[columnIndex].filters;
      const label = columns[columnIndex].label;

      if (filters !== undefined) {
        updateFilters(tableKey, filters, {qualifier: label}, keepPlayers);
        return;
      }

      // If selected column is "total" (no filters)
      clearFilters(tableKey, true);
    }
  };

  const onCellClick = (cell: THighlightedCell) => {
    clearHighlightedRows(tableKey);
    updateHighlightedColumn(tableKey, -1);

    // If cell already selected, clear filters
    if (_.isEqual(cell, highlightedCell)) {
      updateHighlightedCell(tableKey, {column: -1, row: -1});
      clearFilters(tableKey);
      return;
    }

    // Set cell and filter
    const {column, row} = cell;
    updateHighlightedCell(tableKey, cell);

    if (column !== undefined && row !== undefined) {
      const filters = columns[column].filters;
      const label = columns[column].label;
      const rowData = playersList[row];

      const players: TRowClickPlayers = {
        player: rowData.player,
        receiving_player: rowData.receiving_player,
      };

      if (filters !== undefined) {
        updateFilters(
          tableKey,
          {
            ...filters,
            players: players,
          },
          {
            qualifier: label,
          },
        );
        return;
      }

      // If selected column is "total" (no filters)
      updateFilters(
        tableKey,
        {
          players: players,
        },
        {
          qualifier: label,
        },
      );
    }
  };

  const isRowSelected = highlightedRows?.length > 0;

  const createdRows = rows({
    columns,
    playersList,
    onRowClick,
    onCellClick,
    highlightedColumnIndex,
    highlightedCell,
    isRowSelected,
    tablesCount,
    isInteractive,
  });

  return (
    <DynamicTableStateless
      head={createPlayersStatsHead({
        columns,
        onColumnClick,
      })}
      highlightedRowIndex={highlightedRows}
      rows={extendRows(createdRows, () => null)}
      emptyView={
        <span className={styles.empty}>
          {t('dashboard.player-statistics.no-data')}
        </span>
      }
    />
  );
};

interface PlayerTableProps {
  tableKey: string;
  header?: string;
  data: IPlayerStat[];
  columns: IPlayerStatsTableColumn[];
  tablesCount: number;
  hasBorders?: boolean;
  isInteractive?: boolean;
}

export const PlayersTable = ({
  tableKey,
  header,
  data,
  columns,
  tablesCount,
  hasBorders = true,
  isInteractive,
}: PlayerTableProps) => {
  return (
    <div className={styles.table}>
      {header && tablesCount > 1 && <h4>{translateValue(header)}</h4>}
      <div className={`${styles.content} ${!hasBorders && 'no-table-borders'}`}>
        <RegularTable
          tableKey={tableKey}
          playersList={data}
          columns={columns}
          tablesCount={tablesCount}
          isInteractive={isInteractive}
        />
      </div>
    </div>
  );
};
