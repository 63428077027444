import {SxProps} from '@mui/material';
import {Theme} from '@mui/system';

export const typeIconStyles: SxProps<Theme> = {
  width: 0,
  whiteSpace: 'nowrap',
};

export const titleTextStyles: SxProps<Theme> = {
  display: 'block',
};
export const titleDescriptionTextStyles: SxProps<Theme> = {
  display: 'block',
  maxWidth: 280,
};
