import React from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import ListItemIcon from '@mui/material/ListItemIcon';
import LoadingButton from '@mui/lab/LoadingButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';

import DeleteIcon from '@mui/icons-material/Delete';

import {useAuth} from '@/context/auth.context';
import {LOADING_STATE} from '@/types/screen.types';
import {
  FIPlayerObjectiveComment,
  PLAYER_OBJECTIVE_STATUSES,
} from '@my-game-plan/types';
import {useFormik} from 'formik';
import {useConfirm} from 'material-ui-confirm';
import {useSnackbar} from 'notistack';
import {useTranslation} from 'react-i18next';
import PlayerObjectiveStatusIcon from './player-objective-status-icon.view';
import ListItemText from '@mui/material/ListItemText';
import {usePlayerDetail} from '@/context/player-detail.context';
import {
  createObjectiveComment,
  deleteObjectiveComment,
  editObjectiveComment,
} from '@/controllers/player-objectives.controller';
import {DELETE_MODAL_CONFIG} from '@/config/modal.config';
import {useAnalytics} from '@/context/analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

interface IPlayerObjectiveCommentCreateFormProps {
  onClose: () => void;
  data?: FIPlayerObjectiveComment;
  isOpen: boolean;
  objectiveId: string;
}

function PlayerObjectiveCommentCreateForm(
  props: IPlayerObjectiveCommentCreateFormProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _authContext = useAuth();
  const _snackbar = useSnackbar();
  const _playerDetailContext = usePlayerDetail();
  const _analyticsContext = useAnalytics();
  const _confirm = useConfirm();
  const _formik = useFormik<Partial<FIPlayerObjectiveComment>>({
    initialValues: {
      text: props.data?.text || '',
      status: props.data?.status || undefined,
      created_by: _authContext.user?._id,
    },
    onSubmit: _onSubmit,
    enableReinitialize: true,
  });

  const [_loadingState, _setLoadingState] = React.useState<LOADING_STATE>(
    LOADING_STATE.INITING,
  );

  /*
   * Handlers
   */
  async function _onSubmit() {
    try {
      _setLoadingState(LOADING_STATE.LOADING);
      if (props.data) {
        const _updatedObjective = await editObjectiveComment(
          props.objectiveId,
          props.data._id,
          _formik.values,
        );

        _analyticsContext.trackEvent(
          ANALYTICS_EVENT.ADDED_PLAYER_OBJECTIVE_COMMENT,
          {
            objective_id: props.objectiveId,
            player_id: _updatedObjective.player_id,
            category: _updatedObjective.category,
            comment: _formik.values.text || '',
            status: _formik.values.status || undefined,
          },
        );
      } else {
        await createObjectiveComment(props.objectiveId, _formik.values);
      }
      await _playerDetailContext.fetchObjectives();

      const _msg = props.data
        ? t('playerObjectives.comments.success.edit')
        : t('playerObjectives.comments.success.create');
      _snackbar.enqueueSnackbar(_msg, {variant: 'success'});

      _onDiscard();
    } catch (error) {
      _setLoadingState(LOADING_STATE.INITING);
      const _msg = props.data
        ? t('playerObjectives.comments.error.edit')
        : t('playerObjectives.comments.error.create');
      _snackbar.enqueueSnackbar(_msg, {variant: 'error'});
    }
  }

  function _onDiscard() {
    //
    _formik.resetForm();
    _setLoadingState(LOADING_STATE.INITING);
    props.onClose();
  }

  async function _onDelete() {
    //
    if (!props.data) {
      return;
    }

    try {
      await _confirm({
        ...DELETE_MODAL_CONFIG,
        title: t('playerObjectives.comments.deleteWarning.title'),
        confirmationText: t('playerObjectives.comments.deleteWarning.text'),
        description: t('playerObjectives.comments.deleteWarning.description'),
      });
    } catch (error) {
      // user cancelled
    }

    try {
      await deleteObjectiveComment(props.objectiveId, props.data._id);
      _snackbar.enqueueSnackbar(t('playerObjectives.comments.success.delete'), {
        variant: 'success',
      });
      await _playerDetailContext.fetchObjectives();
      _onDiscard();
    } catch (error) {
      _snackbar.enqueueSnackbar(t('playerObjectives.comments.error.delete'), {
        variant: 'error',
      });
    }
  }

  /*
   * Render
   */

  const _title = props.data
    ? t('playerObjectives.comments.edit')
    : t('playerObjectives.comments.new');

  const _submitButtonText = props.data
    ? t('playerObjectives.comments.save')
    : t('playerObjectives.comments.create');
  return (
    <Dialog
      open={props.isOpen}
      onClose={_onDiscard}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        component: 'form',
        onSubmit: _formik.handleSubmit,
      }}>
      <DialogTitle>{_title}</DialogTitle>
      <DialogContent>
        <Stack gap={3} pt={1}>
          {/* Status */}
          <FormControl fullWidth>
            <InputLabel id="status-label">
              {t('playerObjectives.fields.status')}
            </InputLabel>
            <Select
              labelId="status-label"
              label={t('playerObjectives.fields.status')}
              name="status"
              onChange={_formik.handleChange}
              value={_formik.values.status || ''}
              onBlur={_formik.handleBlur}
              error={_formik.touched.status && Boolean(_formik.errors.status)}
              SelectDisplayProps={{
                style: {display: 'flex', alignItems: 'center'},
              }}
              inputProps={{
                MenuProps: {
                  MenuListProps: {
                    sx: {bgcolor: 'background.default'},
                  },
                },
              }}>
              {PLAYER_OBJECTIVE_STATUSES.map((status) => {
                return (
                  <MenuItem key={status} value={status}>
                    <ListItemIcon>
                      <PlayerObjectiveStatusIcon status={status} />
                    </ListItemIcon>
                    <ListItemText
                      sx={{m: 0}}
                      primary={t(`playerObjectives.status.${status}`)}
                    />
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* Comment  */}
          <TextField
            multiline
            fullWidth
            minRows={4}
            label={t('playerObjectives.fields.comment')}
            placeholder={t('playerObjectives.fields.comment')}
            name="text"
            onChange={_formik.handleChange}
            value={_formik.values.text}
            onBlur={_formik.handleBlur}
            error={_formik.touched.text && Boolean(_formik.errors.text)}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Box style={{flex: 1}}>
          {Boolean(props.data) && (
            <IconButton
              color="secondary"
              title={t('playerObjectives.delete')}
              onClick={_onDelete}>
              <DeleteIcon />
            </IconButton>
          )}
        </Box>
        <Button onClick={_onDiscard} color="secondary">
          {t('general.cancel')}
        </Button>
        <LoadingButton
          type="submit"
          variant="contained"
          loading={_loadingState === LOADING_STATE.LOADING}>
          {_submitButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default PlayerObjectiveCommentCreateForm;
