import {COLORS} from '@/config/style-variables.config';
import React from 'react';
import {useTranslation} from 'react-i18next';

export const Privacy = () => {
  const {t} = useTranslation();

  const body = t('privacy.body');
  const and = t('privacy.and');

  return (
    <p>
      {body} <PrivacyPolicy /> {and} <TermsAndConditions />.
    </p>
  );
};

const PrivacyPolicy = () => {
  const {t} = useTranslation();

  return (
    <em>
      <a
        href="https://www.mygameplan.ai/privacy-policy"
        target="_blank"
        rel="noreferrer"
        style={{color: COLORS.accent}}>
        {t('privacy.privacy-policy')}
      </a>
    </em>
  );
};

const TermsAndConditions = () => {
  const {t} = useTranslation();

  return (
    <em>
      <a
        href="https://www.mygameplan.ai/terms-and-conditions"
        target="_blank"
        rel="noreferrer"
        style={{color: COLORS.accent}}>
        {t('privacy.terms-and-conditions')}
      </a>
    </em>
  );
};
