import React from 'react';

import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import {useFileUploads} from '@/context/file-uploads.context';
import {useTranslation} from 'react-i18next';

import FilesTableRow from './files-table-row.view';
import {Link, useLocation} from 'react-router-dom';

function FilesTable() {
  /*
   * Hooks n State
   */
  const _filesContext = useFileUploads();

  /*
   * Handlers
   */
  const {t} = useTranslation();
  const _location = useLocation();

  /*
   * Render
   */

  let _Pagination = null;
  if (
    _filesContext.pagination?.total_pages &&
    _filesContext.pagination.total_pages > 1
  ) {
    _Pagination = (
      <Pagination
        page={_filesContext.pagination.current_page}
        count={_filesContext.pagination.total_pages}
        sx={{display: 'flex', justifyContent: 'center', width: '100%'}}
        renderItem={(item) => {
          const _searchParams = new URLSearchParams(_location.search);
          if (item.page) {
            _searchParams.set('page', item.page.toString());
          }
          const _to = `${_location.pathname}?${_searchParams.toString()}`;
          return (
            <PaginationItem
              {...item}
              sx={{display: 'flex', alignItems: 'center'}}
              component={Link}
              to={_to}
            />
          );
        }}
      />
    );
  }
  return (
    <Stack gap={5}>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                '& .MuiTableCell-root': {
                  py: 0,
                  pb: 1,
                },
              }}>
              <TableCell></TableCell>
              <TableCell>{t('uploads.fields.title')}</TableCell>
              <TableCell>{t('uploads.fields.category')}</TableCell>
              <TableCell>
                {t('uploads.fields.sharedWithPlayersShort')}
              </TableCell>
              <TableCell>{t('uploads.fields.uploadedAt')}</TableCell>
              <TableCell>{t('uploads.fields.expirationDateShort')}</TableCell>
              <TableCell>{t('uploads.actions.title')}</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_filesContext.files.map((file) => {
              return <FilesTableRow key={file._id} file={file} />;
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {_Pagination}
    </Stack>
  );
}

export default FilesTable;
