import React, {useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';

import {
  NameType,
  ValueType,
} from 'recharts/types/component/DefaultTooltipContent';
import {TooltipProps} from 'recharts/types/component/Tooltip';
import {
  Brush,
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  FIAutomationPlayerMatch,
  FIPhysicalMatch,
  IFormattedAutomationPlayerScore,
} from '@my-game-plan/types';
import {useTheme} from '@mui/material';
import {getXAxisTick} from './historyical-trend.helpers';
import {useAuth} from '@/context/auth.context';
import {useTranslation} from 'react-i18next';

interface IHistoricalBenchmark {
  key: string;
  color: string;
  value: number;
}
export interface IHistoricalTrendLine {
  key: string;
  name: string;
  color: string;
  benchmark?: IHistoricalBenchmark;
  scores: IFormattedAutomationPlayerScore[];
}

interface IHistoricalTrendProps<T> {
  data: T[];
  // handleTooltip?: ContentType<ValueType, NameType>;
  handleTooltip?: (
    props: TooltipProps<ValueType, NameType>,
    teamId?: string,
  ) => JSX.Element | null;
  lines: IHistoricalTrendLine[];
  height?: number;
  observingTeamId?: string;
  observingMatchIds?: string[];
  dataLabel?: string;
  valueUnit?: string;
}

const SLIDER_MATCH_LIMIT = 12;
const DEFAULT_HEIGHT = 400;

function HistoricalTrend<T extends FIAutomationPlayerMatch | FIPhysicalMatch>(
  props: IHistoricalTrendProps<T>,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {palette} = useTheme();
  const _authContext = useAuth();
  const {t} = useTranslation();

  const [_chartKey, _setChartKey] = useState<number>(0);
  const [_data, _setData] = useState<T[]>([]);
  const [_shouldDisplaySlider, _setShouldDisplaySlider] =
    useState<boolean>(false);
  const [_sliderValues, _setSliderValues] = useState<number[]>([0, 0]);

  /* Calculate data based on props */
  useEffect(() => {
    const _newData = [...props.data];

    if (_newData.length > SLIDER_MATCH_LIMIT) {
      _setShouldDisplaySlider(true);
      _setSliderValues([
        _newData.length - SLIDER_MATCH_LIMIT,
        _newData.length - 1,
      ]);
    } else {
      _setSliderValues([0, _newData.length - 1]);
    }

    _setData(_newData);
  }, [props.data]);

  /* HACK: Increment chart key to force re-render */
  useEffect(() => {
    _setChartKey((previousKey) => previousKey + 1);
  }, [_sliderValues]);

  /*
   * Handlers
   */
  function _onSliderChange(event: Event, newValue: number | number[]) {
    if (Array.isArray(newValue)) {
      _setSliderValues(newValue);
    }
  }

  function _handleTooltip(
    tooltipProps: TooltipProps<ValueType, NameType>,
  ): JSX.Element | null {
    if (!props.handleTooltip) {
      return null;
    }

    return props.handleTooltip(
      tooltipProps,
      props.observingTeamId || _authContext.user?.team,
    );
  }

  /*
   * Render
   */

  const _visibleMatchesCount = _shouldDisplaySlider
    ? _sliderValues[1] - _sliderValues[0] + 1
    : _data.length;

  const _axisColor = palette.secondary.main;
  const _gridColor = palette.secondary.dark;

  let _yAxisLabel = '';
  if (props.dataLabel) {
    _yAxisLabel = props.dataLabel;

    if (props.valueUnit) {
      _yAxisLabel += ` (${props.valueUnit})`;
    }
  }
  return (
    <Stack spacing={2}>
      <Box flex={1}>
        <ResponsiveContainer
          width="99%"
          height={props.height || DEFAULT_HEIGHT}>
          <LineChart key={_chartKey} data={_data} margin={{top: 16}}>
            <CartesianGrid
              stroke={_gridColor}
              opacity={0.5}
              vertical={false}
              strokeDasharray={4}
            />
            <YAxis
              stroke={_axisColor}
              axisLine={false}
              tickLine={false}
              tick={{fontSize: 12}}>
              {_yAxisLabel && (
                <Label
                  fontSize={10}
                  fontStyle={'italic'}
                  fill={_axisColor}
                  angle={-90}
                  value={_yAxisLabel}
                  position="insideLeft"
                  offset={10}
                />
              )}
            </YAxis>
            <XAxis
              dataKey="match._id"
              axisLine={false}
              tickLine={false}
              interval={0}
              // fontSize={12}
              tickMargin={16}
              padding={{left: 16, right: 16}}
              minTickGap={-_data.length}
              tick={(tick) => {
                return getXAxisTick(
                  tick,
                  _data,
                  props.observingTeamId || _authContext.user?.team,
                  _visibleMatchesCount,
                  props.observingMatchIds?.includes(tick.payload.value),
                  'domestic_league',
                );
              }}
            />
            {/* <Line
              dataKey={props.datakey}
              stroke={palette.common.white}
              type="linear"
              strokeWidth={1}
              animationDuration={400}
            /> */}
            <Tooltip
              cursor={{strokeWidth: 1, strokeDasharray: 4, stroke: _axisColor}}
              content={_handleTooltip}
            />
            {props.lines.map((line) => {
              return (
                <React.Fragment key={line.key as string}>
                  <Line
                    dataKey={line.key as string}
                    stroke={line.color}
                    type="linear"
                    strokeWidth={1}
                    animationDuration={400}
                    dot={{fill: line.color}}
                    name={line.name}
                  />
                  {typeof line.benchmark !== 'undefined' && (
                    <ReferenceLine
                      stroke={line.benchmark.color}
                      type="linear"
                      strokeDasharray={6}
                      strokeWidth={1}
                      ifOverflow="extendDomain"
                      y={line.benchmark.value}
                      opacity={0.6}
                    />
                  )}
                </React.Fragment>
              );
            })}
            {/* {_shouldDisplaySlider && (
              <Brush
                dataKey="match.match_day"
                height={0}
                stroke="none"
                startIndex={_sliderValues[0]}
                endIndex={_sliderValues[1]}
                />
                )} */}
            <Brush
              dataKey="match.match_day"
              height={0}
              stroke="none"
              startIndex={_sliderValues[0]}
              endIndex={_sliderValues[1]}
            />
          </LineChart>
        </ResponsiveContainer>
      </Box>
      {_shouldDisplaySlider && (
        <Stack spacing={-0.5} pl={2}>
          <Typography fontSize={12} color="secondary.main" fontWeight={500}>
            {t('eventAutomations.trend.showingMatchday', {
              matchday1: _data[_sliderValues[0]]?.match.match_day,
              matchday2: _data[_sliderValues[1]]?.match.match_day,
            })}
          </Typography>
          <Slider
            color="secondary"
            sx={{
              '& .MuiSlider-valueLabel': {
                backgroundColor: 'background.paper',
                padding: '6px 12px',
              },
            }}
            onChange={_onSliderChange}
            value={_sliderValues}
            valueLabelDisplay="auto"
            size="small"
            min={0}
            max={_data.length - 1}
            step={1}
            valueLabelFormat={(value) => {
              const _matchDay = _data[value].match?.match_day;
              return _matchDay;
              // return `${value + 1}`;
            }}
          />
        </Stack>
      )}
    </Stack>
  );
}

export default HistoricalTrend;
