import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {DOWNLOAD_LIMIT} from '@/config/clips.config';
import {useTranslation} from 'react-i18next';
import {
  getAvailableSourcesForMatch,
  groupEventsOnMatchID,
  sortEvents,
} from '@/helpers/video.helper';
import {useVideo} from '@/context/video/video.context';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';
import {useAuth} from '@/context/auth.context';
import {downloadVideos} from '@/controllers/video/video.controller';
import {FIMatchEvent} from '@my-game-plan/types';
import {useSnackbar} from 'notistack';

import {useAnalytics} from '../analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

export interface VideoPlaylist {
  checkedList: FIMatchEvent[];
  setCheckedList: (checkedList: FIMatchEvent[]) => void;
  isDownloadingDisabled: boolean;
  downloading: boolean;
  toggleSelectAll: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleSelect: (
    e: React.ChangeEvent<HTMLInputElement>,
    clips: FIMatchEvent[],
  ) => void;
  downloadSelected: () => void;
  matchesWithEvents: [string, FIMatchEvent[]][];
}

const context = createCustomContext<VideoPlaylist>();
export const useVideoPlaylist = createContextHook(context);

export const VideoPlaylistProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  const [_isDownloadingDisabled, _setIsDownloadingDisabled] =
    React.useState(false);
  const [_downloading, _setDownloading] = React.useState(false);
  const [_checkedList, _setCheckedList] = useState<FIMatchEvent[]>([]);
  const [_sortedMatchesWithEvents, _setSortedMatchesWithEvents] = useState<
    [string, FIMatchEvent[]][]
  >([]);

  const video = useVideo();
  const _auth = useAuth();
  const _analyticsContext = useAnalytics();

  const {t} = useTranslation();
  const {enqueueSnackbar} = useSnackbar();

  useEffect(() => {
    const isDisabled =
      _checkedList.length <= 0 || _checkedList.length > DOWNLOAD_LIMIT;

    _setIsDownloadingDisabled(isDisabled);
  }, [_checkedList, _downloading]);

  useEffect(() => {
    if (!video.isVideoPlayerOpen) {
      _setCheckedList([]);
    }
  }, [video.isVideoPlayerOpen]);

  useEffect(() => {
    const _groupedData = groupEventsOnMatchID(video.playlist);
    const _sortedData = sortEvents(_groupedData);
    _setSortedMatchesWithEvents(_sortedData);
  }, [video.playlist]);

  const _toggleSelectAll = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const {checked} = e.target;
      if (checked) {
        const _matchesWithVideo = video.matches.filter((match) => {
          const _sources = getAvailableSourcesForMatch(match.video);
          return _sources.length > 0;
        });
        _setCheckedList(
          video.playlist.filter((event) =>
            _matchesWithVideo.some((match) => match._id === event.match._id),
          ),
        );
      } else {
        _setCheckedList([]);
      }
    },
    [video.playlist],
  );

  const _toggleSelect = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, clips: FIMatchEvent[]) => {
      const {checked} = e.target;

      if (checked) _setCheckedList([...new Set([..._checkedList, ...clips])]);
      // use Set to remove duplicates
      else {
        const idsToRemove = clips.map((clip) => clip._id);
        _setCheckedList(
          _checkedList.filter((c) => !idsToRemove.includes(c._id)),
        );
      }
    },
    [_checkedList],
  );

  /* Handlers */
  async function _downloadSelected(): Promise<void> {
    try {
      _setDownloading(true);
      enqueueSnackbar(t('downloads.downloading.title'), {
        variant: 'info',
      });

      const _eventIds = _checkedList.map((e) => e._id);
      if (!_auth.user?.team) throw new Error('No team id or event id');
      await downloadVideos(
        _eventIds,
        _auth.user.team,
        video.title,
        video.videoSource || undefined,
      );

      _analyticsContext.trackEvent(ANALYTICS_EVENT.VIDEO_DOWNLOADED, {
        team_id: _auth.user?.team,
        event_ids: _eventIds,
      });

      enqueueSnackbar(t('downloads.complete.title'), {
        variant: 'success',
      });
      _setDownloading(false);
      _setCheckedList([]);
    } catch (e) {
      _setDownloading(false);
      enqueueSnackbar(t('downloads.error.title'), {
        variant: 'error',
      });
    }
  }

  return createProvider(context, props, {
    checkedList: _checkedList,
    setCheckedList: _setCheckedList,
    toggleSelectAll: _toggleSelectAll,
    toggleSelect: _toggleSelect,
    downloading: _downloading,
    downloadSelected: _downloadSelected,
    isDownloadingDisabled: _isDownloadingDisabled,
    matchesWithEvents: _sortedMatchesWithEvents,
  });
};
