import React from 'react';

import styles from '../overview-card.module.scss';

interface CardHeaderProps {
  title?: string | React.ReactNode;
  createdAt: Date;
}

export const CardHeader = ({title}: CardHeaderProps) => {
  let _title: string | React.ReactNode | null = null;
  if (typeof title === 'string') {
    _title = <h3 className={styles.title}>{title}</h3>;
  } else {
    _title = title;
  }
  return (
    <div>
      {_title}
      {/* <CardDateSubtitle createdAt={createdAt} /> */}
    </div>
  );
};
