import 'moment/locale/en-gb';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import en from './en/index';
import {defaultLocale} from './localization.config';

const _resources = {
  en,
};

i18n.use(initReactI18next).init({
  resources: _resources,
  lng: defaultLocale,
  keySeparator: '.',
});

export default i18n;
