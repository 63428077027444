import {TRANSLATIONS} from '@my-game-plan/types';

import DASHBOARDS_TRANSLATIONS from './dashboards.localization';
import ERRORS_TRANSLATIONS from './errors.localization';
import GENERAL_TRANSLATIONS from './general.localization';
import NAVIGATION_TRANSLATIONS from './navigation.localization';
import OVERVIEW_DASHBOARDS_TRANSLATIONS from './overview-dashboards.localization';
import SENTENCE_FORM_TRANSLATIONS from './sentence-form.localization';
import TOOLTIP_TRANSLATIONS from './tooltip.localization';
import SINGLE_SENDS_TRANSLATIONS from './single-sends.localization';
import EVENT_AUTOMATIONS_TRANSLATIONS from './event-automations.localization';
import PLAYER_POSITIONS_TRANSLATIONS from './player-positions.localization';
import {PREFERENCES_TRANSLATIONS} from '@/localization/en/preferences.localization';
import SHOT_ANALYSIS_TRANSLATIONS from './shot-analysis.localization';
import COMPETITIONS_TRANSLATIONS from './competitions.localization';
import PLAYER_TRANSLATIONS from './player.localisation';
import PHYSICAL_TRANSLATIONS from './physical.localization';
import TEAM_TRANSLATIONS from './team.localization';
import SCOUTING_TRANSLATIONS from './scouting.localization';
import UPLOAD_TRANSLATIONS from './uploads.localization';
import PLAYER_OBJECTIVES_TRANSLATIONS from './player-objectives.localization';
import MATCHES_TRANSLATIONS from './matches.localization';
import EVENT_DATA_TRANSLATIONS from './event-data.localization';
import PLAYER_PERFORMANCE_TRANSLATIONS from './player-performance.localization';

const en = {
  translation: {
    'error-states': ERRORS_TRANSLATIONS,
    general: GENERAL_TRANSLATIONS,
    navigation: NAVIGATION_TRANSLATIONS,
    'overview-dashboards': OVERVIEW_DASHBOARDS_TRANSLATIONS,
    dashboard: DASHBOARDS_TRANSLATIONS,
    event: TRANSLATIONS.en.event,
    zones: TRANSLATIONS.en.zones,
    singleSends: SINGLE_SENDS_TRANSLATIONS,
    sentenceForm: SENTENCE_FORM_TRANSLATIONS,
    eventAutomations: EVENT_AUTOMATIONS_TRANSLATIONS,
    preferences: PREFERENCES_TRANSLATIONS,
    shotAnalysis: SHOT_ANALYSIS_TRANSLATIONS,
    competitions: COMPETITIONS_TRANSLATIONS,
    players: PLAYER_TRANSLATIONS,
    physical: PHYSICAL_TRANSLATIONS,
    team: TEAM_TRANSLATIONS,
    scouting: SCOUTING_TRANSLATIONS,
    uploads: UPLOAD_TRANSLATIONS,
    playerObjectives: PLAYER_OBJECTIVES_TRANSLATIONS,
    matches: MATCHES_TRANSLATIONS,
    eventData: EVENT_DATA_TRANSLATIONS,
    playerPerformance: PLAYER_PERFORMANCE_TRANSLATIONS,
    /* TODO: Clean up copy and move to right files */
    'video-player': {
      'error-title': 'Server error',
      'error-body': 'There was an error loading video. Please try again.',
      wyscoutError:
        "We're currently experiencing issues with our video provider. Please try again later.",
      button: {
        play: 'Play videos',
        watch: 'Watch videos',
        download: {
          title: 'Select up to {{count}} videos to download',
        },
      },

      sidebar: {
        playlist: 'Playlist',
        open: 'Open playlist sidebar',
        close: 'Close playlist sidebar',
        selectAll: 'Select all',
      },
      tooltip: TOOLTIP_TRANSLATIONS,
      edit: {
        saveEditedClip: 'Save clip',
        success: {
          title: 'Clip has been saved',
        },
        error: {
          title: 'Something went wrong. Please try again later',
        },
      },
      share: 'Share playlist',
      source: {
        tactical: 'Tactical',
        broadcast: 'Broadcast',
      },
    },
    downloads: {
      downloading: {
        title: 'Downloading videos...',
        description:
          'Your videos are downloading, this may take a while. You are free to continue using the platform while you wait.',
      },
      complete: {
        title: 'Download complete!',
        description: 'Your clips have been downloaded to your device.',
      },
      error: {
        title: 'Error downloading videos',
        description:
          "There was an error downloading the videos. Please try again later. Also make sure you don't exceed the 25 videos limit.",
      },
    },
    login: {
      title: 'Login',
      otherAccount: 'Log in with another account',
      form: {
        name: 'Name',
        password: 'Password',
        button: 'Login',
      },
      /*error: {
        default: 'Something went wrong. Please try again later.',
        'auth-incorrect-credentials': 'Wrong credentials. Try again.',
        'not-analyst': 'You must be an analyst to login.',
        'user-not-active':
          'This account is not active. Please contact your team staff.',
        'team-not-active':
          'Looks like your team has not been activated yet. Please try again later.',
      },*/
    },
    privacy: {
      body: 'By continuing you agree to our',
      'privacy-policy': 'Privacy Policy',
      and: 'and',
      'terms-and-conditions': 'Terms and Conditions',
    },
    empty: {
      dashboard: {
        title: 'This dashboard needs tracking data.',
        description:
          'Unfortunately, your current package does not include tracking data. Contact our team to upgrade to a tracking data feed.',
        cta: 'Contact us',
      },
      'match-analysis': {
        title: "We're still finalizing Match Analysis dashboards.",
        description:
          "You'll receive a message from us when these are ready. If you have any questions, feel free to drop us a line.",
        cta: 'Contact us',
      },
    },
    playerPosition: PLAYER_POSITIONS_TRANSLATIONS,
    gamePosition: {
      winning: 'Winning',
      drawing: 'Drawing',
      losing: 'Losing',
    },
    'player-details': {
      approved: 'Shared with player',
      declined: 'Not shared with player',
    },
    settings: {
      open: 'Open settings',
      log_out: 'Log out',
      user_manual: 'User manual',
      preferences: 'Team preferences',
      switch_team: 'Switch team',
    },
  },
};

export default en;
