import React, {Fragment, useState} from 'react';

import {useTranslation} from 'react-i18next';
import {TFilterField} from '@/types/filter.types';

import styles from './Filter.module.scss';
import {
  getLabelLength,
  getStyledDropdownOption,
  getTagIcon,
} from './helper/Filter.helper';
import {useFilters} from '@/context/filters.context';

import Button from '@mui/material/Button';
import TuneIcon from '@mui/icons-material/Tune';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import Drawer from '@mui/material/Drawer';
import Checkbox from '@mui/material/Checkbox';

import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const Filter = () => {
  /*
   * Hooks n State
   */
  const {
    filters,
    updateFilters,
    clearFilters,
    possibleFilters,
    submitFilters,
    toggleFiltersSidebar,
    isFiltersSidebarOpened,
  } = useFilters();
  const {t} = useTranslation();
  const [_openedFilterField, _setOpenedFilterField] =
    useState<TFilterField | null>(null);

  /*
   * Handlers
   */

  function _onTriggerClick() {
    toggleFiltersSidebar();
  }

  function _onClose() {
    toggleFiltersSidebar();
  }

  function onClearClick() {
    clearFilters();
    _onClose();
  }

  function _onSubmit(event: React.MouseEvent<HTMLButtonElement>) {
    _onClose();
    submitFilters(event);
  }

  function _onFilterTriggerToggle(filter: TFilterField) {
    if (_openedFilterField === filter) {
      _setOpenedFilterField(null);
      return;
    }

    _setOpenedFilterField(filter);
  }

  function _onDrawerClose() {
    toggleFiltersSidebar();
  }

  function _onCheckboxToggle(filterKey: TFilterField, value: string) {
    updateFilters(filterKey, value);
  }

  /*
   * Render
   */

  const _Content = (
    <Drawer
      anchor="right"
      open={isFiltersSidebarOpened}
      onClose={_onDrawerClose}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 2,
      }}>
      <List
        sx={{flex: 1, minWidth: 320}}
        subheader={
          <ListSubheader>{t('dashboard.filters.title')}</ListSubheader>
        }>
        {Object.keys(possibleFilters).map((filterKey) => {
          const _key = filterKey as TFilterField;
          const filter = possibleFilters[_key];
          if (!filter) return null;

          const labelLength = getLabelLength(
            filters[_key].length,
            filter.options.length - 1,
          );

          const _isOpen = _openedFilterField === _key;
          let _color: 'secondary' | 'primary' | undefined = 'secondary';
          if (_isOpen) {
            _color = 'primary';
          } else if (filters[_key].length) {
            _color = undefined;
          }

          return (
            <Fragment key={_key}>
              {/* <ListItem> */}
              <ListItemButton
                key={`button_${_key}`}
                divider
                dense
                selected={_isOpen}
                onClick={() => _onFilterTriggerToggle(_key)}>
                <ListItemIcon>
                  {getTagIcon(_key, undefined, _color)}
                </ListItemIcon>
                <ListItemText
                  primary={filter.label}
                  primaryTypographyProps={{
                    fontWeight: '500',
                  }}
                  secondary={`(${labelLength})`}
                  secondaryTypographyProps={{
                    fontSize: 12,
                  }}
                />
                {_isOpen ? (
                  <ExpandLessIcon color="secondary" />
                ) : (
                  <ExpandMoreIcon color="secondary" />
                )}
              </ListItemButton>
              {/* </ListItem> */}
              <Collapse key={`collapse_${_key}`} in={_isOpen} unmountOnExit>
                <List
                  disablePadding
                  dense
                  sx={{
                    maxHeight: 320,
                    overflow: 'auto',
                    bgcolor: 'background.default',
                  }}>
                  {filter.options.map((option) => {
                    let _isSelected = false;
                    if (
                      (option.value === '*' && !filters[_key].length) ||
                      filters[_key].includes(option.value)
                    ) {
                      _isSelected = true;
                    }

                    return (
                      <ListItem key={`${_key}_${option.value}`} disablePadding>
                        <ListItemButton
                          sx={{pl: 4}}
                          onClick={() => _onCheckboxToggle(_key, option.value)}>
                          <ListItemIcon sx={{minWidth: 40}}>
                            <Checkbox edge="start" checked={_isSelected} />
                          </ListItemIcon>
                          {/* <ListItemText>{option.label}</ListItemText> */}
                          {getStyledDropdownOption(
                            _key,
                            option.value,
                            option.label,
                            option.sublabel,
                          )}
                        </ListItemButton>
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </Fragment>
          );
        })}
      </List>

      <div className={styles.buttons}>
        <Button onClick={_onSubmit} variant="contained">
          {t('dashboard.filters.apply')}
        </Button>
        <Button onClick={onClearClick} color="secondary" sx={{mt: 1}}>
          {t('dashboard.filters.clear')}
        </Button>
      </div>
    </Drawer>
  );

  return (
    <>
      <Button
        startIcon={<TuneIcon />}
        onClick={_onTriggerClick}
        variant="outlined"
        aria-selected={isFiltersSidebarOpened}>
        {t('dashboard.filters.filter')}
      </Button>

      {_Content}
    </>
  );
};
