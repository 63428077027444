import MatchVideosView from '@/views/match-videos.view';
import SingleSendsView from '@/views/single-sends.view';
import UploadsView from '@/views/uploads.view';
import React from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
function LibraryRoutes(): JSX.Element {
  return (
    <Routes>
      <Route path="" element={<Navigate to="file-uploads" />} />
      <Route path="file-uploads" element={<UploadsView />} />
      <Route path="match-videos" element={<MatchVideosView />} />
      <Route path="single-sends" element={<SingleSendsView />} />

      {/* Redirect */}
      <Route path="*" element={<Navigate to="file-uploads" />} />
    </Routes>
  );
}
export default LibraryRoutes;
