import React, {useEffect, useState} from 'react';

import Popover from '@mui/material/Popover';

import cn from 'classnames';
import {useTranslation} from 'react-i18next';

import {
  ICommonSentenceInputProps,
  TSentenceSize,
} from '@/types/sentence-form.types';

import DetailsDropdownContent from './details-dropdown-content.view';
import {
  generateTransferredPlayerOption,
  removeEmptyFilters,
} from '@/helpers/automation/automation-form.helper';
import Segment from '../..';

import dropdownStyles from '../dropdown.module.scss';
import {
  ACTION,
  ACTIONS_CONFIG,
  FIConditionalEventFilters,
  FIEventAutomationPostData,
} from '@my-game-plan/types';
import {translateValue} from '@/helpers/translation.helper';
import i18n from '@/localization';
import {useTeams} from '@/context/team.context';
import {ITypedOption} from '@/types/option.types';
import {useZones} from '@/context/zones.context';

interface IDetailsDropdownProps<DataType>
  extends ICommonSentenceInputProps<FIConditionalEventFilters, DataType> {
  isGameStateCondition?: boolean;
  action?: ACTION;
}
function DetailsDropdown<DataType = FIEventAutomationPostData>(
  props: IDetailsDropdownProps<DataType>,
): JSX.Element | null {
  /* Hooks n State */
  const [_anchorEl, _setAnchorEl] = useState<HTMLElement | null>(null);
  const [_hasOpenedNestedPopups, _setHasOpenedNestedPopups] =
    useState<boolean>(false);
  const [_hasTransferredPlayer, _setHasTransferredPlayer] =
    useState<boolean>(false);
  const [_transferredPlayers, _setTransferredPlayers] = useState<
    ITypedOption<string>[]
  >([]);
  const [_hasFilters, _setHasFilters] = useState<boolean>(false);
  const {t} = useTranslation();
  const _teamsContext = useTeams();
  const _pitchZonesContext = useZones();

  useEffect(() => {
    const _newHasFilters = props.value?.filters.some(
      (filter) => filter.values?.length,
    );
    _setHasFilters(!!_newHasFilters);
  }, [props.value]);

  // Check if receiving player is set, dellete references
  useEffect(() => {
    // if (props.value?.filters)
    // const
    // const _filtersWithReceivingPlayers = props.value?.filters.find(filter => filter.property === 'pass.receiving_player._id');
    const _tempTransferredPlayerOptions: ITypedOption<string>[] = [];
    props.value?.filters.forEach((filter) => {
      if (
        filter.property === 'pass.receiving_player._id' &&
        Array.isArray(filter.values)
      ) {
        const _doesFilterOnlyContainActivePlayers = (
          filter.values as string[]
        ).every((playerID) => {
          return _teamsContext.ownPlayers.some(
            (player) => player._id === playerID,
          );
        });

        _setHasTransferredPlayer(!_doesFilterOnlyContainActivePlayers);
      }
    });

    const _filtersWithReceivingPlayers = props.value?.filters.filter(
      (filter) => filter.property === 'pass.receiving_player._id',
    );
    if (_filtersWithReceivingPlayers?.length) {
      _filtersWithReceivingPlayers.forEach((filter) => {
        // filter.values
        // const _transferredPlayerIDs =
        (filter.values as string[]).forEach((value) => {
          const _matchingPlayer = _teamsContext.ownPlayers.find(
            (player) => player._id === value,
          );
          if (!_matchingPlayer) {
            _tempTransferredPlayerOptions.push(
              generateTransferredPlayerOption(value),
            );
          }
        });
      });

      _setTransferredPlayers(_tempTransferredPlayerOptions);
    }
  }, [props.value, _teamsContext.ownPlayers]);

  /* Handlers */
  function _onPopupTrigger(event: React.MouseEvent<HTMLButtonElement>) {
    _setAnchorEl(event.currentTarget);
  }

  function _onPopoverClose() {
    _setAnchorEl(null);
    if (props.onChange && props.value) {
      let _filtersToSave: FIConditionalEventFilters | undefined = props.value;
      if (!props.isGameStateCondition) {
        _filtersToSave = removeEmptyFilters(props.value);
      }

      const _property = props.property as keyof DataType;
      props.onChange({
        [_property]: _filtersToSave,
      } as Partial<DataType>);
    }
  }

  function _onNestedPopupToggle(hasOpenPopup: boolean) {
    _setHasOpenedNestedPopups(hasOpenPopup);
  }

  /* Render */
  const _isOpen = Boolean(_anchorEl);
  const _isDisabled =
    (!props.action && !props.isGameStateCondition) ||
    (!props.isGameStateCondition &&
      props.action &&
      !ACTIONS_CONFIG[props.action]?.possibleDetails);

  const _triggerItemClasses = cn(dropdownStyles.triggerItem, {
    [dropdownStyles.triggerItemOpened]: _isOpen,
    [dropdownStyles.triggerItemRequired]: !_hasFilters && props.required,
    [dropdownStyles.triggerItemWithValues]: _hasFilters,
    [dropdownStyles.triggerItemReadOnly]: props.readonly,
    [dropdownStyles.triggerItemError]: props.error,
  });

  let _translatedPlaceholder = t(props.placeholder || 'sentenceForm.all');
  if (props.required) _translatedPlaceholder += '*';
  let _TriggerValue = (
    <span
      className={cn(
        _triggerItemClasses,
        dropdownStyles[`triggerItem-${props.size}`],
      )}>
      {_translatedPlaceholder}
    </span>
  );

  if (_hasFilters) {
    let _valuesCount = 0;
    let _firstValue = '';
    const _textSize: TSentenceSize = props.readonly ? 'small' : props.size;
    const _filtersWithValues = props.value?.filters.filter(
      (filter) => filter.values && filter.values.length,
    );

    if (_filtersWithValues) {
      const _FilledInValue = _filtersWithValues.map((filter, filterIndex) => {
        const _FilterValues = filter.values?.map((value, valueIndex) => {
          if (value) {
            let _valuesLabel = '';

            _valuesCount += 1;

            if (filter.property === 'pass.receiving_player._id') {
              const _matchingPlayer = _teamsContext.ownPlayers.find(
                (player) => player._id === value,
              );
              if (_matchingPlayer) {
                _valuesLabel += _matchingPlayer.display_name;
              } else {
                _valuesLabel += t('playerPosition.transferredPlayer');
              }
            } else if (filter.property.includes('coordinates')) {
              const _zone = _pitchZonesContext.all.find(
                (zone) => zone._id === value,
              );
              if (_zone) {
                _valuesLabel += _zone.name;
              } else {
                _valuesLabel += t('sentenceForm.tracking.deletedZone');
              }
            } else {
              _valuesLabel += translateValue(value.toString(), filter.property);
            }

            if (!_firstValue) {
              const _prefixKey = `sentenceForm.details.valuePrefix.${filter.property}`;
              let _prefix = '';

              _firstValue = _valuesLabel;
              if (i18n.exists(_prefixKey)) {
                _prefix = `${t(_prefixKey)} `;
                _firstValue = `${_prefix}${_firstValue}`;
              }
            }
            if (
              filter.values &&
              filter.values.length > 1 &&
              valueIndex < filter.values.length - 2
            ) {
              _valuesLabel += ', ';
            } else if (
              filter.values &&
              filter.values.length > 1 &&
              valueIndex < filter.values.length - 1
            ) {
              _valuesLabel += ` ${t(`sentenceForm.${filter.operator}`)} `;
            }

            return _valuesLabel;
            // return (
            //   <span key={value as string}>
            //     <span className={_triggerItemClasses}>{t(_key)}</span>
            //   </span>
            // );
          }
        });

        let _FilterSeparator = null;
        if (
          _filtersWithValues.length > 1 &&
          filterIndex < _filtersWithValues.length - 2
        ) {
          // _FilterSeparator = <span>, </span>;
        } else if (
          _filtersWithValues.length > 1 &&
          filterIndex < _filtersWithValues.length - 1
        ) {
          _FilterSeparator = (
            <span
              className={cn(
                _triggerItemClasses,
                dropdownStyles.triggerItemPropertySeparator,
                dropdownStyles[`triggerItem-${_textSize}`],
                dropdownStyles[`triggerItemPropertySeparator-${_textSize}`],
              )}>
              {t(`sentenceForm.${props.value?.operator}`)}
            </span>
          );
        }

        const _prefixKey = `sentenceForm.details.valuePrefix.${filter.property}`;
        let _prefix = '';

        if (i18n.exists(_prefixKey)) {
          _prefix = `${t(_prefixKey)} `;
        }
        return (
          <span
            key={filter.property}
            className={cn(
              dropdownStyles.triggerItemProperty,
              dropdownStyles[`triggerItemProperty-${props.size}`],
            )}>
            <span
              className={cn(
                _triggerItemClasses,
                dropdownStyles[`triggerItem-${_textSize}`],
              )}>
              {_prefix}
              {_FilterValues}
            </span>
            {_FilterSeparator}
          </span>
        );
      });

      if (_valuesCount) {
        _TriggerValue = <span>{_FilledInValue}</span>;
        if (props.readonly) {
          return (
            <Segment.Text
              data={props.data}
              size={props.size}
              text={_firstValue}
              type="value"
              hiddenItemsCount={_valuesCount - 1}
              tooltipContent={_FilledInValue}
              color={_hasTransferredPlayer ? 'warning' : undefined}
            />
          );
        }
      }
    }
  }

  return (
    <>
      <button
        className={dropdownStyles.trigger}
        disabled={_isDisabled}
        onClick={_onPopupTrigger}>
        {_TriggerValue}
      </button>
      <Popover
        open={_isOpen}
        anchorOrigin={{horizontal: 'left', vertical: 'bottom'}}
        anchorEl={_anchorEl}
        slotProps={{
          paper: {sx: {bgcolor: 'background.default', boxShadow: 4}},
        }}
        onClose={_hasOpenedNestedPopups ? undefined : _onPopoverClose}>
        {props.action && (
          <DetailsDropdownContent
            {...props}
            action={props.action}
            onNestedPopupToggle={_onNestedPopupToggle}
            transferredPlayers={_transferredPlayers}
          />
        )}
      </Popover>
    </>
  );
}

export default DetailsDropdown;
