import React from 'react';

import {useTranslation} from 'react-i18next';
import {TagPreferences} from '@/components/preferences/TagPreferences';
import DataDisplayPreferences from '@/components/preferences/data-display/data-display-preferences.view';
import Screen from '@/components/screen/screen.view';
import {Navigate, Route, Routes} from 'react-router-dom';
import {PREFERENCES_ROUTES} from '@/config/navigation.config';

export function PreferencesView(): JSX.Element {
  const {t} = useTranslation();

  return (
    <Screen
      title={t('preferences.title')}
      coloredHeader
      tabs={PREFERENCES_ROUTES}>
      <Routes>
        <Route path="" element={<Navigate to="data-display" />} />
        <Route path="data-display" element={<DataDisplayPreferences />} />
        <Route path="tags" element={<TagPreferences />} />

        {/* Redirect */}
        <Route path="*" element={<Navigate to="data-display" />} />
      </Routes>
    </Screen>
  );
}
