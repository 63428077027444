const NAVIGATION_TRANSLATIONS = {
  // playlists: 'Video playlists',
  trackers: 'Performance trackers',
  'single-sends': 'Single sends',

  team: 'Team performance',
  idp: 'Player development',
  scouting: 'Scouting',
  'opponent-automations': 'Opponent analysis',
  shots: 'Shot analysis',
  shots_created: 'Created shots',
  shots_conceded: 'Conceded shots',
  dashboards: 'League analysis',
  preferences: 'Preferences',
  library: 'Library',
};
export default NAVIGATION_TRANSLATIONS;
