import React, {useEffect, useState} from 'react';

import Autocomplete, {
  AutocompleteProps,
  createFilterOptions,
  AutocompleteInputChangeReason,
  AutocompleteRenderOptionState,
  AutocompleteRenderGroupParams,
  AutocompleteValue,
} from '@mui/material/Autocomplete';

import TextField from '@mui/material/TextField';

import {ITypedOption} from '@/types/option.types';

import {inlineInputProps, inlineInputSX} from './autocomplete.styles';
import AutocompleteOption from './autocomplete-option/autocomplete-option.view';
import AutocompleteGroupHeader from './autocomplete-group/autocomplete-group.view';

import {TSentenceSize} from '@/types/sentence-form.types';

interface IAutocompleteProps<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
> extends Omit<
    AutocompleteProps<ITypedOption<T>, Multiple, DisableClearable, false>,
    'renderInput' | 'onChange' | 'size'
  > {
  onChange: (data: ITypedOption<T> | ITypedOption<T>[] | null) => void;
  groupHeader?: (text: string) => string;
  error?: string;
  isInlineInput?: boolean;
  multiple?: Multiple;
  label?: string;
  showDefaultColor?: boolean;
  tagsSentenceValue?: string;
  size?: TSentenceSize;
  readonly?: boolean;
  required?: boolean;
}

function CustomAutocomplete<
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
>(props: IAutocompleteProps<T, Multiple, DisableClearable>) {
  /*
   * Hooks n State
   */
  const [_inputSize, _setInputSize] = useState<number>(0);

  const {
    onChange,
    groupHeader,
    isInlineInput,
    showDefaultColor,
    tagsSentenceValue,
    size,
    readonly,
    ...autocompleteProps
  } = props;

  /*
   * Side effects
   */
  useEffect(() => {
    if (Array.isArray(props.value)) {
      /// Handle array
      if (props.tagsSentenceValue) {
        _inputValueChanged(props.tagsSentenceValue);
      } else if (!props.value?.length) {
        _inputValueChanged(props.placeholder);
      }
    } else {
      _inputValueChanged(props.value?.label || undefined);
    }
  }, [props.value, props.tagsSentenceValue]);

  /*
   * Handlers
   */
  function _onInputChange(
    event: React.SyntheticEvent,
    value: string,
    reason: AutocompleteInputChangeReason,
  ) {
    if (reason === 'clear') {
      onChange(null);
    }

    _inputValueChanged(value);
  }

  function _onChange(
    event: React.SyntheticEvent,
    value: AutocompleteValue<
      ITypedOption<T>,
      Multiple,
      DisableClearable,
      false
    >,
  ) {
    if (Array.isArray(value)) {
      // Handle multiselect
    } else {
      _inputValueChanged(value?.label);
    }
    onChange(value);
  }

  function _inputValueChanged(value?: string) {
    const _charactersCount = value?.length || props.placeholder?.length || 0;
    _setInputSize(_charactersCount + 1);
  }

  function _onHeaderCheckboxChange(checked: boolean, group: string) {
    const _value = props.value as ITypedOption<T>[] | null;

    let _newValue = _value?.filter((item) => item.group !== group);

    if (checked) {
      // Check all child checkboxes
      const _optionsToAdd = props.options.filter(
        (item) => item.group === group,
      );
      if (_newValue) {
        _newValue = [..._newValue, ..._optionsToAdd];
      }
    }
    // Uncheck all child checkboxes
    if (_newValue) {
      onChange(_newValue);
    }
  }

  function _renderOption(
    optionProps: React.HTMLAttributes<HTMLLIElement>,
    option: ITypedOption<T>,
    state: AutocompleteRenderOptionState,
  ) {
    return (
      <AutocompleteOption
        key={`${option.value}`}
        optionProps={optionProps}
        option={option}
        state={state}
        multiple={props.multiple}
      />
    );
  }

  function _renderGroup(params: AutocompleteRenderGroupParams) {
    if (!groupHeader) return null;

    const _groupOptions = autocompleteProps.options
      .filter((option) => option.group === params.group)
      .slice();
    const _value = autocompleteProps.value as ITypedOption<T>[];
    let _isSelected = false;
    if (props.multiple) {
      _isSelected = _groupOptions.every((option) =>
        _value?.some((selectedOption) => selectedOption.value === option.value),
      );
    }

    return (
      <AutocompleteGroupHeader
        key={params.group}
        params={params}
        groupHeader={groupHeader}
        multiple={props.multiple}
        selected={_isSelected}
        onCheckboxChange={_onHeaderCheckboxChange}
      />
    );
  }

  /*
   * Render
   */
  const _searchOptions = createFilterOptions<ITypedOption<T>>({
    matchFrom: 'any',
    stringify: (option) => option.name || '',
  });

  const _size = size === 'xsmall' ? 'small' : 'medium';

  // const _textFieldSizeProp: TSentenceSize =
  //   props.size === 'medium' ? 'medium' : 'large';

  return (
    <Autocomplete
      {...autocompleteProps}
      autoHighlight
      getOptionLabel={(option) => option.label}
      groupBy={(option) => option.group || ''}
      forcePopupIcon={!props.isInlineInput}
      onChange={_onChange}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      onInputChange={_onInputChange}
      renderInput={(textFieldProps) => {
        let _inputValue = textFieldProps.inputProps.value || '';
        if (
          Array.isArray(props.value) &&
          props.value.length &&
          tagsSentenceValue
        ) {
          _inputValue = tagsSentenceValue;
        }
        return (
          <TextField
            {...textFieldProps}
            label={props.label}
            placeholder={props.placeholder}
            error={!!props.error}
            color={isInlineInput || showDefaultColor ? undefined : 'secondary'}
            variant={isInlineInput ? 'standard' : 'outlined'}
            sx={isInlineInput ? inlineInputSX : undefined}
            // value="positions"
            inputProps={{
              ...(isInlineInput
                ? inlineInputProps(
                    textFieldProps.inputProps,
                    _inputSize,
                    props.size || 'medium',
                    props.required,
                  )
                : textFieldProps.inputProps),
              value: _inputValue,
            }}
          />
        );
      }}
      size={_size || 'small'}
      renderOption={_renderOption}
      renderGroup={groupHeader ? _renderGroup : undefined}
      renderTags={tagsSentenceValue ? () => null : undefined}
      limitTags={props.limitTags || 3}
      componentsProps={{
        popper: {
          style: {width: 'fit-content', minWidth: 120},
          placement: 'bottom-start',
          sx: {boxShadow: 4},
        },
        paper: {
          // style: {backgroundColor: 'red'},
          sx: {bgcolor: 'background.default'},
        },
        clearIndicator: {
          size: 'small',
        },
      }}
      filterOptions={_searchOptions}
    />
  );
}

export default CustomAutocomplete;
