import React, {
  Context,
  createContext,
  PropsWithChildren,
  ReactNode,
  useContext,
} from 'react';

export function createProvider<T, P = ReactNode>(
  context: Context<T>,
  props: PropsWithChildren<P>,
  value: T,
) {
  return <context.Provider value={value}>{props.children}</context.Provider>;
}

export function createCustomContext<T>() {
  return createContext<T>({} as T);
}

export function createContextHook<T>(context: Context<T>) {
  return () => useContext(context);
}
