import React from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import {
  FIShotAnalysis,
  ShotCategory,
  TShotAnalysisTeam,
} from '@my-game-plan/types';

import ShotAnalysisTableRow from './shot-analysis-table-row.view';
import {useTranslation} from 'react-i18next';

interface IShotAnalysisTableProps {
  data: FIShotAnalysis | null;
  type: TShotAnalysisTeam;
  onPlayVideo: (shotCategory?: ShotCategory) => void;
  onViewDetails: (shotCategory?: ShotCategory) => void;
}
function ShotAnalysisTable(props: IShotAnalysisTableProps): JSX.Element | null {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();

  /*
   * Handlers
   */

  /*
   * Render
   */
  if (!props.data) {
    return null;
  }

  return (
    <Card sx={{flex: 1, py: 1}}>
      <Table
        sx={{
          '& .MuiTableCell-root': {
            py: 1.5,
          },
          '& .MuiTableHead-root .MuiTableCell-root': {
            py: 1,
          },
        }}>
        <TableHead>
          <TableRow>
            <TableCell>{t('shotAnalysis.shotType')}</TableCell>
            <TableCell>{t('shotAnalysis.team')}</TableCell>
            <TableCell align="center">
              {t('shotAnalysis.result.shots', {count: 0})}
            </TableCell>
            <TableCell align="center">
              {t('shotAnalysis.result.goals', {count: 0})}
            </TableCell>
            <TableCell align="center">{t('shotAnalysis.goalRate')}</TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.shot_categories.map((category) => {
            if (!category.team) {
              return null;
            }

            return (
              <ShotAnalysisTableRow
                key={category.shot_category}
                teamScore={category.team}
                benchmarkScore={category.benchmark}
                shotCategory={category.shot_category}
                onViewDetails={props.onViewDetails}
                onPlayVideo={props.onPlayVideo}
                type={props.type}
              />
            );
          })}
          <ShotAnalysisTableRow
            teamScore={props.data.total.team}
            benchmarkScore={props.data.total.benchmark}
            onViewDetails={props.onViewDetails}
            onPlayVideo={props.onPlayVideo}
          />
        </TableBody>
      </Table>
    </Card>
  );
}

export default ShotAnalysisTable;
