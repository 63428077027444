import {IFilters} from '@/types/filter.types';
import {
  IOverviewDashboard,
  IOverviewDashboardIntial,
  IWidgetPlaceholder,
} from '@/types/dashboard/overview-dashboards.types';
import {useAPI} from '@/hooks/api.hooks';
import {Resource} from '@/types/api.types';
import {TEAMS_API_CONFIG, WIDGETS_API_CONFIG} from '@/config/api.config';
import {TDataProvider} from '@my-game-plan/types';
import {REQUEST_ERRORS} from '@/config/errors.config';

const api = useAPI();

export const getPlaceholderWidgets = async (
  dataProvider: TDataProvider,
): Promise<IWidgetPlaceholder[] | undefined> => {
  try {
    const {data} = await api.get<IWidgetPlaceholder[]>({
      resource: Resource.widgets,
      url: WIDGETS_API_CONFIG.getPlaceholderWidgets(dataProvider),
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch {
    return undefined;
  }
};

export const getOverviewDashboards = async (
  team_id: string,
): Promise<IOverviewDashboardIntial[] | undefined> => {
  try {
    const {data} = await api.get<IOverviewDashboardIntial[]>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getOverviewDashboards(team_id),
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch {
    return undefined;
  }
};

export const getOverviewDashboard = async (
  team_id: string,
  id: string,
  opponent_id: string | undefined,
  filters?: IFilters,
): Promise<IOverviewDashboard | undefined> => {
  try {
    const {data} = await api.get<IOverviewDashboard>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.getOverviewDashboard(team_id, id),
      params: filters,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch {
    return undefined;
  }
};

export const addOverviewDashboard = async (
  team_id: string,
  title: string,
  widgets: string[],
): Promise<IOverviewDashboard | undefined> => {
  const dashboard = {
    title: title,
    widgets: widgets,
  };

  try {
    const {data} = await api.post<IOverviewDashboard>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.addOverviewDashboard(team_id),
      data: dashboard,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch {
    return undefined;
  }
};

export const editOverviewDashboard = async (
  team_id: string,
  id: string,
  title: string,
  widgets: string[],
): Promise<IOverviewDashboard | undefined> => {
  const dashboard = {
    title: title,
    widgets: widgets,
  };

  try {
    const {data} = await api.put<IOverviewDashboard>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.editOverviewDashboard(team_id, id),
      data: dashboard,
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch {
    return undefined;
  }
};

export const deleteOverviewDashboard = async (
  team_id: string,
  id: string,
): Promise<IOverviewDashboard[] | undefined> => {
  try {
    const {data} = await api.del<IOverviewDashboard[]>({
      resource: Resource.teams,
      url: TEAMS_API_CONFIG.deleteOverviewDashboard(team_id, id),
    });

    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }

    return data;
  } catch {
    return undefined;
  }
};
