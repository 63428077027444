import {SHOT_ANALYSIS_API_CONFIG} from '@/config/api.config';
import {REQUEST_ERRORS} from '@/config/errors.config';
import {useAPI} from '@/hooks/api.hooks';
import {Resource} from '@/types/api.types';
import {
  FIMatchEvent,
  FIShotAnalysis,
  FIShotAnalysisInsight,
  TShotAnalysisTeam,
} from '@my-game-plan/types';

const api = useAPI();

export async function getShotAnalysis(
  teamID: string,
  type: TShotAnalysisTeam,
): Promise<FIShotAnalysis> {
  try {
    const {data} = await api.get<FIShotAnalysis>({
      resource: Resource.shot_analysis,
      url: SHOT_ANALYSIS_API_CONFIG.getShotAnalysis(teamID, type),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
}

export async function getShotAnalysisEvents(
  teamID: string,
  type: TShotAnalysisTeam,
): Promise<FIMatchEvent[]> {
  try {
    const {data} = await api.get<FIMatchEvent[]>({
      resource: Resource.shot_analysis,
      url: SHOT_ANALYSIS_API_CONFIG.getEvents(teamID, type),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
}

export async function getShotAnalysisInsights(
  teamID: string,
  type: TShotAnalysisTeam,
): Promise<FIShotAnalysisInsight[]> {
  try {
    const {data} = await api.get<FIShotAnalysisInsight[]>({
      resource: Resource.shot_analysis,
      url: SHOT_ANALYSIS_API_CONFIG.getInsights(teamID, type),
    });
    if (!data) {
      throw new Error(REQUEST_ERRORS.NO_DATA);
    }
    return data;
  } catch (error) {
    throw new Error((error as Error).message);
  }
}
