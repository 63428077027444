export const COLORS = {
  white: '#ffffff',
  black10: 'rgba(0,0,0,.1)',

  background: '#0e1d28',
  captions: '#4c6273',
  surface: '#162632',
  separator: '#192b39',
  hover: '#293b4b',
  accent: '#5ca58b',
  systemGreen: '#00875a',
  darkRed: '#313131',
  systemRed: '#ca2d2d',
};
