import React, {useState} from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import {darken, useTheme} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useConfirm} from 'material-ui-confirm';

import {useScouting} from '@/context/scouting.context';

function ScoutingHeaderActions(): JSX.Element | null {
  /*
   * Hooks n State
   */
  const _theme = useTheme();
  const {t} = useTranslation();
  const _confirm = useConfirm();
  const _scoutingContext = useScouting();
  const [_moreActionsAnchorEl, _setMoreActionsAnchorEl] =
    useState<null | HTMLElement>(null);

  /*
   * Handlers
   */
  function _onMoreActionsClick(event: React.MouseEvent<HTMLElement>) {
    _setMoreActionsAnchorEl(event.currentTarget);
  }

  function _onMoreActionsClose() {
    _setMoreActionsAnchorEl(null);
  }

  async function _onStopScouting() {
    _onMoreActionsClose();
    try {
      await _confirm({
        title: t('scouting.actions.clearSearches.title'),
        description: t('scouting.actions.clearSearches.description'),
        confirmationText: t('scouting.actions.clearSearches.cta'),
        confirmationButtonProps: {
          color: 'error',
          startIcon: <DeleteIcon />,
        },
      });
      await _scoutingContext.clearHistory();
    } catch (error) {
      // User cancelled
    }
  }

  const PAPER_BG = darken(_theme.palette.background.default, 0.2);
  return (
    <>
      <IconButton onClick={_onMoreActionsClick} color="secondary" size="small">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={_moreActionsAnchorEl}
        open={Boolean(_moreActionsAnchorEl)}
        onClose={_onMoreActionsClose}
        PaperProps={{
          sx: {
            bgcolor: PAPER_BG,
          },
        }}>
        <MenuItem onClick={_onStopScouting}>
          <ListItemIcon>
            <DeleteIcon color="error" />
          </ListItemIcon>
          <ListItemText
            // primaryTypographyProps={{color: 'text.secondary'}}
            primary={t('scouting.actions.clearSearches.title')}
          />
        </MenuItem>
      </Menu>
    </>
  );
}

export default ScoutingHeaderActions;
