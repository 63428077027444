import React, {useState} from 'react';
import {IPlayerStats} from '@/types/dashboard/dashboard-player-stats.types';
import {Card} from '../../common/card/Card';
import {PlayersTable} from './PlayersTable';
import PlayersStatsTabs from './PlayersStatsTabs';
import styles from './stylesheet/players-list.module.scss';
import {useTranslation} from 'react-i18next';
import {defaultTabIndex} from '@/config/general.config';
import {useDashboard} from '@/context/dashboard/dashboard.context';

interface PlayerStaticsProps {
  data: IPlayerStats;
  hasCard?: boolean;
  hasBorders?: boolean;
  isInteractive?: boolean;
}

const PlayerStatistics = ({
  data,
  hasCard = true,
  hasBorders = true,
  isInteractive,
}: PlayerStaticsProps) => {
  /* Hooks n State */
  const {t} = useTranslation();
  const [_selectedTabIndex, _setSelectedTabIndex] =
    useState<number>(defaultTabIndex);
  const _dashboard = useDashboard();

  /* Handlers */
  function _onTabChange(index: number) {
    _setSelectedTabIndex(index);
    _dashboard.setActivePlayerStatsTab(index);
  }

  /* Render */
  const _tabs = data.tabs.map((tab) => tab.label);
  const _currentTab = data.tabs[_selectedTabIndex];

  // Single tab
  const singleTab = () => {
    return (
      <>
        {_tabs.length > 1 && (
          <div className={styles.playerTabs}>
            <PlayersStatsTabs
              playerStatsTabs={_tabs}
              setSelectedTabIndex={_onTabChange}
              selectedTabIndex={_selectedTabIndex}
            />
          </div>
        )}
        <div className={styles.playerStatWrapper}>
          {_currentTab.tables.map((table, tableIndex) => (
            <PlayersTable
              key={table.key}
              tableKey={table.key}
              header={table.label}
              data={table.data}
              columns={table.columns}
              tablesCount={_currentTab.tables.length}
              hasBorders={hasBorders}
              isInteractive={isInteractive && tableIndex === 0}
            />
          ))}
        </div>
        <div className={styles.playerStatsEmpty}>
          <p>{t('dashboard.player-statistics.no-data')}</p>
        </div>
      </>
    );
  };

  if (_tabs.length < 1) {
    return hasCard ? <Card>{singleTab()}</Card> : <>{singleTab()}</>;
  }

  // Multiple tabs
  const multipleTabs = () => {
    return (
      <>
        {_tabs.length > 1 && (
          <div className={styles.playerTabs}>
            <PlayersStatsTabs
              playerStatsTabs={_tabs}
              setSelectedTabIndex={_onTabChange}
              selectedTabIndex={_selectedTabIndex}
            />
          </div>
        )}
        <div className={styles.playerStatWrapper}>
          {_currentTab.tables.map((table, tableIndex) => (
            <PlayersTable
              key={table.key}
              tableKey={table.key}
              header={table.label}
              data={table.data}
              columns={table.columns}
              tablesCount={_currentTab.tables.length}
              hasBorders={hasBorders}
              isInteractive={isInteractive && tableIndex === 0}
            />
          ))}
        </div>
      </>
    );
  };

  return hasCard ? <Card>{multipleTabs()}</Card> : <>{multipleTabs()}</>;
};

export default PlayerStatistics;
