import React, {useEffect} from 'react';

import {FIFormattedAutomation} from '@my-game-plan/types';

import {useOpponentAutomations} from '@/context/event-automations/opponent-automations.context';
import {useAuth} from '@/context/auth.context';
import {useTranslation} from 'react-i18next';
import OverviewScreen, {
  IOverviewScreenView,
} from '@/components/overview-screen/overview-screen.view';
import {useAnalytics} from '@/context/analytics.context';
import ANALYTICS_EVENT from '@/config/analytics/event-names.config';

function OpponentAutomationsView(): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _auth = useAuth();
  const _opponentAutomationsContext = useOpponentAutomations();
  const _analyticsContext = useAnalytics();

  useEffect(() => {
    if (_auth.user) {
      _analyticsContext.trackEvent(
        ANALYTICS_EVENT.VIEWED_OPPONENT_AUTOMATIONS,
        {
          team_id: _auth.user.team,
        },
      );
      _opponentAutomationsContext.getAll();
    }

    return () => {
      _opponentAutomationsContext.setIsInitialised(false);
    };
  }, [_auth.user]);

  /*
   * Handlers
   */

  /*
   * Render
   */
  const _tableView: IOverviewScreenView<FIFormattedAutomation> = {
    loadingState: _opponentAutomationsContext.loadingState,
    type: 'table',
    data: _opponentAutomationsContext.opponentAutomations,
    availableFilterOptions: {
      tags: [],
      actions: [],
      players: [],
    },
    getData: _opponentAutomationsContext.getAll,
  };
  return (
    <OverviewScreen
      title={t('eventAutomations.opponentAutomations', {count: 0})}
      automationType="opponent-automation"
      views={[_tableView]}
      filters={_opponentAutomationsContext.filters}
      onFiltersChange={_opponentAutomationsContext.setFilters}
      canSelectOpponents
    />
  );
}

export default OpponentAutomationsView;
