import React from 'react';

import {useTranslation} from 'react-i18next';
import cn from 'classnames';

import {
  ISentenceTextProps,
  TSentenceTextType,
} from '@/types/sentence-form.types';

import styles from './text.module.scss';
import Tooltip from '../../tooltip/tooltip.view';
import {FIEventAutomationPostData} from '@my-game-plan/types';

function TextSegment<DataType = FIEventAutomationPostData>(
  props: ISentenceTextProps<DataType>,
): JSX.Element {
  /* Hooks n State */
  const {t} = useTranslation();

  /* Handlers */

  /* Render */
  let _Text = null;
  const _type: TSentenceTextType = props.type || 'default';
  if (props.translationKey) {
    _Text = t(props.translationKey, props.translationKeyOptions);
  } else if (props.text) {
    _Text = props.text;
  }

  const _textClasses = cn(
    styles.text,
    styles[`text-${_type}`],
    styles[`text-${_type}`],
    styles[`text-${props.size}`],
    {
      [styles.textUppercase]: props.capitalize,
      [styles[`text-c-${props.color}`]]: props.color,
    },
  );

  return (
    <div className={styles.container}>
      <div className={_textClasses}>{_Text}</div>
      {props.tooltipContent && props.hiddenItemsCount ? (
        <Tooltip
          size={props.size}
          content={props.tooltipContent}
          count={props.hiddenItemsCount}
        />
      ) : null}
    </div>
  );
}

export default TextSegment;
