const PLAYER_OBJECTIVES_TRANSLATIONS = {
  objectives: 'Objectives',
  new: 'New objective',
  edit: 'Edit objective',
  view: 'View objective',
  delete: 'Delete objective',
  noObjectives: 'No objectives found for player',
  noObjectivesPlayer: 'No objectives have been shared with you yet.',

  fields: {
    title: 'Title',
    category: 'Category',
    comment: 'Comment',
    status: 'Status',
    description: 'Description',

    createdAtDate: 'Created on {{date}}',
  },

  status: {
    poor: 'Poor',
    needs_improvement: 'Needs improvement',
    fair: 'Fair',
    good: 'Good',
    great: 'Great',
  },

  category: {
    game: 'Game',
    general: 'General',
    individual: 'Individual',
    performance: 'Performance',
    psychological: 'Psychological',
  },

  form: {
    create: 'Create objective',
    save: 'Save objective',

    editComment: 'Edit comment',
    comments: 'Comments',
    info: 'Objective info',

    closeWarning: {
      text: 'Discard changes',
      title: 'Would you like to cancel and discard changes?',
      description: 'Unsaved changes might be lost.',
    },

    deleteWarning: {
      text: 'Delete objective',
      title: 'Are you sure you want to delete this objective?',
      description: "This can't be undone",
    },
  },

  comments: {
    count_other: '{{count}} comments',
    count_zero: 'No comments yet',
    count_one: '1 comment',
    lastComment: 'Last comment {{time}}',
    latestComment: 'Last comment',
    earlierComments: 'Earlier comments',
    noComments: 'Comments history will be displayed here',

    add: 'Add comment',
    new: 'New comment',
    edit: 'Edit comment',
    delete: 'Delete comment',
    create: 'Add comment',
    save: 'Save comment',

    error: {
      create: 'Something went wrong adding comment. Please try again later',
      edit: 'Something went wrong editing comment. Please try again later',
      delete: 'Something went wrong deleting comment. Please try again later',
    },

    success: {
      create: 'Comment has been added',
      edit: 'Comment has been edited',
      delete: 'Comment has been deleted',
    },

    deleteWarning: {
      text: 'Delete comment',
      title: 'Are you sure you want to delete this comment?',
      description: "This can't be undone",
    },
  },

  error: {
    create: 'Something went wrong creating objective. Please try again later',
    edit: 'Something went wrong editing objective. Please try again later',
    delete: 'Something went wrong deleting objective. Please try again later',
    fetch: 'Something went wrong fetching objectives. Please try again later',
  },

  success: {
    create: 'Objective has been created',
    edit: 'Objective has been edited',
    delete: 'Objective has been deleted',
  },
};

export default PLAYER_OBJECTIVES_TRANSLATIONS;
