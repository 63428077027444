import {
  IPlayerPositionMap,
  POSITIONS_MAP,
} from '@/config/player-positions.config';
import {FIPlayer} from '@my-game-plan/types';

export function getRandomPlayerFromPosition(
  positionMap: IPlayerPositionMap | undefined,
  players: FIPlayer[],
): FIPlayer | null {
  if (!positionMap) {
    // throw new Error('No position map found');
    return null;
  }
  const eligiblePlayers = players.filter((player) =>
    positionMap.positions.includes(player.position[0]),
  );
  if (eligiblePlayers.length === 0) {
    // throw new Error(
    //   `No players found for the given position map: ${positionMap.line}`,
    // );
    return null;
  }
  const randomIndex = Math.floor(Math.random() * eligiblePlayers.length);
  return eligiblePlayers[randomIndex];
}

export function getPositionMap(line: string): IPlayerPositionMap | undefined {
  return POSITIONS_MAP.find((positionMap) => positionMap.line === line);
}
