import React, {useEffect, useState} from 'react';
import {IAutomationsGroupPlayer} from '@/types/event-automation-groups.types';

import Avatar from '@mui/material/Avatar';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AutomationsTable from './automations-table/automations-table.view';
import {FIMatch, TEventAutomationType} from '@my-game-plan/types';
import Logo from '@/components/common/Logo/Logo';
import {useTranslation} from 'react-i18next';
import Indicator from '@/components/dashboard/Indicator/Indicator';
import {useTheme} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';

import {NAV_LEVEL_1_ROUTE} from '@/config/navigation.config';

interface IAutomationsGroupPlayerProps {
  player: IAutomationsGroupPlayer;
  automationType: TEventAutomationType;
  isTeam?: boolean;
  defaultExpanded?: boolean;
  matches: FIMatch[];
}

function AutomationsGroupPlayer(
  props: IAutomationsGroupPlayerProps,
): JSX.Element {
  /*
   * Hooks n State
   */
  const {t} = useTranslation();
  const _location = useLocation();
  const {breakpoints} = useTheme();
  const [_benchmarkLabelText, _setBenchmarkLabelText] = useState<string>('');
  const [_playerDeepDiveLink, _setPlayerDeepDiveLink] = useState<string>('');

  /*
   * Side effects
   */
  useEffect(() => {
    const _baseURL = `${NAV_LEVEL_1_ROUTE.PLAYERS}/${props.player._id}`;

    _setPlayerDeepDiveLink(`${_baseURL}${_location.search}`);
  }, [props.player, _location]);

  // Define benchmark label
  useEffect(() => {
    let _newBenchmarkLabel = t('eventAutomations.benchmark.leagueAverage');

    if (props.player.position?.length) {
      _newBenchmarkLabel = t('eventAutomations.benchmark.positionAverage', {
        position: props.player.position.join(', '),
      });
    }

    _setBenchmarkLabelText(_newBenchmarkLabel);
  }, [props.player]);

  /*
   * Handlers
   */

  /*
   * Render
   */

  // Automations count
  const _countLabel = t(
    `eventAutomations.${
      props.automationType === 'tracker' ? 'trackers' : 'automations'
    }Short`,
    {count: props.player.automations.length},
  );
  const _AutomationsCount = `${
    props.player.automations.length
  } ${_countLabel.toLocaleLowerCase()}`;

  // BenchmarkInfo
  const _benchmarkLabel = `${t(
    'eventAutomations.benchmark.benchmarkAgainst',
  )} ${_benchmarkLabelText}`;

  return (
    <Accordion
      TransitionProps={{unmountOnExit: true}}
      defaultExpanded={props.isTeam}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color="secondary" />}
        aria-controls={`${props.player._id}-content`}
        id={`${props.player._id}-header`}
        sx={{
          '& .MuiAccordionSummary-content': {
            my: 2,
          },
        }}>
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          flex={1}
          pr={3}
          justifyContent="space-between">
          {/* Team/Player info + # of automations */}
          <Stack direction="row" alignItems="center" gap={2}>
            {props.isTeam ? (
              <Logo size="large" src={props.player.image_url} />
            ) : (
              <Avatar
                src={props.player.image_url}
                sx={{bgcolor: 'secondary.main'}}></Avatar>
            )}
            <Stack gap={1}>
              <Typography lineHeight={1}>{props.player.name}</Typography>
              <Typography lineHeight={1} color="secondary">
                {_AutomationsCount}
              </Typography>
            </Stack>
            {!props.isTeam && props.automationType === 'tracker' && (
              <IconButton
                // onClick={_onPlayerClick}
                component={Link}
                to={_playerDeepDiveLink}>
                <InfoIcon color="secondary" />
              </IconButton>
            )}
          </Stack>

          {/* Score */}
          <Stack direction="row" alignItems="center" gap={2}>
            {typeof props.player.overall_benchmarked_score !== 'undefined' ? (
              <>
                <Typography
                  fontSize={12}
                  color="secondary"
                  sx={{[breakpoints.down('md')]: {display: 'none'}}}>
                  {_benchmarkLabel}
                </Typography>
                <Box sx={{width: 184}}>
                  <Indicator value={props.player.overall_benchmarked_score} />
                </Box>
              </>
            ) : (
              <Typography fontSize={12} fontStyle={'italic'} color="secondary">
                {t('eventAutomations.benchmark.notAvailable')}
              </Typography>
            )}
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails>
        <AutomationsTable
          data={
            props.isTeam
              ? undefined
              : {observing_players: {players: [props.player._id]}}
          }
          benchmarkLabel={_benchmarkLabelText}
          automations={props.player.automations}
          automationType={props.automationType}
          positions={props.player.position}
          matches={props.matches}
        />
      </AccordionDetails>
    </Accordion>
  );
}

export default AutomationsGroupPlayer;
