import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import React from 'react';
import styles from './TrackerDetailVideoIcons.module.scss';

interface TrackerDetailVideoIconsProps {
  totalEvents: string | number;
  compact?: boolean;
}

export const TrackerDetailVideoIconsTotalEvents = ({
  totalEvents,
  compact,
}: TrackerDetailVideoIconsProps) => {
  return (
    <div className={styles.icons}>
      <div>
        {!compact && <PlayArrowIcon color="secondary" />}
        <span>{totalEvents}</span>
      </div>
    </div>
  );
};
