const EVENT_DATA_TRANSLATIONS = {
  units: {
    occ_90: "/90'",
    occ_match: '/match',
  },
  unitsLong: {
    occ_90: 'per 90 minutes',
    occ_match: 'per match',
  },
};

export default EVENT_DATA_TRANSLATIONS;
