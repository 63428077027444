import React from 'react';
import {Navigate} from 'react-router-dom';
import LoginForm from '../components/login/LoginForm';
import {AUTH_STATE} from '@/types/user/user.types';
import {useAuth} from '@/context/auth.context';

function LoginRoute(): JSX.Element {
  const _auth = useAuth();

  if (_auth.authState === AUTH_STATE.LOGGED_IN) {
    return <Navigate to="/" replace />;
  }

  return <LoginForm />;
}

export default LoginRoute;
