import React, {PropsWithChildren, useMemo} from 'react';
import {getResponsiveStyle} from 'muuri-react';
import useMediaQuery from '../hooks/use-media-query.hooks';
import {
  createContextHook,
  createCustomContext,
  createProvider,
} from '@/helpers/general/context_generators.helper';

export interface DragAndDropAPI {
  width: string;
  paddingTop: string;
  height: string;
  borderWidth: string;
  margin: string;
}
const context = createCustomContext<DragAndDropAPI>();
export const useDragAndDrop = createContextHook(context);

export const DragAndDropProvider = (
  props: PropsWithChildren<React.ReactNode>,
): JSX.Element => {
  const isBigScreen = useMediaQuery('(min-width: 1400px)');

  // Memoize the style.
  const style = useMemo(() => {
    return getResponsiveStyle({
      columns: isBigScreen ? 1 / 3 : 1 / 2,
      margin: '16px',
      ratio: 1.5,
    });
  }, [isBigScreen]);

  return createProvider(context, props, style);
};
