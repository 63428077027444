import React, {useEffect, useState} from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';

import RestartAltIcon from '@mui/icons-material/RestartAlt';

import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';

import {useTranslation} from 'react-i18next';

import {
  DEFAULT_PLAYER_SEARCH_FILTERS,
  useScouting,
} from '@/context/scouting.context';
import {
  FIPlayerSearchFilters,
  MAX_AGE,
  MAX_HEIGHT,
  MAX_WEIGHT,
  MIN_AGE,
  MIN_HEIGHT,
  MIN_WEIGHT,
} from '@my-game-plan/types';
import SearchDropdown from './input/search-dropdown.view';
import SearchAsyncAutocomplete from './input/search-async-autocomplete.view';
import SearchRangeInput from './input/search-range-input.view';
import {LOADING_STATE} from '@/types/screen.types';
import {playerFiltersToURLParams} from '@/helpers/scouting.helper';
import {createSearchParams, useNavigate} from 'react-router-dom';

import {NAV_LEVEL_1_ROUTE} from '@/config/navigation.config';

interface ISearchDialogProps {
  open: boolean;
  onClose: () => void;
}

function SearchDialog(props: ISearchDialogProps): JSX.Element {
  /*
   * Hooks n State
   */
  const _scoutingContext = useScouting();
  const {t} = useTranslation();
  const _navigate = useNavigate();

  const [_selectedFilters, _setSelectedFilters] =
    useState<FIPlayerSearchFilters>({});
  const [_loadingState, _setLoadingState] = useState<LOADING_STATE>(
    LOADING_STATE.SUCCESS,
  );

  /* Clear on close */
  useEffect(() => {
    return () => {
      if (!props.open) {
        _setLoadingState(LOADING_STATE.SUCCESS);
      }
    };
  }, [props.open]);

  /* Set default selected filters */
  useEffect(() => {
    _setSelectedFilters(_scoutingContext.searchFilters);
  }, [_scoutingContext.searchFilters]);

  /*
   * Handlers
   */
  function _onUpdateFilter(data: Partial<FIPlayerSearchFilters>) {
    _setSelectedFilters({..._selectedFilters, ...data});
  }

  function _onRestoreDefaults() {
    _setSelectedFilters(DEFAULT_PLAYER_SEARCH_FILTERS);
  }

  async function _onSubmit() {
    props.onClose();

    const {page, ..._filtersToSend} = _selectedFilters;

    _navigate({
      pathname: `${NAV_LEVEL_1_ROUTE.SCOUTING}/search`,
      search: createSearchParams(
        playerFiltersToURLParams(_filtersToSend),
      ).toString(),
    });
  }

  function _onCancel() {
    props.onClose();

    _setSelectedFilters(_scoutingContext.searchFilters);
    _setLoadingState(LOADING_STATE.SUCCESS);
  }

  /*
   * Render
   */
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={props.open}
      onClose={_onCancel}
      scroll="body">
      <DialogTitle>{t('scouting.search')}</DialogTitle>
      <DialogContent>
        {/* CONTENT */}
        <Stack gap={5}>
          {/* CONTENT - Search  by name */}
          <SearchAsyncAutocomplete label={t('scouting.searchForm.name')} />

          <Stack direction="row" alignItems="center" gap={2}>
            <Divider sx={{flex: 1}} />
            <Typography
              color="text.disabled"
              textTransform="uppercase"
              fontSize={12}>
              {t('scouting.searchForm.or')}
            </Typography>
            <Divider sx={{flex: 1}} />
          </Stack>

          {/* CONTENT - Search by props */}
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <SearchDropdown
                label={t('players.info.position')}
                property="position"
                value={_selectedFilters.position}
                onChange={_onUpdateFilter}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SearchDropdown
                label={t('players.info.foot')}
                property="preferred_foot"
                value={_selectedFilters.preferred_foot}
                onChange={_onUpdateFilter}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SearchDropdown
                label={t('players.info.country')}
                property="country"
                value={_selectedFilters.country}
                onChange={_onUpdateFilter}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SearchDropdown
                label={t('players.info.competition')}
                property="competition_id"
                value={_selectedFilters.competition_id}
                onChange={_onUpdateFilter}
              />
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={12} sm={6} md={4}>
              <SearchRangeInput
                min={MIN_AGE}
                max={MAX_AGE}
                label={t('players.info.age')}
                property="age"
                value={_selectedFilters.age}
                onChange={_onUpdateFilter}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SearchRangeInput
                min={MIN_HEIGHT}
                max={MAX_HEIGHT}
                label={t('players.info.height')}
                property="height"
                value={_selectedFilters.height}
                onChange={_onUpdateFilter}
                unit={t('physical.units.cm')}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <SearchRangeInput
                min={MIN_WEIGHT}
                max={MAX_WEIGHT}
                label={t('players.info.weight')}
                property="weight"
                value={_selectedFilters.weight}
                onChange={_onUpdateFilter}
                unit={t('physical.units.kg')}
              />
            </Grid>
          </Grid>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Box flex={1}>
          <IconButton
            onClick={_onRestoreDefaults}
            title={t('scouting.searchForm.restoreDefaults')}
            color="secondary">
            <RestartAltIcon />
          </IconButton>
        </Box>
        <Button color="secondary" variant="text" onClick={_onCancel}>
          {t('general.cancel')}
        </Button>
        <LoadingButton
          color="primary"
          variant="contained"
          onClick={_onSubmit}
          loading={_loadingState === LOADING_STATE.LOADING}>
          {t('scouting.search')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default SearchDialog;
