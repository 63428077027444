import {FIShotAnalysis, FITeam} from '@my-game-plan/types';

export function formatShotAnalysisData(
  data: FIShotAnalysis,
  teams: FITeam[],
): FIShotAnalysis {
  const _formattedData: FIShotAnalysis = {
    shot_categories: data.shot_categories.map((category) => {
      return {
        ...category,
        ranking: category.ranking.map((rank) => {
          let _imageUrl = '';
          const _matchingTeam = teams.find((team) => team._id === rank._id);
          if (_matchingTeam) {
            _imageUrl = _matchingTeam.image_url;
          }
          return {
            ...rank,
            image_url: _imageUrl,
          };
        }),
      };
    }),

    total: {
      ...data.total,
      ranking: data.total.ranking.map((rank) => {
        let _imageUrl = '';
        const _matchingTeam = teams.find((team) => team._id === rank._id);
        if (_matchingTeam) {
          _imageUrl = _matchingTeam.image_url;
        }
        return {
          ...rank,
          image_url: _imageUrl,
        };
      }),
    },
  };

  return _formattedData;
}
